import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';


import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';
import Masonry from 'react-masonry-css';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';


const styles = theme => ({
    masonry:{
        width: "100%",
        display: "-webkit-box",
        display: '-ms-flexbox',
        display: "flex",
        paddingRight: 10
    },
    masonryColumn :{
        paddingLeft: 10,
        backgroundClip: "padding-box",
    },
    grow: {
        flexGrow: 1,
    },
});


class TransactionHistory extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if(this.props.userId !== undefined && this.props.userId !== ''){
            
            var db = this.props.firebase
            .purchases(this.props.userId)
            if(this.props.paymentSource !== undefined && this.props.paymentSource !== ''){
                db = db.where('paymentSource', '==', this.props.paymentSource)
            }
            this.unsubscribe = db
            .orderBy('createdAt', 'desc')
            .limit(50)
            .onSnapshot(snapshot => {
                let purchases = [];
                snapshot.forEach(doc => {
                    purchases.push(doc.data())
                    //console.log(doc.data())
                })
                if(this._isMounted){
                    this.setState({ purchases });
                }
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined){this.unsubscribe();}
        this.setState({ loading: false});
    }

    render() {
        //const { user } = this.props;
        const { theme, classes } = this.props;

        return (
            <div className={classes.grow}>
                {this.state.purchases!==undefined && this.state.purchases.length>0
                    &&
                        <>
                            <p style={{paddingLeft: 10}}>Recent Transactions:</p>
                            <Masonry
                                    breakpointCols={3}
                                    className={classes.masonry}
                                    columnClassName={classes.masonryColumn}
                                >
                            { this.state.purchases.map( (purchase, index) => (
                                <Card key={index} style={{padding: 20, marginBottom: 10, minWidth: 200}}>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.createdAt !== undefined
                                                ? ConvertTimestampToDateString(purchase.createdAt, true)
                                                : '-'
                                            }
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Date
                                        </span>
                                    </p>
                                    <hr/>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.title}
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Title
                                        </span>
                                    </p>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.description}
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Description
                                        </span>
                                    </p>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.state}
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            State
                                        </span>
                                    </p>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.quantity}
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Quantity
                                        </span>
                                    </p>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.price}
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Price
                                        </span>
                                    </p>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.expiryDate !== undefined && purchase.expiryDate !== ''
                                                ? ConvertTimestampToDateString(purchase.expiryDate, true)
                                                : '-'
                                            }
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Subscription expiry
                                        </span>
                                    </p> 
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.type}
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Transaction type
                                        </span>
                                    </p>
                                    <p style={theme.textSettings}>
                                        <span style={theme.textSettings.settingsTextValue}>
                                            {purchase.email}
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Email
                                        </span>
                                    </p>
                                    {purchase.receipt!==undefined && purchase.receipt.includes('luesnap') 
                                        ?
                                            <p style={theme.textSettings}>
                                                <span style={theme.textSettings.settingsTextValue}>
                                                    {
                                                        // <a href={purchase.receipt} target="_blank" rel="noopener noreferrer">Link</a>
                                                    }
                                                    <Button 
                                                        onClick={ () => window.open(purchase.receipt, "_blank")}>
                                                        Open receipt
                                                    </Button>
                                                </span>
                                                <span style={theme.textSettings.settingsTextDescription}>
                                                    Receipt
                                                </span>
                                            </p>
                                        :
                                            purchase.shopperAdminUrl!==undefined && purchase.shopperAdminUrl.includes('luesnap') 
                                            ?
                                                <p style={theme.textSettings}>
                                                    <span style={theme.textSettings.settingsTextValue}>
                                                        {
                                                            // <a href={purchase.receipt} target="_blank" rel="noopener noreferrer">Link</a>
                                                        }
                                                        <Button 
                                                            onClick={ () => window.open(purchase.shopperAdminUrl, "_blank")}>
                                                            Open receipt
                                                        </Button>
                                                    </span>
                                                    <span style={theme.textSettings.settingsTextDescription}>
                                                        Receipt
                                                    </span>
                                                </p>
                                            :
                                                ''
                                    }
                                </Card>
                            ))
                            }
                            </Masonry>
                        </>
                }
                {this.state.purchases!==undefined && this.state.purchases.length===0
                    &&
                        <p>No transactions</p>
                }
            </div>
        );
    }
}


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true }),
)(TransactionHistory);
