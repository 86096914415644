import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import DialogueHistory from '../Dialogues/DialogueHistory';
import MindfulnessScore from '../Charts/MindfulnessScore';
//import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';

import { compose } from 'recompose';
// MUI stuff
//import Button from '@material-ui/core/Button';
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SentimentVeryDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentVeryDissatisfiedTwoTone';
import SentimentDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentDissatisfiedTwoTone';
import SentimentSatisfiedTwoToneIcon from '@material-ui/icons/SentimentSatisfiedTwoTone';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';


import Slide from '@material-ui/core/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = {
    date: {
        textAlign: "center",
        fontSize: '0.8em',
        marginBottom: 0,
        paddingTop: 10,
        paddingBottom: 0,
    },
    dialogContent: {
        padding: 8,
        backgroundColor: '#fcd750',
    },
    notDone: {
        color: 'white',
        fontSize: '0.75em'
    },
    paper: {
        backgroundColor: 'milk',
        borderRadius: 0,
        marginLeft: 20,
        paddingBottom: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    priming: {
        background: 'linear-gradient(90deg, transparent 100%, rgba(192,233,252,1) 100%)',
        paddingRight: 10,
        textAlign: 'right'
    },
    priming1: {
        background: 'linear-gradient(90deg, transparent 80%, rgba(192,233,252,1) 80%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming2: {
        background: 'linear-gradient(90deg, transparent 60%, rgba(192,233,252,1) 60%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming3: {
        background: 'linear-gradient(90deg, transparent 40%, rgba(192,233,252,1) 40%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming4: {
        background: 'linear-gradient(90deg, transparent 20%, rgba(192,233,252,1) 20%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming5: {
        background: 'linear-gradient(90deg, transparent 0%, rgba(192,233,252,1) 0%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming1Unfinished: {
        background: 'linear-gradient(90deg, transparent 80%, lightgrey 80%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming2Unfinished: {
        background: 'linear-gradient(90deg, transparent 60%, lightgrey 60%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming3Unfinished: {
        background: 'linear-gradient(90deg, transparent 40%, lightgrey 40%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming4Unfinished: {
        background: 'linear-gradient(90deg, transparent 20%, lightgrey 20%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    priming5Unfinished: {
        background: 'linear-gradient(90deg, transparent 0%, lightgrey 0%)',
        paddingRight: 10,
        textAlign: 'right',
        cursor: 'pointer',
    },
    reflection: {
        background: 'linear-gradient(90deg, #7394C6 0%, transparent 0%)',
        paddingLeft: 10,
        textAlign: 'left'
    },
    reflection1: {
        background: 'linear-gradient(90deg, #7394C6 20%, transparent 20%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection2: {
        background: 'linear-gradient(90deg, #7394C6 40%, transparent 40%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection3: {
        background: 'linear-gradient(90deg, #7394C6 60%, transparent 60%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection4: {
        background: 'linear-gradient(90deg, #7394C6 80%, transparent 80%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection5: {
        background: 'linear-gradient(90deg, #7394C6 100%, transparent 100%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection1Unfinished: {
        background: 'linear-gradient(90deg, lightgrey 20%, transparent 20%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection2Unfinished: {
        background: 'linear-gradient(90deg, lightgrey 40%, transparent 40%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection3Unfinished: {
        background: 'linear-gradient(90deg, lightgrey 60%, transparent 60%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection4Unfinished: {
        background: 'linear-gradient(90deg, lightgrey 80%, transparent 80%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    reflection5Unfinished: {
        background: 'linear-gradient(90deg, lightgrey 100%, transparent 100%)',
        paddingLeft: 10,
        textAlign: 'left',
        cursor: 'pointer',
    },
    circle: {
        width: 5,
        height: 5,
        borderRadius: "50%",
        position: 'relative',
        backgroundColor: "#FCD730",
        bottom: 10,
        zIndex: 4,
        margin: 0,
    }
};

class DayItem extends Component {
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            dialogue: {}
        };
    }

    handleOpen = (dialogue) => () => {
        this.setState({
            dialogue: dialogue,
            open: true,
        });
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    }


    render() {
        const { theme } = this.props;
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        return (
            <div>
                <p style={styles.date}>
                    <strong> { weekdays[this.props.dayDate.getDay()] } </strong> { months[this.props.dayDate.getMonth()] + " "+ this.props.dayDate.getDate()} 
                </p>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ width: '100%'}}
                    >
                    {this.props.priming && this.props.priming !== null && this.props.priming !== undefined
                    ?
                        (() => {
                            switch (this.props.priming.measurement) {
                                case 1:
                                return (
                                    <Grid item xs={6} style={this.props.ended ? styles.priming1 : styles.priming1Unfinished} onClick={this.handleOpen(this.props.priming)}>
                                        {this.showEmoji(this.props.priming.measurement)}
                                        <div style={{...styles.circle, left: ""+ (- 20* this.props.averagePriming + 100) +"%" }}></div>
                                    </Grid>)
                                case 2:
                                return (
                                    <Grid item xs={6} style={this.props.ended ? styles.priming2 : styles.priming2Unfinished} onClick={this.handleOpen(this.props.priming)}>
                                        {this.showEmoji(this.props.priming.measurement)}
                                        <div style={{...styles.circle, left: ""+ (- 20* this.props.averagePriming + 100) +"%" }}></div>

                                    </Grid>)
                                case 3:
                                return (
                                    <Grid item xs={6} style={this.props.ended ? styles.priming3 : styles.priming3Unfinished} onClick={this.handleOpen(this.props.priming)}>
                                        {this.showEmoji(this.props.priming.measurement)}
                                        <div style={{...styles.circle, left: ""+ (- 20* this.props.averagePriming + 100) +"%" }}></div>
                                    </Grid>)
                                case 4:
                                return (
                                    <Grid item xs={6} style={this.props.ended ? styles.priming4 : styles.priming4Unfinished} onClick={this.handleOpen(this.props.priming)}>
                                        {this.showEmoji(this.props.priming.measurement)}
                                        <div style={{...styles.circle, left: ""+ (- 20* this.props.averagePriming + 100) +"%" }}></div>
                                    </Grid>)
                                case 5:
                                return (
                                    <Grid item xs={6} style={this.props.ended ? styles.priming5 : styles.priming5Unfinished} onClick={this.handleOpen(this.props.priming)}>
                                        {this.showEmoji(this.props.priming.measurement)}
                                        <div style={{...styles.circle, left: ""+ (- 20* this.props.averagePriming + 100) +"%" }}></div>
                                    </Grid>)
                                default:
                                return (
                                    <Grid item xs={6} style={styles.priming}>
                                        {this.showEmoji(this.props.priming.measurement)}
                                        <div style={{...styles.circle, left: ""+ (- 20* this.props.averagePriming + 100) +"%" }}></div>
                                    </Grid>)
                            }
                        })()
                    :
                        <Grid item xs={6} style={styles.priming}>
                                <span style={styles.notDone}>-</span>
                                        <div style={{...styles.circle, left: ""+ (- 20* this.props.averagePriming + 100) +"%" }}></div>
                        </Grid>
                    }
                    {this.props.reflection && this.props.reflection !== null && this.props.reflection !== undefined
                    ?
                        (() => {
                            switch (this.props.reflection.measurement) {
                            case 1:
                            return <Grid item xs={6} style={this.props.ended ? styles.reflection1 : styles.reflection1Unfinished} onClick={this.handleOpen(this.props.reflection)}>
                                        {this.showEmoji(this.props.reflection.measurement)}
                                        <div style={{...styles.circle, left: ""+ (20* this.props.averageReflection ) +"%" }}></div>
                                </Grid>;
                            case 2:
                            return <Grid item xs={6} style={this.props.ended ? styles.reflection2 : styles.reflection2Unfinished} onClick={this.handleOpen(this.props.reflection)}>
                                        {this.showEmoji(this.props.reflection.measurement)}
                                        <div style={{...styles.circle, left: ""+ (20* this.props.averageReflection ) +"%" }}></div>
                                </Grid>;
                            case 3:
                            return <Grid item xs={6} style={this.props.ended ? styles.reflection3 : styles.reflection3Unfinished} onClick={this.handleOpen(this.props.reflection)}>
                                        {this.showEmoji(this.props.reflection.measurement)}
                                        <div style={{...styles.circle, left: ""+ (20* this.props.averageReflection ) +"%" }}></div>
                                </Grid>;
                            case 4:
                            return <Grid item xs={6} style={this.props.ended ? styles.reflection4 : styles.reflection4Unfinished} onClick={this.handleOpen(this.props.reflection)}>
                                        {this.showEmoji(this.props.reflection.measurement)}
                                        <div style={{...styles.circle, left: ""+ (20* this.props.averageReflection ) +"%" }}></div>
                                </Grid>;
                            case 5:
                            return <Grid item xs={6} style={this.props.ended ? styles.reflection5 : styles.reflection5Unfinished} onClick={this.handleOpen(this.props.reflection)}>
                                        {this.showEmoji(this.props.reflection.measurement)}
                                        <div style={{...styles.circle, left: ""+ (20* this.props.averageReflection ) +"%" }}></div>
                                </Grid>;
                            default:
                            return <Grid item xs={6} style={styles.reflection}>
                                        {this.showEmoji(this.props.reflection.measurement)}
                                        <div style={{...styles.circle, left: ""+ (20* this.props.averageReflection ) +"%" }}></div>
                                </Grid>;
                            }
                        })()
                    :
                        <Grid item xs={6} style={styles.reflection}>
                                <span style={styles.notDone}>-</span>
                                <div style={{...styles.circle, left: ""+ (20* this.props.averageReflection ) +"%" }}></div>
                        </Grid>
                    }
                </Grid>
                <RemoveScroll enabled={this.state.open || false}>
                    <Dialog
                        open={this.state.open}
                        onClose={() => this.handleClose()}
                        fullScreen={IsMobileDevice()}
                        PaperProps={{style:theme.dialogWindow}}
                        TransitionComponent={Transition}
                        maxWidth="sm"
                        >
                        <DialogTitle style={theme.dialogTitle} >
                            <Grid container spacing={0} direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton onClick={() => this.handleClose()}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        Chat History
                                    </span>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleLink} onClick={() => this.handleClose()}>
                                        Close
                                    </span>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={theme.dialogContent}>
                            {this.props.dayDate!== undefined 
                                && 
                                <h4 style={{color: theme.palette.themeText}}> 
                                    <strong> { weekdays[this.props.dayDate.getDay()] } </strong> 
                                    { months[this.props.dayDate.getMonth()] + " "+ this.props.dayDate.getDate() + " "
                                        //+ this.props.dayDate.getFullYear()
                                    } 
                                </h4>
                            }
                            <Grid container spacing={0} direction='column'>
                                <Grid item >
                                    <MindfulnessScore dialogue={this.state.dialogue}  />
                                    <hr/>
                                </Grid>
                                {(this.props.isCoach && this.props.user?.coachAccess !== true && !(this.context?.roles?.includes("ADMIN")))
                                    ?
                                        <Grid item>
                                            <p style={{ fontSize: 16, textAlign: 'center', color:theme.palette.themeRed}}>You can ask the user to grant you access to their full chats (User Profile Section).</p>
                                        </Grid>
                                    :
                                        <Grid item >
                                            <p style={theme.textSupportCenter}>Rate Questions</p>
                                            <DialogueHistory dialogue={this.state.dialogue} style={{height:'100vh'}} isCoach={this.props.isCoach}/>
                                        </Grid>
                                }
                                {this.props.isCoach &&
                                    <Grid item >
                                        <hr/>
                                        <h4>Recommended Articles</h4>
                                        {this.state.dialogue !== undefined && this.state.dialogue.contentSelection !== undefined
                                            ?
                                                this.state.dialogue.contentSelection.map( (item, index) => (
                                                    <li key={index}>
                                                        {item.title}
                                                        &nbsp; - {item.leadership_category} 
                                                    </li>
                                                ))
                                            : "n/a"
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </RemoveScroll>  
            </div>
        );
    }

    showEmoji(number){
        const { theme } = this.props;
        if(number === 5){
            return(
                < EmojiEmotionsIcon style={{color: theme.palette.themeAlertGreen, fontSize:'medium', position:'relative', bottom: -5}}/>
            )
        }
        else if(number === 4){
            return(
                < SentimentSatisfiedTwoToneIcon style={{color: theme.palette.themeAltGold, fontSize:'medium', position:'relative', bottom: -5}}/>
            )
        }
        else if(number === 3){
            return(
                < SentimentSatisfiedIcon style={{color: theme.palette.white, fontSize:'medium', position:'relative', bottom: -5}}/>
            )
        }
        else if(number === 2){
            return(
                < SentimentDissatisfiedTwoToneIcon style={{color: theme.palette.themeAlertRed, fontSize:'medium', position:'relative', bottom: -5}}/>
            )
        }
        else if(number === 1){
            return(
                < SentimentVeryDissatisfiedTwoToneIcon style={{color: theme.palette.themeAlertRed, fontSize:'medium', position:'relative', bottom: -5}}/>
            )
        }
        else{
            return ("" + number)
        }
    }
}

DayItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles, { withTheme: true })
)(DayItem);
