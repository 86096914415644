import React, { Component } from "react";
//import { withRouter } from "react-router-dom";

import {  withAuthorization,} from '../Session';
import { AuthUserContext } from '../Session';
import { compose } from "recompose";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
//import PropTypes from "prop-types";
// auto push after reload with social

//import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

//stepper and views
import MobileStepper from '@material-ui/core/MobileStepper';
import { autoPlay } from 'react-swipeable-views-utils';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';

import LoadingProgress from '../Modules/LoadingProgress';

import backgroundImageWelcome from "../../images/login-background.jpg";
import logoAnimated from "../../images/rocky-logo-animated.gif";
import logoCaption from "../../images/rockyai-logo-caption.png";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
//import VpnKeyIcon from '@material-ui/icons/VpnKey';

import {StoreMobileDeviceInfo, StoreMobileDeviceToken } from '../Modules/StoreMobileDeviceInfo';
import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    root: {
        backgroundImage: `url(${backgroundImageWelcome})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        height: '100vh',
        //overflow: 'hidden',
        overflowY: 'auto',// for scroll top
        paddingLeft:0,
        paddingRight:0,
        //flexGrow: 1,
    },
    rootMobile: {
        overflowY: 'auto',// for scroll top
        minHeight: "100vh",
        height: '100vh',
        //flexGrow: 1,
    },
});


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class WelcomePage extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        this.state = {
            activeStep : 0,
            username: ''
        };
        //this.state = { };
    }

    componentDidMount() {
        this._isMounted = true;
        const { theme } = this.props;
        //console.log('component mounted', this.props,  this.context)
        var username = this.context.username!==undefined ? this.context.username.split(" ")[0] : "Padawan";
        this.setState({username});
        this.loadWelcomeData();
        StoreMobileDeviceInfo(this.props.firebase.user(this.context.uid));
        StoreMobileDeviceToken(this.props.firebase.user(this.context.uid));
        this.internetConnectionCheck();
        //console.log('origin props', this.context)
        if(this._isMounted){
            if(window.StatusBar !== undefined && this.state.firebaseConnectionError !== true){
                window.StatusBar.overlaysWebView(false);
                window.StatusBar.backgroundColorByHexString(theme.palette.themeAltLoading);
                window.StatusBar.styleLightContent();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    internetConnectionCheck() {
        this.props.firebase.onDisconnectSimple(this, (connected) => {
            if(connected === true && (this.state.welcomePages === undefined || this.state.welcomePages === null || this.state.welcomePages.length < 1) && this._isMounted){
                this.loadWelcomeData();
                StoreMobileDeviceInfo(this.props.firebase.user(this.context.uid));
                StoreMobileDeviceToken(this.props.firebase.user(this.context.uid));
            }
        });
    }
        
    loadWelcomeData() {
        var welcomePages = [];
        this.unsubscribe = this.props.firebase
        .onboardingContents()
        .where('category', '==', 'welcomePages')
        .where('active', '==', true)
        .orderBy('page', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                welcomePages.push({ text: "Regularly reflect and being mindful", gid:"default" });
                return;
            }
            snapshot.forEach(doc => {
                welcomePages.push({ ...doc.data(), pid: doc.id });
            });
            if(this._isMounted){
                this.setState({ 
                    welcomePages: welcomePages 
                });
            }
            //console.log('pages', this.state.welcomePages)
        });
    }    

    render() {
        const { classes, theme } = this.props;
        return (
            <div 
                className={IsMobileDevice() ? classes.rootMobile : classes.root}
                ref={this.dialogRef}
            >
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                >
                    <div style={IsMobileDevice() ? theme.WelcomeMobile : theme.WelcomeDesktop}>
                        <Grid item xs={12} >
                        {(this.state.welcomePages === undefined || this.state.firebaseConnectionError===true)
                        ?
                            <div style={{paddingTop:50}}>
                                <Link to={ROUTES.HOME}>
                                    <div style={theme.logoContainer}>
                                    <img src={logoAnimated} style={theme.logo} alt="Rocky.ai Logo Chatbot Journaling" />
                                    <img
                                        src={logoCaption}
                                        style={theme.logoCaption}
                                        alt="Rocky.ai Logo Leadership Coach"
                                    />
                                    </div>
                                </Link>
                                <hr />
                            </div>
                            :
                            ''
                        }
                        </Grid>
                        
                        <Grid  item xs={12} style={{height: "100%"}}> 
                            {(this.state.welcomePages !== undefined && this.state.firebaseConnectionError!==true)
                            ?
                                <>
                                    <Card style={IsMobileDevice()
                                        ? theme.CardTopCompactMobileNavi 
                                        : {...theme.CardTop, borderRadius: theme.borderRadius} }> 
                                        <div>
                                            {this.state.welcomePages[this.state.activeStep].h1 !== undefined &&
                                                <h1 style={theme.textCenterPadding}>
                                                    {this.state.welcomePages[this.state.activeStep].h1.replace('{{username}}', this.state.username)}
                                                </h1>
                                            }
                                            {this.state.welcomePages[this.state.activeStep].h2 !== undefined &&
                                                <h2 style={theme.textCenterPadding}>
                                                    {this.state.welcomePages[this.state.activeStep].h2.replace('{{username}}', this.state.username)}
                                                </h2>
                                            }
                                        </div>
                                        {!IsMobileDevice() &&
                                            <div style={theme.MobileStepperDesktop}>
                                                <MobileStepper
                                                        variant="dots"
                                                        steps={this.state.welcomePages.length}
                                                        position="static"
                                                        activeStep={this.state.activeStep}
                                                        style={{
                                                            backgroundColor: theme.palette.secondary.light,
                                                            borderRadius: IsMobileDevice()? '12px 12px 0 0' : '12px',
                                                        }}
                                                        nextButton={
                                                            this.state.activeStep === this.state.welcomePages.length - 1 
                                                            ?
                                                                <Link to={ROUTES.ONBOARDING}>
                                                                    <Button variant="contained">
                                                                        Next
                                                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                                                    </Button>
                                                                </Link>
                                                            :
                                                                <Button variant="contained" onClick={this.handleNext}>
                                                                    Next
                                                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                                                </Button>
                                                        }
                                                        backButton={
                                                            <Button onClick={this.handleBack} variant="outlined" disabled={this.state.activeStep === 0}>
                                                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                                                Back
                                                            </Button>
                                                        }
                                                    />
                                            </div>
                                        }
                                    </Card>
                                    {IsMobileDevice() &&
                                        <div style={{paddingTop: theme.CardTopCompactMobileNavi.paddingTop, height: "16vh"}}>
                                            &nbsp;
                                        </div>
                                    }
                                    {IsMobileDevice() &&
                                        <div style={theme.MobileStepperAppBarMobile}>
                                            <MobileStepper
                                                    variant="dots"
                                                    steps={this.state.welcomePages.length}
                                                    position="static"
                                                    activeStep={this.state.activeStep}
                                                    style={{
                                                        backgroundColor: theme.palette.secondary.light,
                                                        borderRadius: IsMobileDevice()? '12px 12px 0 0' : '0 0 12px 12px',
                                                    }}
                                                    nextButton={
                                                        this.state.activeStep === this.state.welcomePages.length - 1 
                                                        ?
                                                            <Link to={ROUTES.ONBOARDING}>
                                                                <Button variant="contained">
                                                                    Next
                                                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                                                </Button>
                                                            </Link>
                                                        :
                                                            <Button variant="contained" onClick={this.handleNext}>
                                                                Next
                                                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                                            </Button>
                                                    }
                                                    backButton={
                                                        <Button onClick={this.handleBack} variant="outlined" disabled={this.state.activeStep === 0}>
                                                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                                            Back
                                                        </Button>
                                                    }
                                                />
                                        </div>
                                    }
                                    <SwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={this.state.activeStep}
                                        onChangeIndex={this.handleStepChange}
                                        enableMouseEvents
                                    >
                                    {this.state.welcomePages.map((step, index) => (
                                        <div key={step.page}>
                                        {Math.abs(this.state.activeStep - index) <= 2 
                                        ? (
                                                <div key={step.page}
                                                        style={{
                                                            overflow:'auto', 
                                                            marginBottom: IsMobileDevice()? '12vh': 40, 
                                                            //marginTop:IsMobileDevice()?'12vh':0
                                                        }}
                                                        >
                                                    {step.h3 !== undefined &&
                                                        <h2 style={{...theme.textSupportCenterPadding, margin:10}}>
                                                            <strong>{step.h3.replace('{{username}}', this.state.username)}</strong>
                                                        </h2>
                                                    }
                                                    {step.h4 !== undefined &&
                                                        <h4 style={{...theme.textCenterPadding, marginTop:10}}>
                                                            {step.h4.replace('{{username}}', this.state.username)}
                                                        </h4>
                                                    }
                                                    {step.imageMain !== undefined && step.imageMain !== '' 
                                                        ? 
                                                        <div style={{
                                                            backgroundImage: `url(${step.imageMain})`,
                                                            backgroundPosition: "center",
                                                            backgroundSize: "cover",
                                                            backgroundRepeat: "no-repeat",
                                                            minHeight: "30vh"
                                                            }} key={index}> 
                                                        </div>
                                                        : step.images !== undefined && step.images !== ''
                                                        ?
                                                            <AutoPlaySwipeableViews >
                                                            {
                                                            step.images.map((image, index) => (
                                                                <div style={{
                                                                    backgroundImage: `url(${image})`,
                                                                    backgroundPosition: "center",
                                                                    backgroundSize: "cover",
                                                                    backgroundRepeat: "no-repeat",
                                                                    minHeight: "30vh"
                                                                }} key={index}> 
                                                                </div>
                                                            )) 
                                                            }
                                                            </AutoPlaySwipeableViews>
                                                        : ''
                                                    }
                                                    {step.h5 !== undefined &&
                                                        <h5 style={{...theme.textSupportCenterPadding, marginBottom: 10}}>
                                                            {step.h5.replace('{{username}}', this.state.username)}
                                                        </h5>
                                                    }
                                                    {step.h6 !== undefined &&
                                                        <h6 style={{...theme.textCenterPadding, marginTop: 10}}>
                                                            {step.h6.replace('{{username}}', this.state.username)}
                                                        </h6>
                                                    }
                                                    {step.buttonLandingPage !== undefined && step.buttonLandingPage !== '' &&
                                                        <div style={theme.divCenter}> 
                                                            {/****
                                                             <Link to={this.context.onboardingDone?ROUTES.ROUTINE:ROUTES[step.buttonLandingPage]}>
                                                            */}
                                                            <Link to={ROUTES[step.buttonLandingPage]}>
                                                                <Button  >
                                                                {step.button}
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    }
                                                </div>
                                        ) 
                                            : ''}
                                        </div>
                                    ))}
                                    </SwipeableViews>
                                </>
                            :
                                <div style={theme.loadingCenterPadding}>
                                    <LoadingProgress 
                                        defaultSet={this.props.defaultSet}
                                        type={'CircularProgress'} loadingText={"loading"} />
                                </div>
                            }

                            { ((!window.navigator.onLine  ) || this.state.firebaseConnectionError===true )
                                &&
                                    <div >
                                        <p style={this.props.theme.textErrorCenterPadding}>
                                            &nbsp; Trying to connect with the internet!
                                        </p> 
                                    </div>
                            }

                        </Grid>
                        {
                        IsMobileDevice()
                        &&
                            <Grid item xs={12}> <div style={{minHeight:50}}>&nbsp;</div> </Grid>
                        }
                    </div>
                </Grid>
            </div>
        );
    }

    handleStepChange = step => {
        this.setState({activeStep : step}, 
            () => this.handleScrollTop());
    };
    handleScrollTop = () => {
        if(this.dialogRef !== undefined) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
    }
    handleNext = () => {
        this.setState({activeStep : this.state.activeStep + 1}, 
            () => this.handleScrollTop());
    };
    handleBack = () => {
        this.setState({activeStep : this.state.activeStep - 1}, 
            () => this.handleScrollTop());
    };
}


const condition = authUser => !!authUser;

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
    withAuthorization(condition),
)(WelcomePage);