import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import LoadingProgress from '../Modules/LoadingProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogSettingPlan from '../Profile/DialogSettingPlan';

/// PREMIUM SUBSCRIPTION CHECK
import {CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';


import CardBigFive from '../Assessments/CardBigFive';
import Button from '@material-ui/core/Button';

import { createGradientImage } from '../Modules/createGradientImage';
import {Textfit} from 'react-textfit';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';


import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

//slider
import Slider from '@material-ui/core/Slider';
const StyledSlider = withStyles({
    /***
    root: {
        color: '#fcd750',
        height: 9,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#2d2c4c',
        border: '3px solid #2d2c4c',
        marginTop: -6,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    mark:{
        backgroundColor: 'red',
        height: '20%',
    },
    active: {},
    track: {
        backgroundColor: '#fcd750',
        opacity: 0.38,
        height: 9,
        borderRadius: 10,
    },
    rail: {
        height: 9,
        borderRadius: 10,
    } */
})(Slider);

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});


class AssessmentPage extends Component {  
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var questionCategories = [];
        var shuffledQuestionOrder = ['O', 'C', 'E', 'A', 'N'];
        shuffledQuestionOrder.sort(function(){
            return 0.5 - Math.random();
        })
        //console.log(shuffledQuestionOrder);
        this.state = {
            questionCategories,
            questionCategoriesReduce: questionCategories,
            currentCategory: this.props.user.currentCategory,
            currentLeadershipQuality: this.props.user.currentLeadershipQuality,
            currentPersonalityTrait: {},
            currentAssessmentItemCounter: {},
            //currentPersonalityTrait: {O: 50, C: 50, E: 50, A: 50, N: 50},
            shuffledQuestionOrder,
            nextQuestion: 'interest',
            questionType: 'interest',
            categories : [],
            loading: true,
            questionsAnswered : 0,
            started: false,
            eggupUserId: 0, ////// add harry default id
            selectionItem: undefined
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if(this.props.questionnaireDetails && this.props.questionnaireDetails.premium === true){
            this.checkNonSubscriber();
        }
        this.loadAssessmentSettings();
        this.initiateAssessment();
        this.setState({loading: false});
        this.loadPreviousAssessment();
    }

    componentWillUnmount(){
        this._isMounted = false;
        if(this.loadPersonalityTrait !== undefined) this.loadPersonalityTrait();
        if(this.getEggupResults !== undefined) this.getEggupResults();
    }

    checkNonSubscriber(){
        var nonSubscriber = true;
        var premiumSubscriber =  false;
        //console.log('opening it')
        if(this.state.nonSubscriber === false && this.state.premiumSubscriber === true){
            nonSubscriber = false;
            premiumSubscriber = true;
            //console.log('already checkedx premium subscription')
            if(this._isMounted){
                //console.log('subscription', 'add non sub ceiber')
                this.setState({nonSubscriber, premiumSubscriber});
            }
            return nonSubscriber;
        }
        else{
            let subscriptionStatus = CheckSubscriptionStatus(this.props.user);
            //console.log('subscription check', subscriptionStatus, this.props.user)
            if(subscriptionStatus.nonSubscriber !== undefined && subscriptionStatus.premiumSubscriber !== undefined){
                nonSubscriber = subscriptionStatus.nonSubscriber;
                premiumSubscriber = subscriptionStatus.premiumSubscriber;
            }
            if(this._isMounted){
                //console.log('subscription', 'add non sub ceiber', nonSubscriber, premiumSubscriber)
                this.setState({nonSubscriber, premiumSubscriber});
            }
            return nonSubscriber;
        }
    }

    startAssessment() {
        this.initiateAssessment();
        this.setState({started: true, loading: true});
        this.loadAssessment();
    }

    initiateAssessment(){
        var currentPersonalityTrait = {};
        //console.log('load new', this.props, this.state)
        if(this.props.questionnaireDetails?.category === 'OCEAN'){
            currentPersonalityTrait = {O: 50, C: 50, E: 50, A: 50, N: 50}
        }
        else if(this.props.questionnaireDetails?.category === 'PERMA-V'){
            currentPersonalityTrait = {pP: 0, pE: 0, pR: 0, pM: 0, pA: 0, pV: 0};
        }
        else{
            currentPersonalityTrait = {}
        }
        this.setState(
            {currentPersonalityTrait, currentAssessmentItemCounter: {}},
            () => this.updateQuestionPair(currentPersonalityTrait, true)
        );
        
    }

    loadAssessment() {
        if(this._isMounted){
            this.setState({
                loading: true,
            });
        }
        var today = new Date();
        today = today.toISOString().substring(0, 10);
        today = today.replace(/-/g, '');
        var assessmentId = this.props.assessmentId;
        var assessmentItems = 0;
        //console.log('this.props.questionnaireDetails', this.props.questionnaireDetails)
        if(this.props.questionnaireDetails !== undefined && this.props.questionnaireDetails.items !== undefined && this.props.questionnaireDetails.items > 0){
            assessmentItems = this.props.questionnaireDetails.items ;
        }
        var assessmentDialogue = (assessmentId+today);
        if(assessmentId === undefined || assessmentId === "") assessmentId= "5pEXdA6wH9eYIyFHTFlx" ;
        this.generalQuestions = this.props.firebase
        .questionnaireSet(assessmentId)
        if(this.props.eggupAPI){
            this.generalQuestions = this.generalQuestions
            .where('eggupUserGuid.'+this.state.eggupUserId, '==', true)
        }
        else{
            this.generalQuestions = this.generalQuestions
            .where('active', '==', true)
            .orderBy("position", "asc")
        }
        this.generalQuestions.get().then(snapshot => {
            if(this._isMounted ){
                let questionSet = [];
                var questionsCount = 0;
                snapshot.forEach(doc => {
                    questionsCount += 1;
                    assessmentItems -= 1;
                    var content = doc.data();
                    questionSet.push({ ...content, id: doc.id });
                    //console.log('questionSet', doc.id, content)
                });//end of snapshot
                if(this._isMounted && questionSet.length > 0 && assessmentItems <= 0){
                    //console.log('questionSet', questionSet)
                    this.setState({
                        questionSet,
                        questionsCount,
                        loading: false,
                        assessmentId: assessmentId,
                        assessmentDialogue: assessmentDialogue,
                    });
                }
                //console.log('questionSet', assessmentItems, questionSet)
                if((questionSet.length === 0 || assessmentItems > 0) && this.props.eggupAPI){
                    this.setState({
                        loadingMessage: 'Creating your personal assessment and preparing the questions. Please wait a moment!',
                        loading: true,
                    });
                    this.props.firebase.documentIdByCollection('users/'+this.props.user.uid+'/accounts/eggup/'+this.state.eggupUserId, assessmentId).set({
                        provider: 'eggup',
                        userGuid: this.state.eggupUserId, 
                        hashId: assessmentId, 
                        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                        status: 'REQUEST',
                        userId: this.props.user.uid
                    }, {merge: true})
                    .then(docRef => {
                        //console.log('docRef creation and loading', docRef)
                        this.prepareNewQuestions = this.props.firebase.documentIdByCollection('users/'+this.props.user.uid+'/accounts/eggup/'+this.state.eggupUserId, assessmentId)
                        .onSnapshot(doc => {
                            var data = doc.data();
                            if(data !== undefined && data.status === 'COMPLETED'){
                                this.prepareNewQuestions();
                                this.loadAssessment();
                            }
                        })

                    })
                    console.log('requested for new questions')
                }
                //console.log('questionSet', questionSet)
            }//end if mounted
        });
        this.createdAssessmentDialogue(this.props.user.uid, assessmentId, assessmentDialogue);
    }
    
    createdAssessmentDialogue(userId, assessmentId, assessmentDialogue) {
        this.props.firebase.documentIdByCollection('personalityTrait', userId+'/archive/'+assessmentDialogue).set({
            createdAt : this.props.firebase.fieldValue.serverTimestamp(),
            userId: userId,
            currentAssessmentStatus: 'created',
            category: this.props.questionnaireDetails.category,
            type: 'self-assessment',
            assessmentId: assessmentId,
            assessmentDialogue: assessmentDialogue
        }, {merge: true});
        if(this.props.user.currentDialogue !== undefined && this.props.user.currentDialogue !== ''){
            this.props.firebase.dialogue(this.props.user.currentDialogue).update({currentAssessment: assessmentDialogue, currentAssessmentStatus: 'created'});
        }
        this.props.firebase.user(userId).update({currentAssessment: assessmentDialogue});
    }

    loadAssessmentSettings(){
        this.loadPersonalityTrait = this.props.firebase.personalityTrait(this.props.user.uid)
        .onSnapshot(doc => {
        //.get().then(doc => {
            if (doc.exists) {
                let assessmentSettings = doc.data();
                if(this.props.eggupAPI){
                    if(assessmentSettings !== null && assessmentSettings !== undefined && assessmentSettings.eggup !== undefined && assessmentSettings.eggup.user_guid !== undefined ){
                        if(this._isMounted){
                            //console.log('got the id', assessmentSettings)
                            this.setState({
                                assessmentSettings,
                                eggupUserId: assessmentSettings.eggup.user_guid
                            });
                        }
                    }
                    else if( false && this.preparingNewEggupAPI !== true){
                        this.preparingNewEggupAPI = true;
                        //console.log('no user data found in existing account');
                        this.createEggupId();
                    }
                }
            } 
            else if(false && this.preparingNewEggupAPI !== true){
                this.preparingNewEggupAPI = true;
                //console.log('no user data found');
                this.createEggupId();
            }
        })
    }

    createEggupId(){
        var username = this.props.user.username;
        if(username.includes('@')){
            username = username.split('@')[0];
        }
        if(username.includes(' ')){
            username = username.split(' ')[0];
        }
        this.props.firebase.documentIdByCollection('users/'+this.props.user.uid+'/accounts', 'eggup').set({
            category: 'eggup',
            username: username, 
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            status: 'REQUEST',
            userId: this.props.user.uid
        }, {merge: true})
        console.log('Requested New EGG Up Id')
    }

    loadPreviousAssessment(){
        //console.log('this.props.user.uid', this.props.user.uid)
        var previousAssessments = [];
        var daysToWait = 0;
        var assessmentId = this.props.assessmentId;
        if(assessmentId === undefined || assessmentId === "") assessmentId= "5pEXdA6wH9eYIyFHTFlx" ;
        this.props.firebase.personalityTraitArchive(this.props.user.uid)
        .where('assessmentId', '==', assessmentId)
        .where('currentAssessmentStatus', '==', 'ended')
        .orderBy("createdAt", "desc")
        .limit(5)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                return;
            }
            var lastDate = undefined;
            snapshot.forEach(doc => {
                let data = doc.data();
                if(data.createdAt !== undefined){
                    if(lastDate === undefined){
                        lastDate = data.createdAt;
                    }
                    else if(lastDate < data.createdAt){
                        lastDate = data.createdAt;
                    }
                }
                previousAssessments.push({...data, id: doc.id});
                //console.log('doc', data)
            });
            if(lastDate !== undefined && lastDate.seconds !== undefined && this.props.ignoreDaysToWait !== true){
                var myDate = new Date().getTime() / 1000;
                var difference = myDate - lastDate.seconds;
                var daysDifference = Math.floor(difference/60/60/24);
                let daysMaxBetween = 30;
                if(daysDifference > 0 && daysDifference <= daysMaxBetween){
                    daysToWait = daysMaxBetween - daysDifference;
                }
                //console.log('my', myDate, 'last', lastDate, 'delta', difference, daysDifference, daysToWait)
            }
            this.setState({previousAssessments, daysToWait});
            //console.log('previousAssessments', previousAssessments)
        });
    }

    calcNewPoints(newPoints, questionsAnswered, valueProvidedFactor =1){
        var oldPoints = this.state.currentPersonalityTrait;
        //console.log('oldPoints', oldPoints)
        var newKeys = Object.keys(newPoints);
        //console.log('questionsAnswered', questionsAnswered)
        //console.log('valueProvidedFactor',  valueProvidedFactor)
        //console.log('old', oldPoints)
        var resultPoints = {};
        var counterItems = {};
        if (this.props.questionnaireDetails?.category === 'OCEAN'){
            var maxPointsPerAnswer = 12;
            var questionsCount = this.state.questionsCount;
            var factorQuestionnaireItem = valueProvidedFactor/maxPointsPerAnswer*120/questionsCount
            resultPoints["O"] = oldPoints["O"] + (( newPoints["O"] || 0 ) *factorQuestionnaireItem);
            resultPoints["C"] = oldPoints["C"] + (( newPoints["C"] || 0 ) *factorQuestionnaireItem);
            resultPoints["E"] = oldPoints["E"] + (( newPoints["E"] || 0 ) *factorQuestionnaireItem);
            resultPoints["A"] = oldPoints["A"] + (( newPoints["A"] || 0 ) *factorQuestionnaireItem);
            resultPoints["N"] = oldPoints["N"] + (( newPoints["N"] || 0 ) *factorQuestionnaireItem);
        }
        else if (this.props.questionnaireDetails?.category === 'CUSTOM'){
            var maxPointsPerAnswer = 10;
            var questionsCount = this.state.questionsCount;
            var factorQuestionnaireItem = valueProvidedFactor/maxPointsPerAnswer*100/questionsCount;
            if(newKeys?.length > 0){
                newKeys.map( item => {
                    if(oldPoints[item] === undefined){
                        //console.log('add', item, oldPoints.item)
                        oldPoints[item] = 50
                    }
                })
            }
            //console.log('newkeys', newKeys, oldPoints)
            Object.keys(oldPoints).forEach( (item, index) => {
                resultPoints[item] = oldPoints[item] + (( newPoints[item] || 0 ) *factorQuestionnaireItem);
                if(resultPoints[item] >= 100){
                    resultPoints[item] = 98;
                }
                else if (resultPoints[item] < 0){
                    resultPoints[item] = 2;
                }
            })
        }
        else{
            if(newKeys?.length > 0){
                newKeys.map( item => {
                    if(oldPoints[item] === undefined){
                        //console.log('add', item, oldPoints.item)
                        oldPoints[item] = 0
                    }
                })
            }
            //console.log('newkeys', newKeys, oldPoints)
            Object.keys(oldPoints).forEach( (item, index) => {
                resultPoints[item] = oldPoints[item];
                if(newPoints[item]!== undefined && newPoints[item] !== null){
                    if(newPoints[item] !== undefined && (newPoints[item] !== 0 || this.props.questionnaireDetails?.category === 'CUSTOM')){
                        if(newPoints[item] < 0){
                            ///// INVERTING THE RESULT SCORE as 9 points actually means 1 point
                            valueProvidedFactor = newPoints[item] + valueProvidedFactor;
                            //console.log('inverted', valueProvidedFactor, newPoints[item])
                        }
                        counterItems = {};
                        if(this.state.currentAssessmentItemCounter !== undefined){
                            Object.assign(counterItems, this.state.currentAssessmentItemCounter);
                        }
                        var counter = 1;
                        if(counterItems[item] === undefined || counterItems[item] <=0){
                            counterItems[item] = 1;
                        }
                        else{
                            counterItems[item] = counterItems[item] + 1;
                            counter = counterItems[item];
                        }
                        //console.log('counterItems', counter, counterItems)
                        if(counter>1){
                            // if several questions appear then factor in the amount of previous number of average
                            resultPoints[item] = ((oldPoints[item]*(counter-1)) + ((newPoints[item] || 0 ) *valueProvidedFactor))/counter;
                        }
                        else{
                            // if new then start with preset default value
                            resultPoints[item] = (oldPoints[item] + ((newPoints[item] || 0 ) *valueProvidedFactor))/counter;
                        }
                    }
                }
            })
        }
        //console.log('resultssd', resultPoints, factorQuestionnaireItem)
        return [resultPoints, counterItems]
    }

    handleRestart = (event=null) => {
        var shuffledQuestionOrder = ['O', 'C', 'E', 'A', 'N'];
        shuffledQuestionOrder.sort(function(){
            return 0.5 - Math.random();
        });
        this.setState({
            shuffledQuestionOrder,
            questionCategoriesReduce: this.state.questionCategories,
            currentPersonalityTrait: {},
            questionType: 'interest',
            nextQuestion: 'interest',
            categories : [],
            questionsAnswered : 0,
            started: false,
        });
        this.initiateAssessment();
    }

    render(){
        const { theme } = this.props;
        //console.log('categories', this.state)
        if(this.state.loading){
            return(
                <div style={theme.loadingCenterPadding}>
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} loadingText={"loading assessment"} />
                    <p>
                        <span style={{color:'red'}}>{this.state.loadingMessage}</span>
                    </p>
                </div>
            )
        }
        else{
            return(
                <div >
                    {this.state.started !== true
                        ?   this.showQuestionIntro()
                        :   (this.state.questionSet !== undefined && this.state.questionsAnswered !== undefined && this.state.questionsAnswered >= this.state.questionSet.length )
                            ?   this.showQuestionResult()
                            :   this.showQuestionPackage() 
                    }
                </div>
            )// end return
        }
    }//end render


    updateQuestionPair = (newSettingPoints, reset=false, valueProvidedFactor) => {
        //console.log("newSettingPoints", newSettingPoints)
        if(this.props.handleScrollTop !== undefined ){
            this.props.handleScrollTop();
        }
        var currentPersonalityTraitBeforeBackup = this.state.currentPersonalityTrait;
        var currentAssessmentItemCounterBackup = this.state.currentAssessmentItemCounter || {};
        this.setState({
            currentPersonalityTraitBeforeBackup: currentPersonalityTraitBeforeBackup,
            currentAssessmentItemCounterBackup: currentAssessmentItemCounterBackup
        })
        var questionsAnswered = this.state.questionsAnswered
        var newPointsCalculation = newSettingPoints;
        var newAssessmentItemCounter = {};
        if(!reset && newSettingPoints !== undefined && newSettingPoints!== null){
            [newPointsCalculation, newAssessmentItemCounter] = this.calcNewPoints(newSettingPoints, questionsAnswered , valueProvidedFactor)
        }
        //console.log('backups', currentPersonalityTraitBeforeBackup, currentAssessmentItemCounterBackup, newPointsCalculation)
        this.setState({
            currentPersonalityTrait: newPointsCalculation,
            currentAssessmentItemCounter: newAssessmentItemCounter
        })
        if(this.props.receiveResults !== undefined){
            this.props.receiveResults(newPointsCalculation);
        }
        if(questionsAnswered === 2){
            if(this.props.user.currentDialogue !== undefined && this.props.user.currentDialogue !== ''){
                this.props.firebase.dialogue(this.props.user.currentDialogue).update({currentAssessmentStatus: 'running'});
            }
            this.props.firebase.documentIdByCollection('personalityTrait', this.props.user.uid+'/archive/'+this.state.assessmentDialogue).update({currentAssessmentStatus: 'running'});
        }
    }
    
    handleSkipQuiz = () => {
        var matchingCategories = [];
        matchingCategories.push(  this.state.categories);
        this.setState({categories: matchingCategories, openLeadershipQualityPicker2:false, openLeadershipQualityPicker1:true });
    }

    XXXpersonalityTraitsTarget(type1, type2, phase, newPoints){
        //console.log(type1, type2, phase)
        var option1Text = '1';
        var option2Text = '2';
        var option1Explain = '';
        var option2Explain = '';
        var optionCategory1Id = '';
        var optionCategory2Id = '';
        var option1Points = {...newPoints};
        var option2Points =  {...newPoints};
        var option1Image = '';
        var option2Image = '';
        var check = true;
        var matchingCategories = [];
        var referenceProfile = newPoints// this.state.currentPersonalityTrait
        //console.log('start',newPoints, option1Points, option2Points)
        var showedCategories = [];

        this.state.questionCategories.forEach(item => {
            if('personalityTraitsTarget' in item
                && item.personalityTraitsTarget[type1] >= 60){
                    if( optionCategory1Id !== '' && Math.random() < 0.5 ){
                        check = true;
                    }
                    else{
                        //console.log('type1', item.text)
                        optionCategory1Id = item.uid;
                        if(phase ==='interest') {
                            option1Text = item.text ;
                            option1Explain = item.answerQuestionInterest;
                        }
                        else if(phase ==='issueShort' ) {
                            option1Text = item.text ;
                            option1Explain = item.answerQuestionIssueShortTerm;
                        }
                        else if(phase ==='issueLong'|| phase ==='issueExtra') {
                            option1Text = item.text;
                            option1Explain = item.answerQuestionIssueLongTerm;
                        }
                        option1Points[type1] = referenceProfile[type1] * 1.5;
                        if(option1Points[type1] >= 100) option1Points[type1] = 95;
                        option1Points[type2] = referenceProfile[type2] * 3/4;
                        if('url' in item && item.url !== '') option1Image = item.url;
                        else option1Image = createGradientImage(item.backgroundColor, "#FFFFFF")
                        //this.setState({optionCategory1Id, option1Text, option1Points, option1Image});
                    }
            }
            if('personalityTraitsTarget' in item
                && item.personalityTraitsTarget[type2] >= 60){
                    if( optionCategory2Id !== '' && Math.random() < 0.5 ){
                        check = true;
                    }
                    else{
                        //console.log('type2', item.text)
                        optionCategory2Id = item.uid
                        if(phase ==='interest'){
                            option2Text = item.text 
                            option2Explain = item.answerQuestionInterest;
                        }
                        else if(phase ==='issueShort') {
                            option2Text = item.text ;
                            option2Explain = item.answerQuestionIssueShortTerm;
                        }
                        else if(phase ==='issueLong'|| phase ==='issueExtra'){
                            option2Text = item.text  ;
                            option2Explain = item.answerQuestionIssueLongTerm;
                        }
                        option2Points[type2] = referenceProfile[type2] * 1.5;
                        if(option2Points[type2] >= 100) option2Points[type2] = 95;
                        option2Points[type1] = referenceProfile[type1] * 3/4;
                        if('url' in item && item.url !== '') option2Image = item.url;
                        else option2Image = createGradientImage(item.backgroundColor, "#FFFFFF")
                    }
            }
            if('personalityTraitsTarget' in item){
                //console.log(item.text, this.state.questionCategories.length)
                if( 
                    item.personalityTraitsTarget.O <= referenceProfile.O+30 && item.personalityTraitsTarget.O >= referenceProfile.O-30
                    && item.personalityTraitsTarget.C <= referenceProfile.C+30 && item.personalityTraitsTarget.C >= referenceProfile.C-30
                    && item.personalityTraitsTarget.E <= referenceProfile.E+30 && item.personalityTraitsTarget.E >= referenceProfile.E-30
                    && item.personalityTraitsTarget.A <= referenceProfile.A+30 && item.personalityTraitsTarget.A >= referenceProfile.A-30
                    && item.personalityTraitsTarget.N <= referenceProfile.N+30 && item.personalityTraitsTarget.N >= referenceProfile.N-30
                ){
                    matchingCategories.push(item)
                    showedCategories.push(item.text)
                }

            }
            
        })
        /////// decide what to do next
        //console.log('before choice selection', matchingCategories, showedCategories)
        if(matchingCategories.length === 1 ){
            this.setState({categories: matchingCategories});
            //console.log('removed index from options randomly', showedCategories)
        }
        else if(matchingCategories.length > 1 && matchingCategories.length <= 3 && ( phase === 'issueExtra') ){
            var index = Math.floor( Math.random()*matchingCategories.length );
            matchingCategories.splice( index, 1 );
            this.setState({categories: matchingCategories});
            //console.log('removed index from options randomly', showedCategories)
        }
        else if(matchingCategories.length === 0 && (phase === 'issueLong' || phase === 'issueExtra')){
            matchingCategories = this.state.lastMatching;
            var indexAll = Math.floor( Math.random()*matchingCategories.length );
            //console.log('random item zero case', indexAll, showedCategories, matchingCategories)
            matchingCategories.splice( indexAll, 1 );
            this.setState({categories: matchingCategories});
        }
        else if(matchingCategories.length > 0  ){
            //console.log('its emptys', showedCategories)
            this.setState({lastMatching: matchingCategories, check});
        }

        //console.log('matches', matchingCategories, option1Points, option2Points)
        //console.log(optionCategory1Id, optionCategory2Id)
        //console.log('showed', showedCategories, newPoints, this.state.currentPersonalityTrait, phase, matchingCategories)
        
        return [option1Text, option2Text, option1Points, option2Points, matchingCategories, option1Image, option2Image, option1Explain, option2Explain]
    }

    showQuestionIntro(){
        const {theme} = this.props;
        return(
            <div style={{textAlign : 'center'}} >
                {this.props.questionnaireDetails && this.props.questionnaireDetails.premium === true
                    &&
                    <div style={{width:'100%', textAlign:'right'}}>
                        <strong>
                            <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeAltGold}} />
                            Premium
                        </strong>
                    </div>
                }
                <p style={theme.textSupport}>Self-Assessment</p>
                {this.props.questionnaireDetails!== undefined && this.props.questionnaireDetails !== null
                    ?
                    <div>
                        <h2>{this.props.questionnaireDetails.title}</h2>
                        <p style={theme.textSupport}><strong>{this.props.questionnaireDetails.subtitle}</strong></p>
                    </div>
                    :
                    <p> </p>
                }
                {this.state.nonSubscriber !== undefined 
                    &&
                    <DialogSettingPlan 
                        defaultSet={this.props.defaultSet || undefined}
                        user={this.props.user}
                        handleOpen={this.state.DialogSettingPlanOpen}
                        premiumOnly={true}
                        purchaseSuccessFlag={() => {
                            this.setState({nonSubscriber: false, premiumSubscriber: true})
                        }}
                        purchaseSuccessHandler={() => {
                            this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                        }}
                        handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                    />
                }
                {this.state.previousAssessments !== undefined && this.state.previousAssessments.length > 0
                    &&
                        <List >
                            {this.state.previousAssessments.map((results, index) => {
                                return(
                                    <ListItem key={'expl_'+index}
                                        onClick={
                                            () => {
                                                this.setState({
                                                    assessmentResults: results,
                                                    questionSet: [],
                                                    questionsAnswered: 0,
                                                    started: true
                                                });
                                                if(this.props.receiveResults !== undefined){
                                                    this.props.receiveResults(results);
                                                }
                                            }
                                        }
                                    >
                                        <ListItemText style={{paddingLeft:10, paddingRight: 10}}>
                                            <strong>Results:</strong> {ConvertTimestampToDateString(results.createdAt, true)}
                                        </ListItemText>
                                        <ListItemIcon style={{marginLeft:10, minWidth:'auto'}}>
                                            <IconButton >
                                                <SearchIcon  /> 
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                )
                            })}
                        </List>
                }
                {this.props.isCoach !== true &&
                    <div style={{marginTop:30, marginBottom:30}}>
                        {(this.state.nonSubscriber === undefined || (this.state.nonSubscriber === false && this.state.premiumSubscriber === true) || (this.state.daysToWait !== undefined && this.state.daysToWait > 2))
                            ?
                                this.props.eggupAPI && (this.state.eggupUserId === undefined || this.state.eggupUserId === '' || this.state.eggupUserId === 0)
                                ?
                                    <Button disabled>
                                        ...preparing assessment
                                    </Button>
                                :
                                    (false && this.state.daysToWait !== undefined && this.state.daysToWait > 2)
                                    ?
                                        <Button disabled 
                                            variant="outlined">
                                            Next assessment in {this.state.daysToWait > 14 ? (Math.floor((this.state.daysToWait)/7) + ' weeks') : (this.state.daysToWait + ' days')} 
                                        </Button>
                                    :
                                        this.state.previousAssessments !== undefined && this.state.previousAssessments.length > 0
                                        ?
                                            <Button onClick={() => this.startAssessment()}>
                                                Start New Assessment
                                            </Button>
                                        :
                                            <Button onClick={() => this.startAssessment()}>
                                                Start Assessment
                                            </Button>
                            :
                                <>
                                    {this.state.premiumSubscriber === false
                                        ?
                                            <p>
                                                Please subscribe to a plan to get access to this <strong>PREMIUM</strong> assessment.
                                            </p>
                                        :
                                            <p>
                                                Please subscribe to a plan to get access to this assessment.
                                            </p>
                                    }
                                    <p>
                                        <Button
                                            onClick={() => this.setState({DialogSettingPlanOpen : true})}
                                            variant='contained'
                                            style={{backgroundColor: theme.palette.themeRed}}
                                            >
                                            Unlock All
                                        </Button>
                                    </p>
                                </>
                        }
                    </div>
                }
                {this.props.questionnaireDetails!== undefined && this.props.questionnaireDetails !== null
                    &&
                        <div 
                            style={{...theme.innerHTML, textAlign : 'left'}}
                            onClick = {(event) => {
                                try{
                                    const link = event.target.getAttribute('href');
                                    if(link !== undefined && link !== null && link !== ""){
                                        event.preventDefault();
                                        window.open(link, "_blank")
                                    }
                                }
                                catch(e){
                                    console.log('No Link', e)
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: this.props.questionnaireDetails.text}} 
                        />
                }
            </div>
        )
    }

    showQuestionResult(){
        return(
            <div style={{textAlign : 'center'}} >
                <p>Here are the assessment results.</p>
                {this.state.assessmentResults !== undefined
                    ?
                        <CardBigFive
                            userTraitHandler = {undefined}
                            assessmentResults = {this.state.assessmentResults || undefined}
                            resultTexts = {this.props.questionnaireDetails?.resultTexts || undefined}
                            questionnaireDetails = {this.props.questionnaireDetails || undefined}
                            showSummary = {false}
                            showGraphs = {true}
                            showButton = {false}
                            openHandler = {undefined}
                            allowDialog = {false}
                            user = {this.props.user}
                            />
                    :
                        <LoadingProgress
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} loadingText={"loading results"} />
                }
                {this.props.handleResults !== undefined && this.state.assessmentResults !== undefined
                    &&
                        <div style={{marginTop:30}}>
                            <Button onClick={() => this.props.handleResults(this.state.assessmentResults)}>
                                Choose New Modules
                            </Button>
                        </div>
                }
                {false &&
                    <div style={{marginTop:30}}>
                        <Button onClick={() => this.handleRestart()}>
                            Restart Assessment
                        </Button>
                    </div>
                }
            </div>
        )
    }

    showQuestionPackage(){
        const { theme } = this.props;
        //const { question, option1Text, option2Text, option1Image, option2Image , option1Points , option2Points, categories, option1Explain, option2Explain} = this.state;
        
        //console.log(this.state.questionCategories, this.state.shuffledQuestionOrder)
        
        if(this.state.questionSet!== undefined && this.state.questionSet.length >= this.state.questionsAnswered && this.state.questionsAnswered !== undefined
            && this.state.questionSet[this.state.questionsAnswered] !== undefined ){


            var lastQuestionFlag = false;
            if(this.state.questionsAnswered >= this.state.questionsCount -1 ){
                lastQuestionFlag = true;
            }
            var questionDict = this.state.questionSet[this.state.questionsAnswered];

            return (
                <div>
                    <div style={{paddingBottom: 20}}>
                        <div style={{textAlign : 'center'}} >Question {this.state.questionsAnswered + 1} of {this.state.questionsCount}</div>
                        <LinearProgress variant="determinate" value={100 *  (this.state.questionsAnswered + 1) /  this.state.questionsCount  } color="secondary" />
                    </div>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                        {questionDict.imageUrl_question !== undefined && questionDict.imageUrl_question !== ''
                            ?
                                <>
                                    <Grid item xs={3}>
                                        <img style={theme.dialogImage} src={questionDict.imageUrl_question } alt={questionDict.title} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        {questionDict.title !== undefined && questionDict.title !== ""
                                            && 
                                            <strong> 
                                                {questionDict.title}
                                            </strong>
                                        }
                                        <h4 style={{color: theme.palette.themeTextSecondary}}>
                                            {this.state.selectionItem === undefined 
                                                ?   questionDict.question
                                                :   <span style={theme.textSupport}>{questionDict.question}</span>
                                            }
                                        </h4>
                                    </Grid>
                                </>
                            :
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    {questionDict.title !== undefined && questionDict.title !== ""
                                        && 
                                        <h4> 
                                            {questionDict.title}
                                        </h4>
                                    }
                                    <h3 style={{color: theme.palette.themeTextSecondary, paddingBottom:20}}>
                                        {this.state.selectionItem === undefined 
                                            ?   questionDict.question
                                            :   <span style={theme.textSupport}>{questionDict.question}</span>
                                        }
                                    </h3>
                                </Grid>
                        }
                        {questionDict.type === "single" &&
                            questionDict.responses.map( (answer, index) => (
                                <Grid item xs={12} key={"cards_"+index} >
                                    <Card key={"cards_"+index}
                                        style={{
                                            borderRadius: theme.borderRadius,
                                            backgroundColor: this.state.hoverItem === index ? theme.palette.themeAltGold : 'transparent'
                                        }}
                                        onMouseEnter={() => {this.setState({ hoverItem: index}) }}
                                        onMouseLeave={() => {this.setState({ hoverItem: undefined}) }}
                                        onClick={() => {
                                            if(this.state.selectionItem === undefined){
                                                this.addAnswerToChat(answer, questionDict, questionDict["pointsResponse"+index])
                                                this.setState({ hoverItem: index, selectionItem: index})
                                                setTimeout(() => {
                                                    if(this._isMounted){
                                                        this.pickResponse(questionDict["pointsResponse"+index], lastQuestionFlag)
                                                        this.setState({ hoverItem: undefined, selectionItem: undefined})
                                                    }
                                                }, 700);
                                                //this.setState({['cards_'+item.value] : true})
                                            }
                                        }}
                                        >
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={2} >
                                                <Avatar style={{height: theme.CardDialogueButton.height, marginLeft:5, backgroundColor: theme.palette.themeLightGrey, color:  theme.palette.themeRed}}> {index+1}</Avatar>
                                            </Grid>
                                            <Grid item xs={8} > 
                                                <div style={{minHeight:1, maxHeight:theme.CardDialogueButton.maxHeight, paddingTop: 6, paddingBottom: 6}}>
                                                    <Textfit
                                                        style={{
                                                            //height: '17.14130434vw',
                                                            //maxHeight: 90,
                                                            height: "calc(99% + 1px)", 
                                                            width:"100%",
                                                            paddingRight:10,
                                                            color: (this.state.selectionItem === undefined || this.state.selectionItem === index) 
                                                                ?   theme.palette.themeText
                                                                :   theme.palette.themeGrey,
                                                        }}
                                                        onReady = {(size) => {
                                                            //console.log('change', size, this.state.minFontSize );
                                                            if(this.state.minFontSize === undefined || size < this.state.minFontSize){
                                                                this.setState({minFontSize: size})
                                                                //console.log('event', size);
                                                            }
                                                        }}
                                                        id = {'option_'+index}
                                                        max = {this.state.minFontSize !== undefined ? this.state.minFontSize : 100}
                                                        > 
                                                        {answer}
                                                    </Textfit>
                                                </div> 
                                            </Grid>
                                            {questionDict['imageUrl'+index] !== undefined && questionDict['imageUrl'+index] !== ''
                                                ? 
                                                    <Grid item xs={2} > 
                                                        <img style={theme.dialogImage} src={questionDict['imageUrl'+index]} alt={answer} />
                                                    </Grid>
                                                :
                                                    <Grid item xs={2} >    </Grid>
                                            }
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))
                        }
                        {questionDict.type === "likert" &&
                           <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                                >
                                <Grid item xs={2}>
                                    <div style={{textAlign:'center'}} 
                                        onMouseEnter={() => {this.setState({ hoverItem: 1}) }}
                                        onClick={() => {
                                            if(this.state.selectionItem === undefined){
                                                this.addAnswerToChat((questionDict.answers !== undefined && questionDict.answers[1] !== undefined ? questionDict.answers[1] : 'Strongly disagree'), questionDict, questionDict["pointsScaleLikert"], -2)
                                                this.setState({ hoverItem: 1, selectionItem: 1})
                                                setTimeout(() => {
                                                    if(this._isMounted){
                                                        this.pickResponse(questionDict["pointsScaleLikert"], lastQuestionFlag, -2)
                                                        this.setState({ hoverItem: undefined, selectionItem: undefined})
                                                    }
                                                }, 700);
                                            }
                                        }}>
                                        <SentimentVeryDissatisfiedIcon 
                                            style={{ 
                                                fontSize: this.state.hoverItem===1 ? 60: 40,
                                                color: (this.state.selectionItem === 1 || this.state.selectionItem === undefined ) ? theme.palette.themeRed : theme.palette.themeLightGrey}} 
                                        />
                                        <br/>
                                        {this.state.hoverItem===1
                                            ?   <strong>{questionDict.answers !== undefined && questionDict.answers[1] !== undefined ? questionDict.answers[1] : 'Strongly disagree'}</strong>
                                            :   <span>{questionDict.answers !== undefined && questionDict.answers[1] !== undefined ? questionDict.answers[1] : 'Strongly disagree'}</span>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div  style={{textAlign:'center'}} 
                                        onMouseEnter={() => {this.setState({ hoverItem: 2}) }}
                                        onClick={() => {
                                            if(this.state.selectionItem === undefined){
                                                this.addAnswerToChat((questionDict.answers !== undefined && questionDict.answers[2] !== undefined ? questionDict.answers[2] : 'Disagree'), questionDict, questionDict["pointsScaleLikert"], -1)
                                                this.setState({ hoverItem: 2, selectionItem: 2})
                                                setTimeout(() => {
                                                    if(this._isMounted){
                                                        this.pickResponse(questionDict["pointsScaleLikert"], lastQuestionFlag, -1)
                                                        this.setState({ hoverItem: undefined, selectionItem: undefined})
                                                    }
                                                }, 700);
                                            }
                                        }}>
                                        <SentimentDissatisfiedIcon 
                                            style={{ 
                                                fontSize: this.state.hoverItem===2 ? 60: 40,
                                                color: (this.state.selectionItem === 2 || this.state.selectionItem === undefined ) ? theme.palette.themeAlertOrange : theme.palette.themeLightGrey 
                                                //color: theme.palette.themeAlertOrange
                                            }}  
                                        />
                                        <br/>
                                        {this.state.hoverItem===2
                                            ?   <strong>{questionDict.answers !== undefined && questionDict.answers[2] !== undefined ? questionDict.answers[2] : 'Disagree'}</strong>
                                            :   <span>{questionDict.answers !== undefined && questionDict.answers[2] !== undefined ? questionDict.answers[2] : 'Disagree'}</span>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div style={{textAlign:'center'}} 
                                        onMouseEnter={() => {this.setState({ hoverItem: 3}) }}
                                        onClick={() => {
                                            if(this.state.selectionItem === undefined){
                                                this.addAnswerToChat((questionDict.answers !== undefined && questionDict.answers[3] !== undefined ? questionDict.answers[3] : 'Neither or'), questionDict, questionDict["pointsScaleLikert"], 0)
                                                this.setState({ hoverItem: 3, selectionItem: 3})
                                                setTimeout(() => {
                                                    if(this._isMounted){
                                                        this.pickResponse(questionDict["pointsScaleLikert"], lastQuestionFlag, 0)
                                                        this.setState({ hoverItem: undefined, selectionItem: undefined})
                                                    }
                                                }, 700);
                                            }
                                        }}>
                                        <SentimentSatisfiedIcon 
                                            style={{ 
                                                fontSize: (this.state.hoverItem===3) ? 60: 40,
                                                color: (this.state.selectionItem === 3 || this.state.selectionItem === undefined ) ? theme.palette.themeText : theme.palette.themeLightGrey 
                                                }} 
                                        />
                                        <br/>
                                        {this.state.hoverItem===3
                                            ?   <strong>{questionDict.answers !== undefined && questionDict.answers[3] !== undefined ? questionDict.answers[3] : 'Neither or'}</strong>
                                            :   <span>{questionDict.answers !== undefined && questionDict.answers[3] !== undefined ? questionDict.answers[3] : 'Neither or'}</span>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{textAlign:'center'}} 
                                        onMouseEnter={() => {this.setState({ hoverItem: 4}) }}
                                        onClick={() => {
                                            if(this.state.selectionItem === undefined){
                                                this.addAnswerToChat((questionDict.answers !== undefined && questionDict.answers[3] !== undefined ? questionDict.answers[3] : 'Agree'), questionDict, questionDict["pointsScaleLikert"], 1)
                                                this.setState({ hoverItem: 4, selectionItem: 4})
                                                setTimeout(() => {
                                                    if(this._isMounted){
                                                        this.pickResponse(questionDict["pointsScaleLikert"], lastQuestionFlag, 1)
                                                        this.setState({ hoverItem: undefined, selectionItem: undefined})
                                                    }
                                                }, 700);
                                            }
                                        }}>
                                        <SentimentSatisfiedAltIcon 
                                            style={{ 
                                                fontSize: this.state.hoverItem===4 ? 60: 40,
                                                color: (this.state.selectionItem === 4 || this.state.selectionItem === undefined ) ? theme.palette.themeAlertBlue : theme.palette.themeLightGrey
                                            }} 
                                        />
                                        <br/>
                                        {this.state.hoverItem===4
                                            ?   <strong>{questionDict.answers !== undefined && questionDict.answers[4] !== undefined ? questionDict.answers[4] : 'Agree'}</strong>
                                            :   <span>{questionDict.answers !== undefined && questionDict.answers[4] !== undefined ? questionDict.answers[4] : 'Agree'}</span>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div style={{textAlign:'center'}} 
                                        onMouseEnter={() => {this.setState({ hoverItem: 5}) }}
                                        onClick={() => {
                                            if(this.state.selectionItem === undefined){
                                                this.addAnswerToChat((questionDict.answers !== undefined && questionDict.answers[5] !== undefined ? questionDict.answers[5] : 'Strongly agree'), questionDict, questionDict["pointsScaleLikert"], 2)
                                                this.setState({ hoverItem: 5, selectionItem: 5})
                                                setTimeout(() => {
                                                    if(this._isMounted){
                                                        this.pickResponse(questionDict["pointsScaleLikert"], lastQuestionFlag, 2)
                                                        this.setState({ hoverItem: undefined, selectionItem: undefined})
                                                    }
                                                }, 700);
                                            }
                                        }}>
                                        <SentimentVerySatisfiedIcon 
                                            style={{ 
                                                fontSize: (this.state.hoverItem===5) ? 60: 40,
                                                color: (this.state.selectionItem === 5 || this.state.selectionItem === undefined ) ? theme.palette.themeAlertGreen : theme.palette.themeLightGrey 
                                                }}
                                        />
                                        <br/>
                                        {this.state.hoverItem===5
                                            ?   <strong>{questionDict.answers !== undefined && questionDict.answers[5] !== undefined ? questionDict.answers[5] : 'Strongly agree'}</strong>
                                            :   <span>{questionDict.answers !== undefined && questionDict.answers[5] !== undefined ? questionDict.answers[5] : 'Strongly agree'}</span>
                                        }
                                    </div>
                                </Grid>
                           </Grid>
                        }
                        {questionDict.type === "rating" && questionDict["pointsScaleRating"] !== undefined &&
                           <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                                style={{paddingLeft: 10, paddingRight: 10}}
                                >
                                <Grid item xs={12} 
                                    style={{paddingLeft: 20, paddingRight: 20}}
                                >
                                    <StyledSlider
                                        key={"slider_"+questionDict.position}
                                        min={questionDict.scalePointsStart!==undefined? questionDict.scalePointsStart : 1}
                                        max={questionDict.scalePointsEnd || 5}
                                        defaultValue={(questionDict.scalePointsStart!==undefined && questionDict.scalePointsEnd>0) ? ((questionDict.scalePointsStart+questionDict.scalePointsEnd)/2) : 3}
                                        marks={this.state.hoverScale !== true ? true : false}
                                        aria-labelledby={''}
                                        //onChange={ (event, value) => console.log('change', value)}
                                        valueLabelDisplay={this.state.hoverScale !== true ? "auto" : "on"}
                                        //getAriaValueText={(value) => this.showText(trait, value) }
                                        //onChangeCommitted={ (event, value) => console.log('evet', value)} 
                                        onChangeCommitted={ (event, value) =>{
                                            if(this.state.selectionItem === undefined){
                                                //this.addAnswerToChat((questionDict.answers !== undefined && questionDict.answers[5] !== undefined ? questionDict.answers[5] : 'Strongly agree'), questionDict, questionDict["pointsScaleLikert"], 2)
                                                this.setState({ hoverScale: true})
                                                setTimeout(() => {
                                                    if(this._isMounted){
                                                        this.setState({ hoverScale: undefined})
                                                        this.pickResponse(questionDict["pointsScaleRating"], lastQuestionFlag, value)
                                                    }
                                                }, 700);
                                            }
                                        }}
                                        />
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{marginTop: 0, fontSize:12, fontWeight:'bold', color: theme.palette.themeRed}}>
                                        {questionDict.scaleTextStart
                                            ?   questionDict.scaleTextStart
                                            :   'Not at all'
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{marginTop: 0, fontSize:12, fontWeight:'bold', color: theme.palette.themeRed, textAlign:"right"}}>
                                        {questionDict.scaleTextEnd
                                            ?   questionDict.scaleTextEnd
                                            :   "Absolutely"
                                        }
                                    </div>
                                </Grid>
                           </Grid>
                        }
                    </Grid>
                    {!this.props.eggupAPI && this.state.questionsAnswered >=1 && this.state.repeatButtonDisabled !== true && this.state.currentPersonalityTraitBeforeBackup!==undefined
                        &&
                        <div style={{marginTop:60}}>
                            <Button variant="outlined" color="secondary"
                                onClick={() => this.handleRepeatLastQuestion()}>
                                Change Last
                            </Button>
                        </div>
                    }
                </div>
            );
        }
    }

    addAnswerToChat = (optionText, questionObject, points=undefined, valueProvidedFactor = 0) => {
        //console.log('addAnswerToChat',optionText, questionObject )
        var assessmentId = this.state.assessmentId || 'general';
        var question = questionObject.question;
        if(question === undefined) question = "";
        var assessmentDialogue = this.state.assessmentDialogue || 'general';
        var index = this.state.questionsAnswered || 0;
        index = index + 1;
        var assessmentResponse = {
            uid: this.props.user.uid,
            assessmentId,
            assessmentDialogue,
            createdAt : this.props.firebase.fieldValue.serverTimestamp(),
            question: question,
            response: optionText,
            factor: valueProvidedFactor,
            index
        }
        if(points !== undefined){
            assessmentResponse['points'] = points;
        }
        if(this.props.eggupAPI){
            assessmentResponse['eggupUserGuid'] = this.state.eggupUserId;
            if(questionObject!== undefined && questionObject.eggup_question_ids !== undefined){
                assessmentResponse['eggupQuestionId'] = questionObject.eggup_question_ids[this.state.eggupUserId];
            }
            assessmentResponse['eggupAnswerNumber'] = (valueProvidedFactor + 3);
        }
        //console.log('assessmentResponse', assessmentResponse)
        //ExtendDialogueMessage(this.props.firebase, this.props.user, optionText, question, 'utter_ask_assessment', '', 'profile');
        try{
            this.props.firebase.collectionByName('personalityTrait/'+this.props.user.uid+'/assessments').add(assessmentResponse);
        } catch (e){
            console.log('error question set', e)
        }
    }


    handlercheck = () => {
        //console.log('opener called')
        this.setState({
            openLeadershipQualityPicker1: false,
            openLeadershipQualityPicker2: true,
        });
    }

    handleLeadershipQualityPickerOpen2 = (entry) => {
        //console.log('new things', entry);
        this.setState({
          chosenCategory: entry,
          openLeadershipQualityPicker1: false,
          openLeadershipQualityPicker2: true,
        });
    }

    handleLeadershipQualityPickerClose2 = () => {
        //console.log('handler called for 2')
        this.setState({
            openLeadershipQualityPicker1: true,
            openLeadershipQualityPicker2: false,
        });
        //this.props.closeDialog();
    }
    handleLeadershipQualityPickerCloseAll = () => {
        //console.log('handler called')
        this.setState({
            openLeadershipQualityPicker2: false,
            openLeadershipQualityPicker1: false,
        });
        if (this.props.closeDialogNext !== undefined ) this.props.closeDialogNext();
    }

    pickResponse(newPoints=undefined, finish=true, valueProvidedFactor=1){
        //console.log("response", newPoints, finish, valueProvidedFactor)
        this.setState({questionsAnswered : this.state.questionsAnswered + 1, repeatButtonDisabled: false});
        this.updateQuestionPair(newPoints, false, valueProvidedFactor) 
        //console.log("finish", finish, this.state)
        if(finish){
            var userAssessment = {};
            var overwriteSet = {};
            var archiveSet = {};
            /// EGGUP
            if(this.props.eggupAPI){
                this.setState({
                    loadingMessage: 'Preparing results for you, please wait a moment!',
                    loading: true,
                });
                this.getEggupResults = this.props.firebase.documentIdByCollection('personalityTrait', this.props.user.uid+'/archive/'+this.state.assessmentDialogue)
                .onSnapshot(doc => {
                    var updateScore = false;
                    var data = doc.data();
                    var scores = {};
                    //console.log('active and loading for change')
                    if(data.scores !== undefined){
                        scores = data.scores;
                        updateScore = true;
                        this.setState({
                            loading: false,
                            assessmentResults: data
                        });
                        //console.log('found change new', data)
                        //// moved to central itemns
                        //this.getEggupResults();
                    }
                    if(updateScore){
                        userAssessment = {};
                        Object.keys(scores).map(item => {
                            var subScores = scores[item];
                            Object.keys(subScores).map(key => {
                                var keyScores = subScores[key];
                                if(keyScores?.range > 0 && keyScores?.value >= 0){
                                    userAssessment[key] = Math.ceil( keyScores.value/keyScores.range * 100 )
                                }
                            })
                        })
                    }
                })
            }
            /// PERMA
            else if(this.props.questionnaireDetails?.category === 'PERMA-V'){
                var userAssessment = this.state.currentPersonalityTrait;
                var generalUserUpdateSet = {};
                Object.keys(userAssessment).forEach((item) => {
                    userAssessment[item] = Math.ceil(userAssessment[item]);
                    generalUserUpdateSet[item] = userAssessment[item];
                })
                overwriteSet = {
                    userAssessment,
                    createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                    type: 'average',
                    userId: this.props.user.uid
                }
                this.props.firebase.personalityTrait(this.props.user.uid).set(overwriteSet, {merge:true });
                archiveSet = {
                    userAssessment,
                    createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                    type: 'self-assessement',
                    userId: this.props.user.uid,
                    category: this.props.questionnaireDetails.category,
                    currentAssessmentStatus: 'ended',
                    assessmentId: this.state.assessmentId
                }
                this.setState({assessmentResults: archiveSet});
                this.props.firebase.documentIdByCollection('personalityTrait', this.props.user.uid+'/archive/'+this.state.assessmentDialogue).set(archiveSet, {merge:true });
            }
            /// OCEAN
            else if(this.state.currentPersonalityTrait !== undefined && this.state.currentPersonalityTrait.O !== undefined){
                userAssessment = {
                    O :  this.state.currentPersonalityTrait.O > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.O),
                    C :  this.state.currentPersonalityTrait.C > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.C),
                    E :  this.state.currentPersonalityTrait.E > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.E),
                    A :  this.state.currentPersonalityTrait.A > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.A),
                    N :  this.state.currentPersonalityTrait.N > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.N),
                }
                overwriteSet = {
                    userAssessment,
                    createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                    type: 'average',
                    userId: this.props.user.uid
                }
                this.props.firebase.personalityTrait(this.props.user.uid).set(overwriteSet, {merge: true});
                archiveSet = {
                    userAssessment,
                    createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                    type: 'onboardingQuiz',
                    userId: this.props.user.uid,
                    category: "OCEAN",
                    currentAssessmentStatus: 'ended',
                    assessmentId: this.state.assessmentId
                }
                this.setState({assessmentResults: archiveSet});
                this.props.firebase.documentIdByCollection('personalityTrait', this.props.user.uid+'/archive/'+this.state.assessmentDialogue).set(archiveSet, {merge: true});
            }
            /// CUSTOM
            else {
                //console.log('keys', this.state.currentPersonalityTrait)
                var keys = Object.keys(this.state.currentPersonalityTrait || {});
                userAssessment = {};
                keys.map(item => {
                    userAssessment[item] = (this.state.currentPersonalityTrait[item] > 99 ? 95 : this.state.currentPersonalityTrait[item] < 1 ? 5 : Math.ceil( this.state.currentPersonalityTrait[item]));
                })
                //console.log('userAssessment', userAssessment)
                overwriteSet = {
                    userAssessment,
                    createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                    type: 'average',
                    userId: this.props.user.uid
                }
                this.props.firebase.personalityTrait(this.props.user.uid).set(overwriteSet, {merge: true});
                archiveSet = {
                    userAssessment,
                    createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                    type: 'onboardingQuiz',
                    userId: this.props.user.uid,
                    category: "CUSTOM",
                    currentAssessmentStatus: 'ended',
                    assessmentId: this.state.assessmentId
                }
                this.setState({assessmentResults: archiveSet});
                this.props.firebase.documentIdByCollection('personalityTrait', this.props.user.uid+'/archive/'+this.state.assessmentDialogue).set(archiveSet, {merge: true});

            }

            if(this.props.user.currentDialogue !== undefined && this.props.user.currentDialogue !== ''){
                this.props.firebase.dialogue(this.props.user.currentDialogue).update({currentAssessmentStatus: 'ended'});
            }
            if(userAssessment !== {}){
                //console.log('update userassessment', userAssessment)
                this.props.firebase.user(this.props.user.uid).set({userAssessment, userAssessmentUpdatedAt: this.props.firebase.fieldValue.serverTimestamp()}, {merge: true});
            }
        }
    }

    handleRepeatLastQuestion(){
        if(this.state.questionsAnswered >= 1){
            var updatePersonalityTrait = this.state.currentPersonalityTrait;
            var updateAssessmentItemCounter = this.state.currentAssessmentItemCounter;
            if(this.state.currentPersonalityTraitBeforeBackup !== undefined){
                updatePersonalityTrait = this.state.currentPersonalityTraitBeforeBackup;
            }
            if(this.state.currentAssessmentItemCounterBackup !== undefined){
                updateAssessmentItemCounter = this.state.currentAssessmentItemCounterBackup;
                //console.log('update before', updateAssessmentItemCounter)
            }
            this.setState({
                questionsAnswered : this.state.questionsAnswered - 1, 
                repeatButtonDisabled: true,
                currentPersonalityTrait: updatePersonalityTrait,
                currentAssessmentItemCounter: updateAssessmentItemCounter
            });
        }
    }
};//end class

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
    
)(AssessmentPage);

//  module.exports.handleCurrentAchievementGoalOpen = DialogChangeGoal.handleCurrentAchievementGoalOpen();
