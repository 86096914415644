import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
//import _ from "lodash";

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentReverseMapList extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        var defaultValue = {};
        this.state = {
            defaultValue,
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            var content = this.props.content[p] || {};
            if(this.props.objectSet){
                if(p.includes('.')){
                    const myArray = p.split(".")
                    myArray.map((item, index) => {
                        if(index === 0) content = this.props.content[item];
                        else content = content[item]
                        //console.log('content', content)
                        return true;
                    })
                }
            }
            if(content!== undefined){
                return this.setState({[p] : content, [(p+'Change')]: content});
            }
            else{
                return this.setState({[p] : this.state.defaultValue, [(p+'Change')]: this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }


    handleSettingChange = (param, index, key, event) => {
        console.log(param, event.target.value, index, key)
        var newValue = '';
        var activateUpdate = true;
        if(event.target.value === undefined){
            activateUpdate = false;
        }
        else{
            newValue  = event.target.value;
        }
        if(this.props.max!==undefined && this.props.max>0 ){
            if(event.target.value.length > this.props.max){
                activateUpdate = false;
                this.setState({
                    errorMessage: 'Text is too long - max ' + this.props.max + ' characters'
                });
            }
        } 
        if(false 
            //index === 0 && this.state[(param+'Change')].length === 1 && newValue === '' && this.props.emptyOption !== true
          ){
            activateUpdate = false;
        }
        if(activateUpdate){
            console.log(this.state)
            var myArray = {};
            var tempCopy = this.state[(param+'Change')] || {};
            if(tempCopy[key] === undefined){
                tempCopy[key] = [newValue];
                console.log('tempCopy', tempCopy)
            }
            /// start chek
            myArray = {...tempCopy};
            console.log('myarray', myArray)
            if(myArray[key] === undefined){
                myArray[key] = [];
            }
            if(index >= (myArray[key]?.length)){
                console.log('push', myArray[key].length, myArray[key])
                myArray[key].push(newValue);
                this.setState({
                    [(param+'Change')]: myArray,
                    errorMessage: ''
                });
                console.log('added new', myArray)
            }
            else if(myArray.length >= index ){
                console.log('extra new', myArray)
                myArray[key][index] = newValue;
            }
            else{
                console.log('extra new', myArray)
                myArray[key][index] = newValue;
            }
            this.setState({
                [(param+'Change')]: myArray,
                errorMessage: ''
            });
            console.log('State', myArray, this.state)
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            //console.log('new  close', p, this.state, this.state.defaultValue)
            return this.setState({[p] : this.state.defaultValue, [p+'Change']: this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            //console.log('pre setting', this.state[(p+'Change')])
            if(this.state[(p+'Change')] !== null && this.state[(p+'Change')] !== undefined){
                var myObject = this.state[(p+'Change')] || {};
                var cleanObject = {};
                Object.keys(myObject).map((p) => {
                    //var addItemFlag = false;
                    var set = myObject[p];
                    cleanObject[p] = [];
                    set.map((q) => {
                        //console.log('save', p, q, [p][q])
                        if(q?.length>0 && q.trim() !== ''){
                            //console.log('save check', p, q)
                            //addItemFlag = true;
                            var text = q.trim();
                            cleanObject[p].push(text);
                        }
                        return true;
                    })
                    if(cleanObject[p].length === 0){
                        delete cleanObject[p];
                    }
                    return true;
                })
                if(this.props.dbUpdateLink !== undefined){
                    this.props.dbUpdateLink.update({[p]: cleanObject });
                }
                if(this.props.updateState !== undefined){
                    this.props.updateState({[p]: cleanObject });
                }
                console.log('new setting', cleanObject)
            }
            return true;
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title, mapOptions, mapOptionsText} = this.props;
        const { theme, classes } = this.props;
        //console.log('state', this.state)
        //console.log('props', this.props)
        return (
        content
            ?
            <div className={classes.grow}>
                <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                          </Grid>
                        <Grid item xs >  </Grid>
                        <Grid item xs >
                            {(this.props.access === undefined || this.props.access === true)
                            &&
                                <IconButton onClick={this.handleSettingOpen}> 
                                    <EditIcon fontSize="small" style={{color:"red"}}/>
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                    {params.map((p, index) => (
                        <p style={theme.textSettings} key={"current_"+p}>
                            <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                {(p !== undefined && content[p] !== undefined && Object.keys(content[p]).length > 0 )
                                    ?   <strong>{Object.keys(content[p]).length + ' Items available'}</strong>
                                    :   'n/a'
                                }
                            </span>
                        </p>
                    ))}
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                        <DialogContent>
                            { this.state.openSetting && params.map((p, index) => (
                                <div style={{width: `100%`}} key={"change_"+p}>
                                    <Grid
                                        container
                                        direction="row"
                                        style={{width: `100%`}}
                                        justify="space-between"
                                        alignItems="flex-start"
                                    >
                                        {mapOptions!== undefined && mapOptions.map((m, indexLow) => (
                                            <>
                                                <Grid item xs={6} key={'title_'+m+indexLow}>
                                                    <strong>{mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m}</strong>
                                                </Grid>
                                                <Grid item xs={6} key={'field_'+m+'_'+indexLow}>
                                                    <TextField
                                                        key={'main_'+m+'_'+indexLow+'_0'}
                                                        fullWidth
                                                        style={ {fontSize: '16px'} }
                                                        label={'Add ' + (mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m) + ' - Line '+'1' + (this.props.max > 0 ? (" (" + (this.state[(p+'Change')]?.[m]?.[0]?.length || "0") + "/"+ this.props.max+ " character)") : "")}
                                                        value={this.state[(p+'Change')]?.[m]?.[0] || ''}
                                                        onChange={(e) => this.handleSettingChange(p, 0, m, e)}
                                                    />
                                                    {this.props.maxItems > 1 &&
                                                        <TextField
                                                            key={'main_'+m+'_'+indexLow+'_1'}
                                                            fullWidth
                                                            style={ {fontSize: '16px'} }
                                                            label={'Add ' + (mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m) + ' - Line '+'2'}
                                                            value={this.state[(p+'Change')]?.[m]?.[1] || ''}
                                                            onChange={(e) => this.handleSettingChange(p, 1, m, e)}
                                                        />
                                                    }
                                                    {this.props.maxItems > 2 &&
                                                        <TextField
                                                            key={'main_'+m+'_'+indexLow+'_2'}
                                                            fullWidth
                                                            style={ {fontSize: '16px'} }
                                                            label={'Add ' + (mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m) + ' - Line '+'3'}
                                                            value={this.state[(p+'Change')]?.[m]?.[2] || ''}
                                                            onChange={(e) => this.handleSettingChange(p, 2, m, e)}
                                                        />
                                                    }
                                                </Grid>
                                            </>
                                        )) }
                                        {false && this.state[p] !== undefined && Array.isArray(this.state[p]) 
                                            &&
                                            this.state[p].map((x, indexParam) => {
                                                return(
                                                    mapOptions!== undefined && mapOptions.map((m, indexLow) => (
                                                        <Grid item xs={6} key={'field_'+indexParam+'_'+indexLow}>
                                                            <TextField
                                                                key={'main_'+m+'_'+indexLow}
                                                                fullWidth
                                                                style={ {fontSize: '16px'} }
                                                                label={'Add ' + (mapOptionsText!==undefined && mapOptionsText[indexLow]!== undefined ? mapOptionsText[indexLow] :  m)}
                                                                value={this.state[p][indexParam][m] || ''}
                                                                onChange={(e) => this.handleSettingChange(p, indexParam, m, e)}
                                                            />
                                                        </Grid>
                                                    ))
                                                )
                                            })
                                        }
                                        <Grid item xs={12}>
                                            <p>&nbsp;</p>
                                        </Grid>
                                    </Grid>
                                </div>
                            )) }
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                    <p style={theme.textErrorCenterPadding}>
                                        {this.state.errorMessage}
                                    </p>
                                :   <p> </p>
                            }
                        </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSettingSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        : <div> </div>
                
        );
    }
}

SettingContentReverseMapList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentReverseMapList);
