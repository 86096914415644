import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import * as chatAgent from '../../../chatAgent';
import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Switch from "react-switch";
import {DeleteExamplesFromOtherIntentes} from '../Training/TrainingDataManagement';
import PromptSupport from '../../Admin/Modules/PromptSupport';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';

import DialogIntents from '../Modules/DialogIntents';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';

/// Group move
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentArray extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: [],
            openSetting: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        if(this.props.externalOpen === true && this.state.openSetting !== true && prevProps.externalOpen !== true){
            this.handleSettingOpen();
        }
    }

    loadNLUSets = async () =>  {
        let counterSetsAvailable = 0;
        let counterSetsGood = 0;
        if(this.state.intentSets?.length > 0){
            console.log('intents there')
        }
        else{
            let examples = [];
            let intentSets = {};
            //console.log('props', this.props)
            if(this.props.exampleResults!== undefined && Object.keys(this.props.exampleResults).length>1){
                examples = Object.keys(this.props.exampleResults);
                intentSets = this.props.exampleResults;
            } 
            //console.log(Object.keys(intentSets))
            //console.log('content', this.props.content)
            if(this.props.searchIntents === true ){
                if(this.props.params !== undefined && this.props.content !== undefined){
                    this.props.params.map( async (p) => {
                        //console.log(p, this.props.content[p])
                        if(this.props.content[p] !== undefined && this.props.content[p] instanceof Array
                            && this.props.content[p].length > 0){
                            let indexItem = -1;
                            //this.props.content[p].forEach(async (item) => {
                            let indexKey = 0;
                            while(indexKey < (this.props.content[p].length)){
                                let item = this.props.content[p]?.[indexKey] || '';
                                indexItem = parseInt(indexKey);
                                //this.props.content[p].map(async (item, indexItem) => {
                                //console.log('sending item', indexKey, item, indexItem)
                                if(item?.length>0 && !examples.includes(item) && this._isMounted){
                                    examples.push(item);
                                    chatAgent.sendMessage({ 'text': item }, 'nluAnalysis')
                                    .then( (result) => {
                                        //console.log('indexSub', indexSub)
                                        //indexItem = indexSub;
                                        try{
                                            var version = 'v2';
                                            if(result.response_selector && result.response_selector[result.intent.name] && 'full_retrieval_intent' in result.response_selector[result.intent.name]) version = 'v1';
                                            //console.log('result', result)
                                            var newIntentMatch = {
                                                //text: item,
                                                text: result.text,
                                                entities : result.entities,
                                                sentiment : result.intent.name,
                                                confidence : {}
                                            };
                                            var retrievalIntent = result.intent.name;
                                            if(result.response_selector[result.intent.name] !== undefined ){
                                                if(version === 'v1'){
                                                    retrievalIntent = result.response_selector[result.intent.name].full_retrieval_intent;
                                                }
                                                else{
                                                    retrievalIntent = result.response_selector[result.intent.name].response.intent_response_key;
                                                }
                                            }
                                            //console.log('retrievalIntent', retrievalIntent)
                                            var intentName = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                                            newIntentMatch['intent'] = intentName;
                                            var intents = []
                                            if(result.response_selector[result.intent.name] !== undefined){
                                                var intent1 = result.response_selector[result.intent.name]['ranking'][0];
                                                retrievalIntent = '';
                                                if(version === 'v1'){
                                                    retrievalIntent = intent1.full_retrieval_intent;
                                                    intent1['text'] = intent1.name+'';
                                                }
                                                else{
                                                    retrievalIntent = intent1.intent_response_key;
                                                    intent1['text'] = result.response_selector[result.intent.name].response.responses[0].text+'';
                                                }
                                                intent1['sentiment'] = retrievalIntent.substring(0, retrievalIntent.indexOf("/"));
                                                intent1['name'] = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                                                intents.push(intent1);
                                                var intent2 = result.response_selector[result.intent.name]['ranking'][1];
                                                if(version === 'v1'){
                                                    retrievalIntent = intent2.full_retrieval_intent;
                                                    intent2['text'] = intent2.name+'';
                                                }
                                                else{
                                                    retrievalIntent = intent2.intent_response_key;
                                                    intent2['text'] = result.response_selector[result.intent.name].response.responses[0].text+'';
                                                }
                                                intent2['sentiment'] = retrievalIntent.substring(0, retrievalIntent.indexOf("/"));
                                                intent2['name'] = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                                                intents.push(intent2);
                                            }
                                            else{
                                                intents=[retrievalIntent]
                                            }
                                            try{
                                                var intent3 = result.response_selector[result.intent_ranking[1].name]['ranking'][0];
                                                if(version === 'v1'){
                                                    retrievalIntent = intent3.full_retrieval_intent;
                                                    intent3['text'] = intent3.name+'';
                                                }
                                                else{
                                                    retrievalIntent = intent3.intent_response_key;
                                                    intent3['text'] = '';
                                                }
                                                intent3['sentiment'] = retrievalIntent.substring(0, retrievalIntent.indexOf("/"));
                                                intent3['name'] = retrievalIntent.substring(retrievalIntent.indexOf("/") + 1);
                                                intents.push(intent3);
                                            }
                                            catch(err){}
                                            newIntentMatch.confidence['intent'] = intents;
                                            var sentiments = []
                                            sentiments.push(result.intent_ranking[0]);
                                            sentiments.push(result.intent_ranking[1]);
                                            newIntentMatch.confidence['sentiment'] = sentiments;
                                            if(result.response_selector[result.intent.name] !== undefined){
                                                if(version === 'v1'){
                                                    newIntentMatch['response'] = result.response_selector[result.intent.name].response.name;
                                                }
                                                else{
                                                    newIntentMatch['response'] = result.response_selector[result.intent.name].response.responses[0].text;
                                                }
                                                newIntentMatch['accuracy'] = result.response_selector[result.intent.name].response.confidence;
                                            }
                                            else{
                                                newIntentMatch['response'] = result.text;
                                                newIntentMatch['accuracy'] = result.intent.confidence;
                                            }
                                            //intentSets[item] = newIntentMatch;
                                            intentSets[result.text] = newIntentMatch;
                                            if(this._isMounted){
                                                this.setState({intentSets})
                                            }
                                            counterSetsAvailable += 1;
                                            if(newIntentMatch['accuracy'] >= 0.5 && newIntentMatch['intent'] === this.props.intentUser?.intent){
                                                //console.log(newIntentMatch['accuracy'], newIntentMatch['intent'] , this.props.intentUser?.intent)
                                                counterSetsGood += newIntentMatch['accuracy'];
                                            }
                                            else if(newIntentMatch['intent'] === this.props.intentUser?.intent){
                                                //console.log('lowaccuracy', item, result)
                                                if(this.props.updateMoveLowAccuracy){
                                                    this.props.updateMoveLowAccuracy(item, newIntentMatch);
                                                    indexKey -= 1;
                                                }
                                            }
                                            else if(newIntentMatch['intent'] !== this.props.intentUser?.intent){
                                                if(this.props.updateMoveFalseData){
                                                    this.props.updateMoveFalseData(item, newIntentMatch);
                                                    indexKey -= 1;
                                                }
                                                if(this.props.addToTrainingData){
                                                    this.addToTrainingData(newIntentMatch);
                                                }
                                                //console.log('NLU bad example:', item, result)
                                            }
                                            //console.log('SHARE examples', this.props.content[p].length, indexItem )
                                            if(this.props.content[p].length === indexItem+1 ){
                                                if(this._isMounted){
                                                    //this.setState({examples})
                                                    //console.log('SHARE examples', examples )
                                                    if(this.props.updateAccuracy !== undefined){
                                                        this.props.updateAccuracy((counterSetsGood/counterSetsAvailable).toFixed(2));
                                                    }
                                                    if(this.props.updateExampleResults !== undefined){
                                                        this.props.updateExampleResults(intentSets);
                                                        //console.log('SHARE intent', intentSets)
                                                    }
                                                    //console.log('intent',  this.props.intentUser?.intent)
                                                    //console.log('examples',this.props.content[p].length, indexItem, counterSetsAvailable, counterSetsGood, counterSetsGood/counterSetsAvailable)
                                                }
                                            }
                                            //console.log(item, newIntentMatch)
                                            return true;
                                        }
                                        catch(error){
                                            console.log(error)
                                            return true;
                                        }
                                    })
                                    await new Promise(resolve => setTimeout(resolve, 500))
                                }
                                else if(!this._isMounted){
                                    console.log('do nothing')
                                    return true;
                                }
                                else{
                                    counterSetsAvailable += 1;
                                    if(intentSets[item] !== undefined && intentSets[item]['accuracy'] > 0.4 && intentSets[item]['intent'] === this.props.intentUser?.intent){
                                        //console.log(newIntentMatch['accuracy'], newIntentMatch['intent'] , this.props.intentUser?.intent)
                                        counterSetsGood += intentSets[item]['accuracy'];
                                    }
                                    //return true;
                                }
                                indexKey += 1;
                            }
                            if(this._isMounted){
                                this.setState({intentSets});
                            }
                            if(this.props.updateAccuracy !== undefined){
                                this.props.updateAccuracy((counterSetsGood/counterSetsAvailable).toFixed(2));
                            }
                        }
                        return true;
                    })
                }
            }
        }
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                this.loadNLUSets();
                return this.setState({[p] : this.props.content[p], [(p+'Change')]: this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue, [(p+'Change')]:this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, index, event) => {
        var activateUpdate = true;
        if(this.props.max!==undefined && this.props.max>0 ){
            if(event.target.value.length > this.props.max){
                activateUpdate = false;
                this.setState({
                    errorMessage: 'Text is too long - max ' + this.props.max + ' characters'
                });
            }
        }
        if(activateUpdate){
            var myArray = [...this.state[(param+'Change')]];
            var textValue = event.target.value;
            var textValueItems = textValue.split("#");
            var cleanValues = [];
            //console.log(textValueItems)
            if(textValueItems.length > 1){
                textValueItems.map(item => {
                    item = item.trim()
                    if (item !== ""){
                        console.log(item)
                        cleanValues.push(item);
                    }
                    return true;
                })
                if(cleanValues.length>0){
                    textValue = cleanValues[0];
                }
            }
            if(index > (this.state[(param+'Change')].length+1) && textValue !== ''){
                myArray.push(textValue);
                this.setState({
                    [(param+'Change')]: myArray
                });
            } 
            else if(textValue !== undefined){
                myArray[index] = textValue;
            }
            if(cleanValues.length>1){
                textValue = cleanValues[0];
                cleanValues.map((item, index) => {
                    if (index > 0){
                        myArray.push(item);
                    }
                    return true;
                })
            }
            this.setState({
                [(param+'Change')]: myArray,
                errorMessage: ''
            });
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue, [p+'Change']:this.state.defaultValue});
        })
        if(this.props.externalCloseHandler !== undefined){
            console.log('state close handler')
            this.props.externalCloseHandler();
        }
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[(p+'Change')] !== null && this.state[(p+'Change')].length>0 && this.state[(p+'Change')] !== undefined && this.state[(p+'Change')] !== this.props.content[p] ){
                var newArray = this.state[(p+'Change')].filter(function(v){return (v!=='' && v!==undefined)});
                if(this.props.sorting){
                    newArray.sort();
                }
                if(this.props.dbUpdateLink !== undefined){
                    this.props.dbUpdateLink.update({[p]: newArray });
                    //console.log('new setting', newArray)
                }
                if(this.props.updateState !== undefined){
                    this.props.updateState(newArray);
                }
            }
            return '';
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title } = this.props;
        const { theme, classes } = this.props;
        //console.log('state', this.state)
        return (
            content
            ?
            <div className={classes.grow}>
                <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                    <DialogIntents
                        openDialogueUtterance = {this.state.openDialogueUtterance || false}
                        openDialogueIntents = {this.state.openDialogueIntents || false}
                        selectedText = {this.state.selectedText || ""}
                        nluIntent = {this.state.nluIntent || undefined}
                        cleanItem = {this.props.cleanItem || undefined}
                        intentUser = {this.props.intentUser || undefined}
                        handlerMoveList = {(sentiment, intent, tense) => {this.setState({selectionForChange: [], moveSentiment: sentiment, moveIntent: intent, moveTense: tense}) }}
                        handlerRemoveItem = {(item, replaceId, key) => {this.removeItemFromList(item, replaceId, key)}}
                        closeDialogue = {() => {this.setState({openDialogueUtterance: false, openDialogueIntents:false})}}
                        classFilter={this.props.classFilter}
                        leadershipCategories={this.props.leadershipCategories || []}
                        leadershipQualities={this.props.leadershipQualities || []}
                        />
                    <Grid container>
                        <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                        </Grid>
                        <Grid item xs > 
                        </Grid>
                        <Grid item xs >
                            {(this.props.access === undefined || this.props.access === true)
                                &&
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <EditIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                            }
                        </Grid>
                    </Grid>
                    {
                        params.map((p) => (
                            <p style={theme.textSettings} key={"current_"+p}>
                                {content[p]!== undefined && content[p] instanceof Array
                                    ? 
                                        <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                            {this.props.showShort
                                                ?
                                                    content[p].map( (item, index) => (
                                                       index<4
                                                        ? <li key={"sort_"+index}>{item}</li>
                                                        : index === 4
                                                            ? <li key={"sort_"+index}>... more</li>
                                                            : ""
                                                    ))
                                                :
                                                    content[p].map( (item, index) => (
                                                        <li key={"sort_"+index}>{item}</li>
                                                    ))
                                            }                                        
                                        </span>
                                    : <span style={{ float: 'right'}}>{"[n/a]"}</span>
                                }
                                <span>{p} {content[p]!== undefined ? ('('+content[p].length+')') : ''}</span>
                            </p>
                        ))
                    }
                </Card>
                <Dialog
                    open={this.state.openSetting || false}
                    onClose={this.handleSettingClose}
                    fullWidth={true}
                    maxWidth = {'100vw'}
                    PaperProps={{style: {minHeight: '70vh', width: '100vw', maringLeft: -40, marginRight: 0}}}
                >
                    {this.props.intentUser?.sentiment?.length > 2
                        ?
                        <DialogTitle 
                            onClick = {() => {this.setState({selectionForChange: [], moveSentiment: this.props.intentUser?.sentiment, moveIntent: this.props.intentUser?.intent, moveTense: 'default'}) }}
                            >
                                Change Intent - {this.props.intentUser?.sentiment} - {this.props.intentUser?.intent}
                        </DialogTitle>
                        :
                        <DialogTitle> Intent</DialogTitle>
                    }
                    <DialogContent>
                    <form>
                        {params.map((p) => (
                            <div style={{width: `100%`}} key={"change_"+p}> 
                                Array for: <strong>{p}</strong> (Erase, modify, move or add) {this.state[p]?.length}
                                {this.props.promptSupport?.length > 2 &&
                                    <PromptSupport 
                                        prompt = {this.props.promptSupport || ""}
                                    />
                                }
                                {this.state.moveSentiment!== undefined && this.state.moveSentiment!=='' && this.state.moveIntent!==undefined && this.state.moveIntent !== ''
                                    &&
                                    <div style={{marginBottom: 50}}>
                                        <p>
                                            Move check items to: <strong style={{color: "red"}}>{this.state.moveSentiment} / {this.state.moveIntent }</strong> ({this.state.moveTense})
                                        </p>
                                        <RadioGroup row aria-label="Type" value={this.state.moveTense} name="type" onChange={(e) => {this.setState({moveTense: e.target.value}) } }>
                                            <FormControlLabel value="default" control={<Radio color='secondary' />} label="default" />
                                            <FormControlLabel value="past" control={<Radio color='secondary'/>} label="past" />
                                            <FormControlLabel value="future" control={<Radio color='secondary' />} label="future" />
                                        </RadioGroup>
                                        &nbsp;
                                        <Switch
                                            uncheckedIcon={false}
                                            checked={(this.state.showFeaturedOnly) ? true : false}
                                            onChange={(c, event, id) => {
                                                this.setState({showFeaturedOnly: (c)});
                                            }}
                                        />
                                        &nbsp;
                                        <Checkbox
                                            style ={{ color: "blue" }}
                                            onChange={(event) => {
                                                var selectionForChange = this.state.selectionForChange || [];
                                                if(event.target.checked){
                                                    selectionForChange = this.state[p] || [];
                                                }
                                                else{
                                                    selectionForChange = [];
                                                }
                                                this.setState({selectionForChange});
                                            }}
                                        />
                                        Select All
                                        &nbsp;
                                        <IconButton disabled={this.state.loadingNewSentences || false} onClick={() => this.getNewResponses()}> 
                                            <PlaylistAddIcon fontSize="small" style={{color:this.state.loadingNewSentences? 'grey' : "orange"}}/>
                                        </IconButton>
                                    </div>
                                }
                                {this.state[p] !== undefined && Array.isArray(this.state[p]) 
                                    &&
                                    this.state[p].map((x, index) => (
                                    <Grid
                                        key={"org_"+index+x}
                                        container
                                        spacing={1}
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{
                                            visibility: this.state.showFeaturedOnly && (this.state.intentSets[x]===undefined || (this.state.intentSets[x].intent !== this.state.moveIntent)) ? 'hidden' : 'visible',
                                            height: this.state.showFeaturedOnly && (this.state.intentSets[x]===undefined || (this.state.intentSets[x].intent !== this.state.moveIntent )) ? 0 : 'auto'
                                        }}
                                        >
                                        <Grid item xs={1}>
                                            {this.state.moveSentiment!== undefined && this.state.moveSentiment!=='' && this.state.moveIntent!==undefined && this.state.moveIntent !== ''
                                                ?
                                                    <Checkbox
                                                        style ={{  color: "red" }}
                                                        checked={this.state.selectionForChange?.includes(x) || false}
                                                        onChange={(event) => {
                                                            //console.log('chnge', event)
                                                            var selectionForChange = this.state.selectionForChange || [];
                                                            if(event.target.checked){
                                                                if(!selectionForChange.includes(x)){
                                                                    selectionForChange.push(x);
                                                                }
                                                            }
                                                            else{
                                                                if(selectionForChange.includes(x)){
                                                                    var index = selectionForChange.indexOf(x);
                                                                    if (index !== -1) {
                                                                        selectionForChange.splice(index, 1);
                                                                    }
                                                                }
                                                            }
                                                            this.setState({selectionForChange});
                                                        }}
                                                    />
                                                :   index
                                            }
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField
                                                style={{ paddingLeft: 10, paddingRight:10, fontSize: '16px'}}
                                                fullWidth
                                                key={"change_"+index}
                                                id={"change_"+index}
                                                disabled={this.props.addOnly || false}
                                                defaultValue={x}
                                                label={this.props.noLabel? "" : x}
                                                onChange={(e) => this.handleSettingChange(p, index, e)}
                                            />
                                            {this.state.intentSets!== undefined && this.state.intentSets[x] !== undefined
                                                && this.state.intentSets[x].accuracy !== undefined 
                                                && 
                                                <span>
                                                    {this.state.moveIntent === this.state.intentSets[x].intent
                                                        ? 
                                                            <strong style={{color: "green"}}
                                                                onClick = {() => {this.setState({selectionForChange: [x], showFeaturedOnly: true, moveSentiment: this.state.intentSets[x].sentiment, moveIntent: this.state.intentSets[x].intent, moveTense: 'default'}) }}
                                                            >
                                                            <br/>
                                                                {this.state.intentSets[x].sentiment}/{this.state.intentSets[x].intent}
                                                            </strong>
                                                        :
                                                            this.state.intentSets[x].intent === this.props.intentUser?.intent
                                                            ?
                                                                <strong style={{color: "blue"}}
                                                                onClick = {() => {this.setState({selectionForChange: [x], showFeaturedOnly: true, moveSentiment: this.state.intentSets[x].sentiment, moveIntent: this.state.intentSets[x].intent, moveTense: 'default'}) }}
                                                                >
                                                                    <br/>
                                                                    {this.state.intentSets[x].sentiment}/{this.state.intentSets[x].intent}
                                                                </strong>
                                                            :
                                                                <strong style={{color: "red"}}
                                                                    onClick = {() => {this.setState({selectionForChange: [x], showFeaturedOnly: true,moveSentiment: this.state.intentSets[x].sentiment, moveIntent: this.state.intentSets[x].intent, moveTense: 'default'}) }}
                                                                >
                                                                <br/>
                                                                    {this.state.intentSets[x].sentiment}/{this.state.intentSets[x].intent}
                                                                </strong>
                                                    }
                                                    
                                                </span>
                                            }
                                        </Grid>
                                        <Grid item xs={1}>
                                            {this.props.addOnly !== true
                                                &&
                                                <CastForEducationIcon  
                                                    onClick={() => {
                                                        if(this.props.searchIntents && this.state.intentSets!== undefined && this.state.intentSets[x] !== undefined
                                                            && this.state.intentSets[x].intent !== undefined  ){
                                                            this.setState({
                                                                selectedText: x, 
                                                                openDialogueUtterance: false, 
                                                                openDialogueIntents: true,
                                                                nluIntent: this.state.intentSets[x],
                                                                selectedSentiment: this.state.intentSets[x].sentiment,
                                                                selectedIntent: this.state.intentSets[x].intent,
                                                            })
                                                        }
                                                        else if( this.props.intentUser!== undefined && this.props.intentUser?.sentiment!== undefined && this.props.intentUser?.intent!== undefined ){
                                                            this.setState({
                                                                selectedText: x, 
                                                                openDialogueUtterance: false, 
                                                                openDialogueIntents: true,
                                                                nluIntent: this.props.intentUser,
                                                                selectedSentiment: this.props.intentUser?.sentiment || '',
                                                                selectedIntent: this.props.intentUser?.intent || '',
                                                            })
                                                        }
                                                        else{
                                                            this.setState({
                                                                selectedText: x, 
                                                                openDialogueUtterance: false, 
                                                                openDialogueIntents: true,
                                                                nluIntent: this.props.intentUser,
                                                            })
                                                        }
                                                    }}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={1}>
                                            {this.state.intentSets!== undefined && this.state.intentSets[x] !== undefined
                                                && this.state.intentSets[x].accuracy !== undefined 
                                                && 
                                                <span>
                                                    {this.state.intentSets[x].intent === this.props.intentUser?.intent
                                                        ? 
                                                            <strong style={{color: "green"}}>
                                                                {parseFloat(this.state.intentSets[x].accuracy).toFixed(2)+"%"}
                                                            </strong>
                                                        :
                                                            this.state.intentSets[x].intent === this.props.intentUser?.intent
                                                            ?
                                                                <strong style={{color: "blue"}}>
                                                                    {parseFloat(this.state.intentSets[x].accuracy).toFixed(2)+"%"}
                                                                </strong>
                                                            :
                                                                <strong style={{color: "red"}}>
                                                                    {parseFloat(this.state.intentSets[x].accuracy).toFixed(2)+"%"}
                                                                </strong>
                                                    }
                                                    
                                                </span>
                                            }
                                        </Grid>
                                    </Grid>
                                ))}
                                <div style={{marginTop: 40}}>
                                    {(this.props.addNewButton === true)
                                        ?
                                            this.state.fordbidNewSentences
                                            ?
                                                <SyncDisabledIcon fontSize="small" style={{color: 'red'}}/>
                                            :
                                                <IconButton disabled={this.state.loadingNewSentences || false} onClick={() => this.getNewSentences()}> 
                                                    <PlaylistAddIcon fontSize="small" style={{color:this.state.loadingNewSentences? 'grey' : "blue"}}/>
                                                </IconButton>
                                        :   ''
                                    }
                                    <TextField
                                        style={{ float: 'right', width:'60%', fontSize: '16px'}}
                                        fullWidth
                                        key={"change_new"}
                                        id={"change_new"}
                                        label={'add new'}
                                        onChange={(e) => this.handleSettingChange(p, (this.state[p].length+1), e)}
                                    />
                                </div>
                            </div>
                        ))  }
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?
                                <p style={theme.textErrorCenterPadding}>
                                    <br/><br/><br/><br/>
                                    {this.state.errorMessage}
                                </p>
                            : 
                                <p> </p>
                        }
                    </form>
                    </DialogContent>
                    <DialogActions>
                        {this.state.moveSentiment!== undefined && this.state.moveSentiment!=='' && this.state.moveIntent!==undefined && this.state.moveIntent !== ''
                            && this.state.selectionForChange!== undefined && this.state.selectionForChange.length > 0
                            &&
                                <Button style={{backgroundColor: 'red'}} onClick={this.handleMoveIntents}>
                                    Move
                                </Button>
                        }
                        {false && this.props.moveItems !== undefined && params !== undefined && this.state[params[0]]?.length>0
                            &&
                            <Button style={{backgroundColor: 'red'}} 
                                onClick={() => {
                                    this.props.moveItems((this.state[params[0]] || []))
                                    console.log('this.state[params[0]]', this.state[params[0]])
                                }}>
                                Accept
                            </Button>
                        }
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        {((this.state.errorMessage === undefined || this.state.errorMessage==='') && this.props.dbUpdateLink !== undefined)
                        ?
                            <Button onClick={this.handleSettingSubmit}>
                                Save
                            </Button>
                        :
                            <Button disabled>
                                Save
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        :   <div> </div>
                
        );
    }

    removeItemFromList(item, replaceId, key='') {
        // 1st remove from array list
        if(this.props.keepContent !== true){
            this.props.params.map((p) => {
                //console.log('key', key, p)
                if(replaceId !== this.props.intentId || (key !== '' && key !== p)){
                    if(this.props.dbUpdateLink !== undefined){
                        this.props.dbUpdateLink.update({[p]: this.props.firebase.fieldValue.arrayRemove(item) })
                        .then(() => {
                            //console.log('úpdate',  this.props.content[p])
                            if(this.props.content[p]!== undefined){
                                this.setState({[p] : this.props.content[p], [p+'Change']: this.props.content[p]});
                            }
                        });
                    }
                }
                return true;
            })
        }
        //console.log('removeing element', item)
        // 2nd remove from intentlist if available
        if(item !== undefined && item!== ""){
            var id = item.replace(/[^A-Za-z]/g,'');
            id = id.replace(/ /g,'');
            if(id.length > 75){
                id = id.substring(0, 75);
            }
            //console.log('DELETE id from training data sets', id)
            if(id !== undefined && id !== ""){
                this.props.firebase.trainingDataItem(id).delete();
            }
        }
    }

    addToTrainingData(intentSet) {
        //console.log('negative', intentSet)
        if(intentSet !== undefined && intentSet.text !== ""){
            var id = intentSet.text.replace(/[^A-Za-z]/g,'');
            id = id.replace(/ /g,'');
            if(id.length > 75){
                id = id.substring(0, 75);
            }
            intentSet['createdAt'] = this.props.firebase.fieldValue.serverTimestamp();
            console.log('Add id to training data sets', id)
            if(id !== undefined && id !== ""){
                this.props.firebase.trainingDataItem(id).set(intentSet);
            }
        }
    }

    handleMoveIntents = () => {
        if(this.state.moveSentiment!== undefined && this.state.moveSentiment!=='' && this.state.moveIntent!==undefined && this.state.moveIntent !== ''
            && this.state.moveTense!==undefined && this.state.moveTense !== ''){
            if(this.state.selectionForChange !== undefined && this.state.selectionForChange.length > 0){
                this.state.selectionForChange.map(example => {
                    this.addExampletoIntentFromArray(example, this.state.moveTense , this.state.moveSentiment, this.state.moveIntent);
                    return true;
                })
                /// deactivte as this can have negative impacts
                if(false && this.state.selectionForChange?.length < 5){
                    this.state.selectionForChange.map(example => {
                        console.log('start paraphrase', example)
                        this.getParaphrasesRockyAI(example).then(result => {
                            console.log('result parah', result)
                            result.forEach(item => {
                                this.addExampletoIntentFromArray(item, this.state.moveTense , this.state.moveSentiment, this.state.moveIntent);
                            })
                        })
                        return true;
                    })
                }
            }
        } 
    }

    addExampletoIntentFromArray = (example, tense, sentiment, intent) => {
        if(this._isMounted){
            //console.log('home: ' + this.props.groupIntent, this.props.groupSentiment )
            if(sentiment!==undefined && intent!==undefined){
                //console.log('Searching for ('+tense+ '): ' + intent, sentiment)
                this.setState({errorMessage : 'Searching for ('+tense+ '): ' + intent});
                //ar index = 0;
                this.props.firebase
                .agentIntentClasses()
                .where('sentiment', '==', sentiment)
                .where('intent', '==', intent)
                .where('tenses', 'array-contains', tense)
                .get().then(snapshot => {
                    if(snapshot.empty){
                        return;
                    }
                    else{
                        var newIds = []
                        snapshot.forEach((doc) =>{
                            newIds.push(doc.id);
                            //console.log( tense + ":", example)
                            var upDateSet = {}
                            var key = 'examples';
                            if(this.props.selectedExplanation!== undefined &&  this.props.selectedExplanation!== ""){
                                upDateSet["responses"] = this.props.firebase.fieldValue.arrayUnion(this.props.selectedExplanation.trim())
                            }
                            if(tense === 'future' || example.includes('will ') || example.includes("'ll ")){
                                upDateSet["examplesFuture"] =  this.props.firebase.fieldValue.arrayUnion(example.trim());
                                key = 'examplesFuture';
                            }
                            else if (tense === 'past' || example.includes('did ') || example.includes("had ")){
                                upDateSet["examplesPast"] =  this.props.firebase.fieldValue.arrayUnion(example.trim());
                                key = 'examplesPast';
                            }
                            else{
                                upDateSet["examples"] = this.props.firebase.fieldValue.arrayUnion(example.trim())
                            }
                            this.props.firebase.agentIntent(doc.id).update(upDateSet)
                            var removeText = example;
                            //console.log('remove', removeText)
                            this.removeItemFromList(removeText, doc.id, key);
                            if(this._isMounted){
                                console.log('found  :', doc.data(), upDateSet)
                                this.setState({errorMessage : 'Added '+example.trim() +' to ('+tense+ ') ' + intent})
                            }
                            //index += 1;
                        })

                        // 3rd remove from from all other training sets
                        DeleteExamplesFromOtherIntentes(this, example, newIds);
                        if(this.props.cleanItem !== undefined){
                            this.props.cleanItem(example);
                            console.log('state clean item')
                            this.handleSettingOpen();
                        }
                        //console.log('added: ', sentiment, intentList)
                    }
                })
            }
            else{
                this.setState({errorMessage : 'Please choose sentiment and intent first!'})
            }
        }
    }


    getNewSentences(){
        this.setState({loadingNewSentences : true})
        this.getNewSentencesRockyAI();
    }
    getNewResponses(){
        this.setState({loadingNewSentences : true})
        this.getNewSentencesRockyAI('responses');
    }

    getNewSentencesWordAI = async () => {
        //var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences'
        var url = 'https://wai.wordai.com/api/rewrite'
        let data = {
            'input': 'your text here',
            'rewrite_num' : 10,
            'quality': 'Readable',
            'email': 'admin@rockyrobots.com',
            'key': '81c8385223a1cf1f4a149486807ec4e2',
            'return_rewrites': 'true',
            'uniqueness': 3
        }
        data['uniqueness'] = 3;
        data['variants'] = 10;
        let inputTexts = [];
        this.props.params.map((p) => {
            inputTexts = inputTexts.concat(this.props.content[p]);
            return true;
        })
        var inputTextChain = ''
        inputTexts.map( item => {
            if( inputTextChain === ''){
                inputTextChain = item
            }
            else{
                inputTextChain = inputTextChain + '\n' + item
            }
            return true;
        })
        data['input'] = inputTextChain;
        let formData = {
            method: 'post',
            body: JSON.stringify(data)
        }
        console.log('requeting wordau', formData)
        var response = await requestAPI(url, formData);
        let result = await response.json();
        console.log('result', result, response)
        return response;
    }
    getNewSentencesRockyAI = async (type=undefined) => {
        var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences'
        let data = {
            'input': "your text here",
            'rewrite_num' : 10,
            'uniqueness': 3,
        }
        if(type==='responses'){
            data['phrase'] = "Give me the 3 best reactions, each in a short sentence, as an empathetic listener with coaching skills that matches well to any of these example comments "
            console.log('phrase')
        }
        let inputTexts = [];
        this.props.params.map((p) => {
            inputTexts = inputTexts.concat(this.props.content[p]);
            return true;
        })
        var inputTextChain = ''
        var counter = 0;
        inputTexts.map( item => {
            if( inputTextChain === ''){
                inputTextChain = item
            }
            else{
                inputTextChain = inputTextChain + ', \n' + item
            }
            counter += 1;
            return true;
        })
        if(counter > 30) data['rewrite_num'] = 1;
        else if(counter > 20) data['rewrite_num'] = 3;
        data['input'] = inputTextChain;
        console.log('rewrite examples from', type, inputTextChain)
        if(counter > 0 && counter < 60){
            data['input'] = inputTextChain;
            let formData = {
                method: 'post',
                body: JSON.stringify(data)
            }
            //console.log('input', formData)
            var response = await requestAPI(url, formData);
            let result = await response.json();
            console.log('results', type, result)
            if (result !== undefined && result.length > 0){
                var update = {};
                this.props.params.map((p) => {
                    if(type ==='responses'){
                        update['responses'] = this.props.firebase.fieldValue.arrayUnion(...result);
                    }
                    else {
                        update[p] = this.props.firebase.fieldValue.arrayUnion(...result);
                    }
                    return true;
                })
                //console.log('update', update)
                if(this.props.dbUpdateLink !== undefined){
                    this.props.dbUpdateLink.update(update);
                }
            }
            this.setState({loadingNewSentences : false})
            return response;
        }
        else{
            this.setState({fordbidNewSentences : true})
        }
        return response;
    }
    getParaphrasesRockyAI = async (text) => {
        try{
            var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences'
            let data = {
                'input': text,
                'rewrite_num' : 10,
                'uniqueness': 0.5,
                'phrase' : "thesaurus"
            }
            let formData = {
                method: 'post',
                body: JSON.stringify(data)
            }
            //console.log('input sending', data)
            var response = await requestAPI(url, formData);
            let result = await response.json();
            //console.log('paraphrasee result', result)
            return result;
        } catch (e){
            return []
        }
    }

}

function requestAPI(url, options) {
    //console.log('options', options)
    //console.log(options)
    return fetch(url, {
        mode: 'cors',
        headers: {
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}

SettingContentArray.propTypes = {
    classes: PropTypes.object.isRequired
};

//const condition = authUser => !!authUser;

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentArray);
