import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';

/// username
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Card from '@material-ui/core/Card';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class CardChangeName extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            title: (this.props.title || 'Change Name'),
            text: (this.props.text || 'How do you want to be called?')
        };
    }


    render() {
        const { theme } = this.props;
        if(this.props.simple){
            return(
                <div>
                    <p style={{textAlign: 'center', color: theme.palette.secondary.main}} onClick={() => this.setState({openDialogName: true})}>
                        {this.context !==undefined && this.context.username!==undefined ? this.context.username : 'Padawan'}
                    </p>
                    {this.returnDialogueWindow()}
                </div>
            )
        }
        else{
            return (
                <Card style={{...theme.cardSettings, overflowX:'hidden' }}>
                    <div onClick={() => this.setState({openDialogName: true})}>
                        <Grid container direction="row" justify="center" alignItems="center"  spacing={1}>
                            <Grid item > 
                                <AccountCircleIcon style={{ color: theme.palette.primary.dark, fontSize: 34}} /> 
                            </Grid>
                            <Grid item > 
                                <strong>{this.state.title}</strong>
                            </Grid>
                            <Grid item > 
                            </Grid>
                        </Grid>
                        <div style={{ padding:10}}>
                            <span style={theme.textSupport} > 
                                {this.state.text}
                            </span>
                            <br/>
                            <strong style={theme.textSettings.settingsTextDescription} >
                                <q>{this.context !==undefined && this.context.username!==undefined ? this.context.username : 'Padawan'}</q>
                            </strong>
                        </div>
                        <Grid container direction="row" justify="space-between" alignItems="center"  spacing={1}>
                            <Grid item > 
                                
                            </Grid>
                            <Grid item > 
                            </Grid>
                            <Grid item > 
                                <div style={{fontSize:"small", color:'grey', textAlign:'center', marginRight:10}} >
                                    <IconButton color="secondary" onClick={this.handleOpen} style={{padding: 2}}>
                                        <EditIcon fontSize="small" style={{color: "grey"}}/>
                                    </IconButton>
                                    <br/>Change 
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {this.returnDialogueWindow()}
                </Card>
            ); 
        }
    }

    returnDialogueWindow (){
        const { theme } = this.props;
        return(
            <Dialog
                open={this.state.openDialogName || false}
                onClose={() => this.setState({openDialogName: false})}
                fullWidth
                maxWidth="sm">
                <DialogTitle>Change Name</DialogTitle>
                <DialogContent>
                    {this.state.openDialogName &&
                        <>
                            <span style={theme.textSupport}>{this.state.text}</span>
                            <span style={{ float: 'right', display: 'inline-block'}}>
                                <TextField
                                    id="name"
                                    //label="Nickname"
                                    type="text"
                                    variant="outlined"
                                    style={ {fontSize: '16px'} }
                                    //defaultValue={this.context.username || 'Padawan'}
                                    value={this.state.newUsername!== undefined ? this.state.newUsername : (this.context.username || 'Padawan')}
                                    onChange={(e)=> {this.handleChange(e)}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </span>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSettingClose}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSettingSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleChange = (e) => {
        if(e !== undefined && e.target !== undefined){
            var newUsername = e.target.value;
            if(newUsername!==undefined && newUsername!== '' && newUsername.length>1){
                newUsername = newUsername.replace(/[^a-zA-Z ]/g, "");
                //newUsername = newUsername.toLowerCase();
                newUsername = this.titleCase(newUsername);
                if(newUsername.length > 30){
                    newUsername = newUsername.substring(0, 30); 
                }
            }
            if(this.context!== undefined && this.context.uid!== undefined && newUsername!== undefined){
                this.setState({newUsername: newUsername});
            }
        }
    }
    handleSettingSubmit = () => {
        if(this.context!== undefined && this.context.uid!== undefined ){
            var newUsername = this.state.newUsername;
            if(newUsername !== undefined && newUsername !=='' && newUsername.length>=2 ){
                newUsername = newUsername.trim();
                newUsername = this.titleCase(newUsername);
                //console.log(newUsername);
                if(newUsername !== this.context.username){
                    this.props.firebase.user(this.context.uid).update({username : newUsername});
                }
            }
        }
        this.setState({openDialogName: false});
    }
    titleCase(stringName){
        //var name = stringName[0].toUpperCase() + stringName.slice(1).toLowerCase();
        var name = stringName[0].toUpperCase() + stringName.slice(1);
        return name;
    }

    handleSettingClose = () => {
        this.setState({
            openDialogName: false,
            username: '',
        });
    }
}


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(CardChangeName);