import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import DialogAssessment from '../Assessments/DialogAssessment';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {CheckXPLevel} from '../Charts/XPLevels';
import SwipeableViews from 'react-swipeable-views';
//import badgeStart from "../../images/Badge_XP_congrats.png";
import SwipeStepper, {customWidthStyle, customWidthSlideStyle} from '../Modules/SwipeStepper';
import { DataCacheWrite} from "../Modules/DataCache";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DialogAssessQuality from '../Onboarding/DialogAssessQuality';

import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import ForumIcon from '@material-ui/icons/Forum';
import Skeleton from '@material-ui/lab/Skeleton';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = theme => ({
    cardActive: {
        backgroundColor: theme.palette.themeLightGrey,
        marginTop: 2,
        borderRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
        maxWidth: '90%',
    },
    listItem: {
        listStyleType: "none",
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
    listItemAddNew: {
        listStyleType: "none",
        boxShadow: 1,
        //opacity: '40%',
        //border: '0px',
        borderRadius: theme.borderRadius,
        height: '100%',
        //borderStyle: 'dotted',
        borderColor: theme.palette.themeGrey,
        padding: 0
    }
})

export function LoadQuestionnaires(that, user){
    //console.log('user?.programs ', user?.programs )
    var questionnaireSets = [];
    var tempItem = [];
    var programs = (["default"].concat(user?.programs || []))
    programs = [...new Set(programs)];
    //console.log('programs', programs)
    that.props.firebase.questionnaires()
    .where('active', '==', true)
    //.where('public', '==', true)
    .where('program', 'in', programs)
    .get()
    .then((snapshot) => {
        snapshot.forEach(doc => {
            var assessmentPackage = doc.data();
            if(that._isMounted && assessmentPackage!==undefined && assessmentPackage!==null){
                //console.log('assessmentPackage', assessmentPackage)
                if(assessmentPackage.category !== undefined && ['OCEAN', 'EGGUP', 'PERMA-V', 'CUSTOM'].includes(assessmentPackage.category)){
                    var set = {...assessmentPackage, id: doc.id};
                    //console.log('set', set)
                    //// ignore the specific topic questionanires
                    if(!(set.program in user?.programs) && set.leadership_category?.length > 3 && set.leadership_category !== 'default' && set.program === 'default' && set.leadership_category !== user.currentCategory){
                        //console.log('ignore items', set.leadership_category)
                    }
                    //// second is the perma
                    else if(doc.id === 'jk0HXnxdDJ2fCmqswkYS' && that._isMounted){
                        tempItem.push(set);
                        that.setState({
                            permaAssessment: set,
                            permaAssessmentId: doc.id
                        })
                        //questionnaireSets.unshift(set);
                    }
                    /// first positon the standard 
                    else if(doc.id === '5pEXdA6wH9eYIyFHTFlx' && that._isMounted){
                        tempItem.push(set);
                        that.setState({
                            oceanAssessment: set,
                            oceanAssessmentId: doc.id
                        })
                    }
                    else if(assessmentPackage.leadership_category !== undefined && assessmentPackage.leadership_category !== ''
                        && assessmentPackage.leadership_category === user.currentCategory){
                        questionnaireSets.push(set);
                    }
                    else if((programs.includes(set.program)) && set.program !== 'default'){
                        questionnaireSets.unshift(set);
                        //console.log('unshift')
                    }
                    else if((programs.includes(set.program))){
                        questionnaireSets.push(set);
                    }
                    else{
                        //console.log('set.program in programs', set.program, programs)
                    }
                }
            }
        })
        if(that._isMounted){
            if(tempItem.length >= 2){
                questionnaireSets.push(tempItem[1]);
                questionnaireSets.push(tempItem[0]);
            }
            that.setState({questionnaireSets});
            //console.log('items', questionnaireSets)
        }
    })
}

class CardAssessmentSwipe extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            textLoading: "...",
            recentUpdatePoints: 0,
            questionnaireSets: [],
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const xpLevels = {
            modules: CheckXPLevel(this.props.user, 'modules'),
        }
        this.loadQuestionnaires();
        //console.log('xpLevels', xpLevels)
        if (this._isMounted) this.setState({xpLevels});
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps)  {
        if(this._isMounted && this.props.open && prevProps.open !== true && this.state.open !== true){
            if(this.state.dialogEngagementQuality === undefined || this.state.dialogEngagementQuality === ''){
                if (this.props.questionnaireSets!==undefined && this.props.questionnaireSets.length>0){
                    var entry = this.props.questionnaireSets[0];
                    this.setState({ 
                        dialogEngagementQuality: entry.value, 
                        dialogEngagementOpen : true, 
                        dialogEngagementText: entry.definition,
                        dialogEngagementTitle: entry.explanationShort,
                    });
                }
            }
            else if(this._isMounted){
                this.setState({dialogEngagementOpen:true})
            }
        }
        if(this._isMounted && this.props.questionnaireSets !== prevProps.questionnaireSets && prevProps.questionnaireSets !== undefined){
            this.setState({activeStep:1});
        }
        return true
    }

    loadQuestionnaires(){
        LoadQuestionnaires(this, this.props.user)
    }

    render () {
        const {questionnaireSets, engagementStats} = this.state;
        if (true || (questionnaireSets!==undefined && questionnaireSets.length>0)){
            return (
                <div>
                    {
                        this.renderQualitySwipe(questionnaireSets, engagementStats)
                    }
                </div>
            )
        }
        else return "" 
    }

    renderQualitySwipe(questionnaireSets, engagementStats) {
        const {theme, classes} = this.props;
        //console.log(questionnaireSets, engagementStats)
        return(
            <div>
                <div style={{position: 'relative'}}>
                    <h4 style={theme.CardContent.titleText}>
                        {this.props.title?.length> 2 ? this.props.title : 'Self-Assessments'}
                    </h4>
                    {this.props.tutorialOpen 
                    &&
                        <div style={{position: 'absolute', top: -4, right: -6}}>
                            <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('assessments')}}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </div>
                    }
                    {(this.props.user?.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
                        &&
                        <p style={theme.textCenter}> 
                            Strength & weakness &bull; Personality test
                        </p>
                    }
                    {(questionnaireSets!==undefined && questionnaireSets!==null && questionnaireSets.length>=1)
                        ?
                        
                            <React.Fragment>
                                <SwipeableViews
                                    key={('reload'+(Object.keys(questionnaireSets).length+1))}
                                    axis={'x'}
                                    index={this.state['activeStep']}
                                    onChangeIndex={(step) => {
                                        try{
                                            if(this._isMounted) this.setState({activeStep : step});
                                        }
                                        catch (err) {console.log('Swipe Error')}
                                    }}
                                    enableMouseEvents = {this.state.allowRefresh === false? false : true}
                                    disabled = {this.state.allowRefresh === false? true : false}
                                    resistance = {true}
                                    style={customWidthStyle('50vw', '200px', (Object.keys(questionnaireSets).length))}
                                    slideStyle={customWidthSlideStyle('50vW', '200px', (Object.keys(questionnaireSets).length))}
                                    >
                                    {questionnaireSets.map((entry, index) => (
                                        <div key={"quali_"+index}
                                            onClick={() => this.setState({ 
                                                dialogEngagementQuality: entry.value, 
                                                dialogEngagementOpen : true, 
                                                dialogEngagementText: entry.definition,
                                                dialogEngagementTitle: entry.explanationShort,
                                            } )}
                                        >
                                            {(this.state.xpLevels?.modules?.valid || 
                                                (this.props.user !== undefined && this.props.user.roles!==undefined && (!this.props.user.roles.includes("COACHEE") && !this.props.user.roles.includes("OBSERVE"))))
                                                ?
                                                    this.itemTopicTile(entry, index)
                                                :
                                                    this.itemTopicTile(entry, index)
                                                    //this.contactCoach()
                                            }
                                        </div>
                                    ))}
                                </SwipeableViews>
                                <SwipeStepper
                                    handlerSteps = {(step) => this.setState({activeStep:step}) }
                                    steps={(questionnaireSets.length) || 0}
                                    activeStep = {this.state.activeStep || 0}
                                />
                                {true //this.state.openAssessmentId !== undefined //&& this.state.openAssessment
                                    &&
                                    <>
                                        <DialogAssessment
                                            defaultSet={this.props.defaultSet || undefined}
                                            handleOpen={this.state.openAssessment || this.props.openDefaultAssessment || this.props.openOCEANAssessment || false}
                                            assessmentId = {this.props.openDefaultAssessment 
                                                ?   this.state.permaAssessmentId 
                                                :   this.props.openOCEANAssessment
                                                    ?   this.state.oceanAssessmentId
                                                    :   this.state.openAssessmentId}
                                            questionnaireDetails = {
                                                this.props.openDefaultAssessment 
                                                ?   this.state.permaAssessment
                                                :   this.props.openOCEANAssessment
                                                    ?   this.state.oceanAssessment
                                                    :   (this.state.questionnaireDetails || undefined)}
                                            eggupAssessment = {false}
                                            handleClose={() => {
                                                this.setState({openAssessment: false});
                                                if(this.props.handlerClose !== undefined){
                                                    this.props.handlerClose()
                                                }
                                            }}
                                            user={this.props.user}
                                            handleResults={(assessmentResults) => {
                                                //console.log('assessmentResults', assessmentResults) 
                                                this.setState({assessmentResults, quiz: true})
                                            }}
                                        />
                                        {this.state.quiz
                                            &&
                                            <DialogAssessQuality 
                                                openDialog={this.state.quiz || false}
                                                restartDialog={() => {
                                                    this.setState({quiz : true });
                                                }}
                                                closeDialog={() => {
                                                    this.setState({quiz : false })
                                                }}
                                                closeDialogNext={() => {
                                                    this.setState({quiz : false, openAssessment: false});
                                                    if(this.props.handlerClose !== undefined){
                                                        this.props.handlerClose()
                                                    }
                                                }}
                                                openQualityPickerAfterQuiz = {(this.props.openQualityPickerAfterQuiz || this.state.extraQuiz)}
                                                //handleTopicSelection={(preselectQuality) => { this.setState({preselectQuality: preselectQuality})}}
                                                leadershipQualities={this.props.leadershipQualities}
                                                leadershipCategories={this.props.leadershipCategories}
                                                user={this.props.user}
                                                assessmentResults={this.state.assessmentResults}
                                            />
                                        }
                                    </>
                                }
                            </React.Fragment>
                        :
                            <div style={{height: '100%'}}>
                                <GridListTile 
                                    className={classes.listItemAddNew}
                                    style={{
                                        height: '20vh',
                                        minHeight: '200px'
                                    }}
                                >
                                    <Skeleton variant="rect" width={'100%'} height={'100%'}/>
                                </GridListTile>
                            </div>
                    }
                    {(this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
                        ?
                            <p style={theme.textSupportCenter}> 
                                Monthly benchmark &bull; Change & improvement
                            </p>
                        :
                            <p style={theme.textSupportCenter}> 
                                Strength & weakness &bull; Personality test
                            </p>
                    }
                </div>
            </div>
        )
    }

    updateScore(newPoints, oldPoints = undefined){
        //console.log( 'd new', newPoints)
        if(newPoints !== undefined && newPoints > 0){
            if (oldPoints === undefined){
                oldPoints = this.state.recentUpdatePoints || 0 ;
            }
            var totalScore = newPoints + oldPoints;
            if (this.props.updateScore !== undefined){ 
                this.props.updateScore(totalScore) ;
                //console.log('update', totalScore);
            }
            if (this._isMounted && totalScore !== oldPoints){
                //console.log(totalScore, oldPoints, newPoints)
                this.setState({recentUpdatePoints : totalScore});
                DataCacheWrite('updateQualities_'+this.props.user.uid , totalScore, 600)
            }
        }
    }
       
    itemTopicTile(questionnaireSet, index){
        const {classes, theme} = this.props;
        return(
            <div key={'add_new_topic_card_'+index} style={{height: '100%'}}>
                <GridListTile key={'add_new_topic_card_'+index} 
                    className={classes.listItemAddNew}
                    style={{
                        height: '20vh',
                        minHeight: '200px',
                        backgroundColor: this.state['hoveradd_'+index] 
                            ? theme.palette.themeAltGold 
                            : questionnaireSet.backgroundColor!== undefined && questionnaireSet.backgroundColor.length > 3
                                ?   questionnaireSet.backgroundColor
                                :   theme.palette.themeAlertBlue
                                ,
                        backgroundImage:
                            questionnaireSet.url !== undefined && questionnaireSet.url !== '' 
                                ?  'linear-gradient( to right, '+
                                    (this.state['hoveradd_'+index] 
                                        ? theme.palette.themeAltGold 
                                        : questionnaireSet.backgroundColor!== undefined && questionnaireSet.backgroundColor.length > 3
                                            ?   questionnaireSet.backgroundColor
                                            :   theme.palette.themeAlertBlue
                                    )
                                    +' 30%, transparent ), url("'+questionnaireSet.url+'")'
                                :   '',
                        backgroundPosition: 'right 40%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                    onMouseEnter={(this.state['hoveradd_'+index] !== true) ? () => {this.setState({['hoveradd_'+index]: true})} : void(0)}
                    onMouseLeave={(this.state['hoveradd_'+index] === true) ? () => {this.setState({['hoveradd_'+index]: false})} : void(0)}
                    onClick={ () => { 
                        this.setState({questionnaireDetails: questionnaireSet, openAssessmentId: questionnaireSet.id, openAssessment: true});
                    } }
                >
                    <div style={{ 
                        color: theme.palette.secondary.light,
                        height: '64%',
                        padding: 15,
                        }}>
                        {questionnaireSet.icon!== undefined && questionnaireSet.icon.length > 3
                            ? 
                                <img 
                                    src={questionnaireSet.icon} 
                                    style={{ height: '60%', verticalAlign: 'middle'}} 
                                    alt={questionnaireSet.title}  />
                            :   <AddShoppingCartIcon fontSize='large' />
                        }
                    </div>               
                    <GridListTileBar
                        title={
                            <span>
                                {questionnaireSet.premium &&
                                    <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeAltGold}} />
                                }
                                {questionnaireSet.title}
                            </span>
                            }
                        subtitle={<span>{questionnaireSet.subtitle}</span>}
                        style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%"}}
                        actionIcon={
                            <IconButton aria-label={`Add Quest`} >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        }
                    />
                </GridListTile>
            </div>
        )
    }    
    contactCoach(){
        const {classes, theme} = this.props;
        return(
            <div key={'contact_coach'} className={classes.listItemAddNew}>

                <div key={'contact_coach_box'} 
                    style={{height: '100%'}}
                    >
                    <Link to={ROUTES.SUPPORT}>
                    <div style={{ 
                        color: theme.palette.secondary.light,
                        height: '64%',
                        padding: 15,
                        }}> 
                        <ForumIcon fontSize='large' />
                    </div> 

                    <div style={{ 
                        color: theme.palette.secondary.light,
                        height: '36%',
                        paddingLeft: 8
                        }}> 

                        <Grid container 
                            direction="row"
                            justify="space-between"
                            alignItems="flex-end">
                            <Grid item xs={8} >
                                <span>Contact Coach</span>
                            </Grid>
                            <Grid item xs={4} >
                                <IconButton aria-label={'Add New Module'} >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div> 
                    </Link>
                </div>
            </div>
        )
    }
}

export default compose(
    withStyles(styles, { withTheme: true}),
    withFirebase,
)(CardAssessmentSwipe);