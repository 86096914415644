import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import imageSettingGoal from "../../images/settinggoal.png";
import {ExtendDialogueMessage} from '../Routine/DiaryManual';
import SnackbarInfo from '../Modules/SnackbarInfo';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class RoleOverview extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { theme } = this.props;
        //console.log('state', this.state)
        return (
            <Card style={{...theme.cardSettings, overflowX:'hidden', paddingLeft: this.props.type==='quick'? 0 : 20 , paddingRight: this.props.type==='quick'? 0 : 20 }}>
                <div>
                    <Grid container direction="row" justify={this.props.type==='quick'?"center":'flex-start'} alignItems="center"  spacing={1}>
                        <Grid item > 
                            <img src={imageSettingGoal} alt='Vision' style={theme.cardSettings.iconDesign} />
                        </Grid>
                        <Grid item > 
                            <strong>Life Stage</strong>
                        </Grid>
                        <Grid item > 
                        </Grid>
                    </Grid>
                    <div style={{marginBottom:10, textAlign:'center'}}>
                        {this.props.defaultSet?.programLifeStages !== undefined
                            ?   ['Teenager', 'Student', 'Available', 'Professional', 'Executive'].map( (item, index) => {
                                    if(this.props.defaultSet.programLifeStages[item] !== undefined){
                                        return(
                                            <Button 
                                                key={item}
                                                onClick = {this.props.user?.onboardingResponses?.includes(item) ? () => this.roleRemove(item) : () => this.roleAdd(item)}
                                                variant = {this.props.user?.onboardingResponses?.includes(item) ? 'text' : "outlined"}
                                                color = {this.props.user?.onboardingResponses?.includes(item) ? 'primary' : "secondary"}
                                                style={{marginLeft:(index=== 0? 0: 10), marginTop: 10}}>
                                                {this.props.defaultSet.programLifeStages[item]}
                                            </Button>
                                        )
                                    }
                                    else{
                                        return ""
                                    }
                                })
                            :   ['Teenager', 'Student', 'Available', 'Professional', 'Executive'].map( (item, index) => (
                                    <Button 
                                        key={item}
                                        onClick = {this.props.user?.onboardingResponses?.includes(item) ? () => this.roleRemove(item) : () => this.roleAdd(item)}
                                        variant = {this.props.user?.onboardingResponses?.includes(item) ? 'text' : "outlined"}
                                        color = {this.props.user?.onboardingResponses?.includes(item) ? 'primary' : "secondary"}
                                        style={{marginLeft:(index=== 0? 0: 10), marginTop: 10}}>
                                        {item === 'Executive' ? 'Leader' : item === 'Teenager' ? 'Youngster' : item}
                                    </Button>
                                ))
                        }
                    </div>
                </div>

                <SnackbarInfo 
                    alertName={'info_'+String(this.props.features)}
                    alertText = {this.state.alertText || 'This setting will adjust the question asked in the chat.'}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.alertFlag || false}
                    closeHandler = { this.alertCloseHandler}
                />
            </Card>
        ); 
    }

    roleAdd(item){
        if(this._isMounted){
            var preText = 'I am a ';
            if(this.props.coachAccount){
                preText = '';
            }
            const textOptions = {
                Teenager : preText+"teenager on the path to study well",
                Student : preText+"student on the path to develop career",
                Available : preText+"person in search for opportunities", 
                Professional : preText+"professional who wants to improve life and career", 
                Executive : preText+"leader, executive, or entrepreneur", 
            }
            var text = textOptions[item];
            var onboardingResponses = this.props.user?.onboardingResponses || [];
            var removeIndex = onboardingResponses?.indexOf('Student');
            if (removeIndex > -1) {
                onboardingResponses?.splice(removeIndex, 1); 
            }
            removeIndex = onboardingResponses?.indexOf('Available');
            if (removeIndex > -1) {
                onboardingResponses?.splice(removeIndex, 1); 
            }
            removeIndex = onboardingResponses?.indexOf('Teenager');
            if (removeIndex > -1) {
                onboardingResponses?.splice(removeIndex, 1); 
            }
            removeIndex = onboardingResponses?.indexOf('Professional');
            if (removeIndex > -1) {
                onboardingResponses?.splice(removeIndex, 1); 
            }
            removeIndex = onboardingResponses?.indexOf('Executive');
            if (removeIndex > -1) {
                onboardingResponses?.splice(removeIndex, 1); 
            }
            onboardingResponses.push(item);
            //console.log(String(this.props.features), this.props.user)
            this.setState({alertFlag: true, alertText: text})
            if(this.props.user && this.props.user.uid){
                this.props.firebase.user(this.props.user.uid).update({onboardingResponses: onboardingResponses});
                if(!this.props.coachAccount){
                    ExtendDialogueMessage(this.props.firebase, this.props.user, text, 'What is your current role or stage in your life?', 'utter_ask_job', '', 'profile');  
                }  
            }
        }
    }

    roleRemove(item){
        /// do not remove
        if(false && this._isMounted){
            var onboardingResponses = this.props.user?.onboardingResponses || [];
            const removeIndex = onboardingResponses?.indexOf(item);
            if (removeIndex > -1) {
                onboardingResponses?.splice(removeIndex, 1); // 2nd parameter means remove one item only
                if(this.props.user && this.props.user.uid){
                    this.props.firebase.user(this.props.user.uid).update({onboardingResponses: onboardingResponses});
                }
            }
        }
    }

    alertCloseHandler = () => {
        if(this._isMounted){
            //console.log(String(this.props.features), this.state)
            this.setState({alertFlag: false})
        }
    }

}

export function BestForProfile(requirements=['executive', 'professional', 'seeker', 'student', 'teenager'], defaultSet=undefined, preText="Best for:"){
    var text = "";
    var defaultPhrase = {
        executive : "Executives & Leaders",
        professional : "Professionals",
        seeker : "Available or Searching",
        student : "Higher Education Student",
        teenager : "Youngster"
    }
    var defaultNames = {
        executive : "Executive",
        professional : "Professional",
        seeker : "Available",
        student : "Student",
        teenager : "Teenager"
    }
    /***
    {previewCategory.requirements.includes('executive') ? ' | Executives & Leaders' : '' }
    {previewCategory.requirements.includes('professional') ? ' | Professionals' : '' }
    {previewCategory.requirements.includes('seeker') ? ' | Available or Searching' : '' }
    {previewCategory.requirements.includes('student') ? ' | Higher Education Students' : '' }
    {previewCategory.requirements.includes('teenager') ? ' | K-12 & Teenager' : '' }
     */
    var programLifeStages = defaultSet?.programLifeStages || undefined;
    requirements.map((item, index) => {
        if (programLifeStages === undefined){
            if(text !== ""){
                text = text + " | " + (defaultPhrase?.[item] || item);
            }
            else{
                text = (defaultPhrase?.[item] || item);
            }
        }
        else if (programLifeStages?.[defaultNames[item]]?.length > 0){
            if(text !== ""){
                text = text + " | " + programLifeStages[defaultNames[item]][0];
            }
            else{
                text = programLifeStages[defaultNames[item]][0];
            }
        }
        return true;
    } )
    if(text.length > 2){
        text = preText + " " + text
    }
    //console.log('BestoForProfile', 'text', text)
    return text
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(RoleOverview);