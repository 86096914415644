import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import ProgressDoughnut from '../Charts/ProgressDoughnut';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const styles = {
    root: {
        backgroundColor: '#2d2c4c',
    },
    average: {
        float: 'right',
        marginBottom: 0,
        marginRight: 20,
    },
    averageNumber: {
        fontFamily: '"Nunito Sans", sans-serif',
        fontSize: 26,
        fontWeight: 700,
        marginRight: 10,
    },
    card: {
        backgroundColor: 'snow',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
    },
    days: {
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 400,
    },
    delta: {
        backgroundColor: '#2d2c4c',
        borderRadius: 5,
        color: 'white',
        fontFamily: '"Nunito Sans", sans-serif',
        fontSize: 13,
        padding: 2,
    },
    deltaMinus: {
        backgroundColor: '#b50003',
        borderRadius: 5,
        color: 'white',
        fontFamily: '"Nunito Sans", sans-serif',
        fontSize: 13,
        padding: 2,
    },
    info: {
        color: 'darkgrey',
        float: 'right',
        fontSize: 13,
        marginBottom: 0,
        marginRight: 20,
    },
    label: {
        fontSize: 14,
        fontWeight: 700,
        padding: 20,
        marginBottom: 0,
    },
    legend1: {
        color: '#2d2c4c',
        float: 'right',
        fontSize: 13,
        fontWeight: 700,
        marginBottom: 0,
        marginRight: 20,
    },
    legendTitle: {
        color: '#2d2c4c',
        align: 'middle',
        fontSize: 13,
        fontWeight: 700,
        marginBottom: 0,
        marginRight: 20,
    },
    legend2: {
        color: '#7394C6',
        float: 'right',
        fontSize: 13,
        fontWeight: 700,
        marginBottom: 0,
        marginRight: 20,
    },
    nostats: {
        color: 'white',
        alignText: 'center',
        fontSize: 16,
        marginTop: 30,
    },
    subtitle: {
        color: 'white',
        fontSize: 13,
        fontWeight: 700,
        marginTop: 30,
        textAlign: 'center',
    },
    title: {
        color: 'darkgrey',
        fontSize: 13,
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 80,
        paddingRight: 80,
        textAlign: 'center',
    },
};

class CardRoutineStats extends Component {

    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            days: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.unsubscribe = this.props.firebase
        .dialogues()
        .orderBy('createdAt', 'desc')
        .where('userId', '==', this.context.uid)
        .where('dialogueEnded', '==', true)
        .limit(30)
        .get()
        .then(snapshot => {
            if (snapshot.size) {
                let days = [];
                snapshot.forEach(doc => days.push({ ...doc.data(), uid: doc.id }) );
                if(this._isMounted){
                    this.calculateScore(days);
                    this.setState({
                        days,
                        loading: false,
                    });
                }
            } 
            else if(this._isMounted){
                this.setState({ days: null, loading: false });
            }
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
        //this.unsubscribe();
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    getDateArray = (start, end) => {
        let arr = [];
        let dt = new Date(start);
        if (this.state.days) {
            while (dt <= end) {
                dt.setDate(dt.getDate() + 1);
                arr.push([new Date(dt), this.getPrimingSession(dt), this.getReflectionSession(dt)]);
            }
        } else {
            return arr;
        }
        return arr.reverse();
    }
    getPrimingSession = day => {
        let obj =
            this.state.days.find(
            d =>
                new Date(d.createdAt.seconds * 1000).getDate() === day.getDate() &&
                new Date(d.createdAt.seconds * 1000).getMonth() === day.getMonth() &&
                d.dialogueType === "priming");
        return obj;
    }
    getReflectionSession = day => {
        let obj =
            this.state.days.find(
            d =>
                new Date(d.createdAt.seconds * 1000).getDate() === day.getDate() &&
                new Date(d.createdAt.seconds * 1000).getMonth() === day.getMonth() &&
                d.dialogueType === "reflection");
        return obj;
    }

    calculateScore (days) {
        let startDate = new Date(Date.now() - (29 * 24 * 60 * 60 * 1000));
        let endDate = new Date(Date.now());
        let last30Days = this.getDateArray(startDate, endDate);
        // if (startDate < new Date(this.context.journeyStartedAt.seconds * 1000)) {
        //   startDate = new Date(this.context.journeyStartedAt.seconds * 1000);
        // }
        // if (startDate < this.state.firstDialogueDate) {
        //   startDate = this.state.firstDialogueDate;
        // }

        // Count sessions in last 7 days
        let primingCount = 0;
        let reflectionCount = 0;
        let primingMeasurements = 0;
        let reflectionMeasurements = 0;

        // Count sessions in the week before last week ago
        let primingCountPrevWeek = 0;
        let reflectionCountPrevWeek = 0;
        let primingMeasurementsPrevWeek = 0;
        let reflectionMeasurementsPrevWeek = 0;

        last30Days.slice(0,7).forEach(d => {
            if (d[1]) {
                primingCount += 1;
                primingMeasurements += d[1].measurement;
            }
            if (d[2]) {
                reflectionCount += 1;
                reflectionMeasurements += d[2].measurement;
            }
        });

        last30Days.slice(8,15).forEach(d => {
            if (d[1]) {
                primingCountPrevWeek += 1;
                primingMeasurementsPrevWeek += d[1].measurement;
            }
            if (d[2]) {
                reflectionCountPrevWeek += 1;
                reflectionMeasurementsPrevWeek += d[2].measurement;
            }
        });

        let deltaPriming = 0;
        let deltaReflection = 0;

        if (primingCount === 0) { 
            deltaPriming = (0 - (primingMeasurementsPrevWeek / primingCountPrevWeek)).toFixed(1);
        } else {
            deltaPriming =
            ((primingMeasurements / primingCount) -
            (primingMeasurementsPrevWeek / primingCountPrevWeek)).toFixed(1);
        }

        if (reflectionCount === 0) {
            deltaReflection = (0 - (reflectionMeasurementsPrevWeek / reflectionCountPrevWeek)).toFixed(1);
        } else {
            deltaReflection =
            ((reflectionMeasurements / reflectionCount) -
            (reflectionMeasurementsPrevWeek / reflectionCountPrevWeek)).toFixed(1);
        }
        this.setState({primingCount, reflectionCount, deltaPriming, deltaReflection, primingMeasurements, reflectionMeasurements})
    }


    render() {
        const { days , primingCount, reflectionCount, deltaPriming, deltaReflection, primingMeasurements, reflectionMeasurements} = this.state;


        //console.log('Primingcount', primingCount)
        if(this.props.weekChartsOnly){
            return(
                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                    <Grid item sm={6}  > 
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                    >

                                <Grid item xs={12}>
                                    <p style={styles.legend1}>Coaching Chat</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <div  style={{height:80, width:"100%", maxWidth:150, padding:2, margin:0, align:'center'}}>
                                        <ProgressDoughnut
                                            percentage={primingCount / 5 * 100}
                                            />
                                    </div>
                                </Grid>
                                {/* 
                                <Grid item xs={8}>
                                    <p style={styles.legend1}>Coaching Chat</p>
                                    <p style={styles.legend1}>{primingCount} completed</p>
                                    <p style={styles.legend2}>5 weekly goal </p>
                                </Grid>
                                */}
                            </Grid>
                    </Grid>
                    <Grid item sm={6}  >
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                    >
                                <Grid item xs={12}>
                                    <p style={styles.legend1}>Reflective Chat</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <div  style={{height:80, width:"100%", maxWidth:150, padding:2, margin:0, align:'center'}}>
                                        <ProgressDoughnut
                                            percentage={reflectionCount / 5 * 100}
                                        />
                                    </div>
                                </Grid>
                                {/* 
                                <Grid item xs={8}>
                                    <p style={styles.legend1}>Reflective Chat</p>
                                    <p style={styles.legend1}>{reflectionCount} completed</p>
                                    <p style={styles.legend2}>5 weekly goal </p>
                                </Grid>
                                */}
                            </Grid>
                    </Grid>
                </Grid>
            )
        }
        else{
            return(
                <div style={styles.root}>
                    {days
                        ?
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                justify="center"
                                alignItems="flex-start"
                                >
                            <Grid item md={6} style={{ width: '100%' }}>
                                    <h2 style={styles.subtitle}>This Week</h2>
                                <Card style={styles.card}>
                                <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                                >
                            <Grid item xs={5}>
                                <p style={styles.label}>Average Morning&nbsp;Energy</p>
                            </Grid>
                            <Grid item xs={7}>
                                <p style={styles.average}>
                                <span style={styles.averageNumber}>
                                    {isNaN((primingMeasurements / primingCount).toFixed(1))
                                ?
                                "-"
                                :
                                (primingMeasurements / primingCount).toFixed(1) 
                                    }
                                </span>
                                {isNaN(deltaPriming)
                                    ?
                                    <span style={styles.delta}>
                                                +/- 0
                                    </span>
                                    :
                                    <span style={deltaPriming > 0 ? styles.delta : styles.deltaMinus}>
                                {deltaPriming > 0 ? "+" : ""}{deltaPriming}
                                    </span>
                                }
                                </p>
                                {/* <p style={styles.info}>Compared to last week</p> */}
                            </Grid>
                                </Grid>
                                </Card>
                                <Card style={styles.card}>
                                <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                                >
                            <Grid item xs={5}>
                                <p style={styles.label}>Average Day&nbsp;Satisfaction</p>
                            </Grid>
                            <Grid item xs={7}>
                                <p style={styles.average}>
                                <span style={styles.averageNumber}>
                                    {isNaN((reflectionMeasurements / reflectionCount).toFixed(1))
                                ?
                                "-"
                                :
                                (reflectionMeasurements / reflectionCount).toFixed(1)
                                    }
                                </span>
                                {isNaN(deltaReflection)
                                    ?
                                    <span style={styles.delta}>
                                                +/- 0
                                    </span>
                                    :
                                    <span style={deltaReflection > 0 ? styles.delta : styles.deltaMinus}>
                                {deltaReflection > 0 ? "+" : ""}{deltaReflection}
                                    </span>
                                }
                                </p>
                                {/* <p style={styles.info}>Compared to last week</p> */}
                            </Grid>
                                </Grid>
                                </Card>
                                <h2 style={styles.subtitle}>Last 7 Days Stats</h2>
                                <Card style={styles.card}>
                                <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                                >
                            <Grid item xs={4}>
                                <p style={styles.label}>Coaching Chat</p>
                            </Grid>
                            <Grid item xs={4} style={{ paddingBottom: 7 }}>
                                    <ProgressDoughnut
                                        percentage={primingCount / 5 * 100}
                                        />
                            </Grid>
                            <Grid item xs={4}>
                                <p style={styles.legend1}>{primingCount} completed</p>
                                <p style={styles.legend2}>5 weekly goal </p>
                            </Grid>
                                </Grid>
                                </Card>
                                <Card style={styles.card}>
                                <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                                >
                            <Grid item xs={4}>
                                <p style={styles.label}>Reflective Chat</p>
                            </Grid>
                            <Grid item xs={4} style={{ paddingBottom: 7 }}>
                                <ProgressDoughnut
                                    percentage={reflectionCount / 5 * 100}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <p style={styles.legend1}>{reflectionCount} completed</p>
                                <p style={styles.legend2}>5 weekly goal </p>
                            </Grid>
                                </Grid>
                                </Card>
                            </Grid>
                            
                            </Grid>
                        :
                            <div>
                                <p style={styles.nostats}>Please talk with Rocky first to see some information here.</p>
                            </div>
                    }
                </div>
            );
        }
    }
}

export default withFirebase(CardRoutineStats);
