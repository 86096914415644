import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import {IsMobileDevice} from '../Modules/IsMobileDevice';

import TutorialPage from './TutorialPage';
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
});

class DialogTutorialPage extends Component { 
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        var handleBadgeFamily = 'total';
        if(this.props.handleBadgeFamily !== undefined && this.props.handleBadgeFamily !== '' ){
            handleBadgeFamily = this.props.handleBadgeFamily ;
        }
        this.state = {
            loading: true,
            handleBadgeFamily,
        };
    }
    componentDidMount() {
        this.setState({
            loading: false,
        });
    }
    componentDidUpdate(prevProps){
        if(this.props.handleBadgeFamily !== prevProps.handleBadgeFamily && prevProps.handleBadgeFamily !== undefined){
            this.setState({ handleBadgeFamily : this.props.handleBadgeFamily})
        }
    }
    
    render(){
        const { theme } = this.props;
        //console.log(", DT props", this.props)
        return(
            <RemoveScroll enabled={this.props.handleOpen === undefined ? false : this.props.handleOpen}>
                <Dialog
                    open={this.props.handleOpen === undefined ? false : this.props.handleOpen}
                    onClose={this.closeAndUpdate}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    >
                    <DialogTitle style={theme.dialogTitle}>
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.closeAndUpdate}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                Tutorials & App Guide
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={{...theme.dialogTitle.titleLink, color: 'transparent'}} onClick={this.closeAndUpdate}>
                                    Close
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent 
                        style={theme.dialogContent}
                        ref={el => this.dialogRef = el}
                        >
                        {this.props.text?.length> 0
                        &&
                            <h5>{this.props.text}</h5>
                        }
                        {this.props.handleOpen === true &&
                            <TutorialPage 
                                addDefaults={this.props.addDefaults? this.props.addDefaults : false}
                                badgeFamily={this.props.handleBadgeFamily || this.state.handleBadgeFamily} 
                                handleClose={this.closeAndUpdate}
                                handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                            />
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )// end return
    }//end render

    closeAndUpdate = () => {
        if(this.context !== undefined && this.context.tutorialChecked === undefined){
            this.props.firebase.user(this.context.uid).update({tutorialChecked: true});
            //console.log('updated tutoriakl', this.context)
        }
        this.props.handleClose()
    }
};//end class


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogTutorialPage);
