import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import StatsBar from '../Charts/StatsBar';
import BadgeCard from '../Modules/BadgeCard';
import DialogCardBadges from '../Charts/DialogCardBadges';
import BadgeStart from "../../images/Badge_XP_congrats.png";
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import {SetStateLoadingBatches} from '../Charts/LoadingBatches';

import Grid from '@material-ui/core/Grid';
//import * as ROUTES from '../../constants/routes';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
    },
    listItem: {
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
});

class CardGroupRanking extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            loading: true 
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        this.getRankingStats();
    }
    componentWillUnmount (){
        this._isMounted = false;
    }

    getRankingStats () {
        //console.log('start searching')
        var currentengagementCounter = 0
        if (this.context.engagementCounter !== undefined && this.context.engagementCounter > 0){
            currentengagementCounter = this.context.engagementCounter;
            //console.log('my points', currentengagementCounter)
        }
        var topScorers = [];
        var topCoaches = [];
        var currentCategory = this.context.currentCategory;
        if(currentCategory === undefined || currentCategory === ''){
            currentCategory = 'clarity';
        }
        //var groups = [];
        if (this.context.groups !== undefined && this.context.groups.length > 0){
            //groups = this.context.groups;
        }
        var groupCompetitionId = '';
        if (this.context.groupCompetitionId !== undefined && this.context.groupCompetitionId !== '' && this.context.groupCompetitionId !== 'default'){
            groupCompetitionId = this.context.groupCompetitionId;
        }
        var counter = 0;
        var currentPosition = 0;
        var db = this.props.firebase
        .users()
        .where('currentCategory', '==', currentCategory)
        .where('engagementCounter', '<=', (currentengagementCounter+500))
        //console.log('my currentCategory', currentCategory);
        if(groupCompetitionId!== undefined && groupCompetitionId !== ''){
            //console.log('my groups', groups, this.context);
            db = this.props.firebase
            .users()
            //.where('groups', 'array-contains-any', groups)
            .where('groupCompetitionId', '==', groupCompetitionId)
        }
        db
        .orderBy('engagementCounter', 'desc')
        .limit(50)
        //.where(programs, 'array-contains-an7')
        .get()
        .then(snapshot => {
            snapshot.forEach(doc =>{
                counter += 1;
                var userSample = doc.data();
                if(userSample.email !== "DELETED ACCOUNT"){     
                    var usernameShort = userSample.username.split(' ')[0];
                    if(doc.id === this.context.uid ){
                        usernameShort = 'Me';
                        //console.log(userSample.username)
                    }
                    if(doc.id !== this.context.uid && (userSample?.roles?.includes('ADMIN') || userSample?.roles?.includes('COACHADMIN') || userSample?.roles?.includes('REMOTECOACH') || userSample?.roles?.includes('TEAM') )){
                        //console.log(userSample.username)
                        topCoaches.push({name: usernameShort, points: userSample.engagementCounter})
                    }
                    else{
                        if(usernameShort.includes('DELETED') || usernameShort.includes('Padawan') || usernameShort.includes('anonymous')){
                            usernameShort = 'User_'+counter;
                        }
                        else if(usernameShort.includes("@")){
                            usernameShort = usernameShort.split("@")[0];
                        }
                        if(userSample.engagementCounter !== undefined && userSample.engagementCounter > currentengagementCounter ){
                            //console.log('better',userSample.engagementCounter, currentengagementCounter)
                            currentPosition = currentPosition + 1;
                        }
                        topScorers.push({name: usernameShort, points: userSample.engagementCounter})
                    }
                }
            })
            if(topScorers.length < 3){
                topScorers = topScorers.concat(topCoaches);
            }
            topScorers = topScorers.slice(0,3)
            if(topScorers!== undefined && topScorers.length > 0 && this._isMounted){
                this.setState({
                    topScorers,
                    currentengagementCounter,
                    currentPosition
                })
            }
            //console.log(topScorers)
        })

        SetStateLoadingBatches(this, this.props.firebase, this.context.uid,  'all', 10);
        if(this.context?.achievementSelf?.icon !== undefined && this.context?.achievementSelf?.icon !== ''){
            if(this._isMounted){
                var badgeExtraTitle = this.context?.achievementSelf?.text; 
                this.setState({badgeSelfIcon: this.context?.achievementSelf?.icon, badgeExtraTitle});
            }
        }
    }

    render() {
        //console.log('stte', this.state)
        const { theme } = this.props;
        const {topScorers, currentengagementCounter,  currentPosition} = this.state;
        return (
            <div style={{paddingBottom: 20}}>
                <div style={{position: 'relative'}}>
                    <h4 style={theme.CardContent.titleText}>
                        Achievements
                    </h4>
                    {this.props.tutorialOpen 
                    &&
                        <div style={{position: 'absolute', top: -4, right: -6}}>
                            <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('insights')}}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </div>
                    }
                </div>
                <div style={{padding:0, height:"100%"}}>
                    <Grid container cellSpacing={2} direction="row" justify="center" alignItems="center"
                        //style={{ height:theme.CardDialogueButtonDesktop.minHeight}}
                        >
                        {this.props.compactView !== true && topScorers!==undefined && topScorers.length > 0 &&
                            <>
                                <Grid item xs={12}>
                                    <div style={{ minWidth: 240, marginTop: 0, marginBottom: 20}}>
                                        <strong style={{wordWrap:'break-word'}}>
                                            Top Engagers in Group: {this.props.badgeTotal?.description}<br />
                                        </strong>
                                    </div>
                                </Grid>

                                {topScorers.map((item,index )=> {
                                    if(index === 0){
                                        return (
                                            <React.Fragment key={index} >
                                                <Grid item xs ={3} key={index} style={{textAlign:'center'}}>
                                                    <EmojiEventsIcon fontSize="large" style={{color: theme.palette.themeAltGold}}/>
                                                </Grid>
                                                <Grid item xs ={9} key={'point'+index}>
                                                    <span>
                                                        <strong>1st</strong>
                                                        {": " + item.name + " -"}
                                                        <strong>{" " +item.points + " "}</strong>
                                                            points
                                                    </span>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                    else if(index === 1){
                                        return (
                                            <React.Fragment key={index} >
                                                <Grid item xs ={3} key={index} style={{textAlign:'center'}}>
                                                    <EmojiEventsIcon fontSize="large" style={{color: "silver"}}/>
                                                </Grid>
                                                <Grid item xs ={9} key={'point'+index}>
                                                    <span>
                                                        <strong>2nd</strong>
                                                        {": " + item.name + " -"}
                                                            <strong>{" " +item.points + " "}</strong>
                                                            points
                                                    </span>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                    else if(index === 2){
                                        return (
                                            <React.Fragment key={index} >
                                                <Grid item xs ={3} key={index} style={{textAlign:'center'}}>
                                                    <EmojiEventsIcon fontSize="large" style={{color: '#af7914'}}/>
                                                </Grid>
                                                <Grid item xs ={9} key={'point'+index}>
                                                    <span>
                                                        <strong>3rd</strong>
                                                        {": " + item.name + " -"}
                                                        <strong>{" " +item.points + " "}</strong>
                                                        points
                                                    </span>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                    else return ""
                                })}
                                {currentPosition !== undefined && currentPosition > 2 &&
                                    <>
                                        <Grid item xs ={3} key='me' style={{textAlign:'center'}}>
                                            <TrendingUpIcon fontSize="large" style={{color: 'red'}}/>
                                        </Grid>
                                        <Grid item xs ={9}  key='meppint'>
                                            <span>
                                                <strong>{(currentPosition+1) +  ". " }</strong>
                                                : 
                                                <strong>{" Me "}</strong>
                                                -
                                                <strong>{" " + currentengagementCounter + " "}</strong>
                                                points
                                            </span>
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                        
                        <DialogCardBadges 
                            handleOpen={this.state.dialogCardOpen || false}
                            handleClose={() => {this.setState({dialogCardOpen: false})}}
                            badgeFamilyDetailSelection= {this.state.badgeFamilyDetailSelection || 'all'}
                            />
                        <Grid item xs style={{width:'100%'}}>
                            {this.state.badge?.family !== 'total' && this.state.badge?.family?.length>2 &&
                                <BadgeCard
                                    backgroundColor ={this.props.showLinks ? "transparent" : "transparent"}
                                    image={(this.state.badgeSelfIcon && (this.state.badge?.family === 'total')) ? this.state.badgeSelfIcon : (this.state.badgeIcon!== undefined && this.state.badgeIcon!== '') ? this.state.badgeIcon :  BadgeStart} 
                                    title = {
                                            ((this.state.badgeExtraTitle !== undefined && this.state.badge?.family === 'total')
                                                ?
                                                    this.state.badgeExtraTitle + ': '
                                                :   ''
                                            )
                                            +
                                            (this.state.badge !== undefined && this.state.badge?.title !== undefined
                                                ? this.state.badge?.title 
                                                : 'My program'
                                            )
                                            }
                                    subtitle = {this.state.badgeName !== undefined && this.state.badgeName !== ""
                                                ? this.state.badgeName
                                                : FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory)
                                            }
                                    strongSubtitle = {true}
                                    onClick={this.props.showLinks ? () => this.setState({dialogCardOpen: true, badgeFamilyDetailSelection: 'all'}) : undefined}
                                    clickText={this.props.showLinks ? 'Show' : undefined}
                                />
                            }
                        </Grid>
                        <Grid item xs style={{width:'100%'}}>
                            {this.state.badgeTotal !== undefined && this.state.badgeTotal?.family === 'total' &&
                                <BadgeCard
                                    backgroundColor= {this.props.showLinks ? "transparent" : "transparent"}
                                    image={this.state.badgeIconTotal ? this.state.badgeIconTotal :  BadgeStart} 
                                    title = {
                                            (this.state.badgeExtraTitle !== undefined
                                                ?
                                                    this.state.badgeExtraTitle + ': '
                                                :   ''
                                            )
                                            +
                                            (this.state.badgeTotal?.title !== undefined
                                                ? this.state.badgeTotal?.title 
                                                : ''
                                            )
                                            }
                                    subtitle = {this.state.badgeTotal?.description !== undefined && this.state.badgeTotal?.description !== ""
                                                ? this.state.badgeTotal?.description
                                                : FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory)
                                            }
                                    strongSubtitle = {true}
                                    onClick={this.props.showLinks ? () => this.setState({dialogCardOpen: true, badgeFamilyDetailSelection: 'total'}) : undefined }
                                    clickText={this.props.showLinks ? 'Show' : undefined }
                                />
                            }
                        </Grid>
                        {this.props.showWeekly!== false &&
                            <>
                                {/***
                                <Grid item xs ={12}>
                                    <div style={{ minWidth: 240, marginTop: 20}}>
                                        <strong style={{wordWrap:'break-word'}}>
                                            Next Level:<br />
                                        </strong>
                                    </div>
                                </Grid>
                                <Grid item xs ={12}>
                                    <div style={{ minWidth: 240, marginTop: 20, textAlign:"center"}}>
                                        <StatsBar
                                            width="100%"
                                            type="total"
                                            size="large"
                                            labelShort="XP"
                                            avatarGreyOut={true}
                                        />
                                    </div>
                                </Grid>
                                    */}
                                <Grid item xs ={12}>
                                    <div style={{ minWidth: 240, marginTop: 20}}>
                                        <strong style={{wordWrap:'break-word'}}>
                                            Weekly Engagement:<br />
                                        </strong>
                                    </div>
                                </Grid>
                                <Grid item xs ={12}>
                                    <div style={{ minWidth: 240, marginTop: 20, textAlign:"center"}}>
                                        <StatsBar
                                            width="100%"
                                            type="weekly"
                                            size="large"
                                            labelShort="New XP"
                                        />
                                    </div>
                                </Grid>
                            </>
                        }
                    </Grid>
                </div>
            </div>
        )
    }

}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true})
)(CardGroupRanking);
