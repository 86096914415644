import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../Modules/LoadingProgress';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
import Grid from '@material-ui/core/Grid';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CachedIcon from '@material-ui/icons/Cached';
//UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputAdornment from '@material-ui/core/InputAdornment';
import SpeechToTextButton from '../Modules/SpeechToTextButton';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import WidgetsIcon from '@material-ui/icons/Widgets';
import TuneIcon from '@material-ui/icons/Tune';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.backPage,
    },
    expansionPanel: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    expansionPanelSummary: {
        paddingLeft: 5,
        paddingRight: 15,
        userSelect: "auto"
    },
    expansionPanelSummaryCoach: {
        backgroundColor: theme.palette.backPrimary,
        paddingLeft: 5,
        paddingRight: 15,
        userSelect: "auto"
    },
    label: {
        fontSize: '0.8em',
        fontWeight: 700,
        paddingTop: 3,
        paddingRight: 10,
        //minWidth: 60,
        width: '100%',
    },
    messages: {
        padding: 0,
        overflow: 'hidden',
    },
    iconVote: {
        color: '#20a5e6',
    },
    iconNoVote: {
        color: '#a5a4a4',
    },
});

const stylesReview = {
};


class DialogueHistory extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            messages: [],
        };
    }

    componentDidMount() {
        if(this.props.messages !== undefined && this.props.messages.length > 0){
            this.setState({ messages: this.props.messages, loading: false });
        }
        else{
            this.unsubscribe = this.props.firebase
            .messages()
            .orderBy('createdAt', 'asc')
            .where('dialogueId', '==', this.props.dialogue.uid)
            if(this.props.messageFilter === 'bot'){
                this.unsubscribe = this.unsubscribe.where('messageFilter', '==', 'bot')
            }
            this.unsubscribe = this.unsubscribe
            .onSnapshot(snapshot => {
                if(snapshot.size) {
                    let messages = [];
                    snapshot.forEach(doc => messages.push({ ...doc.data(), uid: doc.id }),);
                    this.setState({
                        messages: messages,
                        loading: false,
                    });
                } 
                else {
                    this.setState({ messages: null, loading: false });
                }
            });
        }
    }

    componentWillUnmount() {
        if(this.unsubscribe!== undefined) this.unsubscribe();
    }

    upvote = messageId => () => {
        this.props.firebase.message(messageId).update({
            vote: 'up',
            voteSource: 'direct'
        });
    }
    downvote = messageId => () => {
        this.props.firebase.message(messageId).update({
            vote: 'down',
            voteSource: 'direct'
        });
    }
    devote = messageId => () => {
        this.props.firebase.message(messageId).update({
            vote: '',
        });
    }

    upvoteUser = (messageId, message, index) => () => {
        var voteUser = new Array(message.text.length).fill('');
        if(message.voteUser?.length > 0){
            voteUser = message.voteUser;
        }
        voteUser[index] = 'up';
        this.props.firebase.message(messageId).update({
            voteUser,
            voteSource: 'direct'
        });
        if(this.props.feedbackHandler !== undefined){
            this.props.feedbackHandler();
        }
    }
    downvoteUser = (messageId, message, index) => () => {
        var voteUser = new Array(message.text.length).fill('');
        if(message.voteUser?.length > 0){
            voteUser = message.voteUser;
        }
        voteUser[index] = 'down';
        this.props.firebase.message(messageId).update({
            voteUser,
            voteSource: 'direct'
        });
        if(this.props.feedbackHandler !== undefined){
            this.props.feedbackHandler();
        }
    }
    devoteUser = (messageId, message, index) => () => {
        /***
        var voteUser = new Array(message.text.length).fill('');
        if(message.voteUser?.length > 0){
            voteUser = message.voteUser;
        }
        voteUser[index] = '';
        this.props.firebase.message(messageId).update({
            voteUser,
        });
         */
        if(this.props.feedbackHandler !== undefined){
            this.props.feedbackHandler();
        }
    }
    feedbackUser = (messageId, message, index, comment) => {
        //console.log(messageId, message, index, comment)
        if(comment?.length > 4 && messageId?.length > 0){
            if(!(index > 1)){
                index = 1
            }
            var voteFeedback = new Array(message?.text?.length || (index+1)).fill('');
            if(message?.voteFeedback?.length > 0){
                voteFeedback = message.voteFeedback;
            }
            voteFeedback[index] = comment;
            //console.log(voteFeedback)
            this.props.firebase.message(messageId).update({
                voteFeedback,
                voteSource: 'direct'
            });
        }
        this.setState({openFeedback: false});
        if(this.props.feedbackHandler !== undefined){
            this.props.feedbackHandler();
        }
    }

    renderDialogueFeedback(){
        const {theme} = this.props;
        return(
            <Dialog
                open={this.state.openFeedback === undefined ? false : this.state.openFeedback}
                onClose={() => this.setState({openFeedback: false})}
                PaperProps={{style:theme.dialogPopUp}}
            >
                <DialogTitle style={theme.dialogTitle}>
                    <Grid container spacing={0} direction="row"
                        justify="space-between"
                        alignItems="center">
                        <Grid item >
                            <IconButton onClick={() =>  {this.setState({openFeedback: false})} }>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleText}>
                                Feedback
                            </span>
                        </Grid>
                        <Grid item>
                            <IconButton style={{visibility: 'hidden'}} onClick={() =>  {this.setState({openFeedback: false})} }>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={theme.dialogContent}>
                    <p style={theme.textSupport}>Please share your feedback to improve Rocky?</p>
                    <TextField
                        name="comment"
                        style={ {fontSize: '16px'} }
                        value={this.state.comment || ""} 
                        onChange={(e) => {this.setState({ comment: e.target.value})} }
                        type="text"
                        variant="outlined"
                        placeholder="What is in your mind?"
                        fullWidth
                        multiline
                        InputProps={{
                            style:{fontSize: '16px', backgroundColor: theme.palette.white},
                            endAdornment: (
                                <InputAdornment position="end">
                                    {this.state.comment!=="" && this.state.comment!==undefined
                                        ?
                                            <IconButton
                                                edge="end"
                                                aria-label="Send message"
                                                onClick={ () => this.feedbackUser( 
                                                    this.state.cmtMessageId,
                                                    this.state.cmtMessage,
                                                    this.state.cmtIndex,
                                                    this.state.comment
                                                    )}
                                                >
                                                { this.state.comment.length > 4 
                                                    ?   <SendIcon style={{color: theme.palette.themeRed}} />
                                                    :   <SendIcon  />
                                                }
                                            </IconButton>
                                        :            
                                            <IconButton
                                                edge="end"
                                                aria-label="Write message"
                                                disabled={true}
                                                >
                                                <SendIcon />
                                            </IconButton>
                                    }
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SpeechToTextButton 
                                        renderKey={'history_'}
                                        returnText={(result) => this.setState({comment : result})}
                                        existingText={this.state.comment || ""}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        const { loading, messages } = this.state;
        const { theme, classes } = this.props;
        if(this.props.variant === 'showFeedbackButtons'){
            var message = this.props.messages[0];
            var index = this.props.index;
            return(
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="flex-start"
                    >
                    {this.renderDialogueFeedback()}
                    <React.Fragment>
                        <Grid item style={{ textAlign: 'left', paddingRight: 10 }}>
                            {message.voteUser?.[index] === 'up'
                                ?   <ThumbUpIcon className={classes.iconVote} onClick={this.devoteUser(message.uid, message, index)} />
                                :   <ThumbsUpDownIcon className={classes.iconNoVote} 
                                        onClick={() => this.setState({openFeedback: true, comment: message.voteFeedback?.[index] || "", cmtMessageId: message.uid, cmtMessage: message, cmtIndex: index})}
                                        //onClick={this.upvoteUser(message.uid, message, index)} 
                                    />
                            }
                        </Grid>
                        <Grid item  style={{ textAlign: 'left'}}>
                            {this.props.showFavorite !== true
                                ?   ''
                                :   message.voteFeedback?.[index]?.length > 0
                                    ?   <FavoriteIcon className={classes.iconVote} onClick={() => this.setState({openFeedback: true, comment: message.voteFeedback?.[index] || "", cmtMessageId: message.uid, cmtMessage: message, cmtIndex: index})} />
                                    :   <FavoriteBorderIcon className={classes.iconVote} onClick={() => this.setState({openFeedback: true, comment: '', cmtMessageId: message.uid, cmtMessage: message, cmtIndex: index})} />
                            }
                        </Grid>
                        <Grid item style={{ textAlign: 'right', paddingLeft: 10 }}>
                            {this.props.switchHandler !== undefined
                                ?   <CachedIcon className={classes.iconVote} 
                                        onClick={ () => {
                                            this.devoteUser(message.uid, message, index);
                                            this.props.switchHandler();
                                        }}
                                    />
                                :   this.props.showDownVote !== true
                                    ?   ''
                                    :   message.voteUser?.[index] === 'down'
                                        ?   <ThumbDownIcon className={classes.iconVote} onClick={this.devoteUser(message.uid, message, index)} />
                                        :   <ThumbDownIcon className={classes.iconNoVote} onClick={this.downvoteUser(message.uid, message, index)} />
                            }
                        </Grid>
                        <Grid item style={{ textAlign: 'left', paddingLeft: 20 }}>
                            {this.props.tipsHandler !== undefined
                                ?   <WidgetsIcon className={classes.iconVote} 
                                        onClick={ () => {
                                            this.props.tipsHandler();
                                        }}
                                    />
                                :   ''
                            }
                        </Grid>
                        <Grid item style={{ textAlign: 'left', paddingLeft: 20 }}>
                            {this.props.settingsHandler !== undefined
                                ?   <TuneIcon className={classes.iconVote} 
                                        onClick={ () => {
                                            this.props.settingsHandler();
                                        }}
                                    />
                                :   ''
                            }
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
        else
            return (
                <div className={classes.messages}>
                    {this.renderDialogueFeedback()}
                    {loading 
                        ?   
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        :   messages?.length > 1
                            ?   <p style={theme.textSupport}>You can click on each question of Rocky to provide a personal feedback so that the AI can improve and adjust to you.</p>
                            :   ''
                    }
                    {messages && (
                        messages.map(message =>  {
                            if(message.isCoach || this.props.rateOnly!== true){
                                return(
                                    <Accordion key={message.uid} className={classes.expansionPanel}
                                        onChange={(e,expanded) => {this.setState({[message.uid]: expanded})}} 
                                        >
                                            <AccordionSummary style={message.isCoach
                                                    ?
                                                    styles.expansionPanelSummaryCoach
                                                    :
                                                    styles.expansionPanelSummary
                                                }>
                                                {message.isCoach
                                                    ?
                                                        <p className={classes.label}>Rocky: <br/>{this.state[message.uid]!==true && message.text.join(' \n\n')}</p>
                                                    :
                                                        <p className={classes.label}>Answer: <br/>{message.text}</p>
                                                }
                                            </AccordionSummary>
                                            {message.isCoach 
                                                ?
                                                    <AccordionDetails style={{ paddingTop: 24 }}>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="row"
                                                            alignItems="center"
                                                            justify="center"
                                                            >
                                                            {message.text?.length > 0 && message.text.map( (item, index) => (
                                                                <React.Fragment key={'item_'+index}>
                                                                    <Grid item xs={12} style={{ textAlign: 'center', paddingTop :15 }}>
                                                                        {item}
                                                                    </Grid>
                                                                    <React.Fragment>
                                                                        <Grid item xs={4} style={{ textAlign: 'right', paddingRight: 10 }}>
                                                                            {message.voteUser?.[index] === 'up'
                                                                                ?   <ThumbUpIcon className={classes.iconVote} onClick={this.devoteUser(message.uid, message, index)} />
                                                                                :   <ThumbUpIcon className={classes.iconNoVote} onClick={this.upvoteUser(message.uid, message, index)} />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={2} style={{ textAlign: 'center'}}>
                                                                            {message.voteFeedback?.[index]?.length > 0
                                                                                ?   <EmojiObjectsIcon className={classes.iconVote} onClick={() => this.setState({openFeedback: true, comment: message.voteFeedback?.[index] || "", cmtMessageId: message.uid, cmtMessage: message, cmtIndex: index})} />
                                                                                :   <EmojiObjectsIcon className={classes.iconNoVote} onClick={() => this.setState({openFeedback: true, comment: '', cmtMessageId: message.uid, cmtMessage: message, cmtIndex: index})} />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{ textAlign: 'left', paddingLeft: 10 }}>
                                                                            {message.voteUser?.[index] === 'down'
                                                                                ?   <ThumbDownIcon className={classes.iconVote} onClick={this.devoteUser(message.uid, message, index)} />
                                                                                :   <ThumbDownIcon className={classes.iconNoVote} onClick={this.downvoteUser(message.uid, message, index)} />
                                                                            }
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                </React.Fragment>
                                                            ))}
                                                            {/***
                                                            <Grid item xs={12} style={{ textAlign: 'center', paddingBottom :15 }}>
                                                                {message.text[message.text.length-1]}
                                                            </Grid>
                                                            {message.vote
                                                            ?
                                                                <Fragment>
                                                                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                                        {message.vote === 'up'
                                                                        ?
                                                                        <ThumbUpIcon className={classes.iconVote} onClick={this.devote(message.uid)} />
                                                                        :
                                                                        <ThumbUpIcon className={classes.iconNoVote} onClick={this.upvote(message.uid)} />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                                        {message.vote === 'down'
                                                                        ?
                                                                        <ThumbDownIcon className={classes.iconVote} onClick={this.devote(message.uid)} />
                                                                        :
                                                                        <ThumbDownIcon className={classes.iconNoVote} onClick={this.downvote(message.uid)} />
                                                                        }
                                                                    </Grid>
                                                                </Fragment>
                                                            :
                                                                <Fragment>
                                                                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                                        <ThumbUpIcon className={classes.iconNoVote} onClick={this.upvote(message.uid)} />
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                                        <ThumbDownIcon className={classes.iconNoVote} onClick={this.downvote(message.uid)} />
                                                                    </Grid>
                                                                </Fragment>
                                                            } 
                                                            */}
                                                        </Grid>
                                                    </AccordionDetails>
                                                :
                                                    ""
                                            }
                                        </Accordion>
                                )
                            }
                            else return ''
                        })
                    )}
                </div>
            );
    }
}

DialogueHistory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogueHistory);
