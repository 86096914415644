import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import { createGradientImage } from '../Modules/createGradientImage';
import DialogContentItem from '../Content/DialogContentItem';
import Grid from '@material-ui/core/Grid';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SwipeableViews from 'react-swipeable-views';
import { mod } from 'react-swipeable-views-core';
import { virtualize } from 'react-swipeable-views-utils';
import {ContentProgramOverwrite} from '../Content/ContentItemCard';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    progressBar: {
        width: '100%',
        display:"inline-block"
    },
});


class ContentRecommendationsPreview extends Component {
    _isMounted=false;

    constructor(props) {
        super(props);
        var previewWidth = 50;
        var previewHeight = 30;
        var visualLimit = 3;
        if(this.props.previewWidth !== undefined) previewWidth = this.props.previewWidth; 
        if(this.props.previewHeight !== undefined) previewHeight = this.props.previewHeight;
        if(this.props.visualLimit !== undefined) visualLimit = this.props.visualLimit;
        this.state = {
            itemIndex:0,
            open: false,
            visualLimit,
            previewWidth: previewWidth,
            previewHeight: previewHeight,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.handlerShowPosition !== undefined && this.props.handlerShowPosition !== this.state.activeStep && this.props.handlerShowPosition >= 0){
            if(prevProps.handlerShowPosition !== this.props.handlerShowPosition && this._isMounted){
                if(this.props.contentSelection !== undefined && this.props.handlerShowPosition <= this.props.contentSelection.length){
                    this.setState({activeStep : this.props.handlerShowPosition});
                }
                else{
                    this.setState({activeStep : 0});
                }
            }
        }
    }

    render() {
        //console.log('content',this.props)
        if (this.props.contentSelection !== undefined && this.props.contentSelection.length > 0){
            return(
                <div style={{width:'100%'}}>
                    {
                        <VirtualizeSwipeableViews
                            key={'main'}
                            axis={'x'}
                            index={this.state['activeStep'] || 0}
                            onChangeIndex={(step) => {
                                try{
                                    this.setState({activeStep : step});
                                }
                                catch (err) {console.log('Swipe Error')}
                            }}
                            enableMouseEvents = {this.state.allowRefresh === false? false : true}
                            disabled = {this.state.allowRefresh === false? true : false}
                            resistance = {true}
                            slideStyle={{
                                paddingRight: 0,
                                paddingBottom: 0,
                                flex: 'none',
                                overflow: 'hidden',
                            }}
                            slideRenderer={this.slideRenderer}
                            />
                    }
                    <DialogContentItem
                        user={this.props.user}
                        defaultSet={this.props.defaultSet || undefined}
                        open={this.state.DialogContentPreviewOpen}
                        handleClose={() => this.setState({DialogContentPreviewOpen : false})}
                        specialQuery={'contentByIds'}
                        closeAfterActionAdded={this.props.closeAfterActionAdded !== undefined ? this.props.closeAfterActionAdded : false}
                        contentData={this.state.contentData}
                        contentId = {this.state.previewContentId}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                        messageSubmit = {
                            this.props.messageSubmit === undefined
                                ?  undefined
                                :  (text) => {this.submitInspiration(text)}
                            }
                        />

                </div>
            )

        }
        else{
            return ""
        }
    }

    slideRenderer = (params) => {
        const {index, key} = params;
        if (this.props.contentSelection !== undefined && this.props.contentSelection.length > 0){
            //console.log("set", this.props.contentSelection)
            var itemLength = this.props.contentSelection.length;
            var select = mod(index,itemLength);
            //console.log("params",  params, select, this.props.contentSelection[0])
            switch (select){
                case 0:
                    return this.returnGridCards(key, this.props.contentSelection[0], 0)
                case 1:
                    return this.returnGridCards(key, this.props.contentSelection[1], 1)
                case 2:
                    return this.returnGridCards(key, this.props.contentSelection[2], 2)
                case 3:
                    return this.returnGridCards(key, this.props.contentSelection[3], 3)
                case 4:
                    return this.returnGridCards(key, this.props.contentSelection[4], 4)
                case 5:
                    return this.returnGridCards(key, this.props.contentSelection[5], 5)
                case 6:
                    return this.returnGridCards(key, this.props.contentSelection[6], 6)
                default:
                    return null
            }
        }
        else{
            return ""
        }
    }

    submitInspiration = (text) => {
        if(this.props.messageSubmit!== undefined){
            this.props.messageSubmit(text);
            this.setState({DialogContentPreviewOpen:false})
        }
    }
    
    returnGridCards(key, content, index=0){
        const { theme } = this.props;
        //console.log("content rendter", content)
        //console.log(content)
        if(content !== undefined){
            return(
                <Grid container spacing={2} direction='row' style={{height: '100%'}}
                    //justify="flex-end"
                    alignItems="center"
                    id={key}
                    key={key}
                    //key={'preview_'+content.contentId}
                    onClick={(e) => {
                        if(this.props.handleOpen !== undefined){ this.props.handleOpen(content) }
                        else{this.openContent(content.contentId)}
                    }}
                >
                    <Grid item xs={3} style={{paddingLeft:5, paddingBottom:5, textAlign: 'right'}}>
                        {content.url
                            ?
                                <div style={{
                                    backgroundImage: `url(${content.url})`,
                                    backgroundPosition: "center 30%",
                                    borderRadius: theme.borderRadius,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    width: this.state.previewWidth,
                                    height: this.state.previewHeight,
                                    margin: 5,
                                    }}>
                                </div>
                            :
                                <div style={{
                                    backgroundImage: `url(${createGradientImage(theme.palette.primary.dark, "#FFFFFF")})`,
                                    backgroundPosition: "center 30%",
                                    borderRadius: theme.borderRadius,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    width: this.state.previewWidth,
                                    height: this.state.previewHeight,
                                    margin: 5,
                                    }}>
                                </div>
                        }
                    </Grid>
                    <Grid item xs={9}>
                        <div  style={{paddingLeft: 10, paddingRight: 10, display: 'flex'}}>
                            <span>
                                <span style={{fontSize: 10}}>
                                    {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, content.leadership_category, 'text')}
                                    :&nbsp;
                                    <span style={{ color:theme.palette.secondary.main, textDecoration: "underline", whiteSpace: 'nowrap'}}>
                                        {content?.textWordCount > 90
                                            ?
                                                (Math.round(content.textWordCount/100*0.6) + " min read")
                                            :
                                                "Open"
                                        }
                                    </span>
                                </span>
                                <br/>
                                {(content?.category === 'tips' && (content?.premium === true || (content?.program !== 'default' && !(content?.premium === false))) )
                                &&
                                    <ShoppingBasketIcon style={{ ...theme.inlineIconLeft, color:theme.palette.themeAltGold}} />
                                }
                                {content.youtubeId !== undefined && content.youtubeId !== ""
                                    &&
                                    <YouTubeIcon style={{ ...theme.inlineIconLeft}} />
                                }
                                {content.mediaType === 'video' && content.s3Url !== undefined
                                    &&
                                    <YouTubeIcon style={{ ...theme.inlineIconLeft}} />
                                }
                                {content.mediaType === 'audio' && content.s3Url !== undefined
                                    &&
                                    <AudiotrackIcon style={{ ...theme.inlineIconLeft}} />
                                }
                                <span style={{color: theme.palette.themeText}}>
                                    {content.title?.length> 40
                                        ? content.title.substring(0,39) + '...'
                                        : content.title
                                    }
                                </span>
                            </span>
                        </div>
                    </Grid>
                </Grid>
            )
        }
        else{
            return ""
        }
    }

    openContent = (contentId) => {
        //console.log('found info for:', contentId)
        if (this.props.contentSelection !== undefined && contentId !== undefined && contentId !== ''){
            this.props.firebase.content(contentId).get()
            .then( (doc) =>{
                //console.log('found info for:', this.props.user)
                //console.log(contentData.data())
                var contentDict = doc.data();
                contentDict = ContentProgramOverwrite(contentDict, this.props.user);
                this.setState({contentData: contentDict, previewContentId: contentId}, 
                    () => { this.setState({DialogContentPreviewOpen: true }); })
                if(this.props.user!== undefined && this.props.user.uid!==undefined){
                    if(this.props.user.contentRecommendations !== undefined && this.props.user.contentRecommendations.length>3){
                        var currentArray = this.props.user.contentRecommendations;
                        if(currentArray === undefined || currentArray === null){
                            currentArray = [];
                        }
                        if(!this.props.user.contentRecommendations.includes(contentId)){
                            currentArray.push(contentId);
                            currentArray.shift();
                            this.props.firebase.user(this.props.user.uid).update({
                                contentRecommendations: currentArray
                            });
                        }
                    }
                    else{
                        this.props.firebase.user(this.props.user.uid).update({
                            contentRecommendations: this.props.firebase.fieldValue.arrayUnion(contentId),
                        });
                    }
                }
                //// reset the position flag
                if(this.props.handlerResetPosition !== undefined) this.props.handlerResetPosition();
                if(this.props.contentOpened !== undefined) this.props.contentOpened(contentId);
            })
        }
    }

}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ContentRecommendationsPreview);