import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import { BestForProfile } from '../Profile/RoleOverview';
import { FindDictByKey, QualityID } from '../Modules/LoadCategoriesAndQualities';
import {DataCacheWrite} from "../Modules/DataCache";
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import { AccessCheckCoachCreator, AccessCheckCreatorTrialPhase } from '../../constants/roles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';

import BadgeCard from '../Modules/BadgeCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DialogSettingPlan from '../Profile/DialogSettingPlan';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

// General Modules
import LoadingProgress from '../Modules/LoadingProgress';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import AvatarImage, {AvatarCard} from '../Modules/AvatarImage';
import Grid from '@material-ui/core/Grid';
//import SwipeStepper from '../Modules/SwipeStepper';
import Switch from "react-switch";
import SnackbarInfo from '../Modules/SnackbarInfo';

/// for preview
import {RenderMediaYoutube} from  '../Content/RenderMedia';
import PublicIcon from '@material-ui/icons/Public';
///// Classical List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Collapse from '@material-ui/core/Collapse';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


// Swipe
import VizSensor from 'react-visibility-sensor';
//import SwipeableViews from 'react-swipeable-views';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import Skeleton from '@material-ui/lab/Skeleton';
//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Icons
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

// Admin

import SettingContentBase from '../Admin/Modules/SettingContentBase';
import SettingColorPicker from '../Admin/Modules/SettingColorPicker';
import SettingContentActive from '../Admin/Modules/SettingContentActive';
import SettingContentNumber from '../Admin/Modules/SettingContentNumber';
import SettingContentArray from '../Admin/Modules/SettingContentArray';
//import SettingContentImage from '../Admin/Modules/SettingContentImage';
import SettingContentUploadImage from '../Admin/Modules/SettingContentUploadImage';
import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Admin/Modules/SettingContentArrayCheckbox';
import SettingContentSelectCombi from '../Admin/Modules/SettingContentSelectCombi';

import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import SettingContentMapSelect from '../Admin/Modules/SettingContentMapSelect';


import Slide from '@material-ui/core/Slide';
import theme from '../../themes';
import { ExternalHelpLink } from '../Modules/ExternalLinks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

const TextQuest = "A 'Quest' can be equivalent to a chapter of a book or reflect a main learning section of training program."

class ManagePrograms extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: true,
            loadingText: 'loading categories',
            qualities: null
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadPrograms();
    }

    loadPrograms(){
        if(this.unsubscribe!==undefined){this.unsubscribe();}
        if(this.unsubscribeCat!==undefined){this.unsubscribeCat();}
        this.setState({ loading: false, qualities: null });
        var ownedGroups = [];
        var defaultGroups = [];
        var ownedGroupsData = {};
        var adminGroups = [];
        this.setState({ 
            loading: true, 
            newProgram: {program: this.context?.adminPrograms?.[0] || undefined}
        });
        if(this.context.adminPrograms!== undefined && this.context.adminPrograms.length> 0){
            adminGroups = [...new Set(this.context.adminPrograms)];
            if(adminGroups.length > 10) {
                adminGroups = adminGroups.slice(0,9);
            }
            this.unsubscribeCat = this.props.firebase
            .leadershipCategories()
            .where('program', 'in', adminGroups)
            //.get().then((data) => {
            .onSnapshot((data) => {
                data.forEach(result => {
                    var myGroupsDoc = result.data();
                    //console.log('myGroupsDoc', myGroupsDoc)
                    if(myGroupsDoc.program !== 'default' || (myGroupsDoc.program === 'default' && (this.context.adminPrograms.includes(myGroupsDoc.value) || this.context.adminPrograms.includes('default_all')) )){
                        if(!ownedGroups.includes(myGroupsDoc.value)){
                            ownedGroups.unshift(myGroupsDoc.value);
                        }
                        ownedGroupsData[myGroupsDoc.value] = {...myGroupsDoc, cid: result.id};
                    }
                })
                if (this._isMounted) {
                    this.setState({
                        ownedGroups, 
                        ownedGroupsData,
                        loading: false,
                    });
                }
            })
        }
        else{
            this.setState({
                loading: false,
                error: "No assigned categories available."
            });
        }
        let qualities = {};
        var leadershipQualitiesDefault = [];
        var myArray = [];
        var myArrayText = [];
        var myArrayBase = [];
        var myArrayBaseText = [];
        if(this.context.adminPrograms!== undefined && this.context.adminPrograms.length> 0){
            adminGroups = [].concat(this.context.adminPrograms);
            if(adminGroups.length > 10) {
                adminGroups = adminGroups.slice(0,9);
            }
            if(!adminGroups.includes('default')){
                adminGroups.push('default');
            }
            this.unsubscribe = this.props.firebase
            .leadershipQualities()
            .where('program', 'in', adminGroups)
            .orderBy('category', 'asc')
            //.where('roles', 'array-contains', 'COACHEE')
            //.where('code', '==', 'PMCOACHING')
            .onSnapshot(snapshot => {
                if(this._isMounted){
                    qualities = {};
                    myArray = [];
                    myArrayText = [];
                    myArrayBase = [];
                    myArrayBaseText = [];
                    snapshot.forEach(doc => {
                        var group = "Undefined";
                        // *** HN adding filtering to users only allowed to be seen by coach who has same program
                        // any kind of program would work, but default program is filtered
                        var qualityData = doc.data();
                        if(qualityData.valueID === undefined){
                            qualityData['valueID'] = QualityID(qualityData.value);
                        }
                        //console.log('qualityData', qualityData)
                        var baseQuality = false; 
                        var addQuality = false;
                        if(qualityData.program === 'default'){
                            baseQuality = true;
                            addQuality = true;
                        }
                        if(qualityData.program !== 'default' || (qualityData.program === 'default' && (this.context.adminPrograms.includes(qualityData.category) || this.context.adminPrograms.includes('default_all')) )){
                            addQuality = true;
                        }
                        if(addQuality){
                            if (qualityData.category!== undefined && qualityData.category!== ""){
                                var groupId = qualityData.category
                                if(groupId===""){groupId = "Undefined"}
                                if(Object.keys(qualities).includes(groupId)){
                                    qualities[groupId].push({ ...qualityData, qid: doc.id });
                                }
                                else{
                                    qualities[groupId] = [{ ...qualityData, qid: doc.id }];
                                }
                            }
                            else{
                                if(Object.keys(qualities).includes(group)){
                                    qualities[group].push({ ...qualityData, qid: doc.id });
                                }
                                else{
                                    qualities[group] = [{ ...qualityData, qid: doc.id }];
                                }
                            }
                            if(baseQuality){
                                myArrayBase.push(qualityData.value);
                                myArrayBaseText.push("(" + groupId + ") " + qualityData.text);
                                leadershipQualitiesDefault.push(qualityData);
                            }
                            else{
                                myArray.push(qualityData.value);
                                myArrayText.push("(" + groupId + ") " + qualityData.text);
                            }
                        }
                    });
                }
                //console.log(qualities)
                leadershipQualitiesDefault.push({active: false, category: "No Template", text: "No Template", value: "none", valueID: "none"});
                myArrayBase.push("none");
                myArrayBaseText.push("No Template")
                if (this._isMounted) {
                    this.setState({
                        qualities,
                        leadershipQualitiesDefault,
                        qualitiesValues: myArray,
                        qualitiesText: myArrayText,
                        qualitiesBaseValues: myArrayBase,
                        qualitiesBaseText: myArrayBaseText,
                        loading: false,
                    });
                }
            });
        }
        else{
            this.setState({
                loading: false,
                error: "No assigned categories available."
            });
        }
        //console.log('context', this.context)
        if(this.context.programs?.includes('default') || AccessCheckCoachCreator(this.context)){
            //console.log('defaultGroups', defaultGroups)
            defaultGroups = this.props.leadershipCategories?.filter( c => c.program === 'default');
            //console.log('defaultGroups', defaultGroups)
            if(defaultGroups?.length > 0 && this._isMounted){
                defaultGroups.map((itemDict) =>{
                    ownedGroupsData[itemDict.value] = itemDict;
                })
                this.setState({defaultGroups: defaultGroups, ownedGroupsData});
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined){this.unsubscribe();}
        if(this.unsubscribeCat!==undefined){this.unsubscribeCat();}
        this.setState({ loading: false, qualities: null });
    }
    componentDidUpdate(prevProps){
        if(this._isMounted ){
            //console.log('update selected Program', this.props, prevProps)
            if(this.props.launchCategory !== this.state.selectedCategoryKey){
                //console.log('update new Program', this.props.launchCategory)
                this.setState({selectedCategoryKey: this.props.launchCategory});
            }
            if(this.props.launchQuality !== this.state.selectedQualityValue){
                //console.log('update new Program', this.props.launchQuality, this.state)
                var qualitySet = FindDictByKey(this.props.firebase, this.props.leadershipQualities, this.props.launchQuality)
                this.setState({
                    selectedQualityValue: this.props.launchQuality,
                    selectedQualityId: (qualitySet?.qid || undefined),
                    selectedQualityText: (qualitySet?.text || undefined)
                });
            }
        }
    }


    updateBrandCache(content, refresh=false){
        //console.log('first instance', content, this.props)
        if(content?.partnerProgram === true && !(content?.programCode?.includes("#"))){
            console.log('missing conteent code', content.code)
        }
        if(this.props.refreshDefaultSet !== undefined && content.partnerProgram === true ){
            this.props.refreshDefaultSet(content, refresh)
            .then(() => {
                if(refresh){
                    //console.log('refresh codee', refresh)
                    //window.location.reload();
                }
            });
        }
    }
    
    setProgramModule(category = undefined, qualityvalue = undefined){
        if(this._isMounted){
            if(this.props.clickAction!== undefined){
                this.props.clickAction(category, qualityvalue)
            }
            if(qualityvalue === undefined){
                this.setState({
                    selectedCategoryKey: category, 
                    selectedQualityValue: undefined,
                    ///// selectedQualityId is different then external quality bz value
                    selectedQualityId: undefined
                })
            }
            else{
                this.setState({
                    selectedQualityValue: qualityvalue,
                    selectedCategoryKey: category
                })
            }
        }
    }

    render(){
        const {qualities, ownedGroups, defaultGroups} = this.state;
        //console.log('Manage programs', this.state)
        return(
            <div>
                <SnackbarInfo 
                    alertText = {this.state.alertText || ""}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.openAlert || false}
                    closeHandler = {() => {this.setState({openAlert: false})}}
                />
                <DialogSettingPlan 
                    defaultSet={this.props.defaultSet || undefined}
                    user={this.context}
                    coach={true}
                    handleOpen={this.state.DialogSettingPlanOpen}
                    premiumOnly={true}
                    purchaseSuccessFlag={() => {
                        this.setState({nonSubscriber: false, premiumSubscriber: true})
                    }}
                    purchaseSuccessHandler={() => {
                        this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                    }}
                    handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                />
                {this.state.selectedDefaultItem !== true
                &&
                    <>
                        <span 
                            onClick={() => {
                                this.setState({selectedDefaultItem: false })
                                this.setProgramModule("",undefined)
                            }}
                            style={{
                                textDecorationLine : this.state.selectedCategoryKey?.length>0 ? "underline" : undefined,
                                color : this.state.selectedCategoryKey?.length>0 ? theme.palette.secondary.main : undefined
                            }}
                        >
                            My Programs
                        </span>
                        {this.state.selectedCategoryKey?.length>0
                            &&
                            " > " 
                        }
                        {this.state.selectedCategoryKey?.length>0
                            &&
                            <span
                                onClick={() => {
                                    this.setProgramModule(this.state.selectedCategoryKey, undefined)
                                }}
                                style={{
                                    textDecorationLine : this.state.selectedQualityId?.length>0 ? "underline" : undefined,
                                    color : this.state.selectedQualityId?.length>0 ? theme.palette.secondary.main : undefined
                                }}
                            >
                                {(this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.text || this.state.selectedCategoryKey)}
                            </span>
                        }
                        {this.state.selectedQualityId?.length>0
                            &&
                            <span>{" > " + (this.state.selectedQualityText || "Quest")}</span>
                        }
                        <div style={theme.textSupport}>
                            A 'Program' can be equivalent to the title of a book or reflect a group or a learning theme of your brand/organization.
                        </div>
                        {true
                            ? 
                                ExternalHelpLink(theme, "Add your programs", "https://help.rocky.ai/rocky-for-creators/create-your-own-content-add-modify-programs-and-quests", 'Help Programs')
                            :   ""
                        }  
                    </>
                }
                {this.state.loading 
                    ?
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'}
                            loadingText={this.state.loadingText} 
                        />
                    :
                        <>
                            {this.state.selectedDefaultItem !== true
                            &&
                                <Grid container spacing={2} direction='row' justify="space-between" style={{marginTop: 30, marginBottom: 30}}>
                                    <Grid item xs={this.state.selectedCategoryKey?.length>0 ? 6 : 12}>
                                        {this.state.selectedCategoryKey?.length>0
                                        &&
                                            <div style={{width: '100%', textAlign:'left', marginBottom: 20}}>
                                                <Link 
                                                    to={'#'}
                                                    style={{
                                                        textDecorationLine : "underline" ,
                                                        color : theme.palette.secondary.main 
                                                    }}
                                                    onClick={() => {
                                                        this.setProgramModule()
                                                    }}
                                                >   
                                                    Show all Programs
                                                </Link>
                                            </div>
                                        }
                                        {ownedGroups?.length>0
                                        ?
                                            <GridList cellHeight={'auto'} cols={3} spacing={4} style={{overflow: 'hidden'}}>
                                                {(this.state.selectedCategoryKey?.length > 3)
                                                    ?   ""
                                                    :   this.renderEmptyGroup(ownedGroups.length)
                                                }
                                                {//Object.keys(qualities).map((key, index) => (
                                                ownedGroups.map((key, index) => (
                                                    key.length > 0 && (!(this.state.selectedCategoryKey?.length>0) || this.state.selectedCategoryKey === key)
                                                    ?
                                                        this.renderGroups(key,index)
                                                    :
                                                        ""
                                                ))}
                                            </GridList>
                                        :
                                            <GridList cellHeight={'auto'} cols={3} spacing={4} style={{overflow: 'hidden'}}>
                                                {this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode)
                                                    ?   this.renderEmptyGroup(0)
                                                    :   this.renderEmptyGroup(0, false)
                                                }
                                            </GridList>
                                        }
                                    </Grid>
                                    <Grid item xs={this.state.selectedCategoryKey?.length>0 ? 6 : false}>
                                        {this.state.selectedCategoryKey?.length>0 && 
                                            <>
                                                {!(this.state.selectedQualityId?.length >2) &&
                                                    <Tabs
                                                        value={this.state.mode || 'modeEditor'}
                                                        orientation={"horizontal"}
                                                        onChange={(event, newValue) => this.setState({mode: newValue})}
                                                        aria-label="Coach Content"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        initialselectedindex={0}
                                                        style={{
                                                            marginTop: 0,
                                                            marginBottom: 0,
                                                            marginLeft: 40,
                                                            width: 'calc(100% + 40px)'
                                                        }}
                                                    >
                                                        <Tab value={"modeEditor"} label={<span><AssignmentIcon style={theme.inlineIconLeft}/>Edit</span>}/>
                                                        <Tab value={"modePreview"} label={<span><ImportantDevicesIcon style={theme.inlineIconLeft}/>Preview</span>}/>
                                                    </Tabs>
                                                }
                                                <div style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:20, padding: 20}}>
                                                    {this.state.selectedQualityId?.length
                                                        ?   
                                                            <>
                                                                <span>
                                                                    Program: <strong>{this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.text || this.state.selectedCategoryKey}</strong>
                                                                </span>
                                                                <h4>
                                                                    Quest: <strong>{ this.state.selectedQualityText || ""}</strong>
                                                                </h4>
                                                            </>
                                                        :   
                                                            <>
                                                                {this.renderAdminContentCategory()}
                                                            </>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {this.state.selectedCategoryKey?.length>0 && this.state.selectedQualityId?.length > 0 &&
                                            <>
                                                {true &&
                                                    <Tabs
                                                        value={this.state.mode || 'modeEditor'}
                                                        orientation={"horizontal"}
                                                        onChange={(event, newValue) => this.setState({mode: newValue})}
                                                        aria-label="Coach Content"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        initialselectedindex={0}
                                                        style={{
                                                            marginTop: 20,
                                                            marginBottom: -20,
                                                            marginLeft: 40,
                                                            width: 'calc(100% + 40px)'
                                                        }}
                                                    >
                                                        <Tab value={"modeEditor"} label={<span><AssignmentIcon style={theme.inlineIconLeft}/>Edit</span>}/>
                                                        <Tab value={"modePreview"} label={<span><ImportantDevicesIcon style={theme.inlineIconLeft}/>Preview</span>}/>
                                                    </Tabs>
                                                }
                                                <div style={{marginTop: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:20, padding: 20}}>
                                                    { this.renderAdminContentQuality()}
                                                </div>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                this.dialogueQualitySettings()
                            }
                            {
                                this.dialogueCategorySettings()
                            }
                            {((this.state.selectedDefaultItem !== false || !(this.state.selectedCategoryKey?.length>0 ))
                                && defaultGroups?.length > 0 && (this.context.programs?.includes('default') || (this.props.defaultSet?.programCode?.length > 1 && this.context.adminPrograms?.includes(this.props.defaultSet.programCode)))
                                && !this.context.adminPrograms?.includes('default_all') && !this.context.adminPrograms?.includes('default')
                                )
                                &&
                                <>
                                    <div 
                                        style={{display: 'flex', alignItems: 'center'}}
                                    >
                                        <span 
                                            onClick={() => {
                                                this.setState({selectedDefaultItem: false})
                                                this.setProgramModule("", undefined)
                                            }}
                                            style={{
                                                textDecorationLine : this.state.selectedCategoryKey?.length>0 ? "underline" : undefined,
                                                color : this.state.selectedCategoryKey?.length>0 ? theme.palette.secondary.main : undefined
                                            }}
                                        >
                                            {this.state.selectedCategoryKey?.length>0 ? "My Programs" : "'Essentials' program"}
                                        </span>
                                        {this.state.selectedCategoryKey?.length>0
                                            &&
                                            <span>&nbsp;{"> " + (this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.text || this.state.selectedCategoryKey)}</span>
                                        }
                                        <span style={{marginLeft: 40, marginRight: 20}}>
                                            <Switch
                                                onChange={() => void(0)}
                                                checked={!(this.context.hidePublicContent === true) && (this.props.defaultSet?.programMinPrograms?.includes('default') || (!(this.props.defaultSet?.programMinPrograms?.length > 0) && this.context.programs?.includes('default')))
                                                    ? true : false}
                                                onColor={theme.palette.themeAlertGreen}
                                                disabled={true}
                                            />
                                        </span>
                                    </div>
                                    <div style={theme.textSupport}>
                                        The Rocky.ai team has curated the 'Essentials' content library with 6 categories and about 30 quests.
                                    </div>
                                    {true
                                        ? 
                                            ExternalHelpLink(theme, "Essentials Programs", "https://help.rocky.ai/rocky-for-creators/deactivate-reactivate-the-built-in-essentials-program", 'Help Essentials')
                                        :   ""
                                    }  
                                    <Grid container spacing={2} direction='row' justify="space-between" style={{marginTop: 30}}>
                                        <Grid item xs={this.state.selectedCategoryKey?.length>0 ? 6 : 12}>
                                            {this.state.selectedCategoryKey?.length>0
                                                &&
                                                    <h1 style={theme.textSupport}>Preview only!</h1>
                                            }
                                            <GridList cellHeight={'auto'} cols={6} spacing={4} style={{overflow: 'hidden'}}>
                                                {defaultGroups?.map((group, index) => (
                                                    group?.value?.length > 0 && (!(this.state.selectedCategoryKey?.length>0) || this.state.selectedCategoryKey === group.value)
                                                    ?
                                                        this.renderDefaultProgram(group.value,index)
                                                    :
                                                        ""
                                                ))}
                                            </GridList>
                                        </Grid>
                                        <Grid item xs={this.state.selectedCategoryKey?.length>0 ? 6 : false}>
                                            {this.state.selectedCategoryKey?.length>0 && 
                                                <>
                                                    <div style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:20, padding: 20}}>
                                                        {this.renderFullProgramPreview(this.state.ownedGroupsData?.[this.state.selectedCategoryKey], true, true)}
                                                    </div>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            {(this.state.selectedDefaultItem !== false || !(this.state.selectedCategoryKey?.length>0 )) &&
                                (this.props.defaultSet?.programCode?.length > 1 && this.context.adminPrograms?.includes(this.props.defaultSet.programCode))
                                &&
                                <>
                                    <div style={{...theme.textSupport, padding: 20}}>
                                        You can choose if your audience should have access to the 'Essentials' content or if they exclusively can only access your own content.
                                        <br/>
                                    </div>
                                    <SettingContentActive
                                        title={"Show 'Essentials' content library from 'Quests' and 'Chatbot'"} 
                                        content={{
                                            hidePublicCatalogue: ((this.state.ownedGroupsData?.[this.props.defaultSet.programCode]?.includes('default') || this.context?.programs?.includes('default'))) === true ? false : true,
                                            hidePublicContent: (this.context.hidePublicContent === true ? true : false)
                                        }} 
                                        showInverted={true}
                                        infoText={["Users can see 'Essentials' programs in catalogue", "Users might get 'Essentials' content recommended during the AI chats"]}
                                        labels={["'Essentials' programs selectable in catalogue", "'Essentials' content available in AI chats"]}
                                        params={['hidePublicCatalogue', 'hidePublicContent']}
                                        updateState={(resultDict) => {
                                            console.log('hidePublicConten newt', resultDict)
                                            var allContentDict = Object.assign({}, this.props.defaultSet)
                                            var programs = this.props.defaultSet?.programMinPrograms || [];
                                            if('hidePublicContent' in resultDict){
                                                if(resultDict['hidePublicContent'] === true){
                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({
                                                        hidePublicContent: true,
                                                        programs : this.props.firebase.fieldValue.arrayRemove('default'), 
                                                        programMinPrograms: this.props.firebase.fieldValue.arrayRemove('default')
                                                    })
                                                    this.props.firebase.user(this.context.uid).update({
                                                        hidePublicContent: true,
                                                        programs : this.props.firebase.fieldValue.arrayRemove('default')
                                                    })
                                                    allContentDict['programMinPrograms'] = programs?.filter(item => item !== 'default');
                                                }
                                                else{
                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({
                                                        hidePublicContent: false,
                                                    })
                                                    this.props.firebase.user(this.context.uid).update({
                                                        hidePublicContent: false
                                                    })
                                                }
                                            }
                                            else if('hidePublicCatalogue' in resultDict){
                                                if(resultDict['hidePublicCatalogue'] === true){
                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({
                                                        programs : this.props.firebase.fieldValue.arrayRemove('default'),
                                                        programMinPrograms : this.props.firebase.fieldValue.arrayRemove('default')
                                                    })
                                                    this.props.firebase.user(this.context.uid).update({
                                                        hidePublicContent: false,
                                                        programs : this.props.firebase.fieldValue.arrayRemove('default')
                                                    })
                                                    allContentDict['programMinPrograms'] = programs?.filter(item => item !== 'default');
                                                }
                                                else{
                                                    this.props.firebase.group(this.props.defaultSet.programCode).update({
                                                        hidePublicContent: false,
                                                        programs : this.props.firebase.fieldValue.arrayUnion('default'),
                                                        programMinPrograms : this.props.firebase.fieldValue.arrayUnion('default')
                                                    })
                                                    this.props.firebase.user(this.context.uid).update({
                                                        hidePublicContent: false,
                                                        programs : this.props.firebase.fieldValue.arrayUnion('default')
                                                    })
                                                    if (!programs?.includes('default')) {
                                                        programs?.push('default');
                                                    }
                                                    allContentDict['programMinPrograms'] = programs;
                                                }
                                            }
                                            this.updateBrandCache(allContentDict, true);
                                        }}
                                    />
                                </>
                            }
                        </>
                }
            </div>
        )
    }

    renderEmptyGroup (index, programExists = true){
        const { theme} = this.props;
        return(
            <Grid item xs={4} key={'EMpty'}>
                <div 
                    style={{height: 'calc(100% - 10px)', minHeight: 300, borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 20}}
                >
                    <Button
                        onClick={() => {this.setState({openAddCategory: true})}}
                        disabled={!(programExists)}
                    >
                        Create new Program
                    </Button>
                    {programExists === false
                        &&
                        <div style={{paddingTop: 20}}>
                            <p style={{color: theme.palette.themeAlertRed}}>No 'Domain-Code' assigned yet!</p>
                            Please add your brand first, then you can add your own Programs and Quests.
                            <br/>
                            <br/>
                            Go to the: &nbsp;
                                <Link to={ROUTES.COACH_ADMIN} style={{ textDecorationLine : "underline" ,color : theme.palette.secondary.main }}>
                                    'App Settings'
                                </Link>
                        </div>
                    }
                    {this.dialogueAddCategory(index)}
                </div>
            </Grid>
        )
    }
    renderEmptyModule (index){
        const { theme} = this.props;
        return(
            <Grid item xs={12} key={'newModule'}>
                <div 
                    style={{height: 'calc(100% - 10px)', minHeight: 80, borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 20}}
                >
                    <Button
                        onClick={() => {this.setState({openAddModule: true})}}
                    >
                    Add new Quest
                    </Button>
                    {this.dialogueAddModule(index)}
                </div>
            </Grid>
        )
    }

    renderDefaultProgram = (key, index) => {
        const { theme} = this.props;
        return(
            <Grid item xs={this.state.selectedCategoryKey?.length>0 ? 12 : 2} key={key+"_"+index}>
                <div key={key+'_'+index}>
                    {this.renderProgramCard(this.state.defaultGroups?.[index], this.state.defaultGroups?.[index]?.qid, key, true)}
                </div>
            </Grid>
        )
    }

    renderGroups = (key, index) => {
        const { qualities } = this.state;
        const { theme} = this.props;
        return(
            <Grid item xs={this.state.selectedCategoryKey?.length>0 ? 12 : 4} key={key+"_"+index}>
                <div>
                    {this.state.selectedCategoryKey === (''+key)
                        ?
                            <div style={{borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 0}}>
                                {this.state.ownedGroupsData?.[key]?.text?.length > 0
                                    &&
                                    <>
                                        {
                                            this.renderProgramCard(this.state.ownedGroupsData[key], this.state.ownedGroupsData[key].qid, key)
                                        }
                                        <div style={{width: '100%', textAlign: 'right', paddingRight: 10}}>
                                            <Button style={{marginRight: 10}}
                                                onClick={() => {
                                                    this.setState({ selectedDefaultItem : false, mode: this.state.mode === 'modeEditor' ? 'modePreview' : 'modeEditor'})
                                                    this.setProgramModule((''+key), undefined)
                                                }}
                                                //disabled={this.state.selectedQualityId===undefined && this.state.mode === 'modeEditor' }
                                            >
                                                {this.state.mode === 'modeEditor' 
                                                    ?   <>Preview Program <ImportantDevicesIcon /></>
                                                    :   <>Edit Program <AssignmentIcon /></>
                                                }
                                            </Button>
                                        </div>
                                    </>
                                }
                                <div style={{padding: 20}}>
                                    <h6>Quests in Program</h6>
                                    <div style={theme.textSupport}>
                                        {TextQuest}
                                    </div>
                                    {this.renderModules(qualities?.[key] || qualities, key) }
                                </div>
                            </div>
                        :
                            <div key={key+'_'+index}>
                                {this.state.ownedGroupsData?.[key]?.text?.length > 0
                                    &&
                                    this.renderProgramCard(this.state.ownedGroupsData[key], this.state.ownedGroupsData[key].qid, key)
                                }
                            </div>
                    }
                </div>
            </Grid>
        )
    }

    renderGroupsOld = (key, index) => {
        const {qualities } = this.state;
        const {theme} = this.props;
        return(
            <VizSensor 
                partialVisibility={true}
                key={key+'_'+index}
                onChange={(isVisible) => { 
                    if(isVisible && this.state['isVisible_'+key]===undefined) { 
                        this.setState({ ['isVisible_'+key] : true})
                        //console.log('t.leadership_category', t.leadership_category)
                    }}
                }
            >
                <div key={key+'_'+index}  
                    onClick={() => {
                        this.setProgramModule((''+key), undefined)
                    }}
                >
                    {this.state.ownedGroupsData!== undefined && this.state.ownedGroupsData[key] !== undefined && this.state.ownedGroupsData[key].icon !== undefined
                        ?
                            <AvatarCard key={key} type='imageIcon' 
                                image={this.state.ownedGroupsData[key].icon} 
                                textBlock={this.state.ownedGroupsData[key].text} 
                                squareSize='20'  
                                backgroundColor={this.state.ownedGroupsData[key].backgroundColor} />
                        :
                            <AvatarCard 
                                key={key} 
                                dict={this.props.leadershipCategories} type='category'
                                item={key} 
                                squareSize='20' />
                    }
                    {this.state.selectedCategoryKey === ''+key
                    &&
                        this.state['isVisible_'+key] && qualities !== undefined && qualities[key] !== undefined
                            ?
                                this.renderModules(qualities[key], key)
                            :
                                //<Skeleton variant="rect" width={IsMobileDevice()?'60vw':'70%' } height={200} />
                                <div style={{color: theme.palette.secondary.main, marginBottom:50}}>
                                    Click to show more
                                </div>
                        
                    }
                </div>
            </VizSensor>
        )
    }

    renderModules = (qualities, key) => {
        //console.log("swipe", key, qualities)
        return(
            <div>
                <GridList cellHeight={'auto'} cols={1} spacing={10} style={{overflow: 'hidden'}}>
                    {(qualities?.length > 7)
                        ?   ""
                        :   this.renderEmptyModule(qualities.length)
                    }
                    {qualities?.length> 0 && qualities?.map((quality, index) => (
                        quality.value
                            ?   <Grid item xs={12} key={quality.value}>
                                    {quality.qid === this.state.selectedQualityId
                                        ?
                                            <div style={{borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', minHeight: 460, borderRadius: theme.borderRadius, padding: 0}}>
                                                {this.renderQualityCard(quality, quality.qid, key)}
                                                <div style={{width: '100%', textAlign: 'right', paddingRight: 10}}>
                                                    <Button style={{marginRight: 10}}
                                                        onClick={() => {
                                                            this.setState({ mode: this.state.mode === 'modeEditor' ? 'modePreview' : 'modeEditor'})
                                                        }}
                                                        //disabled={this.state.mode === 'modeEditor' }
                                                    >
                                                        {this.state.mode === 'modeEditor' 
                                                            ?   <>Preview Quest <ImportantDevicesIcon /></>
                                                            :   <>Edit Quest <AssignmentIcon /></>
                                                        }
                                                    </Button>
                                                </div>

                                                <p>
                                                    
                                                </p>

                                                <div style={{width: "100%", padding: 20}}>
                                                    
                                                    {this.props.clickAction !== undefined 
                                                    &&
                                                        <div style={{textAlign: 'right'}}>
                                                            <Button
                                                                onClick={() => {
                                                                    this.props.clickAction(this.state.selectedCategoryKey, (this.state.selectedQualityValue || undefined), "Articles & Videos")
                                                                }}
                                                            >
                                                                Add/Manage Content
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        :   this.renderQualityCard(quality, quality.qid, key)
                                    }
                                </Grid>
                            :   <div>Failed to load Quests and data</div>
                        ))
                    }
                </GridList>
            </div>
       )
    }
    
    handleStepChange = (step, key) => {
        //console.log(step, key, 'handler')
        if(step !== undefined && key !== undefined) {
            this.setState({['activeStep_'+key] : step});
        }
    };

    renderProgramCard = (category, qid, categoryKey, defaultItem = false) => {
        const {theme} = this.props;
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        if(category.backgroundColor!== undefined && category.backgroundColor!== "" ){
            secondColor = category.backgroundColor;
        }
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        return(
            <GridListTile key={qid} 
                style={{
                    ...theme.cardJournalDayHighlight, 
                    height: this.state.selectedCategoryKey === categoryKey ? 200 : 300,
                    minWidth: 300,
                    listStyleType: 'none',
                }} 
                onClick={() => {
                    this.setState({ selectedDefaultItem : defaultItem, mode: 'modePreview'})
                    this.setProgramModule(''+categoryKey, undefined);
                }}
            >
                {defaultItem === false 
                &&
                    <div 
                        style={{
                            position: 'absolute', 
                            right: 0, 
                            paddingLeft: 4,
                            paddingTop: 4,
                            top: 0, 
                            backgroundColor: theme.palette.white,
                            borderBottomLeftRadius: theme.borderRadius
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ selectedDefaultItem : defaultItem, mode: 'modeEditor'})
                            this.setProgramModule(''+categoryKey, undefined);
                        }}
                    >
                        <Switch
                            onChange={() => void(0)}
                            checked={category.active === true ? true : false}
                            onColor={theme.palette.themeAlertGreen}
                            disabled={true}
                        />
                    </div>
                }
                <div
                    style={{
                        height: '100%',  backgroundColor: secondColor, 
                        padding: 20,
                        borderRadius: theme.borderRadius,
                        backgroundImage : 
                            category.url?.length>1
                                ?  'linear-gradient( to right, '+secondColor+' 30%, transparent ), url("'+category.url+'")'
                                :   background,
                        backgroundPosition: category.url?.length>1 ? 'right 40%' : undefined,
                        backgroundRepeat: category.url?.length>1 ? 'no-repeat' : undefined,
                        backgroundSize: category.url?.length>1 ? 'cover' : undefined,
                        webKitFilter: ((this.state.selectedQualityId !== undefined) ? 'grayscale(1)' : undefined), /* Google Chrome, Safari 6+ & Opera 15+ */
                        filter: ((this.state.selectedQualityId !== undefined) ? 'grayscale(1)' : undefined)
                    }}
                >
                            {category.icon!== ""
                                ?
                                    <AvatarCard key={qid} type='imageIcon' 
                                        image={category.icon} 
                                        textBlock={category.text} 
                                        squareSize='20'  
                                        backgroundColor={category.backgroundColor} />
                                :
                                    <AvatarCard 
                                        key={qid} 
                                        dict={category.value} type='category'
                                        item={qid} 
                                        squareSize='20' />
                            }
                </div>
                <GridListTileBar
                    title={
                        <span>
                            {category.text}
                        </span>
                        }
                    subtitle={<span>{category.explanationShort}</span>}
                    style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%"}}
                    actionIcon={<></>}
                />
            </GridListTile>
        )
    }

    renderQualityCard = (quality, qid, categoryKey, catalogue=false) => {
        const {theme} = this.props;
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        if(quality.backgroundColor!== undefined && quality.backgroundColor!== "" ){
            secondColor = quality.backgroundColor;
        }
        //console.log('renderQualityCard', quality, qid)
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        return(
            <>
                <GridListTile key={qid} 
                    style={{
                        ...theme.cardJournalDayHighlight, 
                        height: this.state.selectedQualityId !== qid ? catalogue ? 130 : 80 : 160,
                        minWidth: catalogue ? '100%' : 200,
                        background: quality.url?.length>1
                            ?  'url("'+quality.url+'")'
                            :   background,
                        backgroundPosition: quality.url?.length>1 ? 'right 40%' : undefined,
                        backgroundRepeat: quality.url?.length>1 ? 'no-repeat' : undefined,
                        backgroundSize: quality.url?.length>1 ? 'cover' : undefined,
                        webKitFilter: ((!catalogue && this.state.selectedQualityId !== qid) ? 'grayscale(1)' : undefined), /* Google Chrome, Safari 6+ & Opera 15+ */
                        filter: ((!catalogue && this.state.selectedQualityId !== qid) ? 'grayscale(1)' : undefined),
                        listStyleType: 'none'
                    }} 
                    onClick={
                        catalogue
                        ?   () => {this.setState({qualityExpended: quality.valueID, mode: 'modePreview'})}
                        :   () => {
                                this.setProgramModule(categoryKey, quality.valueID)
                                this.setState({selectedQualityId: qid, selectedQualityText: quality.text, selectedCategoryKey: categoryKey, openQualitySettings: false, openCategorySettings: false, openNotificationSettings: false, open:false, mode: 'modePreview'} )
                            }
                        
                    }
                >
                    {catalogue !== true
                        &&
                        <div style={{    
                            position: 'absolute', 
                            right: 0, 
                            paddingLeft: 4,
                            paddingTop: 4,
                            top: 0, 
                            backgroundColor: theme.palette.white,
                            borderBottomLeftRadius: theme.borderRadius
                        }}>
                            <Switch
                                onChange={() => void(0)}
                                checked={quality.active === true ? true : false}
                                onColor={theme.palette.themeAlertGreen}
                                disabled={true}
                            />
                        </div>
                    }
                    <div style={{
                        padding: 16, marginBottom: 40,
                    }}>
                        {catalogue 
                            ?   quality.icon!== undefined && quality.icon!== ""
                                ?
                                    <AvatarImage type={'imageIcon'} image={quality.icon} item={quality.value} squareSize='48px' backgroundColor={quality.backgroundColor} />  
                                :
                                    <AvatarImage type='quality' item={quality.value} squareSize='48px' backgroundColor={quality.backgroundColor} />  
                            :
                            quality.icon !== ""
                                ?
                                    <AvatarCard key={qid} type='imageIcon' 
                                        image={quality.icon} 
                                        textBlock={quality.text} 
                                        squareSize='20'
                                        backgroundColor={quality.backgroundColor} />
                                :
                                    <AvatarCard 
                                        key={qid} 
                                        dict={quality.value} type='quality'
                                        item={qid} 
                                        squareSize='20' />
                        }
                    </div>
                    {(catalogue || this.state.selectedQualityId === qid )
                    &&
                        <GridListTileBar
                            title={
                                <span>
                                    {quality.text}
                                </span>
                                }
                            subtitle={<span>{quality.explanationShort}</span>
                                }
                            style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%"}}
                            actionIcon={<></> }
                        />
                    }
                </GridListTile>
                {catalogue &&
                    <Collapse  style={{width: '100%'}} in={this.state.qualityExpended === quality.valueID} key={quality.value} timeout="auto" unmountOnExit>
                        <CardContent 
                            style={{
                                marginTop: -10,
                                paddingTop: 40,
                                marginBottom: 20,
                                marginRight: 10,
                                marginLeft: 10,
                                border: 1, 
                                borderTop: 0, 
                                borderColor: 'black',
                                borderStyle: 'solid',
                                borderBottomLeftRadius: theme.borderRadius,
                                borderBottomRightRadius: theme.borderRadius,
                            }}
                        >
                            <span style={{color: theme.palette.secondary.main }}>{quality.definition}</span>                                                               
                        </CardContent>
                    </Collapse>
                }
            </>
        )
    }

    renderQualityCardOld = (quality, qid, categoryKey) => {
        const {theme} = this.props;
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        if(quality.backgroundColor!== undefined && quality.backgroundColor!== "" ){
            secondColor = quality.backgroundColor;
        }
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        
        return(
            <Card key={qid} style={{...theme.cardJournalDayHighlight, background: background }}   >
                <CardContent style={{minWidth: 300}}>
                    <h4>{quality.text}</h4>
                    <p>Position: {quality.level !== undefined ? quality.level : "n/a"}</p>
                    {quality.icon!== ""
                        ?
                            <AvatarCard key={qid} type='imageIcon' 
                                image={quality.icon} 
                                textBlock={quality.text} 
                                squareSize='20'  
                                backgroundColor={quality.backgroundColor} />
                        :
                            <AvatarCard 
                                key={qid} 
                                dict={quality.value} type='quality'
                                item={qid} 
                                squareSize='20' />
                    }
                    <p> <span  style={theme.textSupport}>{quality.explanationShort}</span></p>
                    <p> {(this.context?.roles?.includes('ADMIN') || this.context?.roles?.includes('TEAM')) && quality?.crossQualities?.length > 0
                        &&
                        <span style={{fontSize: 'small'}}>Related: {quality.crossQualities.join(', ')}</span>
                        }
                    </p>
                    <hr/>
                    <Button onClick={() => this.handleOpenQualitySettings(qid, categoryKey)}>
                        Manage Quest<AssignmentIcon fontSize="small"/>
                    </Button>
                </CardContent>
            </Card>
        )
    }

    handleClose = () => {
        this.setState({openQualitySettings: false, openAddCategory: false, openAddModule: false, openCategorySettings: false, openNotificationSettings: false, open:false} )
    }
    handleOpenQualitySettings = (qid, categoryKey) => {
        this.setState({selectedQualityId: qid, selectedCategoryKey: categoryKey, openQualitySettings: true, openCategorySettings: false, openNotificationSettings: false, open:true} )
    }
    handleOpenCategorySettings = (categoryKey) => {
        this.setState({selectedQualityId: '', selectedCategoryKey: categoryKey,  openQualitySettings: false, openCategorySettings: true, openNotificationSettings: false, open:true} )
    }    

    dialogueCategorySettings = () => {
        const {theme} = this.props;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openCategorySettings || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openCategorySettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Program Settings
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.state.openCategorySettings && this.renderAdminContentCategory()}
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    dialogueAddCategory = (index) => {
        const {theme} = this.props;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openAddCategory || false}>
                <Dialog
                    open={this.state.openAddCategory|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Add new Program
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.state.openAddCategory &&
                            <Grid container spacing={0} direction='row' justify="space-between" >
                                <Grid item xs={2}>

                                </Grid>
                                <Grid item xs={10}>
                                    <p><span style={theme.textSupport}>Domain-Code: </span>{this.context.programCode}</p>
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 20}}>
                                    ⓵
                                </Grid>
                                <Grid item xs={10}>
                                    <SettingContentBase
                                        title={"Title of Program"} 
                                        titleFormat={true}
                                        content={this.state.newProgram || {}}
                                        showRealTimeText={true}
                                        max={30}
                                        noLabel={true}
                                        emptyOption={true}
                                        labels={['Title']}
                                        params={['text']}
                                        infoText={["e.g. The Magic of Leadership"]}
                                        updateExtraChange={this.state.newProgram?.explanationShort?.length > 4
                                            ?   undefined
                                            :   {'validated' : true, 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}
                                        }
                                        updateState={dict => {
                                            if(dict.text?.length > 5 && (!(this.state.newProgram?.explanationShort?.length > 4) || this.state.newProgram?.explanationShort?.includes("Help you with "))){
                                                dict['explanationShort'] = "Help you with " + dict.text
                                            }
                                            this.setState({newProgram: {...this.state.newProgram, ...dict}})
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 20}}>
                                    ⓶
                                </Grid>
                                <Grid item xs={10}>
                                    <SettingContentBase 
                                        title={"Value Proposition of Program"} 
                                        titleFormat={true}
                                        content={this.state.newProgram || {}}
                                        noLabel={true}
                                        max={60}
                                        emptyOption={true}
                                        labels={['Sub-Title']}
                                        showRealTimeText={true}
                                        params={['explanationShort']}
                                        infoText={["e.g. The AI Coach that is here to help 24/7!"]}
                                        updateState={dict => this.setState({newProgram: {...this.state.newProgram, ...dict}})}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 20}}>
                                    ⓷
                                </Grid>
                                <Grid item xs={10}>
                                {true && 
                                    <Card style={theme.cardAdminSettings}>
                                        <div style={{width: '100%'}}>
                                            <span>Select your Brand/Domain:</span>&nbsp;
                                            <Select
                                                value={this.state?.newProgram?.program || ""}
                                                onChange={(e) => {this.setState({newProgram: {...this.state.newProgram, program: e.target.value}} )}}
                                                name='program'
                                            >
                                                {this.context.adminPrograms?.map(entry => {
                                                    return (
                                                        <MenuItem key={"add_"+entry} value={entry}
                                                            selected={this.state.newProgram?.program === entry ? true : false}
                                                        >
                                                            {this.props.defaultSet?.programCode === entry ? (this.props.defaultSet?.title || "") + " "+entry+"" : entry}
                                                        </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </Card>
                                }
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 20}}>
                                    ⓸
                                </Grid>
                                <Grid item xs={10}>
                                    <SettingContentSelectCombi
                                        title={"Select First Quest Template"} 
                                        content={this.state.newProgram || {}}
                                        labels={["Template Quest"]} 
                                        params={['motherQuality']}
                                        selectOptions={this.state.qualitiesBaseValues || []}
                                        selectOptionsText={this.state.qualitiesBaseText || []}
                                        selectOptionsFilter={['confidence', 'priority', 'people', 'clarity', 'communication', 'balance', "No Template"]}
                                        selectOptionsFilterText={['Positivity', 'Engagement', 'Relationships', 'Meaning', 'Accomplishment', 'Life-Balance', "No Template"]}
                                        leadershipQualities={this.state.leadershipQualitiesDefault || []}
                                        leadershipCategories={this.props.leadershipCategories}
                                        updateState={dict => {
                                            console.log('select tempalte', dict)
                                            if(dict['category'] === 'No Template'){
                                                dict['category'] = 'none';
                                            }
                                            var newTemplate = {
                                                motherQuality: dict['motherQuality'], 
                                                motherCategory: dict['category'],
                                                category: dict['category']
                                            }
                                            this.setState({newProgram: {...this.state.newProgram, ...newTemplate}})
                                        }}
                                        infoText={["Choose a Quest from the Essentials Programs to inherit templated AI content"]}
                                    />
                                    {this.state.newProgram?.motherCategory !== undefined
                                    && 
                                        <Card style={theme.cardAdminSettings}>
                                            Program Template: &nbsp;
                                            <Select
                                                defaultValue={"none"}
                                                value={this.state.newProgram?.motherCategory || 'none'}
                                                onChange={(e) =>  this.setState({newProgram: {...(this.state.newProgram || {}), motherCategory : e.target.value, category : e.target.value}})}
                                                name='motherCategory'
                                            >
                                                {['confidence', 'priority', 'people', 'clarity', 'communication', 'balance'].map((item, index) => (
                                                    <MenuItem key={index} value={item}><AvatarCard key={item} dict={this.props.leadershipCategories} type='category' item={item} squareSize='20' /></MenuItem>
                                                ))}
                                                <MenuItem value={'none'} ><AvatarCard textBlock={"No Template"}  squareSize='20'/></MenuItem>
                                            </Select>
                                        </Card>
                                    }
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 40}}>
                                    ►
                                </Grid>
                                <Grid item xs={10} style={{marginTop: 40}}>
                                    {(this.state.newProgram?.text?.length > 0 && this.state.newProgram?.explanationShort?.length > 0)
                                    ?
                                        <div style={{textAlign: 'right', width: '100%'}}>
                                            {this.state.newProgram?.program?.length > 5 &&
                                                <Button
                                                    onClick = {() => this.createNewProgram(index)}
                                                >
                                                    Create new Program
                                                </Button>
                                            }
                                        </div>
                                    :
                                        <div style={theme.textSupportCenter}>
                                            Please fill out the fields above!
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueAddModule = (index) => {
        const {theme} = this.props;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openAddModule || false}>
                <Dialog
                    open={this.state.openAddModule|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Add new Quest
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.state.openAddModule &&
                            <Grid container spacing={0} direction='row' justify="space-between" >
                                <Grid item xs={2}>

                                </Grid>
                                <Grid item xs={10}>
                                    <p><span style={theme.textSupport}>Program: </span>{(this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.text || this.state.selectedCategoryKey)}</p>
                                </Grid>
                                <Grid item xs={2}>

                                </Grid>
                                <Grid item xs={10}>
                                    <p style={theme.textSupport}>{TextQuest}</p>
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 20}}>
                                    ⓵
                                </Grid>
                                <Grid item xs={10}>
                                    <SettingContentBase
                                        title={"Title of Quest"} 
                                        titleFormat={true}
                                        content={this.state.newModule || {}}
                                        max={30}
                                        noLabel={true}
                                        emptyOption={true}
                                        labels={['Title']}
                                        params={['text']}
                                        showRealTimeText={true}
                                        infoText={["e.g. Improve Listening Skills"]}
                                        updateState={dict => {
                                            if(dict.text?.length > 5 && (!(this.state.newModule?.explanationShort?.length > 4) || this.state.newModule?.explanationShort?.includes("Help you with "))){
                                                dict['explanationShort'] = "Help you with " + dict.text
                                            }
                                            this.setState({newModule: {...this.state.newModule, ...dict}})
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 20}}>
                                    ⓶
                                </Grid>
                                <Grid item xs={10}>
                                    <SettingContentBase 
                                        title={"Value Proposition for Quest"} 
                                        titleFormat={true}
                                        content={this.state.newModule || {}}
                                        noLabel={true}
                                        emptyOption={true}
                                        max={60}
                                        labels={['Sub-Title']}
                                        params={['explanationShort']}
                                        showRealTimeText={true}
                                        infoText={["e.g. Discover ideas to increase attention to the conversation partner."]}
                                        updateState={dict => this.setState({newModule: {...this.state.newModule, ...dict}})}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 20}}>
                                    ⓷
                                </Grid>
                                <Grid item xs={10}>
                                    { 
                                        <>
                                            <SettingContentSelectCombi
                                                title={"Select Quest Template"} 
                                                content={this.state.newModule || {}}
                                                labels={["Template Quest"]} 
                                                params={['motherQuality']}
                                                selectOptions={this.state.qualitiesBaseValues || []}
                                                selectOptionsText={this.state.qualitiesBaseText || []}
                                                selectOptionsFilter={['confidence', 'priority', 'people', 'clarity', 'communication', 'balance', "No Template"]}
                                                //selectOptionsFilter={['confidence', 'priority', 'people', 'clarity', 'communication', 'balance']}
                                                selectOptionsFilterText={['Positivity', 'Engagement', 'Relationships', 'Meaning', 'Accomplishment', 'Life-Balance', "No Template"]}
                                                //selectOptionsFilterText={['Positivity', 'Engagement', 'Relationships', 'Meaning', 'Accomplishment', 'Life-Balance']}
                                                leadershipQualities={this.state.leadershipQualitiesDefault || []}
                                                leadershipCategories={this.props.leadershipCategories}
                                                updateState={dict => this.setState({newModule: {...this.state.newModule, ...dict}})}
                                                infoText={["Choose a Quest from the Essentials Programs to inherit fundamental AI content"]}
                                            />
                                        </>
                                    }
                                </Grid>
                                <Grid item xs={2} style={{marginTop: 40}}>
                                    ►
                                </Grid>
                                <Grid item xs={10} style={{marginTop: 40}}>
                                    {this.state.newModule?.text?.length > 0 && this.state.newModule?.explanationShort?.length > 0 && this.state.newModule?.motherQuality?.length > 0 
                                        ?
                                        <>
                                            {this.state.newModule?.text?.length > 0 && this.state.newModule?.explanationShort?.length > 0 && 
                                                <p>
                                                    {(this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.text || this.state.selectedCategoryKey) + " > " + this.state.newModule?.text} 
                                                </p>
                                            }
                                            <div style={{textAlign: 'right', width: '100%'}}>
                                                <Button
                                                    onClick = {() => this.createNewModule(index)}
                                                >
                                                    Create new Quest
                                                </Button>
                                            </div>
                                        </>
                                        :  
                                            <div style={theme.textSupportCenter}>
                                                Please fill out the fields above!
                                            </div>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    createNewProgram(index){
        var text = this.state.newProgram?.text || "";
        var explanationShort = this.state.newProgram?.explanationShort || "";
        var program = this.state.newProgram?.program || this.context.programCode || "";
        var value = program.slice(0,9).toLowerCase().replace(/[^A-Z]/ig, "").trim() + "_"+((index || 0) + 1) + "_" + text.slice(0,5).toLowerCase().replace(/[^A-Z]/ig, "").trim() + "_" + explanationShort.slice(0,5).toLowerCase().replace(/[^A-Z]/ig, "").trim();
        value = ""+value;
        var newProgram = {program: this.context.adminPrograms?.[0] || undefined};
        if(text !== '' && value !== '' && program !== '') {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            var defaultNewCategory = {
                text: text,
                value: value,
                isOriginal: false,
                explanationShort: explanationShort,
                program: program,
                active: false,
                premium: true,
                public: false,
                createdAt: timestamp,
                author: [this.context.uid]
            }
            var motherProgramDict = {};
            var motherCategory = this.state.newProgram?.motherCategory || undefined;
            if(motherCategory?.length > 2){
                motherProgramDict = FindDictByKey(this.props.firebase, this.props.leadershipCategories, motherCategory) || {};
                if(motherProgramDict?.definition?.length > 0){
                    defaultNewCategory['motherCategory'] = this.state.newProgram?.motherCategory;
                    // keep the new programs inactive
                    defaultNewCategory['active'] = true;
                }
            }
            var newCategory = Object.assign({}, motherProgramDict, defaultNewCategory);
            //console.log('createNewProgram', newCategory)
            {/*** */}
            this.props.firebase.leadershipCategories().add(newCategory);
            this.props.firebase.user(this.context.uid).update({programs : this.props.firebase.fieldValue.arrayUnion(program)})
            this.setState({errorMessage : 'Added category', newProgram: newProgram, openAddCategory: false})
            var updateNewModule = {
                newModule: {
                    active: defaultNewCategory['active'],
                    text: text,
                    explanationShort: explanationShort,
                    motherQuality: (this.state.newProgram?.motherQuality || undefined),
                    selectedProgram: program
                },
                selectedCategoryKey: value
            }
            //console.log('updateNewModule', updateNewModule)
            this.setState(updateNewModule, () => {this.createNewModule(1)})
        }
    }

    async createNewModule (index){
        var text = this.state.newModule?.text || "";
        var motherQuality = this.state.newModule?.motherQuality || undefined;
        var explanationShort = this.state.newModule?.explanationShort || "";
        var program = this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.program || this.state.newModule?.selectedProgram || undefined;
        var category = this.state.selectedCategoryKey || undefined;
        var value = program.slice(0,5).toLowerCase().replace(/[^A-Z]/ig, "").trim() + "_" + ((index || 0) +1) + "_" + text.slice(0,5).toLowerCase().replace(/[^A-Z]/ig, "").trim() + "_" + explanationShort.slice(0,5).toLowerCase().replace(/[^A-Z]/ig, "").trim()
        if(FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, value, 'valueID') === value){
            const alphabet = "abcdefghijklmnopqrstuvwxyz";
            const randomIndex = Math.floor(Math.random() * alphabet.length);
            value = alphabet[randomIndex]+value;
        }
        var valueNew = value 
        if(motherQuality?.length > 0 && motherQuality !== "none"){
            valueNew = value + "." + motherQuality;
        }
        //console.log('value', value, program, category)
        if(category && value?.length > 0 && program && text !== '' && value !== '' && program !== '') {
            var motherQualityDict = FindDictByKey(this.props.firebase, this.props.leadershipQualities, motherQuality) || {};
            var position = (this.state.qualities?.[category]?.length || 0) + 1;
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            var newModuleInfo = {
                text: text,
                value: valueNew,
                valueID: value,
                isOriginal: false,
                explanationShort: explanationShort,
                category: category,
                program: program,
                active: (this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator && motherQuality?.length > 0 && motherQuality !== "none") ? true : false,
                premium: true,
                public: false,
                createdAt: timestamp,
                author: [this.context.uid],
                level: position
            }
            //// With motherquality making it active
            if(motherQuality?.length > 0 && motherQuality !== "none"){
                newModuleInfo['motherQuality'] = motherQuality;
                ////// it always needs to be published first
                if(this.state.newModule?.active === true){
                    newModuleInfo['active'] = true;
                }
            }
            var newModuleDict = Object.assign({}, motherQualityDict, newModuleInfo);
            //// remove inherited sisterqualityes and add the program modules instead
            ['sisterQualities', 
                'sisterQuality1', 
                'sisterQuality2', 
                'sisterQuality3', 
                'sisterQuality4', 
                'sisterQuality5', 
                'sisterQuality6', 
                'sisterQuality7',
                'asCategory',
                'qid',
            ].map(item => {
                if(newModuleDict.hasOwnProperty(item)) {
                    delete newModuleDict[item];
                }
            })
            ///// add curent program sisters
            var sisterQualities = [];
            this.state.qualities?.[category]?.map(quality => {
                if(quality.valueID){ 
                    sisterQualities.push(quality.valueID);
                }
            }) 
            if(sisterQualities?.length > 0){
                newModuleDict['sisterQualities'] = sisterQualities;
            }
            //// use bg color from program
            if(this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.backgroundColor?.includes("#")){
                newModuleDict['backgroundColor'] = this.state.ownedGroupsData[this.state.selectedCategoryKey].backgroundColor;
            }
            //// use quality bg color or program bg color
            if(!(motherQualityDict?.url?.includes("http")) && this.state.ownedGroupsData?.[this.state.selectedCategoryKey]?.url?.includes("http")){
                newModuleDict['url'] = this.state.ownedGroupsData[this.state.selectedCategoryKey].url;
            }
            //console.log('newModuleDict', newModuleDict)
            {/*** */}
            const docRef = await this.props.firebase.leadershipQualities().add(newModuleDict);
            if(position > 0){
                var cid = this.state.ownedGroupsData[category].cid;
                if(cid){
                    if (position === 1){
                        this.props.firebase.leadershipCategory(cid).update({initialLevelQuality: value});
                    }
                    else{
                        this.props.firebase.leadershipCategory(cid).update({initialLevelQuality: value});
                    }
                }
            }
            this.setProgramModule(category, value);
            this.updateQualityCache(newModuleDict);
            this.setState({openAddModule: false, errorMessage : 'Added Quest', newModule: undefined, selectedQualityId: docRef.id, mode: 'modeEditor'});
        }
    }

    renderInactiveCategory(content, cid){
        const {theme} = this.props;
        return(
            <>
                {content?.program !== 'default' 
                    && 
                    <>
                        {content.active !== true
                            ?   
                                <div style={{marginBottom: 20}}
                                    onClick={() => {
                                        this.setState({ selectedDefaultItem : false, mode: 'modeEditor'})
                                    }}
                                >
                                    {content?.definition?.length > 10 
                                        && (content?.backgroundColor?.length > 2)
                                        &&  content?.icon?.length > 2
                                        && content?.url?.length > 2
                                        && this.state.qualities?.[this.state.selectedCategoryKey]?.length >= 1
                                        ?
                                            <SettingContentActive 
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Publish Program"} 
                                                content={content} 
                                                params={['active']}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, content, resultDict)
                                                    //this.setDefaultCategory(allContentDict);
                                                    this.updateCategoryCache(allContentDict);
                                                }}
                                            />
                                        :   <p>Complete following tasks to publish this Program:</p>
                                    }
                                    {!(content?.definition?.length > 10)
                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add a short description of the Program</span>
                                    }
                                    {!(content?.backgroundColor?.length > 2)
                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Choose the backgrond color</span>
                                    }
                                    {!(content?.icon?.length > 2)
                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add a Program icon</span>
                                    }
                                    {!(content?.url?.length > 2)
                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add a theme image for your Program</span>
                                    }
                                    {!(this.state.qualities?.[this.state.selectedCategoryKey]?.length >= 1)
                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add at least 1 Quest</span>
                                    }
                                </div>
                            :
                                <div style={{marginBottom: 20}}>
                                    <SettingContentActive 
                                        dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                        title={"Program Published"} 
                                        content={content} 
                                        params={['active']}
                                        updateState={(resultDict) => {
                                            var allContentDict = Object.assign({}, content, resultDict)
                                            //this.setDefaultCategory(allContentDict);
                                            this.updateCategoryCache(allContentDict);
                                        }}
                                    />
                                </div>
                        }
                    </>
                }
            </>
        )
    }

    renderInactiveQuality(content, cid, allowUnpublish = false){
        const {theme} = this.props;
        return(
            <>
                {content?.program !== 'default' 
                    && 
                    <>
                        {content.active !== true
                            ?   
                                <div style={{marginBottom: 10}}>
                                    {content?.definition?.length > 10 
                                        && (content?.backgroundColor?.length > 2)
                                        ?
                                            !(this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator)
                                            ?
                                                <Grid container spacing={0} direction='row' justify="space-between" style={{width: '100%', margin: 6}}>
                                                    <Grid item xs={6}>
                                                        <strong>Publish Quest &nbsp;</strong>
                                                    </Grid>
                                                    <Grid item xs={6} style={{textAlign: 'right', paddingRight: 10}}>
                                                        <Switch
                                                            onChange={() => {
                                                                this.setState({openAlert: true, showShop: true, alertText: "Publishing your own Quests requires a subscription! But you can explore them for yourself."})
                                                            }}
                                                            checked={this.state.openAlert || false}
                                                            onColor={theme.palette.themeAlertGreen}
                                                            disabled={false}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {this.state.showShop 
                                                        &&
                                                            <div style={{margin: 20, textAlign: 'center', maxWidth: 500}}>
                                                                <BadgeCard
                                                                    key={"creator"}
                                                                    backgroundColor= {"white"}
                                                                    image={undefined} 
                                                                    avatarImage = {<ShoppingCartIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                                    title = {"Publish your Quests"}
                                                                    subtitle = {"Subscribe to the business packages"}
                                                                    strongSubtitle = {false}
                                                                    onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                                    clickText= {'Subscribe'}
                                                                />
                                                            </div>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            :
                                                <SettingContentActive 
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Publish Quest"} 
                                                    content={content} 
                                                    params={['active']}
                                                    labels={["Publish"]}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateQualityCache(allContentDict, true);
                                                    }}
                                                />
                                        :   "To publish this Quest:"
                                    }
                                    {!(content?.definition?.length > 10)
                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Add a short description of the Quest</span>
                                    }
                                    {!(content?.backgroundColor?.length > 2)
                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ Choose the backgrond color</span>
                                    }
                                </div>
                            :
                                allowUnpublish
                                ?
                                    <>
                                        {content?.definition?.length > 10 
                                            && (content?.backgroundColor?.length > 2)
                                            ?
                                                <SettingContentActive 
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Unpublish Quest"} 
                                                    content={content} 
                                                    params={['active']}
                                                    labels={["Published"]}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateQualityCache(allContentDict, true);
                                                    }}
                                                />
                                            :   "To publish this Quest:"
                                        }
                                        {
                                            <span style={{color: theme.palette.themeAlertRed}}><br/>Removes access of clients to this Quest and it's related content and bot questions!</span>
                                        }
                                    </>
                                :   ""
                        }
                    </>
                }
            </>
        )
    }

    renderAdminContentCategory(){
        const {theme} = this.props;
        const {selectedCategoryKey} = this.state;
        var content = undefined;
        var cid = undefined;
        if(selectedCategoryKey?.length>0 && this.state.ownedGroupsData!== undefined && this.state.ownedGroupsData[selectedCategoryKey] !== undefined && this.state.ownedGroupsData[selectedCategoryKey]
            ){
            content =  this.state.ownedGroupsData[selectedCategoryKey];
            cid = this.state.ownedGroupsData[selectedCategoryKey].cid
        }
        return(
            <>
                { cid!==undefined && content !== undefined 
                    ?   
                        <>
                            <div>
                                {!(this.state.mode === "modePreview" || (this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator) || AccessCheckCreatorTrialPhase(this.context))
                                ?
                                    <div style={{margin: 20, textAlign: 'center', maxWidth: 500}}>
                                        <BadgeCard
                                            key={"creator"}
                                            backgroundColor= {"white"}
                                            image={undefined} 
                                            avatarImage = {<ImportantDevicesIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                            title = {!AccessCheckCreatorTrialPhase(this.context) ? "Activate Creator Tool" : "Manage coaching content"}
                                            subtitle = {"Subscribe to the business packages"}
                                            strongSubtitle = {false}
                                            onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                            clickText= {'Subscribe'}
                                        />
                                    </div>
                                :   this.state.mode === "modePreview"
                                    ?      
                                        <div style={{width: '100%', marginTop: 60}}>
                                            {this.renderInactiveCategory(content, cid)}
                                            {
                                                this.renderFullProgramPreview(this.state.ownedGroupsData?.[this.state.selectedCategoryKey], true, false)
                                            }
                                        </div>
                                    :
                                        <>
                                            <h4>1. Program Description</h4>
                                            {this.renderInactiveCategory(content, cid)}
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Title of Program"} 
                                                content={content}  
                                                max={30}
                                                noLabel={true}
                                                labels={['Title']}
                                                params={['text']}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, content, resultDict)
                                                    this.updateCategoryCache(allContentDict);
                                                }}
                                            />
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Sub-Title / Value Proposition"} 
                                                titleFormat={true}
                                                content={content} 
                                                noLabel={true}
                                                max={60}
                                                labels={['Sub-Title']}
                                                params={['explanationShort']}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, content, resultDict)
                                                    this.updateCategoryCache(allContentDict);
                                                }}
                                            />
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Short description of Program"} 
                                                content={content} 
                                                noLabel={true}
                                                multiline={true}
                                                labels={['Description']}
                                                params={['definition']} 
                                                max={300}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, content, resultDict)
                                                    this.updateCategoryCache(allContentDict);
                                                }}
                                            />
                                            {this.context.adminPrograms?.length > 1
                                            && 
                                                <SettingContentSelect 
                                                    dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                    title={"Assigned Domain-Code"} 
                                                    content={content}
                                                    labels={["Domain-Code"]}
                                                    noLabel={true}
                                                    params={['program']}
                                                    selectOptions={this.context.adminPrograms}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateCategoryCache(allContentDict);
                                                    }}
                                                />
                                            }
                                            <Grid container spacing={0} direction='row' justify="space-between" >
                                                <Grid item xs={6}>
                                                    <SettingColorPicker
                                                        dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                        title={"Icon background-color"} 
                                                        content={content} 
                                                        noLabel={true}
                                                        hexColorFormat={true}
                                                        params={['backgroundColor']}
                                                        updateState={(resultDict) => {
                                                            var allContentDict = Object.assign({}, content, resultDict)
                                                            this.updateCategoryCache(allContentDict);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SettingContentUploadImage
                                                        dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                        title={"Program icon (500x500px PNG transparent)"} 
                                                        imgDef={"Square format, PNG file, max. 250kb, transparent background"}
                                                        imgExtensionList={[".png"]}
                                                        imgFormat={"sqaure"}
                                                        imgMinWidth={80}
                                                        previewImageSize={true}
                                                        backgroundColor={content.backgroundColor || undefined}
                                                        content={content} 
                                                        noLabel={true}
                                                        params={['icon']}
                                                        uploadDatabase={this.props.firebase.storageImages('icons/'+cid)}
                                                        updateState={(resultDict) => {
                                                            var allContentDict = Object.assign({}, content, resultDict)
                                                            this.updateCategoryCache(allContentDict);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <SettingContentUploadImage
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Standard theme image for articles (800x400px JPG/GIF)"} 
                                                imgDef={"16:9 format, min 600px width, JPG or GIF, max. 700kb"}
                                                //imgExtensionList={[".png"]}
                                                imgFormat={"rectangle"}
                                                imgMinWidth={600}
                                                content={content} 
                                                noLabel={true}
                                                params={['url']}
                                                uploadDatabase={this.props.firebase.storageImages('categories/'+cid)}
                                                updateState={(resultDict) => {
                                                    var allContentDict = Object.assign({}, content, resultDict)
                                                    this.updateCategoryCache(allContentDict);
                                                }}
                                            />
                                            <SettingContentRichText
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Optional: Extended description of Program"} 
                                                content={content} 
                                                noLabel={true}
                                                params={['extraText']}
                                                />
                                            <h4>2. Reference Goal Description</h4>
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Define the pre-set goals for new users when selecting your program"} 
                                                max={160}
                                                content={content} 
                                                labels={['Long-term Vision (e.g. "Change the world for...")', 'Mid-term Objective (e.g. "Become a leader who...")', '3-month Goal (e.g. "Practice communication skills by...")']}
                                                params={['answerQuestionIssueLongTerm', 'answerQuestionInterest', 'answerQuestionIssueShortTerm']}
                                            />
                                            <h4>3. Default Coach Instructions</h4>
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                title={"Define the default behaviour of the AI coach on how to reply on users comments and follow-up questions"} 
                                                max={220}
                                                content={content} 
                                                labels={['Default Response Instruction', 'Default Question Instruction']}
                                                params={['promptResponse', 'promptQuestion']}
                                                infoText={['e.g. "Reply as a solution-oriented coach and describe what you understood', 'e.g. "Act as an ICF coach and ask an open ended question that is inspiring and motivating']}
                                            />
                                            {content.program !== "default" && content.program !==undefined && content.program !=="" 
                                            &&
                                                <>
                                                    <h4>4. Coach/Organization Description</h4>
                                                    <SettingContentBase 
                                                        dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                        title={"Name of Coach and Title"} 
                                                        content={content} 
                                                        params={['coach_name', 'coach_title', 'coach_email', 'coach_contact', 'coach_contact_url']}
                                                        labels={['Coach/Organization', 'Title/Value Proposition', 'Email Address', 'Website Name', 'Website URL']}
                                                        />
                                                    <SettingContentRichText
                                                        dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                        title={"Extended Information about Coach/Organization"} 
                                                        content={content} 
                                                        noLabel={true}
                                                        params={['coach_text']}
                                                        />
                                                    <SettingContentUploadImage
                                                        dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                        title={"Image of Coach/Organization 800x400 px"} 
                                                        imgDef={"Ideally 16:9 format, min 600px width, JPG or GIF, max. 700kb"}
                                                        //imgExtensionList={[".png"]}
                                                        //imgFormat={"rectangle"}
                                                        imgMinWidth={600}
                                                        content={content} 
                                                        noLabel={true}
                                                        params={['coach_url']}
                                                        uploadDatabase={this.props.firebase.storageImages('coaches/'+cid)}
                                                        />
                                                </>
                                            }

                                            <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-label="extra"
                                                    aria-controls="extra"
                                                    id="extra"
                                                >
                                                    &nbsp;Program Admin
                                                </AccordionSummary>
                                                <AccordionDetails> 
                                                    <Grid container spacing={0} direction='row' alignItems="center" style={{width:"100%"}}>
                                                        <Grid item xs={12}>
                                                            <p style={{color : theme.palette.themeAlertRed}}>Danger zone!</p>
                                                            {content.active !== true
                                                                ?
                                                                    <Button
                                                                        variant="outlined"
                                                                        style={{borderColor: theme.palette.themeAlertRed}}
                                                                        onClick={() => {this.unpublishCategories(content, cid)}}
                                                                        disabled={ content.active === true}
                                                                    >
                                                                        Archive Program
                                                                    </Button>
                                                                :
                                                                    <p>You have to unpublish the program first, if you want to delete it!</p>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{border:1}}>
                                                                {(this.context?.roles?.includes("ADMIN") || this.context?.roles?.includes("ADMIN") )
                                                                &&
                                                                    <h4 style={{color :'blue'}}>Unique ID value: <strong>{cid}</strong></h4>  
                                                                }
                                                                <p>
                                                                    createdAt: { ConvertTimestampToDateString(content?.createdAt, true)} <br/>
                                                                    updatedAt: { ConvertTimestampToDateString(content?.updatedAt, true)} 
                                                                </p>
                                                            </div>
                                                        </Grid>
                                                        {(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM"))
                                                        &&
                                                            <>
                                                                <SettingContentMapSelect
                                                                    dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                                    title={"Complement Big5 Ocean personality traits positvely. (1x high-contribution, 1x medium-contribution, 3x low-contribution)"} 
                                                                    content={content} 
                                                                    labels={["Big 5, OCEAN"]}
                                                                    params={['personalityTraitsTarget']}
                                                                    mapOptions={['O','C', 'E', 'A', 'N', 'pP', 'pE', 'pR', 'pM', 'pA', 'pV']}
                                                                    mapOptionsText={['Openness','Consciousness', 'Extroversion', 'Agreeableness','Neuroticism',
                                                                        'Positive Emotions', 'Engage', 'Relations.', 'Meaning', 'Accompl.', 'Life-Balance' ]}
                                                                    selectOptions={[10, 25, 50, 75, 90]}
                                                                    selectOptionsText={['none', 'some','medium', 'high', 'perfect']}
                                                                />
                                                                <SettingContentSelect 
                                                                    dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                                    title={"Mother Program / Template"} 
                                                                    content={content} 
                                                                    params={['motherCategory']}
                                                                    selectOptions={['confidence', 'priority', 'people', 'clarity', 'communication', 'balance']}
                                                                    selectOptionsText={['Positivity', 'Engagement', 'Relationships', 'Meaning', 'Accomplishment', 'Life-Balance']}
                                                                />
                                                                <SettingContentBase 
                                                                    dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                                    title={"Initial Quality"} 
                                                                    max={60}
                                                                    content={content} 
                                                                    params={['initialLevelQuality']}
                                                                />
                                                                <SettingContentActive 
                                                                    dbUpdateLink={this.props.firebase.leadershipCategory(cid)}
                                                                    title={"Active Module, Premium"} 
                                                                    content={content} 
                                                                    params={['active', 'premium']}
                                                                />
                                                            </>
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            {(this.context?.roles?.includes("ADMIN") || this.context?.roles?.includes("ADMIN") )
                                            &&
                                               <h4 style={{color :'blue'}}>Unique ID value: <strong>{content.value}</strong></h4>
                                            }
                                        </>
                                }
                            </div>
                        </>
                    :   <div>Loading</div> 
                }
            </>
        )
    }

    updateCategoryCache(content){
        var cats = this.props.leadershipCategories;
        var qid = content.value;
        cats = cats.filter(c => c.value !== qid);
        cats.unshift(content);
        DataCacheWrite('leadershipCategories', cats, 1800);
        if(this.props.refreshCategories !== undefined){
            //console.log('refreesh cats', qid, cats, this.props.leadershipCategories)
            this.props.refreshCategories(cats, true);
        }
    }
    updateQualityCache(content, reload = false){
        var qualities = this.props.leadershipQualities;
        var valueId = content.valueID || content.value;
        qualities = qualities.filter(c => c.valueID !== valueId);
        content['valueID'] = valueId;
        qualities.unshift(content);
        //console.log('qualities', valueId, qualities, this.props.leadershipQualities)
        DataCacheWrite('leadershipQualities', qualities, 1800);
        if(this.props.refreshQualities !== undefined){
            //console.log('refreesh qualities', valueId, qualities, this.props.leadershipQualities)
            this.props.refreshQualities(qualities, true);
        }
    }

    unpublishQuest(content){
        this.setState({selectedQualityId: undefined})
        content['active'] = false;
        content['category'] = "archive_"+(content['program'] || this.context.uid);
        this.props.firebase.leadershipQuality(content.qid).update(content);
        //console.log("unpublishQuest", content)
        var qualities = this.props.leadershipQualities;
        var valueId = content.valueID || content.value;
        qualities = qualities.filter(c => c.valueID !== valueId);
        DataCacheWrite('leadershipQualities', qualities, 1800);
        if(this.props.refreshQualities !== undefined){
            //console.log('refreesh qualities', qid, qualities, this.props.leadershipQualities)
            this.props.refreshQualities(qualities, true);
        }
    }

    unpublishCategories(content, cid){
        this.setState({selectedCategoryKey: undefined, selectedQualityId: undefined})
        content['active'] = false;
        content['public'] = false;
        content['program'] = "archive_"+(content['program'] || this.context.uid);
        var scid = content['cid']
        this.props.firebase.leadershipCategory(cid).update(content);
        //console.log("unpublishQuest", content)
        var categories = this.props.leadershipCategories;
        console.log("unpublishCategories", categories.length)
        categories = categories.filter(c => c.cid !== cid);
        categories = categories.filter(c => c.cid !== scid);
        console.log("unpublishCategories", categories.length)
        DataCacheWrite('leadershipCategories', categories, 1800);
        if(this.props.refreshCategories !== undefined){
            this.props.refreshCategories(categories, true);
        }
        this.setProgramModule()
        this.loadPrograms()
    }

    renderAdminContentQuality(){
        const {selectedQualityId, selectedCategoryKey} = this.state;
        var content = undefined;
        var cid = undefined;
        if(selectedQualityId !== undefined && selectedQualityId !== "" && selectedCategoryKey!==undefined
            && this.state.qualities !== undefined && this.state.qualities[selectedCategoryKey] !== undefined 
            ){
            content =  this.state.qualities[selectedCategoryKey].find(q => q.qid === selectedQualityId );
            cid = selectedQualityId
        }
        //console.log('this.state', this.state, this.props)
        return(
            <>
                {cid!==undefined && content !== undefined 
                    ? 
                        <>
                            <div>
                                {!(this.state.mode === "modePreview" || (this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator) || AccessCheckCreatorTrialPhase(this.context))
                                    ?
                                        <div style={{margin: 20, textAlign: 'center', maxWidth: 500}}>
                                            <BadgeCard
                                                key={"creator"}
                                                backgroundColor= {"white"}
                                                image={undefined} 
                                                avatarImage = {<ImportantDevicesIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                title = {!AccessCheckCreatorTrialPhase(this.context) ? "Activate Creator Tool" : "Manage coaching content"}
                                                subtitle = {"Subscribe to the business packages"}
                                                strongSubtitle = {false}
                                                onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                clickText= {'Subscribe'}
                                            />
                                        </div>
                                    :   this.state.mode === "modePreview"
                                        ?      
                                            <div style={{width: '100%', marginTop: 60}}>
                                                {
                                                    this.renderInactiveQuality(content, cid)
                                                }
                                                {
                                                    this.renderFullProgramPreview(content, true, false, content)
                                                }
                                            </div>
                                        :
                                            <div>
                                                <h4>1. Quest Description</h4>
                                                {
                                                    this.renderInactiveQuality(content, cid)
                                                }
                                                <SettingContentBase 
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Title of Quest"} 
                                                    titleFormat={true}
                                                    content={content}
                                                    max={30}
                                                    noLabel={true}
                                                    params={['text']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateQualityCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingContentBase 
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Sub-Title / Value Proposition"} 
                                                    titleFormat={true}
                                                    content={content} 
                                                    noLabel={true}
                                                    max={60}
                                                    labels={['Sub-Title']}
                                                    params={['explanationShort']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateQualityCache(allContentDict, true);
                                                    }}
                                                />
                                                <SettingContentBase 
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Short description of Quest"} 
                                                    content={content} 
                                                    noLabel={true}
                                                    multiline={true}
                                                    params={['definition']} 
                                                    max={300}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateQualityCache(allContentDict, true);
                                                    }}
                                                />
                                                <Grid container spacing={0} direction='row' justify="space-between" >
                                                    <Grid item xs={6}>
                                                        <SettingColorPicker
                                                            dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                            title={"Icon background-color"} 
                                                            content={content} 
                                                            noLabel={true}
                                                            hexColorFormat={true}
                                                            params={['backgroundColor']}
                                                            updateState={(resultDict) => {
                                                                var allContentDict = Object.assign({}, content, resultDict)
                                                                this.updateQualityCache(allContentDict, true);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <SettingContentUploadImage
                                                            dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                            title={"Quest icon (500x500px, PNG format, transparent, max 250kb)"}
                                                            imgDef={"Square e.g. 500x500px, PNG format, transparent background, max. 250kb"}
                                                            imgExtensionList={[".png"]}
                                                            imgFormat={"square"}
                                                            imgMinWidth={80}
                                                            previewImageSize={true}
                                                            backgroundColor={content.backgroundColor || undefined}
                                                            content={content} 
                                                            noLabel={true}
                                                            params={['icon']}
                                                            uploadDatabase={this.props.firebase.storageImages('icons/'+cid)}
                                                            updateState={(resultDict) => {
                                                                var allContentDict = Object.assign({}, content, resultDict)
                                                                this.updateQualityCache(allContentDict, true);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <SettingContentRichText
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Optional: Extended description of Quest"} 
                                                    content={content} 
                                                    noLabel={true}
                                                    params={['extraText']}
                                                />
                                                <SettingContentUploadImage
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Standard image for articles (800x400px JPG/GIF)"} 
                                                    imgDef={"16:9 format, min 600px width, JPG or GIF, max. 700kb"}
                                                    //imgExtensionList={[".png"]}
                                                    imgFormat={"rectangle"}
                                                    imgMinWidth={600}
                                                    content={content} 
                                                    noLabel={true}
                                                    params={['url']}
                                                    uploadDatabase={this.props.firebase.storageImages('qualities/'+cid)}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateQualityCache(allContentDict, true);
                                                    }}
                                                />
                                                <h4>2. Vision/Objectives Suggestions</h4>
                                                <SettingContentArray 
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"3 to 6 mid- and long-term goals from a user perspective"} 
                                                    max={160}
                                                    multiline={true}
                                                    noLabel={true}
                                                    content={content} 
                                                    labels={['Aspirations']}
                                                    params={['goalSuggestions']}
                                                    />
                                                <h4>3. Sorting and Access Settings</h4>
                                                <SettingContentNumber 
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Position of Quest within Program"} 
                                                    content={content}
                                                    noLabel={true}
                                                    min={1}
                                                    max={10}
                                                    params={['level']}
                                                    updateState={(resultDict) => {
                                                        var allContentDict = Object.assign({}, content, resultDict)
                                                        this.updateQualityCache(allContentDict, true);
                                                    }}
                                                />
                                                {(this.context?.roles?.includes('ADMIN') || this.context?.roles?.includes('TEAM') || AccessCheckCoachCreator(this.context))
                                                    &&
                                                        <SettingContentSelectCombi
                                                            dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                            title={"Preloaded Template (for Articles, Videos & Bot Questions)"} 
                                                            content={content} 
                                                            params={['motherQuality']}
                                                            noLabel={true}
                                                            emptyOption={true}
                                                            selectOptions={this.state.qualitiesBaseValues || []}
                                                            selectOptionsText={this.state.qualitiesBaseText || []}
                                                            selectOptionsFilter={['confidence', 'priority', 'people', 'clarity', 'communication', 'balance', "No Template"]}
                                                            selectOptionsFilterText={['Positivity', 'Engagement', 'Relationships', 'Meaning', 'Accomplishment', 'Life-Balance', "No Template"]}
                                                            leadershipQualities={this.state.leadershipQualitiesDefault || []}
                                                            leadershipCategories={this.props.leadershipCategories || []}
                                                            updateState={(resultDict) => {
                                                                var motherQuality = resultDict.motherQuality || undefined;
                                                                var qualityName = content.value || undefined;
                                                                var qualitySubs = qualityName.split(".");
                                                                if(motherQuality === "" || motherQuality === "none"){
                                                                    var baseQualityName = qualitySubs[0];
                                                                    this.props.firebase.leadershipQuality(cid).update({value : baseQualityName, motherQuality: this.props.firebase.fieldValue.delete() })
                                                                }
                                                                else if(motherQuality?.length > 2 && qualityName?.length > 2){
                                                                    var newQualityName = qualitySubs[0] + "." + motherQuality;
                                                                    //console.log('resultDict', resultDict, newQualityName);
                                                                    this.props.firebase.leadershipQuality(cid).update({value : newQualityName})
                                                                }
                                                                if(this.props.refreshQualities !== undefined){
                                                                    this.props.refreshQualities();
                                                                }
                                                            }}
                                                        />
                                                }
                                                <SettingContentSelect
                                                    dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                    title={"Optional: Required XP level to unlock Quest"} 
                                                    content={content}
                                                    noLabel={true}
                                                    emptyOption={true}
                                                    selectOptions={[0, 50, 100, 200, 300, 400, 600, 800, 1000]}
                                                    selectOptionsText={["0% of first Quest (0XP)", "25% of first Quest (50XP)", "50% of first Quest (100XP)", "100% of first Quest (200XP)", "50% of second Quest (300XP)", "Two Quests completed (400XP)", "Three Quests completed (600XP)", "Four Quests completed (800XP)", "Five Quests completed (1000XP)"]}
                                                    params={['unlockXP']}
                                                />
                                                <h4>4. Admin Settings and Parameters</h4>
                                                {(this.context?.roles?.includes('ADMIN') || this.context?.roles?.includes('TEAM'))
                                                    &&
                                                    <>
                                                        <SettingContentArrayCheckbox
                                                            dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                            title={"Sister Quests that will be used to enrich questions and learnings"} 
                                                            content={content} 
                                                            labels={['Sister Quests']}
                                                            params={['sisterQualities']}
                                                            selectOptions={this.state.qualitiesValues}
                                                            selectOptionsText={this.state.qualitiesText}
                                                            />
                                                        <SettingContentArrayCheckbox
                                                            dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                            title={"3 PERMA-V related Quests from Pool"} 
                                                            content={content} 
                                                            labels={['Related Quests from Default Pool']}
                                                            params={['crossQualities']}
                                                            selectOptions={this.state.qualitiesValues}
                                                            selectOptionsText={this.state.qualitiesText}
                                                        />
                                                        <SettingContentMapSelect
                                                            dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                            title={"Complement Big5 Ocean personality traits positvely. (1x high-contribution, 1x medium-contribution, 3x low-contribution)"} 
                                                            content={content} 
                                                            labels={["Big 5, OCEAN"]}
                                                            params={['personalityTraitsTarget']}
                                                            mapOptions={['O','C', 'E', 'A', 'N', 'pP', 'pE', 'pR', 'pM', 'pA', 'pV']}
                                                            mapOptionsText={['Openness','Consciousness', 'Extroversion', 'Agreeableness','Neuroticism',
                                                            'Positive Emotions', 'Engage', 'Relations.', 'Meaning', 'Accompl.', 'Life-Balance' ]}
                                                            selectOptions={[10, 25, 50, 75, 90]}
                                                            selectOptionsText={['none', 'some','medium', 'high', 'perfect']}
                                                            promptSupport={ `Define changes of attributes in OCEAN and PERMA-V for people who develop to '${(content.text + " and " + content.explanationShort)}'. . Give me change of the score of the target person on a scale of 0 to 100 for each OCEAN attribute and each PERMA-V attribute. Do not repeat the prompt. Show only the score changes `}
                                                        />
                                                        <SettingContentActive 
                                                            dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                            title={"Active Quest, Type, Premium"} 
                                                            content={content} 
                                                            params={['active', 'isOriginal', 'premium']}
                                                        />
                                                        {this.context.roles?.includes('ADMIN') 
                                                        &&
                                                            <SettingContentActive 
                                                                dbUpdateLink={this.props.firebase.leadershipQuality(cid)}
                                                                title={"Public in Search (ADMIN)"} 
                                                                content={content} 
                                                                params={['public']}
                                                            />
                                                        }
                                                    </>
                                                }
                                                {
                                                    this.renderInactiveQuality(content, cid, true)
                                                }
                                                <div style={{paddingLeft: 5 }}>
                                                    <p style={{color : theme.palette.themeAlertRed}}>Danger zone!</p>
                                                    {   
                                                        <Button
                                                            variant="outlined"
                                                            style={{borderColor: theme.palette.themeAlertRed}}
                                                            onClick={() => {this.unpublishQuest(content)}}
                                                            disabled={ content.active === true}
                                                        >
                                                            Archive Quest
                                                        </Button>
                                                    }
                                                    {content.active === true
                                                        &&
                                                        <span>&nbsp;You can only remove and archive unpublished Quests! </span>
                                                    }
                                                </div>
                                                {(this.context?.roles?.includes("ADMIN") || this.context?.roles?.includes("ADMIN") )
                                                &&
                                                    <h4 style={{color :'blue'}}>Unique ID value: <strong>{content.value}</strong></h4>  
                                                }
                                            </div>
                                }
                            </div>
                        </>
                    :   <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                }
            </>
        )
    }

    renderFullProgramPreview(previewCategory, flagshowCategory = false, flagShowQualities = false, content={}){
        const {theme} = this.props;
        //console.log('previewCategory', previewCategory, this.state)
        if(previewCategory){
            return(
                <>
                    {flagshowCategory && previewCategory !== undefined && previewCategory.explanationShort !== undefined && previewCategory.definition !== undefined 
                        ? (
                            <div ref={this.dialogRef}>
                                <div key={previewCategory.title}>
                                    {previewCategory.icon!== undefined && previewCategory.icon!== ""
                                        ?
                                            <AvatarCard key={previewCategory.title} type='imageIcon' image={previewCategory.icon} textBlock={previewCategory.text} squareSize='20'  backgroundColor={previewCategory.backgroundColor} />
                                        :
                                            <AvatarCard key={previewCategory.title} dict={this.state.leadershipCategories} type='category' item={this.state.chosenCategory} squareSize='20' />
                                    }
                                </div>
                                {previewCategory.coach_name !== undefined  && previewCategory.coach_name !== ''
                                    ?
                                    <Grid container spacing={2} direction='row' alignItems="center" justify="space-between" >
                                        <Grid item xs={7}>
                                            <p>
                                                <strong>
                                                    Premium
                                                </strong>
                                            </p>
                                            <p>
                                                by <strong>{previewCategory.coach_name }</strong>
                                            </p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            {previewCategory.url !== undefined && previewCategory.url !== ''
                                            &&
                                                <div style={{
                                                    backgroundImage: `url(${previewCategory.url})`,
                                                    backgroundPosition: IsMobileDevice()? "center center" : "top center",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",
                                                    borderRadius: theme.borderRadius,
                                                    width: '100%',
                                                    height: 150,
                                                }}>
                                                    &nbsp;
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    <div>
                                        <div style={{width:'100%', textAlign:'right'}}>
                                            <strong>
                                                    Premium
                                            </strong>
                                        </div>
                                    </div>
                                }
                                <h2 style={theme.CardContentItem.TextTitle}>
                                    {previewCategory.explanationShort}
                                    {
                                    //FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.state.chosenCategory, 'explanationShort')
                                    }
                                </h2>
                                {previewCategory.requirements !== undefined  && previewCategory.requirements.length > 0 && (previewCategory.requirements?.length===1 && previewCategory.requirements?.[0] !== 'default')
                                    &&
                                    <div style={theme.textSupport}>
                                        {BestForProfile(previewCategory.requirements, this.props.defaultSet)}
                                    </div>
                                }
                                <div>
                                    <strong style={{...theme.CardContentItem.TextSubTitle, marginBottom: 20}}>
                                        {previewCategory.definition}
                                        {
                                        //FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.state.chosenCategory, 'definition')
                                        }
                                    </strong>
                                </div>
                                <div style={{
                                    marginTop: 20,
                                    marginBottom: 20,
                                    marginRight: 0,
                                    marginLeft: -20,
                                    height: 200,
                                    width: 'calc(100% + 40px)',
                                    padding: 4,
                                    backgroundImage : 
                                        previewCategory.url?.length>1
                                            ?  'linear-gradient( to right, '+(previewCategory.backgroundColor || "transparent")+' 5%, transparent 40%), url("'+previewCategory.url+'")'
                                            :   "",
                                    backgroundPosition: previewCategory.url?.length>1 ? 'right 40%' : undefined,
                                    backgroundRepeat: previewCategory.url?.length>1 ? 'no-repeat' : undefined,
                                    backgroundSize: previewCategory.url?.length>1 ? 'cover' : undefined,
                                }}>
                                    {previewCategory.url?.length > 2
                                        &&
                                            <span>Image</span>
                                    }
                                </div>
                                
                            </div>
                            )
                        :
                        ""
                    }
                    {(flagShowQualities)
                    ?
                        <Grid container spacing={2} style={{marginTop: 40, marginBottom: 40}}>
                            {this.props.leadershipQualities?.filter(q => (q.category === previewCategory?.value))?.map((entry, indexEntry) => {
                                if (entry.url?.includes("http")) {
                                    return (
                                        //this.renderChildren(entry, indexEntry)
                                        <React.Fragment key={'en'+entry+indexEntry}>
                                            { this.renderQualityCard(entry, indexEntry, previewCategory?.value, true)}
                                        </React.Fragment>
                                    );			
                                }
                                else {
                                    return (
                                        <React.Fragment key={'en'+entry+indexEntry}>
                                            { this.renderChildren(entry, indexEntry)}
                                        </React.Fragment>
                                    )	
                                }
                            }) }
                        </Grid>
                        :   !flagshowCategory && !flagShowQualities && content?.qid?.length > 2
                            ?
                                <Grid container spacing={2} style={{marginTop: 40, marginBottom: 40}}>
                                    {
                                        this.renderQualityCard(content, 0, previewCategory?.value, true)
                                    }
                                </Grid>
                            :   ""
                    }
                    {flagshowCategory 
                    && 
                        <div style={{marginTop: 20}}>
                            {previewCategory.extraText !== undefined  && previewCategory.extraText !== ''
                                &&
                                <div 
                                    className={theme.innerHTML}
                                    onClick = {(event) => {
                                        try{
                                            const link = event.target.getAttribute('href');
                                            if(link !== undefined && link !== null && link !== ""){
                                                event.preventDefault();
                                                window.open(link, "_blank")
                                            }
                                        }
                                        catch(e){
                                            console.log('No Link', e)
                                        }
                                    }}
                                    dangerouslySetInnerHTML={{ __html: previewCategory.extraText}} />
                            }

                            <div style={{marginBottom: 100}}></div>
                            {previewCategory?.coach_name !== undefined && previewCategory?.coach_name !== ''
                                &&
                                <div>
                                    <h2 style={theme.CardContentItem.TextTitle}>
                                        {previewCategory.coach_name }
                                    </h2>
                                </div>
                            }
                            {(
                                (previewCategory?.youtubeId!==undefined && previewCategory?.youtubeId!=='') 
                                ||
                                (previewCategory?.coach_url !== undefined && previewCategory?.coach_url !== '')
                            ) &&
                                <div style={{
                                    marginTop: 20,
                                    marginRight: 0,
                                    marginLeft: -20,
                                    width: 'calc(100% + 40px)',
                                    padding: 4
                                }}>
                                    {previewCategory.youtubeId!==undefined && previewCategory.youtubeId!==''
                                        ?
                                            <div key={previewCategory?.value || 'empty'}>
                                                <RenderMediaYoutube
                                                    contentData = {{
                                                        youtubeId: previewCategory.youtubeId, 
                                                        title: (previewCategory.text || 'Intro'),
                                                        url: (previewCategory.coach_url !== undefined && previewCategory.coach_url !== ''  ? previewCategory.coach_url : undefined)
                                                    }}
                                                    enabled = {true}
                                                />
                                            </div>
                                        :
                                            <img style={theme.dialogImage} src={previewCategory.coach_url} alt={'My Coach'} />
                                    }
                                </div>
                            }
                            {previewCategory !== undefined && previewCategory.explanationShort !== undefined && previewCategory.definition !== undefined 
                                &&
                                <div style={{marginTop:30, marginBottom:30}}>
                                    {previewCategory.coach_title !== undefined  && previewCategory.coach_title !== ''
                                        &&
                                        <p>
                                            <strong style={theme.CardContentItem.TextSubTitle}>
                                                {previewCategory.coach_title }
                                            </strong>
                                        </p>
                                    }
                                    {previewCategory.coach_text !== undefined  && previewCategory.coach_text !== ''
                                        &&
                                        <div 
                                            className={theme.innerHTML}
                                            onClick = {(event) => {
                                                try{
                                                    const link = event.target.getAttribute('href');
                                                    if(link !== undefined && link !== null && link !== ""){
                                                        event.preventDefault();
                                                        window.open(link, "_blank")
                                                    }
                                                }
                                                catch(e){
                                                    console.log('No Link', e)
                                                }
                                            }}
                                            dangerouslySetInnerHTML={{ __html: previewCategory.coach_text}} />
                                    }
                                    {previewCategory.coach_contact !== undefined  && previewCategory.coach_contact !== ''
                                        &&
                                        <div style={{marginTop: 20}}>
                                            <span style={theme.CardContentItem.TextSubTitle}>
                                                {previewCategory.coach_contact }&nbsp;
                                                {previewCategory.coach_contact_url !== undefined  && previewCategory.coach_contact_url !== ''
                                                    &&  
                                                    //<a href={previewCategory.coach_contact_url} target="_blank" rel="noopener noreferrer"><PublicIcon/></a> 
                                                    <span style={{color: theme.palette.secondary.main}}
                                                        onClick={ () => window.open(previewCategory.coach_contact_url, "_blank")}>
                                                        &nbsp; <PublicIcon/>
                                                    </span>
                                                }
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                            <div>
                                {[previewCategory?.answerQuestionIssueLongTerm || "", previewCategory?.answerQuestionInterest || "", previewCategory?.answerQuestionIssueShortTerm || ""]?.map((g, index) => {
                                    if(g?.length > 2){
                                        return(
                                            <ListItem 
                                                key={index} 
                                                style={{paddingLeft:0, paddingRight:0}}
                                            >
                                                <ListItemIcon>
                                                    <AssignmentTurnedInIcon fontSize={"small"}/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {g}
                                                </ListItemText>
                                            </ListItem>
                                        )   
                                    }
                                    else return ""          
                                })}
                                {previewCategory?.goalSuggestions?.map((g, index) => {
                                    return(
                                        <ListItem 
                                            key={index} 
                                            style={{paddingLeft:0, paddingRight:0}}
                                        >
                                            <ListItemIcon>
                                                <AssignmentTurnedInIcon fontSize={"small"}/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                {g}
                                            </ListItemText>
                                        </ListItem>
                                    )                    
                                })}
                            </div>
                        </div>
                    }
                </>
            )
        }
    }
    renderChildren (entry, indexEntry){
        const {theme} = this.props;
        const {previewCategory} = this.state;
        return(
            <Grid item key={entry.value} xs={12} >
                <Card key={entry.value} 
                    style={{
                        ...theme.CardDialogueButtonFrame,
                        backgroundColor : entry.value === this.state.currentLeadershipQuality ? theme.palette.themeAltGold : theme.CardGoals.backgroundColor
                    }}
                    >
                    <List style={{padding:0, margin:0}} >
                        {/** <ListItem style={{width: '100%'}} button onClick= {this.state.qualityExpended !== entry.value ?() => this.handleCatExpandClick(entry.value, entry.text) : () =>  this.handleSubmit(entry.value, entry.text)} > **/}
                        <ListItem 
                            style={{width: '100%', margin:0, paddingLeft:10}} 
                            onClick={() => {this.setState({qualityExpended: entry.value})}}
                        >
                            {entry.icon!== undefined && entry.icon!== ""
                                ?
                                    <AvatarImage type={'imageIcon'} image={entry.icon} item={entry.value} squareSize='11vh' backgroundColor={entry.backgroundColor} />  
                                :
                                    <AvatarImage type='quality' item={entry.value} squareSize='11vh' backgroundColor={entry.backgroundColor} />  
                            }
                            <ListItemText 
                                style={{paddingLeft: 15, paddingTop: 0}}
                                primary={
                                    <Typography style={{ color: entry.value === this.state.currentLeadershipQuality ? theme.palette.black : theme.palette.themeText}}>
                                        {entry.value === this.state.currentLeadershipQuality ? entry.text + ' (active)': entry.text }
                                    </Typography>
                                }
                                secondary=
                                    {<Typography style={{ color: this.state.qualityExpended !== entry.value ? theme.palette.secondary.main : theme.palette.themeText, width:'95%' }}>
                                        {entry.explanationShort}
                                    </Typography>}
                            />
                            {this.state.qualityExpended === entry.value ? '' : <SearchIcon style={{color: theme.palette.secondary.main}}/>}
                        </ListItem>
                    </List>
                    <Collapse style={{width: '100%'}} in={this.state.qualityExpended === entry.value} key={entry.value} timeout="auto" unmountOnExit>
                        <CardContent>
                                <Grid container spacing={2}  >
                                    <Grid item xs={10}>
                                        <span style={{color: theme.palette.secondary.main }}>{entry.definition}</span>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton									
                                            //onClick={() => this.handleSubmit(entry.value, entry.text)}
                                            aria-label="Chooose the skill"
                                            style={{padding:0}}
                                            >
                                            <AddCircleOutlineIcon  fontSize="large" style={{ color: theme.palette.themeRed}} />
                                        </IconButton>
                                    </Grid>
                                </Grid>                                                                    
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
        )
    }

    dialogueQualitySettings = () => {
        const {theme} = this.props;
        //console.log(content, selectedQualityId)
        return(
            <RemoveScroll enabled={this.state.openQualitySettings || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openQualitySettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Quest Settings
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.state.openQualitySettings && this.renderAdminContentQuality()}
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }


}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ManagePrograms);
