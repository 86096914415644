import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

import logoIOS from "../../images/AppiOSAppstore.png";
import logoAndroid from "../../images/AppAndroidPlaystore.png";
import logoPWAMobile from "../../images/AppPWADesktop.png";
import logoPWADesktop from "../../images/AppRockyDesktop.png";
import { IsMobileDevice } from '../Modules/IsMobileDevice';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class AppInstallLinks extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        try{
            window.removeEventListener("beforeinstallprompt", () => {});
            window.addEventListener("beforeinstallprompt", this.pwaInstallEvent);
        } catch  (e){
            console.log(e)
        }
    }
    pwaInstallEvent = (event) => {
        //console.log('beforeinstallprompt', event)
        event.preventDefault();
        var installPrompt = event;
        //console.log('beforeinstallprompt', event)
        if(this._isMounted){
            this.setState({installPrompt})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        try{
            window.removeEventListener("beforeinstallprompt", () => {});
        } catch  (e){
            console.log(e)
        }
    }

    render() {
        const { screen } = this.props;
        //console.log('info')
        if(process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER" && !(this.props.user?.mobileDeviceInfo?.platform === "PWA")){
            return(
                <div>
                    {false && this.state.installPrompt !== undefined && 
                        <Button
                            onClick = { () => {
                                const result = this.state.installPrompt?.prompt();
                            }}
                        >
                            Install
                        </Button>
                    }
                    <div style={{padding: screen === 'desktop' ? 20 :0, width: "100%", textAlign: "center"}}> 
                        <Grid container 
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            {this.props.defaultSet?.programURL?.includes('http')
                            ?
                                this.state.installPrompt !== undefined 
                                ?
                                    IsMobileDevice()
                                    ?
                                        <>
                                            <Grid item xs={12}
                                                onClick={() => {this.state.installPrompt?.prompt();}}
                                            >
                                                <img src={logoPWAMobile} style={{maxWidth: "90%", height: 70}} alt="Install Mobile App" />
                                            </Grid>
                                        </>
                                    :
                                        <>
                                            <Grid item xs={12}
                                                onClick={() => {this.state.installPrompt?.prompt();}}
                                            >
                                                <img src={logoPWADesktop} style={{maxWidth: "90%", height: 70}} alt="Install Web App" />
                                            </Grid>
                                        </>
                                :
                                    this.props.defaultSet.programInstallURL?.includes("http") && IsMobileDevice()
                                    ?
                                        <>
                                            <Grid item xs={12}
                                                onClick={() => {window.open(this.props.defaultSet.programInstallURL, "_blank")}}
                                            >
                                                <img src={logoPWAMobile} style={{maxWidth: "90%", height: 70}} alt="Install Mobile App" />
                                            </Grid>
                                        </>
                                    :
                                        ""
                            :
                                IsMobileDevice()
                                ?
                                    <>
                                        <Grid item xs={6}
                                            onClick={() => {window.open("https://apps.apple.com/app/id1492680249?utm_source=in-app", "_blank")}}
                                        >
                                            <img src={logoIOS} style={{maxWidth: "90%", height: 70}} alt="Install Apple iOS" />
                                        </Grid>
                                        <Grid item xs={6}
                                            onClick={() => {window.open("https://play.google.com/store/apps/details?id=ai.rocky.app&utm_source=in-app", "_blank")}}
                                        >
                                            <img src={logoAndroid} style={{maxWidth: "90%", height: 70}} alt="Install Google Android" />
                                        </Grid>
                                    </>
                                :
                                    <>
                                        <Grid item xs
                                            onClick={() => {window.open("https://apps.apple.com/app/id1492680249?utm_source=in-app", "_blank")}}
                                        >
                                            <img src={logoIOS} style={{maxWidth: "90%", height: 70}} alt="Install Apple iOS" />
                                        </Grid>
                                        <Grid item xs
                                            onClick={() => {window.open("https://play.google.com/store/apps/details?id=ai.rocky.app&utm_source=in-app", "_blank")}}
                                        >
                                            <img src={logoAndroid} style={{maxWidth: "90%", height: 70}} alt="Install Google Android" />
                                        </Grid>
                                        {this.state.installPrompt !== undefined 
                                        && 
                                            <Grid item xs
                                                onClick={() => {this.state.installPrompt?.prompt()}}
                                            >
                                                <img src={logoPWADesktop} style={{maxWidth: "90%", height: 70}} alt="Install Web App" />
                                            </Grid>
                                        }
                                    </>
                            }
                        </Grid>
                    </div>
                </div>
            )
        }
        else{
            return ""
        }
    }
}


export default compose(
    withStyles(styles, { withTheme: true })
)(AppInstallLinks);