import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import ActionFabButton from '../Home/ActionFabButton';
import badgeCongrats from "../../images/congrats.png";
import SnackbarInfo from '../Modules/SnackbarInfo';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';


// MUI stuff
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});
const stylesTab = {
    tabMain: {
        padding: 0,
        margin: 0,
        width: '100%',
        minWidth: 'auto',
        display: 'contents'
    },
    tabsNavi: {
        backgroundColor: 'transparent', 
        padding: 0,
        margin: 0,
        opacity: 100,
        width: 'auto',
        minWidth: 'auto',
        display: 'contents'
    }
}

class Task4Topics extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            challenges: 4,
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        var engagementStats = this.props.engagementStats?.leadership_quality || undefined;
        if(engagementStats){
            this.loadTopEngagements(this.props.engagementStats);
        }
        if(this.props.loadStats){
            this.props.firebase.insight(this.props.user.uid)
            .get().then(snapshot => {
                if(this._isMounted ){
                    var engagementSet = snapshot.data();
                    //console.log('userstats', engagementSet)
                    if(engagementSet!== undefined && engagementSet.engagements !== undefined){
                        if(engagementSet.engagements.leadership_quality !== undefined){
                            engagementSet['leadership_quality'] = engagementSet.engagements.leadership_quality
                            this.loadTopEngagements(engagementSet);
                            //console.log('en', engagementSet)
                        }
                    }
                }
            });
        }
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    componentDidUpdate(prevProps)  {
        if(this._isMounted && this.props.engagementStats !== undefined){
            if(prevProps.engagementStats === undefined){
                this.loadTopEngagements(this.props.engagementStats);
            }
            else if(this.props.engagementStats?.total?.points?.points !== prevProps.engagementStats?.total?.points?.points){
                this.loadTopEngagements(this.props.engagementStats);
            }
        }
        return true
    }

    loadTopEngagements(engagementStats){
        if(engagementStats?.leadership_quality){
            var engagementDict = engagementStats?.leadership_quality;
            var key = 'points';
            var sortedList = Object.keys( engagementDict ).map( key => ({ key, value: engagementDict[key] }) ).sort( (a, b) => a.value[key] - b.value[key] );
            //console.log('sorted', sortedList)
            var prioList = sortedList.slice(-4);
            prioList = prioList.reverse()
            var totalPoints = 0;
            [0,1,2,3].forEach(index => {
                if(prioList.length >= index && prioList[index]?.value?.value !== undefined){
                    //console.log('index', index, sortedList, prioList[index]['quality'])
                    prioList[index]['icon'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, prioList[index]?.value?.value, 'icon');
                    prioList[index]['quality'] = prioList[index]?.value?.value;
                    prioList[index]['text'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, prioList[index]?.value?.value, 'text');
                    prioList[index]['points'] = prioList[index]?.value?.points;
                    prioList[index]['percentage'] = Math.round(prioList[index]['points']/200*100);
                    if(prioList[index]['percentage'] > 100) prioList[index]['percentage'] = 100;
                    totalPoints += prioList[index]['percentage'];
                    prioList[index]['bgColor'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, prioList[index]?.value?.value, 'backgroundColor');
                }
                else{
                    prioList[index] = {'text': '(new)', 'points': 0};
                }
            })
            var mission3Level = false;
            var sisterQualities = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user['currentLeadershipQuality'], 'sisterQualities');
            if(this.props.isCoach || totalPoints >= 400 || this.props.user?.roles?.includes('COACHEE')){
                totalPoints = 0;
                mission3Level = true;
                [0,1,2,3].forEach(index => {
                    var topic = this.props.user.currentLeadershipQuality;
                    var ranking = index;
                    if (index > 0){
                        topic = this.props.user['additionalLeadershipQuality'+index] || undefined;
                        if(topic === undefined && sisterQualities!== undefined){
                            topic = sisterQualities[index-1] || undefined;
                        }
                    }
                    else{
                        topic = this.props.user['currentLeadershipQuality'] || undefined;
                    }
                    if(topic !== undefined && prioList.length > ranking ){
                        var benchmarkPoints = 200;
                        if(this.props.engagementBenchmarkQuality > 200){
                            benchmarkPoints = this.props.engagementBenchmarkQuality;
                        }
                        prioList[ranking]['icon'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, topic, 'icon');
                        prioList[ranking]['quality'] = topic;
                        prioList[ranking]['text'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, topic, 'text');
                        prioList[ranking]['points'] = (engagementDict?.[topic]?.points || 0);
                        var percentageRate = Math.round(prioList[ranking]['points']/benchmarkPoints*100);
                        prioList[ranking]['percentage'] = percentageRate;
                        if(prioList[ranking]['percentage'] > 100) prioList[ranking]['percentage'] = 100;
                        totalPoints += prioList[ranking]['percentage'];
                        prioList[ranking]['bgColor'] = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, topic, 'backgroundColor');
                    }
                    else{
                        prioList[ranking] = {};
                        prioList[ranking]['text'] = 'Please add a new topic!';
                        prioList[ranking]['points'] = 0;
                    }
                })
            }
            this.setState({growthPathList: prioList, totalPoints, mission3Level});
            //console.log('prioList', prioList)
        }
    }


    render(){
        return(
            this.renderButtons()
        )
    }

    handleStartRoutine = () => {
        if(this._isMounted){
            this.setState({ disabled: true });
            this.props.history.push(ROUTES.ROUTINE);
        }
    }

    renderButtons() {
        const { theme } = this.props;
        //console.log('props', this.props)
        return (
            <div style={{margin:this.props.noMargin? 0 : 20}}>
                <div style={{position: 'relative'}}>
                    {this.props.isCoach
                        ?
                            <strong>Coaching Plan Engagement</strong>
                        :
                            <h4 style={theme.CardContent.titleText}>
                                {this.state.mission3Level
                                    ?   (this.props.user?.roles?.includes('COACHEE') || this.props.isCoach)
                                        ?   "Coaching Plan Engagement"
                                        :   "Explore new Modules"
                                    :   "30-Days Challenge"
                                }
                            </h4>
                    }
					{this.props.tutorialOpen 
					&&
						<div style={{position: 'absolute', top: -4, right: -6}}>
							<IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('mission')}}>
								<HelpOutlineIcon />
							</IconButton>
						</div>
					}
				</div>
                <div style={{...theme.textSupport, marginBottom: 20}}>
                    {this.props.isCoach
                        ?   "Following list shows the modules of the personal Coaching Plan versus engagement"
                        :   this.state.mission3Level
                            ?   this.props.user?.roles?.includes('COACHEE')
                                ?   "In cooperation with your coach, reflect on modules that are relevant to your personal Coaching Plan."
                                :   "It's time to get out of your comfort zone and work on new Modules that will further enhance your skillset!"
                            :   "Prove you are fit for the mission by showing your dedication, determination, and desire to grow into your true potential by reaching 200 XP for 4 modules."
                    }
                </div>
                <SnackbarInfo 
                    alertText = {this.state.alertText}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.openAlert || false}
                    closeHandler = {() => {this.setState({openAlert: false})}}
                />
                {this.state?.totalPoints > 0
                    ?   
                        <p style={theme.textSupportCenter}>
                            {(this.state.totalPoints / 400 * 100) > 100
                                ?   "Advanced Level"
                                :   (Math.round(this.state?.totalPoints / 400 * 100) + "% of mission completed.")
                            }
                             <br/>
                        </p>
                    :
                        <p style={theme.textSupportCenter}> 
                            1% of personal Coaching Plan engaged. <br/>
                        </p>
                }
                <div style={{padding: '10px 0px 10px 0px', margin:0, width: "100%", maxHeight: 200, height:"100%", overflow:'hidden'}}>
                    <Tabs
                        aria-label="Coaching Modules"
                        variant="scrollable"
                        scrollButtons="auto"
                        value={4}
                        style={{
                            //marginLeft:20, 
                            //marginRight:20, 
                            //paddingLeft: 10
                        }} 
                    >
                        {[0,1,2,3].map(position => {
                            var percentage = this.state.growthPathList?.[position]?.percentage || 0;
                            return(
                                <Tab 
                                    key={"fab_"+position}
                                    style={{...stylesTab.tabsNavi}}
                                    value={position+1}
                                    onClick={() => this.setState({ openAlert: false},
                                        () => setTimeout( () => {this.setState({alertText: this.state.growthPathList?.[position]?.text + ' - ' + this.state.growthPathList?.[position]?.points + ' XP', openAlert: true})}, 300)
                                    )}
                                    label={
                                        <ActionFabButton
                                            text={percentage >= 99 ? 'Advanced' : (percentage) + "%"}
                                            image={(this.state.growthPathList?.[position]?.icon )}
                                            theme={theme}
                                            height = '9vH'
                                            style={{marginLeft:10}}
                                            percentage = {percentage}
                                            colorCircle = { theme.palette.themeAlertGreen }
                                            colorButton = { this.state.growthPathList?.[position]?.bgColor || theme.palette.themeAltGold}
                                        />
                                    }
                                />
                            )
                        })}
                    </Tabs> 
                </div>
                {this.props.isCoach
                    ?   ''
                    :   this.state?.totalPoints >= 400
                        ?
                            <div style={{paddingBottom:40, paddingTop:40, textAlign:'center'}}>
                                <p style={theme.textErrorCenterPadding}>
                                    <img src={badgeCongrats} 
                                        style={{maxWidth:120, width:"25%", height: 'auto', verticalAlign: 'middle'}} 
                                        alt='well done'  />
                                </p>
                                {this.state.mission3Level
                                    ?   <p>You completed 4 new modules! Time to add new challenging modules.</p>
                                    :   <p>You achieved your 30-day mission. Time to change the modules!</p>
                                }
                                <h4 style={theme.textSupportCenter}>
                                    Congratulations!
                                </h4>
                                <Button onClick={() => {
                                        this.props.history.push(ROUTES.STATS);
                                        try{
                                            var mydoc = document.getElementById('growthpath')
                                            //console.log('1xxxwww', mydoc)
                                            if (mydoc) {
                                                mydoc.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        } catch(e) {}
                                    }}>
                                    {this.props.statspage
                                        ?   'Add Quest'
                                        :   'Show AI insights'
                                    }
                                </Button>
                            </div>
                        :
                            <div style={{paddingBottom:40, paddingTop:0, textAlign:'center'}}>
                                <p style={theme.textSupportCenter}> 
                                    Coaching Plan progress &bull; Add & modify
                                </p>
                                <Button onClick={() => {
                                        this.props.history.push({
                                            pathname: ROUTES.STATS,
                                            state: {
                                                value: 'Coaching Plan',
                                            }
                                        });
                                        this.setState({})
                                        try{
                                            var mydoc = document.getElementById('growthpath')
                                            //console.log('1xxxwww', mydoc)
                                            if (mydoc) {
                                                mydoc.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        } catch(e) {}
                                    }}>
                                    Change Coaching Plan
                                </Button>
                            </div>
                }
            </div>
        )
    }

    renderActionItem(key, icon, subtitle, title, actionResult, link){
        const {theme} =  this.props;
        //console.log('redner', key, title)
        var color = theme.palette.themeAlertGreen ;
        if(actionResult) color = theme.palette.themeLightGrey ;
        return(
            <Card id={key} key={key} 
                style={{...theme.CardDialogueButtonFrame, 
                    background : 'linear-gradient(to bottom, '+ color +' 0%, '+theme.palette.white+' 30%)',
                    //backgroundColor : 'linear-gradient(to bottom, '+color+' 0%, #FFFFFF 30%)',
                    margin:10, 
                    padding:20,
                    height: "90%",
                    overflow: "hidden"
                }}
            >
                        <Grid container spacing={2} direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={2}>
                                {icon}
                            </Grid>
                            <Grid item xs={10}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                    <strong style={{wordWrap:'break-word'}}>
                                    &nbsp; {title}
                                    </strong>
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                   {subtitle}
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                                {actionResult
                                    ?
                                        <CheckCircleIcon
                                            style={{color:"green",  float: "right"  }}
                                        />
                                    :
                                        <Link to={link}>
                                            <Button
                                                style={{float: "right", backgroundColor: theme.palette.themeRed, marginBottom:10}}
                                                size={IsMobileDevice() ? "small" : "medium"}
                                                endIcon={icon}
                                            >
                                                Start
                                            </Button>
                                        </Link>
                                }
                            </Grid>
                        </Grid>
                    
            </Card>
        )
    }
    
}

export default compose(
    withRouter,
    withFirebase,
    withStyles(styles, { withTheme: true})
)(Task4Topics);
