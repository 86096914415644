import React, { Component } from 'react';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import defaultIcon from '../../images/quality_focus_white_320x320.png';
import defaultCategoryIcon from '../../images/category_priority_white_320x320.png';
import { FindTextValueInDict, FindDictByKey } from '../Modules/LoadCategoriesAndQualities';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// is used in case an picture is not available
//import ArchiveIcon from '@material-ui/icons/Archive';


class AvatarImage extends Component {
    _isMounted=false;
    constructor(props) {
        super(props);

        var squareSize = this.props.squareSize;
        if (this.props.squareSize === undefined){
            squareSize = 50;
        }
        var image = '';
        if (this.props.image !== undefined && this.props.image !== ''){
            image = this.props.image;
        }
        var backgroundColor = this.props.backgroundColor;
        if (this.props.dict !== undefined && (image==='' || backgroundColor === undefined)){
            try{
                //var fullDict = this.props.dict.find(c => c.value === this.props.item);
                var fullDict = FindDictByKey(this.props.firebase, this.props.dict, this.props.item);
                if(backgroundColor === undefined || backgroundColor === ''){
                    backgroundColor = fullDict.backgroundColor;
                }
                if (fullDict.icon !== undefined && fullDict.icon !== ""){
                    image = fullDict.icon;
                }
            }
            catch (err){
                if(this.props.backgroundColor !== undefined){
                    backgroundColor = this.props.backgroundColor;
                }
                else{
                    backgroundColor = '#FCD730';
                }
            }
        }
       
        this.state = {
            type: this.props.type,
            item: this.props.item,
            backgroundColor: backgroundColor,
            key : this.props.item+"_"+backgroundColor,
            squareSize:squareSize,
            optionalImage: image,
            imageSource : defaultIcon
        };

    }
    componentDidUpdate(prevProps){
        //console.log('props image:', this.props);
        if (prevProps.image !== undefined && this.props.image !== this.state.optionalImage && this._isMounted) {
            var dict = {
                optionalImage: this.props.image,
            }
            if(this.props.backgroundColor!==undefined && this.props.backgroundColor!==''){
                dict['backgroundColor'] = this.props.backgroundColor;
            }
            this.setState(dict, () => this.loadImage());
            //console.log('update:', this.props);
        }
    }
    componentDidMount () {
        this._isMounted = true;
        this.loadImage();
        //console.log('mount:', this.state);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadImage(){
        //var imageSource = '';
        if(this.props.type === 'icon' || this.props.type === 'imageIcon'){
            this.setState({imageSource : this.state.optionalImage})
        }
        else{
            if(this.state.optionalImage !== undefined && this.state.optionalImage !== '') {
                this.setState({imageSource : this.state.optionalImage})
            }
            else{
                try {
                    if (false){
                        var url = `../../images/${this.state.type}_${this.state.item}_white_320x320.png`;
                        //console.log('loading icon', url)
                        const img = new Image();
                        img.src = url;
                        if (img.complete) {
                            this.setState({imageSource: img});
                        }
                        /***
                        imageSource = require(url)
                        import(url).then(image => {
                            this.setState({imageSource: image});
                            //console.log('loaded image', image)
                        });
                        */
                    }
                } 
                catch (e) {
                    //console.log('loading failed', imageSource, e)
                    //imageSource = defaultIcon;      
                }
            }
        }
    }
    
    render() {
        var {imageSource} = this.state;
        //console.log('props', this.props)
        if(this.props.type === 'icon'){
            return (
                <Avatar
                    key={this.state.key}
                    variant="rounded"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: this.state.backgroundColor,
                        //height: this.state.squareSize,
                        height: '100%',
                        maxHeight:100,
                        maxWidth:100,
                        width: this.props.fitavatar === 'fit' ? 'fit-content' : this.state.squareSize,
                        borderRadius: 12,
                        margin: 0,
                        padding: 3,
                    }}
                    >
                    {imageSource} 
                </Avatar>
            )
        }
        else if(this.props.type === 'imageIcon'){
            return (
                <Avatar
                    key={this.state.key}
                    variant="rounded"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: this.state.backgroundColor,
                        //height: this.state.squareSize,
                        height: '100%',
                        maxHeight:100,
                        maxWidth:100,
                        width: this.props.fitavatar === 'fit' ? 'fit-content' : this.state.squareSize,
                        borderRadius: 12,
                        margin: 0,
                        padding: 3
                    }}
                    src= {imageSource} 
                    alt={"R"}
                    >
                </Avatar>
            )
        }
        else{
            return (
                <Avatar
                    key={this.state.key}
                    variant="rounded"
                    src={imageSource}
                    alt={this.state.item}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: this.state.backgroundColor,
                        //height: this.state.squareSize,
                        height: '100%',
                        maxHeight:100,
                        maxWidth:100,
                        width: this.props.fitavatar === 'fit' ? 'fit-content' : this.state.squareSize,
                        borderRadius: 12,
                        margin: 0,
                        padding: 3,
                    }}
                    />
            )
        }
    }


}


export class AvatarCard extends Component {
    constructor(props) {
        super(props);

        var squareSize = this.props.squareSize;
        if (this.props.squareSize === undefined){
            squareSize = 50;
        }
        var image = '';
        if (this.props.image !== undefined && this.props.image !== ''){
            image = this.props.image;
        }
        var textBlock = this.props.textBlock;
        if(this.props.textBlock === undefined || this.props.textBlock === ''){
            textBlock = this.props.item;
        }
        if (this.props.dict !== undefined && this.props.dict.length > 1){
            try{
                //textBlock = this.props.dict.find(c => c.value === this.props.item).text;
                textBlock = FindTextValueInDict(this.props.firebase, this.props.dict, this.props.item);
            }
            catch (err){
                textBlock = 'Other';
                if(this.props.fallbackText) {
                    textBlock = this.props.item;
                }
            }
        }
        this.state = {
            type: this.props.type,
            item: this.props.item,
            backgroundColor: this.props.backgroundColor,
            squareSize:squareSize,
            optionalImage: image,
            textBlock:textBlock,
        };
        //console.log('text', this.props.textBlock)

    }
    render() {
        if((this.state.type === 'icon' || this.state.type === 'imageIcon' ) && this.props.title){
            return (
                <div style={{display: 'inline-block', padding : 0,  paddingRight : 10, border:'none',  borderRadius: 12, marginBottom: 10 }}   >
                    <div style={{
                        display: 'inline-flex', 
                        verticalAlign:'middle'
                        }}>
                        <AvatarImage type={this.state.type}  image={this.state.optionalImage}
                                squareSize={this.state.squareSize} backgroundColor={this.state.backgroundColor} />
                        
                        <h5 style={{margin:"auto"}}>
                            &nbsp;{this.state.textBlock}
                        </h5>
                    </div>
                </div>
            )
        }
        else if(this.state.type === 'icon' || this.state.type === 'imageIcon' ){
            return (
                <Card style={{display: 'inline-block', padding : 0,  paddingRight : 10, opacity: '0.80', borderRadius: 12, marginBottom: 10 }} >
                    <div style={{display: 'inline-flex', verticalAlign:'middle'}} >
                        <AvatarImage type={this.state.type}  image={this.state.optionalImage}
                                squareSize={this.state.squareSize} backgroundColor={this.state.backgroundColor} />
                        
                        <span style={{margin:"auto"}}>
                            &nbsp;{this.state.textBlock}
                        </span>
                    </div>
                </Card>
            )
        }
        else if(this.props.title && this.state.type !== 'icon' && this.state.type !== 'imageIcon'){
            return (
                <div style={{display: 'inline-block', padding : 0,  paddingRight : 10, border:'none', opacity: '0.80', borderRadius: 12, marginBottom: 10}} >
                    <div style={{display: 'inline-flex', verticalAlign:'middle'}}>
                        <AvatarImage dict={this.props.dict} type={this.state.type} item={this.state.item} image={this.state.optionalImage}
                                squareSize={this.state.squareSize} backgroundColor={this.state.backgroundColor} />
                        
                        <h5 style={{margin:"auto"}}>
                            &nbsp;{this.state.textBlock}
                        </h5>
                    </div>
                </div>
            )
        }
        else{
            return (
                <Card 
                    style={{
                        display: 'inline-block', padding : 0,  paddingRight : 10, opacity: '0.80', borderRadius: 12, marginBottom: 10}}
                    >
                    <div style={{display: 'inline-flex', verticalAlign:'middle'}}
                        >
                        <AvatarImage dict={this.props.dict} type={this.state.type} item={this.state.item} image={this.state.optionalImage}
                                squareSize={this.state.squareSize} backgroundColor={this.state.backgroundColor} />
                        
                        <span style={{margin:"auto"}}>
                            &nbsp;{this.state.textBlock}
                        </span>
                    </div>
                </Card>
            )
        }
    }


}

export class AvatarFoto extends Component {
    constructor(props) {
        super(props);

        var squareSize = this.props.squareSize;
        if (this.props.squareSize === undefined){
            squareSize = 50;
        }
        var image = defaultCategoryIcon;
        if (this.props.image !== undefined && this.props.image !== ''){
            image = this.props.image;
        }
        var backgroundColor = this.props.backgroundColor;
        if (this.props.dict !== undefined){
            try{
                var fullDict = FindDictByKey(this.props.firebase, this.props.dict, this.props.item);
                backgroundColor = fullDict.backgroundColor;
                var dbImage = fullDict.url;
                if(dbImage !== undefined && dbImage !== ""){
                    image = dbImage;
                    //console.log('image loaded in the icon', image)
                }
            }
            catch (err){
                if(this.props.backgroundColor !== undefined){
                    backgroundColor = this.props.backgroundColor;
                }
                else{
                    backgroundColor = '#FEFFFE';
                }
            }
        }
       
        //console.log('image loaded on start', image)
        this.state = {
            type: this.props.type,
            item: this.props.item,
            backgroundColor: backgroundColor,
            squareSize:squareSize,
            optionalImage: image,
            imageSource: defaultCategoryIcon,
        };

    }
    componentDidUpdate = (nextProps) => () => {
        //console.log('update:', nextProps);
        if (nextProps.item !== this.state.item) {
            this.setState({
                item: nextProps.item,
                backgroundColor: nextProps.backgroundColor
            });
        }
    }
    componentDidMount (){
        //var imageSource = '';
        if(this.props.type === 'icon' || this.props.type === 'imageIcon' ){
            this.setState({imageSource : this.state.optionalImage})
            //console.log('updated image foto', this.state.optionalImage)
        }
        else if(this.state.optionalImage !== undefined && this.state.optionalImage !== '') {
            this.setState({imageSource : this.state.optionalImage})
        }
        else{
            try {
                if (false){
                    //var url = `../../images/${this.state.type}_${this.state.item}_white_320x320.png`;
                    var url = defaultIcon;
                    const img = new Image();
                    img.src = url;
                    if (img.complete) {
                        this.setState({imageSource: img});
                    }
                    /***
                    imageSource = require(url)
                    import(url).then(image => {
                        this.setState({imageSource: image});
                    });
                    */
                }
            } 
            catch (e) {
                //console.log('loading failed', imageSource, e)
                //imageSource = defaultIcon;      
            }
        }
    }

    render() {
        var {imageSource} = this.state;

        if(this.props.type === 'icon'){
            return (
                <Avatar
                        variant="rounded"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: this.state.backgroundColor,
                            //background : 'radial-gradient(circle, rgba(0,0,0,0.5) 20%, '+this.state.backgroundColor+' 70%)',
                            //height: this.state.squareSize,
                            height: '100%',
                            width: this.state.squareSize,
                            borderRadius: 12,
                            margin: 0,
                            padding: 3,
                            opacity: '0.94',
                            paddingRight: '50%',
                            paddingBottom: '50%',
                        }} 
                    >
                        {imageSource}
                </Avatar>
            )
        }
        else if(this.props.type === 'imageIcon'){
            return (
                <Avatar
                        variant="rounded"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: this.state.backgroundColor,
                            //background : 'radial-gradient(circle, rgba(0,0,0,0.5) 20%, '+this.state.backgroundColor+' 70%)',
                            //height: this.state.squareSize,
                            height: '100%',
                            width: this.state.squareSize,
                            borderRadius: 12,
                            margin: 0,
                            padding: 3,
                            opacity: '0.94',
                            paddingRight: '50%',
                            paddingBottom: '50%',
                        }}
                        src=  {imageSource}
                        alt="R"
                    >
                </Avatar>
            )
        }
        else{
            return (
                <Avatar
                        variant="rounded"
                        src={imageSource}
                        alt={this.state.item}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: this.state.backgroundColor,
                            //background : 'radial-gradient(circle,  rgba(0,0,0,0.5) 20%, '+this.state.backgroundColor+' 70%)',
                            height: this.state.squareSize,
                            width: this.state.squareSize,
                            borderRadius: 12,
                            margin: 0,
                            opacity: '0.94',
                            padding: 3,
                            paddingRight: '50%',
                            paddingBottom: '50%',
                        }}
                    />
            )
        }
    }


}

export default compose(
)(AvatarImage);