import React, { Component } from 'react';
import ProgressDoughnut from '../../Charts/ProgressDoughnut';
import Grid from '@material-ui/core/Grid';

const styles = {
  canvas: {
    height: 100,
  },
  chart: {
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginTop: 10,
    marginLeft: 25,
  },
};

class AdminDashboardPage extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const data = {
      datasets: [{
	data: [82, 18],
	backgroundColor: [
	  '#7394C6',
	  '#cde0ff'
          // '#da9833',
          // '#64b2cd',
        ],
	borderColor: [
	  '#7394C6',
	  '#cde0ff'
          // '#da9833',
          // '#64b2cd',
        ],
      }],
    };

    return <div style={styles.root}>
	     <h1 style={styles.title}>Rocky Dashboard</h1>
	     <Grid
               container
               spacing={2}
               direction="row"
               justify="center"
               alignItems="center"
             >
	       <Grid item md={4}>
		 <ProgressDoughnut height={80} data={data} text={"82%"} style={styles.chart}/>
	       </Grid>
	       <Grid item md={4}>
		 <ProgressDoughnut height={80} data={data} text={"82%"} style={styles.chart}/>
	       </Grid>
	       <Grid item md={4}>
		 <ProgressDoughnut height={80} data={data} text={"82%"} style={styles.chart}/>
	       </Grid>
	       <Grid item md={4}>
		 <ProgressDoughnut height={80} data={data} text={"82%"} style={styles.chart}/>
	       </Grid>
	       <Grid item md={4}>
		 <ProgressDoughnut height={80} data={data} text={"82%"} style={styles.chart}/>
	       </Grid>
	       <Grid item md={4}>
		 <ProgressDoughnut height={80} data={data} text={"82%"} style={styles.chart}/>
	       </Grid>
	     </Grid>
	   </div>;
  }
}

export default AdminDashboardPage;
