import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from "react-router-dom";

import Card from '@material-ui/core/Card';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/Info';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import RockyIcon from '../Modules/RockyIcon';
import { LinkCompanyURL, LinkPrivacyURL, LinkSupportURL, LinkTermsURL } from '../../constants/routes';
//import ForumIcon from '@material-ui/icons/Forum';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingAbout extends Component {

    constructor(props) {
        super(props);

        const url = LinkCompanyURL(this)
        const hostname = new URL(url).hostname;
        this.state = {
            openSetting: false,
            companyUrl: url,
            companyHost: hostname
        };
        console.log(this.state)
    }

    render() {
        //const { user } = this.props;
        const { theme, classes } = this.props;
        //console.log("window params", window)
        return (
            <div className={classes.grow}>
                <Card style={theme.cardSettings}>
                    <p style={theme.textSettings}>
                        <span style={theme.textSettings.settingsTextValue}>
                            <Link style={{color: theme.palette.secondary.main}}
                                to={void(0)}
                                onClick={() => window.open(this.state.companyUrl, "_blank")}>
                                {this.state.companyHost?.includes("rocky.ai")
                                    ?   this.props.defaultSet?.programCompany?.length > 2 
                                        ?   "Powered by Rocky.ai"
                                        :   "www.rocky.ai"
                                    :   this.state.companyHost
                                }
                            </Link>
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            {this.props.defaultSet?.programCompany?.length > 2 
                            ?
                                <InfoIcon />
                            :
                                <RockyIcon />
                            }
                        </span>
                    </p>
                    <p style={theme.textSettings}>
                        <span style={theme.textSettings.settingsTextValue}>
                            <Link style={{color: theme.palette.secondary.main}}
                                to={void(0)}
                                onClick={ () => window.open((LinkSupportURL(this)), "_blank")}>
                                Service & Support
                            </Link>
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            <HelpOutlineIcon />
                        </span>
                    </p>
                    <p style={theme.textSettings}>
                        <span style={theme.textSettings.settingsTextValue}>
                            <Link style={{color: theme.palette.secondary.main}}
                                to={void(0)}
                                onClick={ () => window.open(LinkPrivacyURL(this), "_blank")}>
                                Privacy Policy
                            </Link>
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            <VpnKeyIcon />
                        </span>
                    </p>
                        <p style={theme.textSettings}>
                        <span style={theme.textSettings.settingsTextValue}>
                            <Link style={{color: theme.palette.secondary.main}}
                                to={void(0)}
                                onClick={ () => window.open(LinkTermsURL(this), "_blank")}>
                                Terms & Conditions
                            </Link>
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            <HowToRegIcon />
                        </span>
                    </p>
                    {window!== undefined && window.AppVersion !== undefined && window.AppVersion.version !== undefined  &&
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextDescription} >
                                    <span >Version &nbsp;{ window.AppVersion.version }</span>
                                </span>
                            </p>
                    }
                </Card>
            </div>
                
        );
    }
}


export default compose(
  withStyles(styles, { withTheme: true }),
)(SettingAbout);
