import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
//import {  Link } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';
import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

import DialogKeyWords from '../../Admin/Content/DialogKeyWords';
import {ConvertTimestampToDateString} from '../../Modules/ConvertTimestampToDateString';

import Popper from '@material-ui/core/Popper';
import Grid from '@material-ui/core/Grid';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';

//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
import SettingContentRichText from '../Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';
import SettingContentSelect from '../Modules/SettingContentSelect';
import SettingContentMapSelect from '../Modules/SettingContentMapSelect';
import SettingContentMapList from '../Modules/SettingContentMapList';

import {
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
    Masonry,
  } from 'react-virtualized';

//// for keywords
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
    innerHTML : {
        width: '100%', 
        maxWidth: '100%', 
        height: 'auto', 
        //margin:20, 
        padding: 20,
        '& p':{
            width: '100%', 
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& img": {
            width: '100%', 
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& span": {
            width: '100%', 
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& h1": {
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& div": {
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& table": {
            maxWidth: '100%', 
            width: '100%',
            height: 'auto', 
        },
    },
};

class AdminContentPage extends Component {
    render() {
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <Switch>
                    <Route exact path={ROUTES.ADMIN} component={ContentList} />
                    <Route exact path={ROUTES.ADMIN_CONTENTS} component={ContentList} />
                </Switch>
            </div>
        );
    }
}

class ContentListBase extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;
        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            title: '',
            author: '',
            program: '',
            open: false,
            sortBy,
            sortDirection,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var name = '';

        this.unsubscribe = this.props.firebase
        .contents()
        .orderBy('title', 'asc')
        .onSnapshot(snapshot => {
            if(this._isMounted ){
                let contentsTab = [];
                let contentsTabList = [];
                let contentsLabels = [];
                let maxVotes = [];
                var content={};

                var keySorting = 'leadership_category'

                
                snapshot.forEach(doc =>{
                    if(this.context.roles?.includes('ADMIN')){
                        if(this.context.programs.includes(doc.data().program) || doc.data().program===undefined){
                            name = doc.data()[keySorting];
                            content = doc.data();
                            if(name === undefined){
                                name = '---undefined---'
                            }
                            if(!(name in contentsTab)){
                                //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                contentsTab[name] = [];
                                contentsTabList[name]= [];
                                contentsLabels.push(name );
                                maxVotes[name] = 1;
                            }
                            contentsTab[name].push({ ...doc.data(), uid: doc.id });
                            contentsTabList[name].push({
                                active: content.active? 'Yes': 'No',
                                validated: content.validated,
                                createdAt:  ConvertTimestampToDateString(content.createdAt, true) ,
                                updatedAt:  ConvertTimestampToDateString(content.updatedAt, true) ,
                                category: content.category || '-',
                                contentVotes : content.contentVotes || '-',
                                actions: content.actions!==undefined ? content.actions.length : '-',
                                text:  content.subtitle ? content.text!=='' ? 'Yes' : '-' : '-',
                                source:  content.source || '-',
                                title:  content.title.length>30 ? (content.title.substring(0,29)+'...'): content.title,
                                subtitle:  content.subtitle ? content.subtitle.length>12 ? (content.subtitle.substring(0,12)+'...'): content.subtitle : '-',
                                program: content.program || '-',
                                cid: doc.id,
                                author: content.author || '-',
                                roleDefault: content.requirements?.includes('default') ? 'Y' : '',
                                roleTeenager: content.requirements?.includes('teenager') ? 'Y' : '',
                                roleStudent: content.requirements?.includes('student') ? 'Y' : '',
                                roleSeek: content.requirements?.includes('seeker') ? 'Y' : '',
                                rolePro: content.requirements?.includes('professional') ? 'Y' : '',
                                roleExec: content.requirements?.includes('executive') ? 'Y' : '',
                            });
                            if(doc.data().contentVotes > maxVotes[name]){
                                maxVotes[name] = doc.data().contentVotes;
                            }
                        } // end if true
                    } // end of if ADMIN
                });//end of snapshot


                //console.log('contents', contentsTab)
                if(this.state.showTab === undefined || this.state.showTab === ''){
                    this.setState({showTab: name})
                }
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    maxVotes,
                    loading: false,
                });
            }//end if mounted
        });

        this.loadAvailableIntents();
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.title !== '' && this.state.author !== '' && this.state.category !== '') {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.contents().add({
                title: this.state.title,
                author: [this.state.author],
                program: this.state.program,
                active: false,
                createdAt: timestamp
            });
            this.setState({errorMessage : 'Added content (category undefined)'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a content'})
        }
        
        this.setState({
            title: '',
            author: '',
            program: '',
        });
        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue
        });
    }

    loadAvailableIntents(){
        var availableIntents = {
            goals:[],
                intentions:[], 
                challenges:[],
                sentiment:[], 
                failures:[],
                successes:[],
                conclusions: []
            }
        this.props.firebase.agentIntentClasses()
        .where('active', '==', true)
        .where('sentiment', '>=', 'inform')
        .where('tenses', 'array-contains', 'default')
        .orderBy('sentiment', 'asc')
        .orderBy('description', 'asc')
        .get().then((snapshot) => {
            snapshot.forEach(doc => {
                var dataSet = doc.data();
                var intent = dataSet.intent;
                //var description = dataSet.description;
                if(intent === undefined) intent = "nothingToFind"
                if(intent.includes('inform_sentiment')){
                    availableIntents['sentiment'].push(intent);
                }
                else if(intent.includes('inform_challenge')){
                    availableIntents['challenges'].push(intent);
                }
                else if(intent.includes('inform_goal')){
                    availableIntents['goals'].push(intent);
                }
                else if(intent.includes('inform_intention') || intent.includes('inform_activit')){
                    availableIntents['intentions'].push(intent);
                }
                else if(intent.includes('inform_failure')){
                    availableIntents['failures'].push(intent);
                }
                else if(intent.includes('inform_conclusion')){
                    availableIntents['conclusions'].push(intent);
                }
                else if(intent.includes('inform_success') || intent.includes('inform_accomplish') ){
                    availableIntents['successes'].push(intent);
                }
            });
            this.setState({availableIntents});
        })
    }

  render() {
    const { showTab, contentsTab, contentsLabels, maxVotes, loading } = this.state;
    //console.log('votes', maxVotes)
    return (
        <div style={{height: '100vh'}}>
        {loading && 
            <LoadingProgress 
                defaultSet={this.props.defaultSet}
                type={'LoadingPageAnimated'} 
                loadingText={"many, many, data"} 
                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                allowRefresh={true}
            />
        }
        <div>
          <IconButton style={styles.addButton} onClick={this.handleOpen}>
            <AddIcon />
          </IconButton>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm">
            <DialogContent>
              <DialogTitle>Add a new content set</DialogTitle>
              <form>
                <TextField
                  name="title"
                  type="text"
                  label="1. Title"
                  placeholder="Enter title"
                  value={this.state.title}
                  onChange={this.handleChange}
                  fullWidth
                  style={ {fontSize: '16px'} }
                  />
                <TextField
                  name="author"
                  type="text"
                  label="2. Author"
                  placeholder="Enter content author name"
                  value={this.state.author}
                  onChange={this.handleChange}
                  fullWidth
                  style={ {fontSize: '16px'} }
                  />
                <p>3. Select program</p>
                <Select
                  value={this.state.program}
                  onChange={this.handleChange}
                  name='program'
                  >
                  {this.context.programs.map(entry => {
                        return (
                            <MenuItem key={"add_"+entry} value={entry}
                            >
                            {entry}
                            </MenuItem>
                          );
                        })
                    
                  }
                    
                </Select>
                
                </form>
                <DialogActions>
                <Button onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button onClick={this.handleSubmit}>
                  Save
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
        {this.state.errorMessage !== '' && 
          <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
        }
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          onChange={this.handleTabChange}
          initialselectedindex={0}
          value={showTab}
        >
          {contentsLabels.length>0 && 
            contentsLabels.map( (key) => (
              <Tab 
                value={key} 
                key={key}
                label={key + " ("+contentsTab[key].length+")"}/>
            ))
          }
        </Tabs>
    
        {contentsLabels.length>0 && 
          contentsLabels.map( (key) => (
            <div 
              value={key} 
              key={key} 
              hidden={showTab !==key}>

                <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={700}
                        headerHeight={20}
                        rowHeight={30}
                        rowCount={this.state.contentsTabList[key].length}
                        sort={this._sort}
                        sortBy={this.state.sortBy}
                        sortDirection={this.state.sortDirection}
                        rowGetter={({index}) => this.state.contentsTabList[key][index]}
                        onRowClick={({ event, index, rowData })  => {this.setState({
                            currentContentId: this.state.contentsTabList[key][index].cid, 
                            dialogOpen:true,
                            currentMaxVote: maxVotes[key],
                            currentContent: this.state.contentsTabList[key][index],
                        })}}
                            >
                        <Column label="Active" dataKey="active" width={70} disableSort={false}  />
                        <Column label="Program" dataKey="category" width={70} disableSort={false} />
                        <Column label="Validated" dataKey="validated" width={70} disableSort={false}  />
                        <Column label="Title" dataKey="title" width={200} disableSort={false} />
                        <Column label="Subtitle" dataKey="subtitle" width={130} disableSort={false} />
                        <Column label="Default" dataKey="roleDefault" width={50} disableSort={false} />
                        <Column label="Student" dataKey="roleStudent" width={50} disableSort={false} />
                        <Column label="Seek" dataKey="roleSeek" width={50} disableSort={false} />
                        <Column label="Pro" dataKey="rolePro" width={50} disableSort={false} />
                        <Column label="Exec" dataKey="roleExec" width={50} disableSort={false} />
                        <Column label="Program" dataKey="program" width={130} disableSort={false} />
                        <Column label="Action" dataKey="actions" width={70} disableSort={false} />
                        <Column label="Text" dataKey="text" width={70} disableSort={false} />
                        <Column label="Source" dataKey="source" width={130} disableSort={false} />
                        <Column label="Date" dataKey="createdAt" width={130} disableSort={false}  />
                        <Column label="Update" dataKey="updatedAt" width={130} disableSort={false}  />
                        <Column label="Author" dataKey="author" width={130} disableSort={false}  />
                        
                    </Table>
                )}
                </AutoSizer>
            </div>
          ))
        }
        <ContentItemDialog 
            contentId={this.state.currentContentId}
            maxVote={this.state.currentMaxVote}
            contentsLabels={contentsLabels}
            openDialog={this.state.dialogOpen|| false}
            closeDialog={(e) => {this.setState({dialogOpen: false })}}
            availableIntents={this.state.availableIntents || {}}
        />
	    </div>
    );
  }
}


class ContentItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var cid = this.props.contentId;
        var errorMessage = '';
        var contentsLabels = ['tips, quotes'];
        var maxVote = 30;

        // delivered from db section pool
    
        contentsLabels = this.props.contentsLabels;
        maxVote = this.props.maxVote;
        
        //console.log('location',cid)
        this.state = {
            loading: false,
            content: null,
            cid: cid,
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
            maxVote : maxVote,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
        this.getBotUtterArray();
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }

    componentDidUpdate() {
        //console.log('init', this.props.contentId)
        if (this.props.contentId && this.state.cid !== this.props.contentId) {
            //console.log('myidopen', this.props.contentId)
            this.setState({ loading: true, cid: this.props.contentId });
            this.unsubscribe = this.props.firebase
                .content(this.props.contentId)
                .onSnapshot(snapshot => {
                    if(this._isMounted ){
                        //console.log('data',snapshot.data())
                        this.setState({
                            content: snapshot.data(),
                            maxVote:  this.props.maxVote,
                            loading: false,
                        });
                    }//end ifmounted
                });
        }
    }

    getleadershipCategoriesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push(doc.data().text);
            }
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipCategories: myArray,
                leadershipCategoriesText: myArrayText
            });
        }
    }
    getleadershipQualitiesArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
                if(this.context.programs.includes(doc.data().program)){
                    myArray.push(doc.data().value);
                    myArrayText.push( "(" + doc.data().category + ") " + doc.data().text);
                };
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipQualities: myArray,
                leadershipQualitiesText: myArrayText
            });
        }
         
    }
    getBotUtterArray()
    {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .textBotUtter()
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
                myArray.push(doc.data().value);
                myArrayText.push(doc.data().text + " (" + doc.data().value + ")");
            });
        });
        if(this._isMounted){
            this.setState({
                botUtters: myArray,
                botUttersText: myArrayText
            });
        }
         
    }

    render() {
        const { content, loading } = this.state;
        
         
        //console.log('my context', this.context)
        return (
            <Dialog
                PaperProps={{style: {position: "absolute", right: 10}}}
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
            >
                <DialogTitle >
                    <Grid container spacing={0} direction='row' justify="space-between" >
                        <Grid item >
                            <IconButton onClick={this.props.closeDialog}>
                                (X)
                            </IconButton>
                        </Grid>
                        <Grid item >
                            Modify Content
                        </Grid>
                        <Grid item >
                            <Button onClick={() => { this.setState({visibility:true} ) }}>
                                View Content
                            </Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent >
                    <div>
                        <h5 style={styles.subtitle}>Content ({this.props.contentId})</h5>
                        {loading && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        }

                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {content && (
                            <div>
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Active and public Content"} 
                                    content={content} 
                                    labels={['Published in the app', 'Can be found by people outside the program']}
                                    params={['active', 'public']}
                                    />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Basic Text Settings"} 
                                    titleFormat={true}
                                    content={content}  
                                    max={60}
                                    params={['title', 'subtitle']}
                                    />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Content category (tips/quotes/...)"} 
                                    content={content} 
                                    params={['category']}
                                    selectOptions={['tips', 'quotes', 'challenges', 'donts', 'other']}
                                    />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Leadership category/topic (priority/balance/...)"} 
                                    content={content} 
                                    params={['leadership_category']}
                                    selectOptions={this.state.leadershipCategories}
                                    selectOptionsText={this.state.leadershipCategoriesText}
                                    />
                                <SettingContentArrayCheckbox
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Assigned dev. areas / skills / qualities"} 
                                    content={content} 
                                    params={['leadership_quality']}
                                    selectOptions={this.state.leadershipQualities}
                                    selectOptionsText={this.state.leadershipQualitiesText}
                                    />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Original program"} 
                                    content={content} 
                                    params={['program']}
                                    selectOptions={this.context.programs}
                                    />
                                <SettingContentUploadImage
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Link to image used (URL with https)"} 
                                    content={content} 
                                    params={['url']}
                                    previewImageSize={true}
                                    uploadDatabase={this.props.firebase.storageImages('content/'+this.state.cid)}
                                    />
                                <SettingContentRichText
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Edit Richt Text (external tool)"} 
                                    content={content}
                                    addWordCount={true}
                                    params={['text']}
                                    />
                                {content?.textWordCount > 0 &&
                                    <p style={{textAlign: 'right'}}>
                                        {content.textWordCount + " Words ("+ Math.round(content.textWordCount/100*0.6) + " minutes)" } 
                                    </p>
                                }
                                <SettingContentMapList
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Optional: Interactive Questions"} 
                                    content={content} 
                                    params={['interactiveQuestions']}
                                    noLabel={true}
                                    mapOptions={['question', 'description']}
                                    mapOptionsText={['Question', 'Description (Optional)']}
                                    />
                                {content?.interactiveQuestions?.length > 0 &&
                                    <SettingContentActive 
                                        dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                        title={"Optional: Review responses"} 
                                        content={content} 
                                        labels={['Goal setting review', 'Coach review or test']}
                                        params={['isGoalSetting', 'isReviewable']}
                                        />
                                }
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Optional: Advanced interaction filters"} 
                                    content={content} 
                                    labels={['Deactivate Community Feature', 'Part of a course']}
                                    params={['isNonCommunity', 'isCourse']}
                                    />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Youtube Player ID"} 
                                    content={content} 
                                    youTubeFormat={true}
                                    params={['youtubeId']}
                                />
                                <SettingContentArray
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Follow up actions"} 
                                    content={content} 
                                    params={['actions']}
                                    updateExtraChange={{'validated' : true, 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                    noLabel={true}
                                    />
                                <SettingContentNumber 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Overwrite Content Votes"} 
                                    content={content} 
                                    min={0}
                                    max={this.state.maxVote>1 ? this.state.maxVote : 30}
                                    params={['contentVotes']}
                                    />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Sources and external link"} 
                                    content={content} 
                                    params={['source', 'sourceUrl', 'amazonUrl']}
                                    />
                                <SettingContentMapSelect
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Optional: Promote article on a weekday"} 
                                    content={content} 
                                    params={['promotion']}
                                    noLabel={true}
                                    mapOptions={['weekday']}
                                    mapOptionsText={['Day of promotion']}
                                    selectOptionsText={['No special day', 'Monday', 'Tuesday','Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]}
                                    selectOptions={['default', 'monday', 'tuesday','wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
                                    />
                                <SettingContentArrayCheckbox
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"What does the user have to possess for this article?"} 
                                    content={content} 
                                    params={['requirements']}
                                    selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                    selectOptionsText={['Question for every situation', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'K-12/Teenager' ]}
                                    />
                                <SettingContentSelect
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Sentiment preference"} 
                                    content={content} 
                                    params={['sentiment']}
                                    noLabel={true}
                                    selectOptionsText={['positive - previous response positive', 'neutral - can be always asked', 'negative - previous response negative' ]}
                                    selectOptions={['positive', 'default', 'negative']}
                                    />
                                <SettingContentArray
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Search Key Words"} 
                                    content={content} 
                                    params={['tags']}
                                    noLabel={true}
                                    sorting={true}
                                    />

                                {content.analyzed !== true && this.state.startedAITagging
                                    &&
                                    <span style={{color:"red"}}>Please wait 2 minutes until data is generated!</span>
                                }


                                <SettingContentArray 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Conclusion of article, max 150 characters, used inside bot"} 
                                    content={content} 
                                    max={150}
                                    noLabel={true}
                                    params={['explanation']}
                                    updateExtraChange={{'validated' : true, 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                    />
                                <DialogKeyWords
                                    contentId={this.state.cid}
                                    selectedCategory= {content.category}
                                    contentTags = {content.tags}
                                    selectedQualities= {content.leadership_quality}
                                    leadershipQualities = {this.state.leadershipQualities}
                                    leadershipCategories={this.state.leadershipCategories}
                                    explanations = {content.explanation}
                                />
                                <hr/>
                                {content.utteranceDescriptions !== undefined && content.utteranceDescriptions.utter !== undefined && content.utteranceDescriptions.utter.length > 1
                                    ?
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels"} 
                                            content={content}
                                            noLabel={true}
                                            params={['utteranceClass']}
                                            showIntentDetails={true}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={content.utteranceDescriptions.utter}
                                            selectOptionsText={content.utteranceDescriptions.response}
                                            />
                                    :
                                        <SettingContentArray
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Manage AI labels"} 
                                            content={content}
                                            noLabel={true}
                                            showIntentDetails={true}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            params={['utteranceClass']}
                                            />
                                }
                                {content.intentTags !== undefined
                                    &&
                                        <SettingContentMapSelect
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Active AI label tags"} 
                                            content={content}
                                            params={['intentTags']}
                                            mapOptions={Object.keys(content.intentTags)}
                                            mapOptionsText={Object.keys(content.intentTags)}
                                            selectOptions={[false, true]}
                                            selectOptionsText={['inactive', 'active']}
                                            />
                                }
                                {this.state.showAllIntents
                                ?
                                    <>
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels: goals"} 
                                            content={content}
                                            noLabel={true}
                                            hideUnavailable={true}
                                            params={['utteranceClass']}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={this.props.availableIntents.goals || []}
                                            />
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels: challenges"} 
                                            content={content}
                                            noLabel={true}
                                            hideUnavailable={true}
                                            params={['utteranceClass']}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={this.props.availableIntents.challenges || []}
                                            />
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels: intentions"} 
                                            content={content}
                                            noLabel={true}
                                            hideUnavailable={true}
                                            params={['utteranceClass']}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={this.props.availableIntents.intentions || []}
                                            />
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels: sentiment"} 
                                            content={content}
                                            noLabel={true}
                                            hideUnavailable={true}
                                            params={['utteranceClass']}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={this.props.availableIntents.sentiment || []}
                                            />
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels: conclusions"} 
                                            content={content}
                                            noLabel={true}
                                            hideUnavailable={true}
                                            params={['utteranceClass']}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={this.props.availableIntents.conclusions || []}
                                            />
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels: failures"} 
                                            content={content}
                                            noLabel={true}
                                            hideUnavailable={true}
                                            params={['utteranceClass']}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={this.props.availableIntents.failures || []}
                                            />
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                            title={"Add AI labels: successes"} 
                                            content={content}
                                            noLabel={true}
                                            hideUnavailable={true}
                                            params={['utteranceClass']}
                                            groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                            selectOptions={this.props.availableIntents.successes || []}
                                            />
                                    </>
                                    :
                                        <Button onClick={() => this.setState({showAllIntents : true})}>
                                            Access all AI lables
                                        </Button>
                                }
                                <hr/>

                                {ConvertTimestampToDateString(content.analyzedDate, true) }
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Confirm clean labels, actions, summaries."} 
                                    content={content} 
                                    params={['validated']}
                                    updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                    />
                                <hr/>
                                <Button style={ {backgroundColor : (content.analyzed || this.state.startedAITagging )? "grey" : "red" }} 
                                    onClick={() => this.startAITagging(this.state.cid, true)}
                                    >
                                    Generate lables and questions by AI
                                </Button>
                                <SettingContentArray 
                                    dbUpdateLink={this.props.firebase.content(this.state.cid)}
                                    title={"Internal author"} 
                                    content={content} 
                                    params={['author']}
                                    />
                                <Button onClick={() => { this.handleDuplicateContent( content )  }}>
                                    Duplicate into an article copy
                                </Button>
                            </div>
                        )}
                    </div>
                    
                    {content && 
                        <>
                            <Dialog id={'contentSum'} open={this.state.visibility || false} 
                                onClose={() => this.setState({visibility: false})}
                                PaperProps={{style: {position: "absolute", left: 0, visibility: this.state.visibility ? this.state.visibility: 'visible'}}}
                            > 
                                <DialogContent>
                                    <div
                                        className={styles.innerHTML}
                                        onClick = {(event) => {
                                            try{
                                                const link = event.target.getAttribute('href');
                                                if(link !== undefined && link !== null && link !== ""){
                                                    event.preventDefault();
                                                    window.open(link, "_blank")
                                                }
                                            }
                                            catch(e){
                                                console.log('No Link', e)
                                            }
                                        }}
                                        onMouseLeave={ () => this.setState({visibility : false})}
                                        dangerouslySetInnerHTML={{ __html: "<span style='color : red'>" + content.title + "<br/><br/>" + content.subtitle + "</span><br/>" + content.text }}>
                                    </div>
                                </DialogContent>
                            </Dialog>
                            <Popper id={'contentSum'} open={!this.state.visibility} anchorEl={null} > 
                                <div style={{width: '60vW', minWidth: 300, backgroundColor:'#fcd750', margin:20, padding: 20}}
                                    dangerouslySetInnerHTML={{ __html: "<span style='color : red'>" + content.title + "<br/><br/>" + content.subtitle + "</span><br/>" + content.text }}>
                                </div>
                            </Popper>
                        </>
                    }
                </DialogContent>
                {
                    /***

                                
                            <Button onClick={() => this.handleOpenDialogueKeyWords()}>
                            Manage Keywords
                            </Button>
                            <Button onClick={() => this.handleOpenDialogueQuestions()}>
                            Manage Questions
                            </Button>
                    this.dialogueKeyWords()
                }
                {
                    this.dialogueQuestions()

                     */
                }
            </Dialog> 
        );
    }


    startAITagging = (itemId, overwrite=false) => {
        if(overwrite){
            this.props.firebase.content(itemId).update({active: true, analyzedDate: this.props.firebase.fieldValue.delete()});
        }
        else{
            this.props.firebase.content(itemId).update({active: true});
        }
        this.props.firebase.content(itemId + "/postprocess/searchKeyWords" )
        .set({triggerStartSearch: false})
        .then( () => {
            this.props.firebase.content(itemId + "/postprocess/searchKeyWords" )
            .set({triggerStartSearch: true})
            this.setState({startedAITagging: true})
        })
    }

    handleCloseDialogueKeyWords = () => {
        this.setState({openDialogueKeyWords: false} )
    }
    handleOpenDialogueKeyWords = () => {
        this.props.firebase.content(this.state.cid + "/postprocess/searchKeyWords" ).get()
        .then((doc) => {
            var postprocessData = { triggerStartSearch : false}
            if(doc){
                if(doc.data() !== undefined && doc.data().triggerStartSearch !== undefined){
                    postprocessData = doc.data();
                }
            }
            //console.log(('processdata'), postprocessData)
            if(this._isMounted){
                this.setState({postprocessData} );
            }
        })
        this.props.firebase.content(this.state.cid + "/keyWords/tags" ).get()
        .then((doc) => {
            var identifiedTags = []
            if(doc){
                if(doc.data() !== undefined && doc.data().tags !== undefined){
                    identifiedTags = doc.data().tags;
                }
            }
            if(this._isMounted){
                this.setState({identifiedTags} );
            }
        })

        var activeTags = [];
        var activeTagsObjects = {};
        if(this.state.content !== undefined && this.state.content !== null && this.state.content.tags !== undefined ){
            activeTags = [...this.state.content.tags];
            //activeTagsObject = Object.assign(...activeTags.map(k => ({ ['tags_'+k.replace(/ /g,'')] : true })));
            activeTagsObjects =  Object.assign.apply(null, this.state.content.tags.map(k =>({['tags_'+k.replace(/ /g,'')]:true})));
        }
        if(this._isMounted){
            this.setState({openDialogueKeyWords: true, activeTags, activeTagsObjects: activeTagsObjects} )
        }

        if(this.state.content !== undefined && this.state.content.leadership_quality !== undefined ){
            this.props.firebase.contents()
            .where('leadership_quality', 'array-contains-any', (this.state.content.leadership_quality || []))
            .get()
            .then((snapshot) => {
                if(snapshot){
                    var otherTags = [];
                    snapshot.forEach(doc => {
                        var tempTags = doc.data().tags;
                        //console.log("tempTags", tempTags)
                        if( tempTags !== undefined && tempTags.length>1 ){

                            //console.log("other tags", otherTags)
                            otherTags = _.union(otherTags, tempTags)
                        }
                    })
                    if(this._isMounted){
                        //console.log("other tags", otherTags)
                        this.setState({otherTags} );
                    }
                }
            })
        }
        //console.log('activtags', this.state)
    }
    handleCloseDialogueQuestions = () => {
        this.unsubscribeQuestions()
        this.setState({openDialogueQuestions: false} )
    }
    handleOpenDialogueQuestions = () => {
        var questionsGenerated = [{questions:[], questionsFinal:[]}]
        this.unsubscribeQuestions = this.props.firebase.content(this.state.cid + "/keyWords/questions" )
        .onSnapshot(doc => {
        //.get().then((doc) => {
            if(doc){
                questionsGenerated = doc.data()
            }
            if(this._isMounted){
                this.setState({openDialogueQuestions: true, questionsGenerated} );
            }
        })
    }

    handleDuplicateContent = (content) => {
        if(content!== undefined ){
            var newContent = {...content};
            newContent['status'] = 'inactive';
            newContent['title'] = 'COPY: ' + content.title;
            newContent['tags'] = [];
            this.props.firebase.contents().add(newContent);
            this.setState({errorMessage : 'Copied content without tags and status inactive'})
        }
        else{
            this.setState({errorMessage : 'Original data missing for a copy'})
        }
    }

    dialogueKeyWords = () => {
        return( 
            <RemoveScroll enabled={this.state.openDialogueKeyWords || false}>
                <Dialog
                    open={this.state.openDialogueKeyWords || false}
                    onClose={this.handleCloseDialogueKeyWords}
                    fullWidth={true}
                >
                    <DialogTitle  >
                        <IconButton onClick={this.handleCloseDialogueKeyWords}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span>
                            Key Word Management
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.openDialogueKeyWords
                            ?
                                <div>
                                    {this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && 
                                        <Button onClick={() => this.saveUpdatedKeyWords()}>
                                            Save Updated Keywords
                                        </Button>
                                    }
                                    {this.state.postprocessData!==undefined && 
                                        <SettingContentActive 
                                            setUpdate = {true}
                                            dbUpdateLink={this.props.firebase.content(this.state.cid + "/postprocess/searchKeyWords" )}
                                            title={"Trigger Key Word generator "} 
                                            content={this.state.postprocessData} 
                                            labels={['Change from Off -> On']}
                                            params={['triggerStartSearch']}
                                            />
                                    }
                                    {this.state.content !== undefined && this.state.content !== null && this.state.content.tags !== undefined &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="Account-Expand"
                                                aria-controls="Account-content"
                                                id="Account-header"
                                                >
                                                Current Active
                                            </AccordionSummary>
                                            <AccordionDetails> 
                                                <div style={{border:1}}>
                                                    <p>Active in Content</p>
                                                    {this.state.content.tags.map ( (currentTag, index) => (
                                                        <FormControlLabel
                                                            key={"currentTags_"+index}
                                                            id={"currentTags_"+index}
                                                            control={<Checkbox
                                                                onClick={(event) => this.updateActiveTags(currentTag, event.target.checked) }
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+currentTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                //checked={ 
                                                                //    this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.includes(currentTag)
                                                                //        ? true
                                                                //        : false
                                                                //} 
                                                                name={"currentTags_"+index} />}
                                                            label={currentTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion> 
                                    }
                                    {this.state.identifiedTags !== undefined && this.state.identifiedTags !== null &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="Account-Expand"
                                                aria-controls="Account-content"
                                                id="Account-header"
                                                >
                                                Found tags in text
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{border:1}}>
                                                    <p>Found tags in text</p>
                                                    {this.state.identifiedTags.map ( (possibleTag, index) => (
                                                        <FormControlLabel
                                                            key={"possibleTag_"+index}
                                                            id={"possibleTag_"+index}
                                                            control={<Checkbox
                                                                onClick={(event) => this.updateActiveTags(possibleTag, event.target.checked) }
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+possibleTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                //checked={ 
                                                                //    this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.includes(possibleTag)
                                                                //        ? true
                                                                //        : false
                                                                //} 
                                                                name={"possibleTag_"+index} />}
                                                            label={possibleTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>  
                                    }
                                    {this.state.otherTags !== undefined && this.state.otherTags !== null &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="otherqualities"
                                                aria-controls="otherqualities"
                                                id="otherqualities"
                                                >
                                                From other qualities
                                            </AccordionSummary>
                                            <AccordionDetails>  
                                                <div style={{border:1}}>
                                                    <p>Found in other modules</p>
                                                    {false &&
                                                        <Masonry
                                                            height={400}
                                                            width={400}
                                                            cellMeasurerCache={this.cache}
                                                            cellPositioner={this.cellPositioner}
                                                            cellRenderer={this.renderRowCheckbox}
                                                            cellCount={this.state.otherTags.length}
                                                        />
                                                    }
                                                    {this.state.otherTags.map ( (alternativeTag, index) => (
                                                        <FormControlLabel
                                                            key={"alternativeTag_"+index}
                                                            id={"alternativeTag_"+index}
                                                            control={<Checkbox
                                                                checked = {
                                                                    this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+alternativeTag.replace(/ /g,'')] 
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={(event) => this.updateActiveTags(alternativeTag, true) }
                                                                name={"alternativeTag_"+index} />}
                                                            label={alternativeTag}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                </div>
                            :
                                ""
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueQuestions = () => {
        return(
            <RemoveScroll enabled={this.state.openDialogueQuestions || false}>
                <Dialog
                    open={this.state.openDialogueQuestions || false}
                    onClose={this.handleCloseDialogueQuestions}
                    fullScreen={true}
                    style={{height:'100%'}}
                >
                    <DialogTitle  >
                        <IconButton onClick={this.handleCloseDialogueQuestions}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span>
                            Question Management
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.openDialogueQuestions
                            ?
                                <div style={{height:'100%'}}>
                                    <SettingContentArray
                                        dbUpdateLink={this.props.firebase.content(this.state.cid + "/keyWords/questions" )}
                                        title={"Created Questions"} 
                                        content={this.state.questionsGenerated} 
                                        params={['questionsFinal']}
                                        noLabel={true}
                                        />
                                    {this.state.questionsGenerated !== undefined && this.state.questionsGenerated.questions !== undefined &&
                                        <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-label="autoquestions"
                                                aria-controls="autoquestions"
                                                id="autoquestions"
                                                >
                                                Auto generated questions
                                            </AccordionSummary>
                                            <AccordionDetails>  
                                                <div style={{border:1}}>
                                                    {this.state.questionsGenerated.questions.map ( (q, index) => (
                                                        <span
                                                            key={"alternativeTag_"+index}
                                                            id={"alternativeTag_"+index}
                                                        >
                                                            {q}
                                                            &nbsp;
                                                            <Button onClick={() => {
                                                                var qs = this.state.questionsGenerated.questionsFinal;
                                                                qs.push(q);
                                                                this.setState({questionsGenerated: {
                                                                    questionsFinal: qs,
                                                                    questions: this.state.questionsGenerated.questions}
                                                                }) }} >
                                                            +</Button><br/>
                                                        </span>
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {/***
                                    <SettingContentArray
                                        dbUpdateLink={this.props.firebase.content(this.state.cid + "/keyWords/questions" )}
                                        title={"Auto questions"} 
                                        content={this.state.questionsGenerated} 
                                        params={['questions']}
                                        noLabel={true}
                                        />
                                         */}
                                </div>
                            :
                                ""
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

        // Default sizes help Masonry decide how many images to batch-measure
    cache = new CellMeasurerCache({
        defaultHeight: 250,
        defaultWidth: 200,
        fixedWidth: true,
    });
    
    // Our masonry layout will use 3 columns with a 10px gutter between
    cellPositioner = createMasonryCellPositioner({
        cellMeasurerCache: this.cache,
        columnCount: 3,
        columnWidth: 200,
        spacer: 10,
    });

    renderRowCheckbox = ({ index, key, parent, style}) => {
        var alternativeTag = this.state.otherTags[index]
        return(
            <CellMeasurer cache={this.cache} index={index} key={key} parent={parent}>
            <FormControlLabel
                key={key+"_"+index}
                id={key+"_"+index}
                control={<Checkbox
                    checked = {
                        this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+alternativeTag.replace(/ /g,'')] 
                            ? true
                            : false
                    }
                    onClick={(event) => this.updateActiveTags(alternativeTag, true) }
                    name={key+"_"+index} />}
                label={alternativeTag}
            />
            </CellMeasurer>
        )
    }

    updateActiveTags = (tag, activate) => {
        if (this._isMounted && this.state.activeTags!==undefined && Array.isArray(this.state.activeTags)) {
            //console.log('before',activate,  tag,  this.state.activeTags)
            var tagUpdate = this.state.activeTags
            if (activate){
                //tagUpdate.push(tag)
                tagUpdate = _.union(tagUpdate, [tag])
                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : true} })
            }
            else{

                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : false} })
                var index = tagUpdate.indexOf(tag);
                tagUpdate.splice(index, 1);
                tagUpdate = tagUpdate.filter((_, i) => i !== tag)
            }
            //console.log('after', tag,  this.state.activeTags)
            this.setState({activeTags : tagUpdate})
            //console.log('activtagsafter update', this.state)
        }
    }

    saveUpdatedKeyWords = () => {
        if (this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.length>1) {
            this.props.firebase.content(this.state.cid ).update({tags : this.state.activeTags })
            .then( () => {
                this.handleCloseDialogueKeyWords()
            })
        }
    }
}

AdminContentPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const ContentList = withFirebase(ContentListBase);
export const ContentItemDialog = withFirebase(ContentItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    // withEmailVerification,
    withAuthorization(condition),
)(AdminContentPage);


