import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Grid from '@material-ui/core/Grid';

import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';

import SettingPlan from './SettingPlan';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
});

class DialogSettingPlan extends Component { 
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }
    componentDidMount() {
        this.setState({
            loading: false,
        });
      }
    
    render(){
        const { theme } = this.props;
        return(
            <RemoveScroll enabled={this.props.handleOpen === undefined ? false : this.props.handleOpen}>
                <Dialog
                    open={this.props.handleOpen === undefined ? false : this.props.handleOpen}
                    onClose={this.props.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}>
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.props.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Shop
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.props.handleClose}>
                                    Cancel
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.props.infoText !== undefined && this.props.infoText !== ''
                            &&
                            <p style={theme.textSupportCenter}>
                                {this.props.infoText}
                            </p>
                        }
                        <div style={{textAlign: 'center'}}>
                            {this.props.handleOpen
                                &&
                                <SettingPlan 
                                    user={this.props.user}
                                    defaultSet={this.props.defaultSet || undefined}
                                    showTerms={true}
                                    coach={this.props.coach || false}
                                    variant = {this.props.defaultSet?.programShopURL?.length > 2 ? 'externalLink' : undefined}
                                    externalLink = {this.props.defaultSet?.programShopURL?.length > 2 ? this.props.defaultSet.programShopURL : undefined}
                                    purchaseSuccessHandler={this.props.purchaseSuccessHandler || undefined}
                                    purchaseSuccessFlag={this.props.purchaseSuccessFlag || undefined}
                                    premiumOnly={this.props.premiumOnly || false}
                                />
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )// end return
    }//end render
};//end class


export default compose(
    withStyles(styles, { withTheme: true }),
)(DialogSettingPlan);
