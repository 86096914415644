function muiStyle (palette) { return {
    root: {
        color: palette.themeText
    },
    body1: {
        color: palette.themeText
    },
    gutterBottom: {
        marginBottom: 8
    },
}}

export default muiStyle;