export const ZonesEnglish = [
    'Pacific/Honolulu',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Los_Angeles',
    'America/New_York',
    'America/New_York',
    'America/New_York',
    'America/Indianapolis',
    'America/Edmonton',
    'America/Phoenix',
    'America/Toronto',
    'America/Vancouver',
    'America/Winnipeg',
    'America/Regina',
    'America/Halifax',
    'America/Whitehorse',
    'America/Anchorage',
    'Europe/London',
    'Europe/Dublin',
    'Australia/Adelaide',
    'Australia/Perth',
    'Australia/Sydney',
    'Australia/Brisbane',
    'Australia/Melbourne',
    'Australia/Darwin',
    'Pacific/Auckland'
]
export const ZonesSpanish = [
    "America/Argentina/",
    "America/Bogota",  // Colombia
    "America/Chihuahua",  // Mexico
    "America/Costa_Rica",
    "America/Dominica",
    "America/El_Salvador",
    "America/Guatemala",
    "America/Guayaquil",  // Ecuador
    "America/Havana",  // Cuba
    "America/La_Paz",  // Bolivia
    "America/Lima",  // Peru
    "America/Los_Angeles",  // Some Spanish speakers in US
    "America/Managua",  // Nicaragua
    "America/Mexico_City",
    "America/Montevideo",  // Uruguay
    "America/Panama",
    "America/Paraguay",
    "America/Puerto_Rico",
    "America/Santiago",  // Chile
    "America/Santo_Domingo",  // Dominican Republic
    "Atlantic/Canary",  // Canary Islands (Spain)
    "Europe/Madrid",  // Spain
    "Pacific/Easter"  // Easter Island (Chile)
]
export const ZonesGerman = [
    "Europe/Berlin",
    "Europe/Vienna",
    "Europe/Zurich",
    "Europe/Busingen",
    "Europe/Vaduz"
]