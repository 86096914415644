import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { AddAnalytics } from '../Modules/AddAnalytics';
 
import withStyles from "@material-ui/core/styles/withStyles";
//import PropTypes from "prop-types";
// auto push after reload with social

//import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import ChatBlock from '../Routine/ChatBlock';

//stepper and views
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';

import LoadingProgress from '../Modules/LoadingProgress';

//import backgroundImageWelcome from "../../images/login-background.gif";
//import backgroundImageDesktop from "../../images/login-background.png";
import backgroundImageSignup from "../../images/bluerunningtrack3.jpg";
import logoAnimated from "../../images/rocky-logo-animated.gif";
import logo from "../../images/rocky-logo-square.png";
//import logoCaption from "../../images/rockyai-logo-caption.png";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
//import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    root: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        backgroundImage: (theme?.palette?.themeAltBGImage?.includes("http") ? `url(${theme.palette?.themeAltBGImage})` : theme.palette.themeBlue === "#20A5E6" ? `url(${backgroundImageSignup})` : `linear-gradient(-20deg, ${theme.palette.themeBlue} 0%, ${theme.palette.themeLightGrey} 100%)`),
        //backgroundImage: (theme.palette.themeBlue === "#20A5E6" ? `url(${backgroundImageSignup})` : `linear-gradient(-20deg, ${theme.palette.themeBlue} 0%, ${theme.palette.themeLightGrey} 100%)`),
        //backgroundImage: `url(${backgroundImageSignup})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
    },
    rootMobile: {
        paddingTop: 'calc( 20px + env(safe-area-inset-top))',
        paddingBottom: 'calc( 20px + env(safe-area-inset-bottom))',
        //paddingTop: 20,
        //paddingBottom: 20,
        //backgroundImage: `url(${backgroundImageWelcome})`,
        //backgroundPosition: "center 30px",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh",
    },
    chat: {
        //background: theme.palette.themeLightGrey,
        //paddingLeft: 20,
        //paddingRight: 20,
        //paddingTop: 'calc( 80px + env(safe-area-inset-top))',
        //paddingTop: 'calc( 15px + env(safe-area-inset-top))',
        //paddingBottom: 130,
        //paddingBottom: 'calc( 5px + env(safe-area-inset-bottom))',
        //flexGrow: 1,
        //flexDirection: "column",
        //minHeight: '100vh',
        //minHeight: 'calc(100vH - 50px)',
        //minHeight: '90%',
        //overflow: 'hidden',
        maxWidth: '500',
        width:0,
        minWidth:'100%',
        //width: 'calc(100vw - 40px)'
        //height: '90vh',
        //minheight: 'calc( 100vh - 150px)',
        //height: '100vh',
    },
});


class WelcomeChat extends Component {
    //static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        this.state = {
            activeStep : 0,
            username: ''
        };
        //this.state = { };
    }

    componentDidMount() {
        this._isMounted = true;
        const { theme } = this.props;
        //console.log('component mounted', this.props,  this.context)
        var username = this.context.username!==undefined ? this.context.username.split(" ")[0] : "Padawan";
        this.setState({username});
        this.loadWelcomeData();
        this.internetConnectionCheck();
        //console.log('origin props', this.context)
        if(this._isMounted){
            if(window.StatusBar !== undefined && this.state.firebaseConnectionError !== true){
                window.StatusBar.overlaysWebView(false);
                window.StatusBar.backgroundColorByHexString(theme.palette.themeAltLoading);
                window.StatusBar.styleLightContent();
            }
        }
        AddAnalytics('screen_view', 'PageWelcome');
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    internetConnectionCheck() {
        this.props.firebase.onDisconnectSimple(this, (connected) => {
            if(connected === true && (this.state.welcomePages === undefined || this.state.welcomePages === null || this.state.welcomePages.length < 1) && this._isMounted){
                this.loadWelcomeData();
            }
        });
    }
        
    loadWelcomeData() {
        var welcomePages = [];
        this.unsubscribe = this.props.firebase
        .onboardingContents()
        .where('category', '==', 'welcomePages')
        .where('active', '==', true)
        .orderBy('page', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                welcomePages.push({ text: "Regularly reflect and being mindful", gid:"default" });
                return;
            }
            snapshot.forEach(doc => {
                var set = doc.data();
                var id = set.id || "";
                //console.log('set', set)
                if(this.props.defaultSet?.programIntro !== undefined && id?.length > 2 && this.props.defaultSet?.programIntro?.[id]?.length > 0){
                    //console.log('set new', this.props.defaultSet?.programIntro?.[id])
                    set['h3'] = this.props.defaultSet?.programIntro?.[id]?.[0] || "";
                    set['h4'] = this.props.defaultSet?.programIntro?.[id]?.[1] || "";
                    set['h5'] = this.props.defaultSet?.programIntro?.[id]?.[2] || "";
                    set['h6'] = this.props.defaultSet?.programIntro?.[id]?.[3] || "";
                }
                welcomePages.push({ ...set, pid: doc.id });
            });
            if(this._isMounted){
                this.setState({ 
                    welcomePages: welcomePages 
                });
            }
            //console.log('pages', this.state.welcomePages)
        });
    }    

    render() {
        const { classes, theme } = this.props;
        //console.log('renderWElcomePages', this.state, this.props)
        return (
            <div style={theme.rootFrame}>
                <div 
                    className={(IsMobileDevice() || this.props.defaultSet?.programColorBack?.includes('#')) ? classes.rootMobile : classes.root}
                    //className={classes.rootSignup}
                    ref={this.dialogRef}
                >
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} style={{ width:'100%', maxWidth: 500 }}>
                            <div style={theme.WelcomeLogin}>
                                <div style={{maxWidth: 'inherit'}}>
                                    {(this.state.welcomePages === undefined || this.state.firebaseConnectionError===true)
                                    ?
                                        <div style={{maxWidth: 'inherit'}}>
                                            <Link to={ROUTES.HOME}>
                                                <div style={theme.logoContainer}>
                                                    <img src={this.props?.defaultSet?.programLogo?.length > 5 
                                                        ?   this.props.defaultSet.programLogo
                                                        :   logoAnimated
                                                        }
                                                        style={theme.logo} 
                                                        alt="AI Coaching Chatbot Journaling" 
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                    :
                                        <div style={{maxWidth: 'inherit'}}>
                                            <Link to={ROUTES.HOME}>
                                                <div style={theme.logoContainer}>
                                                    <img src={this.props?.defaultSet?.programLogo?.length > 5 
                                                        ?   this.props.defaultSet.programLogo
                                                        :   logo
                                                        }
                                                        style={theme.logo} 
                                                        alt="AI Coaching Chatbot Journaling" 
                                                    />
                                                </div>
                                            </Link>
                                            {this.state.welcomePages[this.state.activeStep]?.h1 !== undefined && this.state.welcomePages[this.state.activeStep]?.h1 !== '' &&
                                                <h1 style={theme.textCenterPadding}>
                                                    {this.state.welcomePages[this.state.activeStep].h1.replace('{{username}}', this.state.username)}
                                                </h1>
                                            }
                                            {this.state.welcomePages[this.state.activeStep]?.h2 !== undefined && this.state.welcomePages[this.state.activeStep]?.h2 !== '' &&
                                                <h2 style={theme.textCenterPadding}>
                                                    {this.state.welcomePages[this.state.activeStep].h2.replace('{{username}}', this.state.username)}
                                                </h2>
                                            }
                                            { //!IsMobileDevice() &&
                                                <div style={theme.MobileStepperDesktop}>
                                                    {this.mobileStepperButtons()}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className={classes.chat}>
                                    {(this.state.welcomePages !== undefined && this.state.firebaseConnectionError!==true)
                                    ?
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={this.state.activeStep}
                                            onChangeIndex={this.handleStepChange}
                                            enableMouseEvents
                                            disabled
                                            style={{maxWidth: 'inherit'}}
                                        >
                                            {this.state.welcomePages.map((step, index) => (
                                                <div 
                                                    key={step.page} 
                                                >
                                                {Math.abs(this.state.activeStep - index) <= 2 
                                                ?   (
                                                        
                                                        <div key={step.page}
                                                            >
                                                            {(this.state.activeStep === index || this.state['typingBotPageDone_'+index]) 
                                                            &&
                                                                <ChatBlock
                                                                    key={step.page}
                                                                    variant={"chatMain"}
                                                                    messages={[ {text: [step.h3, step.h4, step.h5, step.h6], isCoach: true, uid: step.page} ]} 
                                                                    typingBotFinishTypingNow={this.state['typingBotPageDone_'+index] || this.state.typingSkipTrigger || false}
                                                                    typingDoneHandler={() => {
                                                                        this.setState({['typingBotPageDone_'+index]: true});
                                                                        //console.log('message end')
                                                                        if(this['refIntro_'+index] !== null && this['refIntro_'+index] !== undefined){
                                                                            //console.log('message scrooll')
                                                                            this['refIntro_'+index].scrollIntoView({ behavior: "smooth" });
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            <div ref={(el) => { this['refIntro_'+index] = el; }}>
                                                                {this.state['typingBotPageDone_'+index] && (step.buttonLandingPage === undefined || step.buttonLandingPage === '') 
                                                                && step.buttonConfirm1 !== undefined && step.buttonConfirm1 !== '' 
                                                                &&
                                                                    <div style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                                                                        {step.buttonConfirm1 === "Student"
                                                                            && 
                                                                            <>
                                                                                {['Teenager', 'Student', 'Available', 'Professional', 'Executive'].includes('Teenager') && Object.keys(this.props.defaultSet?.programLifeStages || {})?.length > 0 && this.props.defaultSet?.programLifeStages?.['Teenager'] === undefined
                                                                                    ?   ""
                                                                                    :
                                                                                        <Button style={{marginTop: 8, marginLeft: 8, marginBottom: 8}} onClick={() => {this.handleNext('Teenager')}}>
                                                                                            {this.props.defaultSet?.programLifeStages?.['Teenager'] !== undefined
                                                                                                ?   this.props.defaultSet.programLifeStages['Teenager'] 
                                                                                                :   'Youngster'
                                                                                            }
                                                                                        </Button>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {step.buttonConfirm1 !== undefined && step.buttonConfirm1 !== '' 
                                                                            &&
                                                                            <>
                                                                                {['Teenager', 'Student', 'Available', 'Professional', 'Executive'].includes(step.buttonConfirm1) && Object.keys(this.props.defaultSet?.programLifeStages || {})?.length > 0 && this.props.defaultSet?.programLifeStages?.[step.buttonConfirm1] === undefined
                                                                                    ?   ""
                                                                                    :
                                                                                        <Button style={{marginTop: 8, marginLeft: 8, marginBottom: 8}} onClick={() => {this.handleNext(step.buttonConfirm1)}
                                                                                        }>
                                                                                            {this.props.defaultSet?.programLifeStages?.[step.buttonConfirm1] !== undefined
                                                                                                ?   this.props.defaultSet.programLifeStages[step.buttonConfirm1] 
                                                                                                :   step.buttonConfirm1 === 'Student' ? 'College/Uni Student' : step.buttonConfirm1
                                                                                            }
                                                                                        </Button>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {step.buttonConfirm2 !== undefined && step.buttonConfirm2 !== '' 
                                                                        &&
                                                                            <>
                                                                                {['Teenager', 'Student', 'Available', 'Professional', 'Executive'].includes(step.buttonConfirm2) && Object.keys(this.props.defaultSet?.programLifeStages || {})?.length > 0 && this.props.defaultSet?.programLifeStages?.[step.buttonConfirm2] === undefined
                                                                                    ?   ""
                                                                                    :
                                                                                        <Button style={{marginTop: 8, marginLeft: 8, marginBottom: 8}} onClick={ () => {this.handleNext(step.buttonConfirm2)}
                                                                                        }>
                                                                                            {this.props.defaultSet?.programLifeStages?.[step.buttonConfirm2] !== undefined
                                                                                                ?   this.props.defaultSet.programLifeStages[step.buttonConfirm2] 
                                                                                                :   step.buttonConfirm2
                                                                                            }
                                                                                        </Button>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {step.buttonConfirm3 !== undefined && step.buttonConfirm3 !== '' 
                                                                        &&
                                                                            <>
                                                                                {['Teenager', 'Student', 'Available', 'Professional', 'Executive'].includes(step.buttonConfirm3) && Object.keys(this.props.defaultSet?.programLifeStages || {})?.length > 0 && this.props.defaultSet?.programLifeStages?.[step.buttonConfirm3] === undefined
                                                                                    ?   ""
                                                                                    :
                                                                                    <Button style={{marginTop: 8, marginLeft: 8, marginBottom: 8}} onClick={() => {this.handleNext(step.buttonConfirm3)}
                                                                                    }>
                                                                                        {this.props.defaultSet?.programLifeStages?.[step.buttonConfirm3] !== undefined
                                                                                            ?   this.props.defaultSet.programLifeStages[step.buttonConfirm3] 
                                                                                            :   step.buttonConfirm3
                                                                                        }
                                                                                    </Button>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {step.buttonConfirm4 !== undefined && step.buttonConfirm4 !== '' 
                                                                        &&
                                                                            <>
                                                                                {['Teenager', 'Student', 'Available', 'Professional', 'Executive'].includes(step.buttonConfirm4) && Object.keys(this.props.defaultSet?.programLifeStages || {})?.length > 0  && this.props.defaultSet?.programLifeStages?.[step.buttonConfirm4] === undefined
                                                                                    ?   ""
                                                                                    :
                                                                                        <Button style={{marginTop: 8, marginLeft: 8, marginBottom: 8}} onClick={() => {this.handleNext(step.buttonConfirm4)}
                                                                                        }>
                                                                                            {this.props.defaultSet?.programLifeStages?.[step.buttonConfirm4] !== undefined
                                                                                                ?   this.props.defaultSet.programLifeStages[step.buttonConfirm4] 
                                                                                                :   step.buttonConfirm4 === 'Executive' ? 'Leader' : step.buttonConfirm4
                                                                                            }
                                                                                        </Button>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                }
                                                                {this.state['typingBotPageDone_'+index] && (step.buttonConfirm1 === undefined || step.buttonConfirm1 === '') 
                                                                && 
                                                                    <div style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                                                                        <Button onClick={() => {this.handleNext('Okay')}}>
                                                                            Okay
                                                                        </Button>
                                                                    </div>
                                                                }
                                                                {/***
                                                                {this.state['typingBotPageDone_'+index] && (step.buttonConfirm1 === undefined || step.buttonConfirm1 === '') && (step.buttonLandingPage !== undefined && step.buttonLandingPage !== '') 
                                                                &&
                                                                    step.buttonLandingPage !== undefined && step.buttonLandingPage !== '' &&
                                                                    <div style={theme.divCenter}> 
                                                                        <Link to={ROUTES[step.buttonLandingPage]}>
                                                                            <Button  >
                                                                            {step.button}
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                }
                                                                 */}
                                                            </div>
                                                        </div>
                                                    ) 
                                                : ''}
                                                </div>
                                            ))}
                                        </SwipeableViews>
                                    :
                                        <div style={theme.loadingCenterPadding}>
                                            <LoadingProgress 
                                                defaultSet={this.props.defaultSet}
                                                type={'CircularProgress'} 
                                                loadingText={"loading"} 
                                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                            />
                                        </div>
                                    }

                                    { ((!window.navigator.onLine  ) || this.state.firebaseConnectionError===true )
                                        &&
                                            <div >
                                                <p style={this.props.theme.textErrorCenterPadding}>
                                                    &nbsp; Trying to connect with the internet!
                                                </p> 
                                            </div>
                                    }

                                </div>
                                <div style={{minHeight:50}}>&nbsp;</div> 
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    mobileStepperButtons(){
        const { theme } = this.props;
        return(
            <MobileStepper
                variant="dots"
                steps={this.state.welcomePages.length}
                position="static"
                activeStep={this.state.activeStep}
                style={{
                    backgroundColor: 'transparent',
                    padding: 0,
                    marginBottom: 20,
                    //opacity: '0.9',
                    //backgroundColor: theme.palette.secondary.light,
                    //borderRadius: IsMobileDevice()? '12px 12px 0 0' : '12px',
                }}
                nextButton={
                    this.state.activeStep === this.state.welcomePages.length - 1 
                    ?
                        <Button 
                            variant={this.state['typingBotPageDone_'+this.state.activeStep] ? "outlined" : "outlined"}
                            disabled={this.state['typingBotPageDone_'+this.state.activeStep] === true}
                            onClick={this.state['typingBotPageDone_'+this.state.activeStep] !== true
                                ?   () => {this.setState({['typingBotPageDone_'+this.state.activeStep] : true, typingSkipTrigger : true})}
                                :   (this['refIntro_'+this.state.activeStep] !== null && this['refIntro_'+this.state.activeStep] !== undefined)
                                    ?   () => this['refIntro_'+this.state.activeStep].scrollIntoView({ behavior: "smooth" })
                                    :   () => {this.props.history.push( {pathname: ROUTES.SIGN_UP})}
                            }
                        >
                            {this.state['typingBotPageDone_'+this.state.activeStep] 
                                ?   <>&nbsp;</>
                                :   <>
                                        {theme.WelcomeLogin.buttonTextDark === true
                                            ?   <span style={{color: 'black'}}>Skip</span>
                                            :   'Skip'
                                        }
                                        {theme.WelcomeLogin.buttonTextDark === true
                                            ?   <KeyboardArrowRight style={{color: 'black'}}/>
                                            :   <KeyboardArrowRight />
                                        }
                                    </>
                            }
                        </Button>
                    :
                        this.state['typingBotPageDone_'+this.state.activeStep] === true
                        ?
                            <Button 
                                variant={"outlined"}
                                disabled={this.state.onboardingResponses !== undefined && this.state.onboardingResponses.length>(this.state.activeStep) ? false : true}
                                onClick={ 
                                    this.state.onboardingResponses !== undefined && this.state.onboardingResponses.length>(this.state.activeStep) 
                                    ?   () => this.handleNext('')
                                    :
                                        (this['refIntro_'+this.state.activeStep] !== null && this['refIntro_'+this.state.activeStep] !== undefined)
                                        ?   () => this['refIntro_'+this.state.activeStep].scrollIntoView({behavior: "smooth", block: "end", inline: "nearest" })
                                        :   () => this.handleNext('')
                                }
                            >
                                {this.state.onboardingResponses !== undefined && this.state.onboardingResponses.length>this.state.activeStep 
                                    ?
                                        <>
                                            {theme.WelcomeLogin.buttonTextDark === true
                                                ?   <span style={{color: 'black'}}>Next</span>
                                                :   'Next'
                                            }
                                            {theme.WelcomeLogin.buttonTextDark === true
                                                ?   <KeyboardArrowRight style={{color: 'black'}}/>
                                                :   <KeyboardArrowRight />
                                            }
                                        </>
                                    :
                                        <>&nbsp;</>
                                }
                            </Button>
                        :
                            <Button 
                                variant={"outlined"}
                                onClick={ 
                                    () =>  {this.setState({['typingBotPageDone_'+this.state.activeStep ]: true, typingSkipTrigger : true})}
                                }
                            >
                                {theme.WelcomeLogin.buttonTextDark === true
                                    ?   <span style={{color: 'black'}}>Skip</span>
                                    :   'Skip'
                                }
                                {theme.WelcomeLogin.buttonTextDark === true
                                    ?   <KeyboardArrowRight style={{color: 'black'}}/>
                                    :   <KeyboardArrowRight />
                                }
                            </Button>
                }
                backButton={
                    <Button onClick={this.handleBack} variant="outlined">
                            {theme.WelcomeLogin.buttonTextDark === true
                                ?   <KeyboardArrowLeft style={{color: 'black'}}/>
                                :   <KeyboardArrowLeft />
                            }
                            {theme.WelcomeLogin.buttonTextDark === true
                                ?   <span style={{color: 'black'}}>Back</span>
                                :   'Back'
                            }
                    </Button>
                    /**
                    <Button onClick={this.handleBack} variant="outlined" disabled={this.state.activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                    </Button>
                    */
                }
            />
        )
    }

    handleStepChange = step => {
        this.handleScrollTop();
        if(this._isMounted){
            //console.log('stepchange')
            //this.setState({activeStep : step});
        }
    };
    handleScrollTop = () => {
        if(this.dialogRef!== null && this.dialogRef!==undefined){
            this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
            //console.log('smooth')
        }
        //document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    handleNext = (response='') => {
        //console.log('handlenedxt',this.state)
        this.handleScrollTop();
        var onboardingResponses = [];
        if(this.state.onboardingResponses!== undefined && this.state.onboardingResponses.length > 0){
            onboardingResponses = this.state.onboardingResponses;
        }
        if(response!=='' && response!==undefined  && response!==null ){
            onboardingResponses.push(response);
            //console.log('onboardingResponses', onboardingResponses, response)
        }
        if(this.state.activeStep === this.state.welcomePages.length - 1 ){
            if(onboardingResponses!== undefined && onboardingResponses.length > 0){
                window.sessionStorage.setItem('onboardingResponses', onboardingResponses);
            }
            this.props.history.push( {pathname: ROUTES.SIGN_UP});
        }
        else if(this._isMounted){
            //console.log('push next', this.state.activeStep)
            this.setState({
                activeStep : this.state.activeStep + 1,
                ['typingBotPageDone_'+this.state.activeStep]: true,
                onboardingResponses
            });
        }
    };
    handleBack = () => {
        if(this.state.activeStep === 0){
            this.props.history.push( {
                pathname: ROUTES.SIGN_IN,
            })
        }
        else{
            this.handleScrollTop();
            this.setState({activeStep : this.state.activeStep - 1});
        }
    };
}

///// this approach has no user
//const condition = authUser => !!authUser;

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(WelcomeChat);