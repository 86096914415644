import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import ChatBlock from '../Routine/ChatBlock';

import InputAdornment from '@material-ui/core/InputAdornment';

import SpeechToTextButton from '../Modules/SpeechToTextButton';
import SendIcon from '@material-ui/icons/Send';
import { CreateAlert } from '../Modules/CreateAlert';


import Grid from '@material-ui/core/Grid';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {RemoveScroll} from 'react-remove-scroll';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Slide from '@material-ui/core/Slide';
import { Submit1on1Message } from './MessagesUser';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});

class DialogSupport extends Component {  
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
    
        this.state = {
            messages : [],
            loading: true,
        };
    }


    componentDidMount() {
        //console.log('props',this.props)
        this._isMounted = true;
        this.loadMessages();
    }
    
    componentWillUnmount() {
        this._isMounted =false;
        if(this.unsubscribeMessages !== undefined) this.unsubscribeMessages()
    }

    componentDidUpdate(prevProps)  {
        var newDialogueId = '';
        var oldDialogueId = '';
        if(this.props.dialogueId !== undefined && this.props.dialogueId !== ""){
            newDialogueId = this.props.dialogueId
        }
        if(prevProps.dialogueId !== undefined && prevProps.dialogueId !== ""){
            oldDialogueId = prevProps.dialogueId
        }
        if(newDialogueId !== oldDialogueId ) {
            if (this.unsubscribeMessages !== undefined){
                this.unsubscribeMessages()
            }
            this.loadMessages()
            var messageDict = { }
            if(this.context.newMessages!== undefined && this.props.groupId!==undefined && this.props.userId!==undefined  ){
                try{ 
                    messageDict = this.context.newMessages.find(q => (q.dialogueId === this.props.dialogueId ));
                    //console.log('messagrease',messageDict)
                    this.props.firebase.user(this.context.uid).update({newMessages : this.props.firebase.fieldValue.arrayRemove(messageDict)})
                }
                catch (error) {messageDict = {}}
            }
        }
        return true
    }
    

    loadMessages() {
        //console.log('props',this.props)
        if(this.props.dialogueId!== undefined && this.props.dialogueId!==''){
            this.unsubscribeMessages = this.props.firebase
            .messages()
            .orderBy('createdAt', 'asc')
            .where('dialogueId', '==', this.props.dialogueId)
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let loadedMessages = [];
                    let messageListById = [];
                    snapshot.forEach( doc => {
                        var newMessage = doc.data();
                        if(!messageListById.includes(doc.id) && newMessage.text !== undefined && newMessage.text.length>0 ){
                            messageListById.push(doc.id);
                            loadedMessages.push({ ...newMessage, uid: doc.id })
                        }
                    })
                    //console.log('messages',loadedMessages)
                    if(this._isMounted ){
                        this.setState({
                            messages: loadedMessages,
                            messageListById : messageListById,
                            loading: false,
                        });
                    }
                } 
                else {
                    if(this._isMounted ){
                        this.setState({ 
                            messages: [], 
                            messageListById: [], 
                            loading: false, 
                        });
                    }
                    console.log('no message requesting')
                    //this.request();
                }
            });
        }
    }

    render(){
        const { theme } = this.props;
        //console.log('props', this.props)
        return(
        <RemoveScroll enabled={this.props.openMessaging || false}> 
            <Dialog
                open={this.props.openMessaging || false}
                onClose={this.props.handleCloseMessaging}
                fullScreen={IsMobileDevice()}
                PaperProps={{style:theme.dialogWindow}}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle style={theme.dialogTitle} >
                    <Grid container spacing={0} direction="row"
                        justify="space-between"
                        alignItems="center">
                        <Grid item >
                            <IconButton onClick={this.props.handleCloseMessaging}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleText}>
                                Conversation
                            </span>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleLink} onClick={this.props.handleCloseMessaging}>
                                Cancel
                            </span>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={theme.dialogContent}>
                    {this.props.openMessaging &&
                        <div style={{width:"100%"}}>
                            <p><strong>Conversation: {this.props.dialogueTitle}</strong></p>
                            {this.context.roles?.includes('ADMIN') &&
                                <span>{this.props.userId}&nbsp;{this.props.userData?.programCode}</span> 
                            }
                            {this.props.isCoach && this.state.messages!== undefined && this.state.messages.length>0 
                            && this.props.dialogueArchive!==true && this.props.hideArchive !== true
                            &&
                                <div style={{width:"100%", textAlign:"right", marginBottom: 30}}>
                                    <Button onClick={ () => this.archiveDialogue()} >
                                        Archive conversation
                                    </Button>
                                </div>
                            }
                            {this.state.messages!== undefined && this.state.messages.length>0
                                &&
                                <ChatBlock  
                                    variant={"chatMain"}
                                    currentDialogueId={this.state.currentDialogueId}
                                    messages={this.state.messages} 
                                    showTextInputHandler={this.showTextInputHandler} 
                                    invertCoach={this.props.isCoach && this.props.userId !== this.context.uid}
                                    noAnimation={true}
                                    showDetail={true}
                                    user={this.context}
                                    typingDoneHandler={() => {
                                        //this.messagesEnd.scrollIntoView({ behavior: "smooth" })
                                    }}
                                />
                            }
                        </div>
                    }
                </DialogContent>
                {this.props.dialogueArchive!==true
                    &&
                    <DialogActions style={theme.dialogAction}>
                        <TextField
                            name="comment"
                            style={ {fontSize: '16px'} }
                            value={this.state.comment || ""} 
                            onChange={(e) => {this.setState({ comment: e.target.value})} }
                            type="text"
                            variant="outlined"
                            placeholder="What do you want to say?"
                            fullWidth
                            multiline
                            InputProps={{
                                style:{fontSize: '16px', backgroundColor: theme.palette.white},
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {this.state.comment!=="" && this.state.comment!==undefined
                                            ?
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Send message"
                                                    onClick={ this.onSubmit(this.state.comment)}
                                                    >
                                                    { this.state.comment.length >  10 
                                                        ? <SendIcon style={{color: theme.palette.themeRed}} />
                                                        : <SendIcon  />
                                                    }
                                                </IconButton>
                                            :            
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Write message"
                                                    disabled={true}
                                                    >
                                                    <SendIcon />
                                                </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SpeechToTextButton 
                                            renderKey={'csupport_'}
                                            returnText={(result) => this.setState({comment : result})}
                                            existingText={this.state.comment || ""}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </DialogActions>
                }
            </Dialog>
        </RemoveScroll>  
        )// end return
        
    }//end render

    onSubmit = (text) => async () => {
        if(text!=="" && this._isMounted){
            Submit1on1Message(this, text);
            /***
            console.log('onSubmit DialpogSupport', text, this.props)
            var username = this.context.username!==undefined && this.context.username !=="" ? this.context.username.split('/')[0] : "User";
            var messageData={
                userId: this.props.userId,
                addFollowUp: false,
                groupId: this.props.groupId,
                isCoach: false,
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                dialogueId: this.props.dialogueId,
                text: [text],
                username: username
            }
            if(this.props.isCoach && this.props.userId !== this.context.uid){
                messageData['isCoach'] = true;
                messageData['coachId'] = this.context.uid;
            }
            //console.log('messageData', messageData)
            this.props.firebase.messages().add(messageData);
            this.props.firebase.dialogue(this.props.dialogueId).update({createdAt : this.props.firebase.fieldValue.serverTimestamp(), phase: 'created'});
            this.setState({comment:""})
            var alertDict = {}
            if(this.props.isCoach){
                var userContext = this.props.userData;
                userContext['uid'] = this.props.userId;
                userContext['username'] = username;
                alertDict = {
                    coachId : this.context.uid,
                    userId : this.props.userId,
                    groupId : this.props.groupId,
                    dialogueId : this.props.dialogueId,
                    type : "SUPPORT"
                }
                //this.updateAlerts(this.props.dialogueId,this.context.uid, this.props.userId, this.props.groupId, username, text, this.props.dialogueTitle)
                //console.log(this.props.userData)
                CreateAlert(this.props.firebase, this.props.userData, text, this.props.dialogueTitle, username, new Date(), alertDict )
            }
            else{
                var groupOwners = this?.props?.groupOwners || [];
                if(this.props.groupId !== 'default' && this.props.groupId?.length>4 && !(groupOwners?.length > 0)){
                    var groupInfo = await this.props.firebase.group(this.props.groupId).get()
                    .then(result => {
                        groupInfo = result?.data() || {};
                        console.log('groupInfo', groupInfo)
                        return groupInfo
                    })
                    console.log('groupInfo results', groupInfo)
                    if(groupInfo?.owner?.length > 0){
                        groupOwners = groupInfo.owner;
                    }
                }
                if (groupOwners?.length > 0){
                    groupOwners.map((coachId) => {
                        alertDict = {
                            coachId : coachId,
                            userId : this.props.userId,
                            groupId : this.props.groupId,
                            dialogueId : this.props.dialogueId,
                            type : "SUPPORT"
                        }
                        CreateAlert(this.props.firebase,  {uid: coachId, username: 'Coach', emailNotifications:'daily'}, text, this.props.dialogueTitle, username, new Date(), alertDict )
                        return true;
                        //this.updateAlerts(this.props.dialogueId, coachId, this.props.userId, this.props.groupId, username, text, this.props.dialogueTitle)
                    })
                }
                else{
                    console.log('there is no groupowner')
                    alertDict = {
                        coachId : 'default',
                        userId : this.props.userId,
                        groupId : this.props.groupId,
                        dialogueId : this.props.dialogueId,
                        type : "SUPPORT"
                    }
                    CreateAlert(this.props.firebase, {uid: 'AwWa9CbyOMY5hcSyeo68ihVbRnx1', username: 'User Service', emailNotifications:'daily'}, text, this.props.dialogueTitle, username, new Date(), alertDict )
                    //this.updateAlerts(this.props.dialogueId,'default', this.props.userId, this.props.groupId, username, text, this.props.dialogueTitle)
                }
            }
                */
        }
    }

    updateXAlerts (dialogueId, coachId, userId, groupId, username, text, title){
        if(coachId==='default') coachId = "AwWa9CbyOMY5hcSyeo68ihVbRnx1";
        //console.log('coach:',coachId, username, ' user', userId, text, title)
        var messageDict = {
            coachId : coachId,
            userId : userId,
            groupId :groupId,
            dialogueId :dialogueId,
        }
        if(this.props.isCoach){
            this.props.firebase.user(userId).update({newMessages : this.props.firebase.fieldValue.arrayUnion(messageDict)})
        }
        else{
            this.props.firebase.user(coachId).update({newMessages : this.props.firebase.fieldValue.arrayUnion(messageDict)})
        }
    }

    archiveDialogue(){
        var data = {
            phase: 'session_ended',
            dialogueEnded: true
        }
        this.props.firebase.dialogue(this.props.dialogueId).update(data); 
        this.props.firebase.dialoguePostprocess(this.props.dialogueId, 'dialogueEnded').set(data, {merge:true});
        this.props.handleCloseMessaging()    
    }

};//end class

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogSupport);