import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import BadgeCard from '../Modules/BadgeCard';
import badgeStart from "../../images/Badge_XP_start.png";
import badgeNext from "../../images/Badge_XP_whatsnext.png";
import Countdown from 'react-countdown';


import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';


// MUI stuff
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import AlarmIcon from '@material-ui/icons/Alarm';
import SnackbarInfo from '../Modules/SnackbarInfo';


// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
    },
});

class Task2Assessments extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            challenges: 4,
            dateReference: this.getDateReference()
        };
    } 

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    getDateReference(){
        var date = Date.now();
        var dateReference = date;
        var timestamp = undefined;
        var offSetForTimer = (1 * 24 * 60 * 60 * 1000);
        if(this.props.user?.journeyStartedAt?.seconds > 0){
            timestamp = this.props.user.journeyStartedAt.seconds;
            dateReference = new Date(timestamp * 1000 + offSetForTimer);
        }
        //console.log('dateReference', dateReference)
        return dateReference
    }

    render(){
        return(
            <>
                <SnackbarInfo 
                    alertText = {this.state.alertText}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.openAlert || false}
                    closeHandler = {() => {this.setState({openAlert: false})}}
                />
                {this.renderButtons()}
            </>
        )
    }

    handleStartRoutine = () => {
        if(this._isMounted){
            this.props.history.push(ROUTES.ROUTINE);
            this.setState({ openAlert: false},
                () => setTimeout( () => {this.setState({alertText: 'You can complete 2 chats per day, one in the morning and one in the evening.', openAlert: true})}, 300)
            )
        }
    }

    renderButtons() {
        const { theme } = this.props;
        return (
            <div style={{margin:this.props.noMargin? 0 : 20}}>
                <div style={{position: 'relative'}}>
                    <h4 style={theme.CardContent.titleText}>
                        {
                            //Day ② - Self-Assessment
                        }
                        Self-Assessment
                    </h4>
                    {this.props.tutorialOpen 
                    &&
                        <div style={{position: 'absolute', top: -4, right: -6}}>
                            <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('mission')}}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </div>
                    }
                </div>
                <p style={theme.textCenter}>
                    Strength & Weakness &bull; Thriving levels<br/>
                    {/****
                     Understanding your current position will help you thrive and shine.
                    */}
                </p>
                {(true || this.props.user?.dialogueCounter >= (this.props.unlockCounter || 4))
                    ?   ''
                    :   <div style={{textAlign: 'center', color: theme.palette.themeBlue, maringTop: 20}}>
                            {this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter)
                                ?   'You can unlock this feature after you complete ' + this.props.unlockCounter + ' reflection practices with Rocky.'
                                :   'The time left to engage with this mission is:'
                            }
                        </div>
                }
                {(this.props.user?.userAssessment?.pA !== undefined || this.props.unlockCounter > 0)
                    ?   ''
                    :
                        <h3 style={{...theme.textSupportCenter}}>
                            <Countdown 
                                daysInHours={true}
                                date={this.state.dateReference > Date.now() 
                                    ? this.state.dateReference 
                                    : (Date.now() + (30 * 1000))} 
                                onComplete={() => this.setState({dateReference: (Date.now() + (1 * 1000)) })}
                            />
                        </h3>
                }
                {this.props.user?.userAssessment?.pA !== undefined
                    ?   
                        <p style={theme.textSupportCenter}> 
                            Well done. Understanding your scores will help you to set up the perfect personal Coaching Plan.
                        </p>
                    :   (this.props.unlockCounter === undefined || this.props.user?.dialogueCounter >= this.props.unlockCounter)
                        ? 
                            <p style={theme.textSupportCenter}> 
                                Complete your personal assessment.
                                <br/>
                            </p>
                        :   ''
                }
                <Grid container spacing={1} justify="center" alignItems="stretch">
                    <Grid item sm={6} style={{width:'100%', height: 'auto'}}>
                        <BadgeCard
                            avatarGreyOut={!(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter)) ? false : true}
                            backgroundColor = {"white"}
                            image={badgeNext} 
                            title = {'Personality Test'}
                            subtitle = {'Start Test'}
                            strongSubtitle = {false}
                            onClick={
                                (this.props.subscriber || (this.props.updateOcean !== undefined && !(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter))))
                                ?   this.props.updateOcean
                                :   () => this.setState({alertText: 'You can start your self-assessment after finishing '+ this.props.unlockCounter +' reflection practices.', openAlert: true})
                            }
                            clickText={undefined}
                            button={(this.props.subscriber || !(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter))) ? 'Start' : 'Unlock'}
                            style={{height: '100%'}}
                        />
                    </Grid>
                    <Grid item sm={6} style={{width:'100%', height: 'auto'}}>
                        <BadgeCard
                            avatarGreyOut={!(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter)) ? false : true}
                            backgroundColor = {"white"}
                            image={badgeStart} 
                            title = {'Thriving Survey'}
                            subtitle = {'Start Assessment'}
                            strongSubtitle = {false}
                            onClick={
                                (this.props.subscriber || (this.props.updateScore !== undefined && !(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter))))
                                ?   this.props.updateScore
                                :   () => this.setState({alertText: 'You can start your self-assessment after finishing '+ this.props.unlockCounter +' reflection practices.', openAlert: true})
                            }
                            clickText={undefined}
                            button={(this.props.subscriber || !(this.props.unlockCounter > 0 && !(this.props.user?.dialogueCounter >= this.props.unlockCounter))) ? 'Start' : 'Unlock'}
                            style={{height: '100%'}}
                        />
                    </Grid>
                </Grid>
				{(this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
					&&
					<p style={theme.textSupportCenter}>
						Personality test &bull; Skill evaluation
					</p>
				}
            </div>
        )
    }

    renderActionItem(key, icon, subtitle, title, actionResult, link){
        const {theme} =  this.props;
        //console.log('redner', key, title)
        var color = theme.palette.themeAlertGreen ;
        if(actionResult) color = theme.palette.themeLightGrey ;
        return(
            <Card id={key} key={key} 
                style={{...theme.CardDialogueButtonFrame, 
                    background : 'linear-gradient(to bottom, '+ color +' 0%, '+theme.palette.white+' 30%)',
                    //backgroundColor : 'linear-gradient(to bottom, '+color+' 0%, #FFFFFF 30%)',
                    margin:10, 
                    padding:20,
                    height: "90%",
                    overflow: "hidden"
                }}
            >
                        <Grid container spacing={2} direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={2}>
                                {icon}
                            </Grid>
                            <Grid item xs={10}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                    <strong style={{wordWrap:'break-word'}}>
                                    &nbsp; {title}
                                    </strong>
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                <span style={theme.CardContentItem.TextSubTitle}> 
                                   {subtitle}
                                </span>  
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                                {actionResult
                                    ?
                                        <CheckCircleIcon
                                            style={{color:"green",  float: "right"  }}
                                        />
                                    :
                                        <Link to={link}>
                                            <Button
                                                style={{float: "right", backgroundColor: theme.palette.themeRed, marginBottom:10}}
                                                size={IsMobileDevice() ? "small" : "medium"}
                                                endIcon={icon}
                                            >
                                                Start
                                            </Button>
                                        </Link>
                                }
                            </Grid>
                        </Grid>
                    
            </Card>
        )
    }
}

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true})
)(Task2Assessments);
