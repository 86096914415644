import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import CardGroupRanking from '../Charts/CardGroupRanking';

/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import CardBadges from './CardBadges';
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
});

class DialogCardBadges extends Component { 
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }
    componentDidMount() {
        this.setState({
            loading: false,
        });
      }
    
    render(){
        const { theme } = this.props;
        return(
            <RemoveScroll enabled={this.props.handleOpen === undefined ? false : this.props.handleOpen}>
            <DialogTutorialPage 
                handleOpen={this.state.handleOpenTutorial || false}
                handleBadgeFamily = {'insights'}
                handleClose={() => {this.setState({handleOpenTutorial: false})}}
            />
            <Dialog
                open={this.props.handleOpen === undefined ? false : this.props.handleOpen}
                onClose={this.props.handleClose}
                fullScreen={IsMobileDevice()}
                PaperProps={{style:theme.dialogWindow}}
                TransitionComponent={Transition}
                maxWidth="sm"
                >
                <DialogTitle style={theme.dialogTitle}>
                    <Grid container spacing={0} direction="row"
                        justify="space-between"
                        alignItems="center">
                        <Grid item >
                            <IconButton onClick={this.props.handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <span style={theme.dialogTitle.titleText}>
                                Milestones
                            </span>
                        </Grid>
                        <Grid item>
                            <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                <HelpOutlineIcon />
                            </IconButton>
                            {/***
                            <span style={theme.dialogTitle.titleLink} onClick={this.props.handleClose}>
                                Close
                            </span>
                             */}
                        </Grid>
                    </Grid>
                    {/**
                    <IconButton onClick={this.props.handleClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <span style={theme.dialogTitle.titleText}>My Milestones</span>
                     */}
                </DialogTitle>
                <DialogContent style={theme.dialogContent}>
                    <h5>{this.props.text}</h5>
                    <CardGroupRanking
                        badgeTotal={this.props.badgeTotal || undefined}                                     
                        screen={'mobile'}
                        user={this.props.user}
                        showWeekly={false}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                    />
                    <CardBadges
                        visualLimit={10}
                        variant={'circle'}
                        showUnfinished={false}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                        badgeFamilyDetailSelection= {this.props.badgeFamilyDetailSelection}
                        //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                        />
                </DialogContent>
            </Dialog>
            </RemoveScroll>
        )// end return
    }//end render
};//end class


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
  )(DialogCardBadges);
