import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
//import CoachGroupSetting from '../Coach/CoachGroupSetting';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import {RemoveScroll} from 'react-remove-scroll';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

//UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});

class ShowVouchersForGroup extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadVouchers();
    }

    componentDidUpdate(){
    }

    componentWillUnmount(){
        this._isMounted = false;
        if(this.unsubscribe !== undefined){
            this.unsubscribe()
        }
    }

    loadVouchers(){
        if(this.unsubscribe !== undefined){
            this.unsubscribe()
        }
        this.unsubscribe = this.props.firebase.collectionByName('groups/'+this.props.group+'/vouchers')
        .orderBy('code', 'asc')
        .onSnapshot(snapshot => {
            var counterVouchersAvailable = 0;
            var counterVouchersCreated = 0;
            let existingVoucherCodes = [];
            let availableVoucherCodes = {};
            let completedVoucherCodes = {};
            if(this._isMounted){
                snapshot.forEach(doc =>{
                    var docData = doc.data();
                    var type = docData.title || 'Undefined';
                    counterVouchersCreated +=1;
                    //console.log('vouchers', availableVoucherCodes)
                    if(docData.active){
                        counterVouchersAvailable +=1;
                        if(availableVoucherCodes[type] === undefined){
                            //console.log('UNDEFINED', availableVoucherCodes)
                            availableVoucherCodes[type] = [];
                        }
                        availableVoucherCodes[type].push(doc.id);
                    }
                    else{
                        if(completedVoucherCodes[type] === undefined){
                            completedVoucherCodes[type] = [];
                        }
                        completedVoucherCodes[type].push(doc.id);
                    }
                    existingVoucherCodes.push(doc.id);
                    //console.log('lsitings' , doc.id, availableVoucherCodes)
                })
            }
            if(this.props.setExistingVoucherCodes!== undefined && existingVoucherCodes?.length>0){
                this.props.setExistingVoucherCodes(existingVoucherCodes)
            }
            this.setState({
                counterVouchersAvailable,
                counterVouchersCreated,
                availableVoucherCodes,
                completedVoucherCodes
            })
        })
    }

    render(){
        const { theme } = this.props;
        return(
            <div>
                Current Vouchers Available: <strong>{this.state.counterVouchersAvailable || '0'}/{this.state.counterVouchersCreated || '0'}</strong>
                <RemoveScroll enabled={this.state.open || false}> 
                    <Dialog
                        open={this.state.open || false}
                        onClose={() => this.setState({open: false})}
                        fullScreen={IsMobileDevice()}
                        PaperProps={{style:theme.dialogWindow}}
                        TransitionComponent={Transition}
                        maxWidth="sm"
                        >
                        <DialogTitle style={theme.dialogTitle} >
                            <Grid container spacing={0} direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton onClick={() => this.setState({open: false})}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        Voucher List
                                    </span>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleLink} onClick={() => this.setState({open: false})}>
                                        Cancel
                                    </span>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={theme.dialogContent}>
                            {this.state.open &&
                                <div>
                                    <p>Available Voucher Codes:</p>
                                    {Object.keys(this.state.availableVoucherCodes || {}).map((key, i1) => (
                                        <div key={'used'+i1}>
                                            <strong>{key} ({this.state.availableVoucherCodes[key].length})</strong>
                                            {this.state.availableVoucherCodes[key].map((item, index) => (
                                                <div style={{color: 'green', position: 'relative', width: '100%'}} key={'used'+index}>
                                                    <strong>{item}</strong>
                                                    <span style={{position: 'absolute', paddingLeft:"10px", paddingRight: 10, right: 0, color: 'red'}}>
                                                        <DeleteForeverIcon 
                                                            onClick= {() => this.deleteVoucher(item)}
                                                        />
                                                    </span>
                                                </div>
                                            ))}
                                            <hr/>
                                        </div>
                                    ))}
                                    <p>Used Voucher Codes:</p>
                                    {Object.keys(this.state.completedVoucherCodes || {}).map((key, i1) => (
                                        <div key={'used'+i1}>
                                            <strong>{key} ({this.state.completedVoucherCodes[key].length})</strong>
                                            <br/>
                                            {this.state.completedVoucherCodes[key].map((item, index) => (
                                                <span style={{color: 'grey'}} key={'used'+index}>
                                                    <span style={{paddingRight: 20}}>{item}</span>
                                                </span>
                                            ))}
                                            <hr/>
                                        </div>
                                    ))}
                                </div>
                            }
                        </DialogContent>
                    </Dialog>
                </RemoveScroll>
                {this.state.counterVouchersCreated > 0 &&
                    <Grid item style={{textAlign: 'center', width: '100%'}}>
                        <Button
                            onClick = {() => {this.setState({open: true})}}
                        >
                            Show All Vouchers
                        </Button>
                    </Grid>
                }
            </div>
        )// end return
    }//end render

    deleteVoucher(item){
        if(this.props.group?.length > 0 && item?.length > 0){
            this.props.firebase.signupCodeByName(item).delete();
            this.props.firebase.documentIdByCollection('groups/'+this.props.group+'/vouchers', item).delete();
        }
    }
};//end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true }),
)(ShowVouchersForGroup);