//import palette from '../palette';

const defaultButtonBorderRadius = 41;

function muiStyle (palette) { return {
    root: {
        padding: '6px 18px',
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white + " !important",
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
        '&:disabled': {
            color: palette.themeGrey,
            backgroundColor: 'transparent',
        }, 
        '&:active': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            }, 
            //background: palette.secondary.light,
        }, 
        boxShadow:  '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        backgroundColor: palette.secondary.main,
        color: palette.primary.main,
        borderRadius: defaultButtonBorderRadius,
        border: "transparent",
        fontWeight: 700,
        fontSize: 14,
        textTransform: "none",
    },
    '&:hover': {
        backgroundColor: palette.secondary.light + " !important",
        color: palette.white,
        borderColor: "transparent",
        borderRadius: defaultButtonBorderRadius,
        '@media (hover: none)': { 
            backgroundColor: palette.secondary.dark,
            color: palette.white,
        },
    },
    contained: {
        boxShadow:  '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        //backgroundColor: palette.themeDarkBlue,
        backgroundColor: palette.secondary.main,
        //background: palette.secondary.light,
        color: palette.primary.main,
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
    },
    containedSecondary: {
        boxShadow:  '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        color: palette.black,
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
    },
    outlined: {
        boxShadow: "none",
        //boxShadow:  '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        backgroundColor: "transparent",// palette.themeRed,//palette.secondary.dark,
        color: palette.secondary.main,
        borderColor: "transparent",
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
        '&:disabled': {
            border: "transparent",
        }
    },
    outlinedSecondary: {
        color: palette.secondary.main,
        borderColor: palette.secondary.main,
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
        '&:disabled': {
            border: "transparent",
        },
    },
    text: {
        padding: '6px 18px',
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
        '&:active': {
            backgroundColor: palette.secondary.dark,
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        }, 
    },
    textPrimary: {
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
        '&:active': {
            backgroundColor: palette.secondary.dark,
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            }, 
        }, 
    },
    textSecondary: {
        '&:hover': {
            backgroundColor: palette.secondary.light + " !important",
            color: palette.white,
            borderColor: "transparent",
            '@media (hover: none)': { 
                backgroundColor: palette.secondary.dark,
                color: palette.white,
            },
        },
    },
}}

export default muiStyle;