import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import CoachUserSetting from '../../Coach/CoachUserSetting';
import CoachUserActions from '../../Coach/CoachUserActions';
import {RemoveScroll} from 'react-remove-scroll';

//Modules
import CardAlert from '../../Alerts/CardAlert';
import {ConvertTimeZoneStampToPresentingString} from '../../Modules/ConvertTimestampToDateString';
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';
import SettingContentRichText from '../Modules/SettingContentRichText';
import OneOnOneSupport from '../../Support/OneOnOneSupport'; 

import DialogMessages from '../Users/Dialog';
import MindfulnessScore from '../../Charts/MindfulnessScore';
import {ContentItemDialog} from '../Content';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    }
});

export const dialogueFailures = [
    'A: NLU Misunderstanding', 
    'A: Bad Follow-up', 
    'A: Unanswered Question',
    'A: Flow repetitive',
    'A: Chat too long',   
    'B: Stuck at start question',
    'B: Stuck at open question', 
    'B: Stuck at end',
    'B: Stuck at extra stage', 
    'C: User confusion', 
    'C: User annoyed', 
    'C: User out of scope', 
    'C: User disinterest', 
    'D: Question difficult', 
    'D: Question breaks flow', 
    'D: Question wrong profile', 
    'D: Question wrong timing', 
    'E: Error Connection', 
    'E: Error Chat Flow', 
    'F: Subscription Request', 
    'other'
]

class DialogTaskFollowUps extends Component { 
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if(this.props.userId!== undefined && this.props.userId!=="" && this._isMounted){
            this.getUser(this.props.userId)
            this.setState({
                loading: false,
            });
        }
        if(this.props.openDialogue!== undefined && this.props.openDialogue!=="" && this._isMounted){
            this.setState({
                openDialogue: this.props.openDialogue,
            });
            this.getFollowUps(this.props.userId)
        }
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    getUser = (userId) => {
        this.props.firebase
        .user(userId)
        .get()
        .then(doc => {
            if (!doc.exists) {
                console.log('no data');
            } 
            else if(this._isMounted) {
                
                this.setState({
                    username: doc.data().username,
                    email: doc.data().email,
                    user: doc.data(),
                    timezone: doc.data().timezone,
                });
            }
        })
        .catch(err => {
            console.log('error getting document', err);
        });
    }
    getFollowUps = (userId) => {
        var userFollowUps = [];
        this.setState({userFollowUps});
        this.props.firebase
        .journeyFollowUps()
        .where("userId","==",userId)
        .orderBy('createdAt', 'desc')
        .limit(20).get()
        .then(followups => {
            if (followups.size) {
                followups.forEach(doc =>{
                    //console.log(doc.data())
                    userFollowUps.push({ ...doc.data(), id: doc.id });
                })
            }
            else {
                console.log('no follow up data');
            }
            if(this._isMounted){
                this.setState({userFollowUps});
            }
        })
        .catch(err => {
            console.log('error getting document', err);
        });
    }

    render(){
        //console.log('dial', this.state.openDialogue, this.context)
        const { theme } = this.props;
        return(
            <Dialog
                open={this.props.handleOpen === undefined ? false : this.props.handleOpen}
                onClose={this.props.handleClose}
                >
                <DialogTitle style={theme.dialogTitle}>
                    <IconButton onClick={this.props.handleClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <span style={theme.dialogTitle.titleText}>User Dialogues</span>
                </DialogTitle>
                <DialogContent style={theme.dialogContent}>
                    {this.props.handleOpen &&
                    <>
                        {this.state.user !== undefined &&
                            <div>
                                <p>Username: <strong>{this.state.user.username}</strong></p>
                                <p>Email: <strong>{this.state.user.email}</strong></p>
                                <p>ID: <strong>{this.props.userId}</strong></p>
                                <p>Dial. ID: <strong>{this.props.openDialogue?.uid}</strong> ({this.props.openDialogue?.dialogueType})</p>
                                <p>Timezone: <strong>{this.state.user?.timezone}</strong></p>
                                <p>Local Time: <strong>{ConvertTimeZoneStampToPresentingString(this.state.openDialogue?.createdAt, this.state.timezone, 'weekday')}</strong></p>
                                <p>Server Time: <strong>{ConvertTimeZoneStampToPresentingString(this.state.openDialogue?.createdAt)}</strong></p>
                                <p>Chats: <strong>{this.state.user?.dialogueCounter}</strong></p>
                                <p>Last Reminder Sent: <strong>
                                    {this.state.user?.lastEmailNotificationSentAt !== undefined
                                        ?  ConvertTimeZoneStampToPresentingString(this.state.user.lastEmailNotificationSentAt, this.state.timezone, 'weekday')
                                        : '-'
                                    }
                                    </strong></p>
                                <p>XP: <strong>{this.state.user.engagementCounter}</strong></p>
                                <p>User current Quest: <strong>{this.state.user.currentLeadershipQuality}</strong></p>
                                <p>Dialogue Quest: <strong>{this.props.openDialogue?.currentLeadershipQuality + ' / ' + this.props.openDialogue?.todaysLeadershipQuality }</strong></p>
                                <p>Purchase: <strong>{this.state.user.purchasedProduct !== undefined ? this.state.user.purchasedProduct.owned ? "YES": "No" : "-"}</strong></p>
                                <p>Rating: <strong>{this.state.user.ratedApp ? "YES": "-"}</strong></p>
                                <p>Device: <strong>{this.state.user.mobileDevice !== undefined ? this.state.user.mobileDevice : this.state.user.mobileDeviceToken !== undefined? "Mobile": "Browser"}</strong></p>
                                <p>Tutorial: <strong>{this.state.user.tutorialChecked ? 'Seen' : "not opened"}</strong></p>
                                <p>Onboarding Feedback: {this.state.user.onboardingResponses?.join(", ")} </p>
                                {this.state.user.mobileDeviceToken !== undefined
                                    &&
                                    <>
                                        <p>App Ver.: <strong>{this.state.user.appVersion !== undefined ? (this.state.user.appVersion) : 'n/a'}</strong></p>
                                        <p>App Status.: <strong>{this.state.user.mobileDeviceToken !== undefined ? this.state.user.mobileDeviceToken === '' ? "deinstalled" : (this.state.user.mobileNotifications + ' - reminders') : "not installed"}</strong></p>

                                    </>
                                }
                                {this.props.openDialogue.uid!== undefined && this.props.userId!=='' && (this.props.showFailureReport) && 
                                // this content is in the dialogue itself
                                    <>
                                        <SettingContentArrayCheckbox 
                                            dbUpdateLink={this.props.firebase.dialogue(this.props.openDialogue.uid)}
                                            title={"Failure Reason"} 
                                            content={this.props.openDialogue} 
                                            params={['teamAnalysisFailure']}
                                            selectOptions={dialogueFailures}
                                            />
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.dialogue(this.props.openDialogue.uid)}
                                            title={"Failure Comment"} 
                                            max={120}
                                            params={['teamAnalysisText']}
                                            content={this.props.openDialogue} 
                                            />
                                    </>
                                }
                                {this.props.openDialogue.uid!== undefined && this.props.userId!=='' &&
                                // this content is in the dialogue itself
                                    <SettingContentActive 
                                        dbUpdateLink={this.props.firebase.dialogue(this.props.openDialogue.uid)}
                                        title={"Task Open!"} 
                                        content={this.props.openDialogue} 
                                        params={['openTask']}
                                        />
                                }
                                {this.props.userId!== undefined && this.props.userId!=='' &&
                                    // this content is in the user data itself
                                    <>
                                        <SettingContentActive 
                                            dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                            title={"Notification set initiated? Reset with a False"} 
                                            content={this.state.user} 
                                            params={['mobileNotificationsInitiated', 'emailNotificationsInitiated']}
                                            />
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                            title={"Call To Action and Last Dilaogue Summary"} 
                                            content={this.state.user} 
                                            params={['ctaText', 'lastTitle']}
                                            />
                                    </>
                                }
                            </div>
                        }
                        {this.state.user!== undefined && this.state.user.email !== undefined &&
                            <Accordion id={`panel_reminder`} key={`panel-reminder`} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-reminder`}
                                    id={`panel-reminderr`}
                                >
                                    Add a Reminder
                                 </AccordionSummary>
                                <AccordionDetails>
                                    <CardAlert userId={this.props.userId} user={this.state.user} 
                                        senderName={this.context.username}
                                        preMessage={this.state.user.ctaText} 
                                        />
                                </AccordionDetails>
                            </Accordion>
                        }
                        {this.state.openDialogue!== undefined && this.state.openDialogue.uid !== undefined &&
                            <Accordion id={`panel-content`} key={`panel-conmtent`} TransitionProps={{ mountOnEnter: true, unmountOnExit: true}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-content`}
                                    id={`panel-content`}
                                >
                                    Add News / In-App-Notes
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{width: '100%'}}>
                                        <p>Add a special Note into the user field:</p>
                                        <SettingContentRichText
                                            dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                            title={"Edit Richt Text (external tool)"} 
                                            content={this.state.user} 
                                            params={['news']}
                                            />
                                        <br/>
                                        <br/>
                                        <p>Send Message</p>
                                        <OneOnOneSupport
                                            userId={this.props.userId} 
                                            user={{uid: this.props.userId, ...this.state.user}} 
                                            groupId={"default"}
                                            isCoach={true}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {this.state.openDialogue!== undefined && this.state.openDialogue.uid !== undefined &&
                            <Accordion id={`cta`} key={`panel-cta`} TransitionProps={{mountOnEnter: true, unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-cta`}
                                    id={`panel-cta`}
                                >
                                    Change CTA and Goal
                                 </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{display: 'grid', with: '100%'}}>
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                            title={"Goals, CTA"} 
                                            content={this.state.user} 
                                            params={['ctaText', 'achievementGoal']}
                                            />
                                        <br/>
                                        <br/>
                                        <Button onClick={() => this.setState({openUserSettings: true})}>
                                            Nudges
                                        </Button> &nbsp;
                                        <Button onClick={() => this.setState({openUserSettings: true})}>
                                            Coaching Plan
                                        </Button>
                                        <RemoveScroll enabled={this.state.openUserSettings || false}>
                                            <Dialog
                                                key={this.state.actionId}
                                                open={this.state.openUserSettings|| false}
                                                onClose={() => this.setState({openUserSettings: false})}
                                                maxWidth="sm"
                                            >
                                                <DialogTitle style={theme.dialogTitle}  >
                                                    <IconButton onClick={() => this.setState({openUserSettings: false})}>
                                                        <ArrowBackIcon />
                                                    </IconButton>
                                                    <span style={theme.dialogTitle.titleText}>
                                                        Program Pools and Modules
                                                    </span>
                                                </DialogTitle>
                                                <DialogContent style={theme.dialogContent}>
                                                    {this.props.userId &&
                                                        <CoachUserSetting 
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            userId={this.props.userId} 
                                                            user={this.state.user}
                                                            leadershipCategories={this.props.leadershipCategories}
                                                            leadershipQualities={this.props.leadershipQualities}
                                                            />
                                                    }
                                                    {this.props.userId &&
                                                        <CoachUserActions 
                                                            userId={this.props.userId} 
                                                            user={this.state.user}
                                                            leadershipCategories={this.props.leadershipCategories}
                                                            leadershipQualities={this.props.leadershipQualities}
                                                            />
                                                    }
                                                </DialogContent>
                                            </Dialog>
                                        </RemoveScroll>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {this.state.openDialogue!== undefined && this.state.openDialogue.uid !== undefined &&
                            <Accordion id={`followups`} key={`panel-followups`} TransitionProps={{mountOnEnter: true, unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-followups`}
                                    id={`panel-followups`}
                                >
                                    Priorities
                                 </AccordionSummary>
                                <AccordionDetails>
                                   {this.state.userFollowUps !== undefined && this.state.userFollowUps.length >= 1
                                    ?
                                        <div>
                                            {this.state.userFollowUps.map((action) => (
                                                <div key={action.id}>
                                                    <hr />
                                                    <p><strong>{action.intention}</strong></p>
                                                    <p><strong>{action.message}</strong></p>
                                                    <p>{action.question}</p>
                                                    <p>
                                                        <strong>
                                                            {action.important? " Important " : ""}
                                                            {action.urgent? " Urgent " : ""}
                                                            {action.onTrack? " On Track " : ""}
                                                            {action.offTrack?" Off Track " : ""}
                                                            {action.done? " Done " : ""}
                                                        </strong>
                                                    </p>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={0} direction='row' justify="space-between" >
                                                            <Grid item >
                                                                <Button variant="contained" onClick={() => this.actionDelete(action.id)}>Delete</Button>
                                                            </Grid>
                                                            <Grid item >
                                                                <Button onClick={() => this.actionImportant(action.id)}>Important</Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button onClick={() => this.actionUrgent(action.id)}>Urgent</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            ) )}
                                        </div>

                                    : "No follow ups"
                                   }
                                </AccordionDetails>
                            </Accordion>
                        }
                        {this.state.openDialogue!== undefined && this.state.openDialogue.uid !== undefined &&
                            <Accordion id={`panel-dialogue`} key={`panel-dialogue`} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-dialogue`}
                                    id={`panel-dialogue`}
                                >
                                    See Dialogue - {ConvertTimeZoneStampToPresentingString(this.state.openDialogue.createdAt, this.state.timezone, 'weekday')}
                                    <br/>
                                    {this.state.openDialogue.title}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={0} direction='column'  >
                                        <Grid item >
                                            <DialogMessages 
                                                id={`messages-${this.state.openDialogue.uid}`} 
                                                dialogue={this.state.openDialogue} 
                                                leadershipCategories={this.props.leadershipCategories}
                                                leadershipQualities={this.props.leadershipQualities}
                                            />
                                        </Grid>
                                        <Grid item >
                                            <hr/>
                                            <MindfulnessScore dialogue={this.state.openDialogue}  />
                                        </Grid>
                                        <Grid item >
                                            {this.state.openDialogue !== undefined && this.state.openDialogue.contentSelection !== undefined
                                            &&
                                                this.state.openDialogue.contentSelection.map( (item, index) => (
                                                    <li key={index}>
                                                        {item.title}
                                                        &nbsp; - (Score: {item.overlap_length!== undefined ? item.overlap_length : "n/a" }, {item.leadership_category}  )
                                                        {this.state.openDialogue.wordCollection !== undefined
                                                            && this.state.openDialogue.wordCollection.intents !== undefined
                                                            &&
                                                            <span>
                                                                <br/>
                                                                <Select
                                                                    value={this.state.contentSearchIntent || ""}
                                                                    onChange={(e) => { this.setState({contentSearchIntent: e.target.value}) }}
                                                                    name='class_option'
                                                                    >
                                                                    {Object.keys(this.state.openDialogue.wordCollection.intents).map( (intent, index2) => (
                                                                        <MenuItem key={index2+"_add_"} id={index2} value={intent} >
                                                                            {intent}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <IconButton onClick={() => {this.addIntentToContent(item.contentId, this.state.contentSearchIntent) } }>
                                                                    <AddToPhotosIcon fontSize="small" />
                                                                </IconButton>
                                                                <IconButton onClick={() => {this.setState({currentDialogContentId: item.contentId, dialogContentOpen: true})} }>
                                                                    <SettingsApplicationsIcon fontSize="small" />
                                                                </IconButton>
                                                            </span>
                                                        }
                                                    </li>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        <ContentItemDialog 
                            contentId={this.state.currentDialogContentId}
                            openDialog={this.state.dialogContentOpen|| false}
                            closeDialog={(e) => {this.setState({dialogContentOpen: false })}}
                            leadershipCategories={this.props.leadershipCategories}
                            leadershipQualities={this.props.leadershipQualities}
                            
                        />
                    </>
                    }
                </DialogContent>
            </Dialog>
        )// end return
    }//end render

    addIntentToContent = (contentId, intent) => {
        if(intent!== ""){
            var tempIntent = intent;
            if(tempIntent.includes("_past")){
                tempIntent = tempIntent.replace("_past", "");
            }
            else if(tempIntent.includes("_future")){
                tempIntent = tempIntent.replace("_future", "");
            }
            this.props.firebase.content(contentId).update({utteranceClass : this.props.firebase.fieldValue.arrayUnion(tempIntent)})
            console.log('added', tempIntent, contentId)
        }
    }
    
    actionDelete = (actionId) => {
        this.props.firebase.journeyFollowUpAction(actionId).delete(); 
        this.getFollowUps(this.props.userId);
    }
    actionImportant = (actionId) => {
        this.props.firebase.journeyFollowUpAction(actionId).update({important:true}); 
        this.getFollowUps(this.props.userId);
    }
    actionUrgent = (actionId) => {
        this.props.firebase.journeyFollowUpAction(actionId).update({urgent:true}); 
        this.getFollowUps(this.props.userId);
    }

};//end class


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogTaskFollowUps);
