export const DigitalTwinsList = {
    angermanage : {
        title : "Anger Management - Your Personal AI Coach",
        code : "#ANGERMANAGE",
        key : "angermanage",
        url : "angermanage.my-ai.coach"
    },
    authenticpurpose : {
        title : "Authentic Purpose - Your Personal AI Coach",
        code : "#AUTHENTICPURPOSE",
        key : "authenticpurpose",
        url : "authenticpurpose.my-ai.coach"
    },
    affirmations : {
        title : "Daily-Affirmations and AI Coaching",
        code : "#AFFIRMATIONS",
        key : "affirmations",
        url : "affirmations.my-ai.coach"
    },
    buddha : {
        title : "Buddha - AI Coaching",
        code : "#LEGENDSBUDDHA",
        key : "buddha",
        url : "buddha.my-ai.coach"
    },
    cexh: {
        title : "AI Coaching and Execution Hub",
        code : "#SOCIALCAPITALBUILDERS",
        key : "cexh",
        url : "cexh.my-ai.coach"
    },
    chance : {
        title : "Coach Chance - Your Personal AI Coach",
        code : "#MYFUTURE",
        key : "chance",
        url : "chance.my-ai.coach"
    },
    coachwale : {
        title : "Coachwale - Your Personal AI Coach",
        code : "#COACHWALE",
        key : "coachwale",
        url : "coachwale.my-ai.coach"
    },
    collaborativeselling : {
        title : "Collaborative Selling - Sales with AI Coaching",
        code : "#COLLABORATIVESELLING",
        key : "collaborativeselling",
        url : "collaborativeselling.my-ai.coach"
    },
    cognozco : {
        title : "Cognozco - AI Coaching",
        code : "#COGNOZCO",
        key : "cognozco",
        url : "cognozco.my-ai.coach"
    },
    educatellc : {
        title : "Educate - AI Coaching",
        code : "#EDUCATELLC",
        key : "educatellc",
        url : "educatellc.my-ai.coach"
    },
    executive : {
        title : "Executive Coaching with AI",
        code : "#EXECUTIVE",
        key : "executive",
        url : "executive.my-ai.coach"
    },
    growthmindset : {
        title : "Growth Mindset AI Coaching",
        code : "#GROWTHMINDSET",
        key : "growthmindset",
        url : "growthmindset.my-ai.coach"
    },
    leadermakers : {
        title : "LeaderMakers - AI Coaching",
        code : "#LEADERMAKERS",
        key : "leadermakers",
        url : "leadermakers.my-ai.coach"
    },
    leadership : {
        title : "Leadership Coaching with AI",
        code : "#LEADERSHIP",
        key : "leadership",
        url : "leadership.my-ai.coach"
    },
    liyt : {
        title : "Live In Your Truth - AI Coaching",
        code : "#LIVEINYOURTRUTH",
        key : "liyt",
        url : "liyt.my-ai.coach"
    },
    lyric : {
        title : "Lyric - Your Personal AI Coach",
        code : "#CHANGEYOURSTORY",
        key : "lyric",
        url : "lyric.my-ai.coach"
    },
    myfuture : {
        title : "MyFuture - Your Personal AI Coach",
        code : "#MYFUTURE",
        key : "myfuture",
        url : "myfuture.my-ai.coach"
    },
    paidtothink : {
        title : "Paid to THINK - A Leaders Toolkit with AI Coaching",
        code : "#PAIDTOTHINK",
        key : "paidtothink",
        url : "paidtothink.my-ai.coach"
    },
    peacebuilding : {
        title : "Peaceify - Conflict Management AI Coach",
        code : "#PEACEBUILDING",
        key : "peacebuilding",
        url : "peacebuilding.my-ai.coach"
    },
    peaceify : {
        title : "Peaceify - Conflict Management AI Coach",
        code : "#PEACEBUILDING",
        key : "peaceify",
        url : "peaceify.my-ai.coach"
    },
    pitchtowin : {
        title : "Pitch To Win - Your Personal AI Coach",
        code : "#PITCHTOWIN",
        key : "pitchtowin",
        url : "pitchtowin.my-ai.coach"
    },
    ppllc : {
        title : "Chew On This - Your Personal AI Coach",
        code : "#POLARPERMACULTURE",
        key : "ppllc",
        url : "ppllc.my-ai.coach"
    },
    pmc : {
        title : "PMC AI Coach - Personalized Management Coaching",
        code : "#ACCESSPMCPROGRAM",
        key : "pmc",
        url : "pmc.my-ai.coach"
    },
    polarpermaculture : {
        title : "Chew On This - Your Personal AI Coach",
        code : "#POLARPERMACULTURE",
        key : "polarpermaculture",
        url : "polarpermaculture.my-ai.coach"
    },
    positivepsychologist : {
        title : "Positive Psychology - Your Personal AI Coach",
        code : "#POSITIVEPSYCHOLOGIST",
        key : "positivepsychologist",
        url : "positivepsychologist.rocky.ai"
    },
    positivemindset : {
        title : "Positive Mindset AI Coaching",
        code : "#POSITIVEMINDSET",
        key : "positivemindset",
        url : "positivemindset.my-ai.coach"
    },
    productivity : {
        title : "Productivity Coaching with AI",
        code : "#PRODUCTIVITY",
        key : "productivity",
        url : "productivity.my-ai.coach"
    },
    sales : {
        title : "AI Sales Coach",
        code : "#SALESCOACHING",
        key : "sales",
        url : "sales.my-ai.coach"
    },
    selfimprovement : {
        title : "Self-Improvement AI Coaching",
        code : "#SELFIMPROVEMENT",
        key : "self-improvement",
        url : "self-improvement.my-ai.coach"
    },
    selfcare : {
        title : "Self-Care Coaching with AI",
        code : "#SELFCARE",
        key : "selfcare",
        url : "selfcare.my-ai.coach"
    },
    younghuman : {
        title : "Younghuman AI Coaching",
        code : "#YOUNGHUMAN",
        key : "younghuman",
        url : "younghuman.my-ai.coach"
    },
}
