import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import {GetTimeZone} from '../Modules/LoadUserTimezone';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import LeadershipQualityPicker from '../Goals/LeadershipQualityPicker';

/// Chat
import InputAdornment from '@material-ui/core/InputAdornment';
import SpeechToTextButton from '../Modules/SpeechToTextButton';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import DialogQuickTopicSetting from '../Goals/DialogQuickTopicSetting';
import {ExtendDialogueMessage} from '../Routine/DiaryManual';

import {SetAgentDialogue, SetAgentStatus} from '../Routine/SetAgentStatus';
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';
import WidgetsIcon from '@material-ui/icons/Widgets';
import logo from "../../images/rocky-logo-square.png";
//import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import BadgeCard from '../Modules/BadgeCard';

import night from '../../images/satisfied1.png';
import evening from '../../images/satisfied2.png';
import afternoon from '../../images/satisfied4.png';
import morning from '../../images/satisfied5.png';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

// import Avatar from '@material-ui/core/Avatar';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// module for AvatarImage replaces the Avatar Object
//import AvatarImage from '../Modules/AvatarImage';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
//import Grid from '@material-ui/core/Grid';
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';


//import ListItemText from '@material-ui/core/ListItemText';


// added plus for choosing daily topic
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	buttonCenter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginBottom: 15,
	},    
	cardBot: {
        backgroundColor: theme.palette.darkMode? theme.palette.backPrimary : theme.palette.backTertiary,
        //color: theme.palette.white,
        maxWidth: '90%',
        marginTop: 2,
        //borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        marginBottom: 2,
        display: 'inline-block',
    },
    cardPadding: {
        color: theme.palette.white,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        "&:last-child": {
            paddingBottom: 10
        },
    },
    bot: {
        color: theme.palette.white,
        textAlign: 'left',
        display: 'inline-block',
        fontSize: 18,
        overflow: 'auto',
        whiteSpace: 'pre-wrap'
    }
});

const icebreakers = [
    "What would you like to talk about?", 
    "How can I help you today?",
    "Ready to tackle something awesome?",
    "What's on your mind today?",
    "How may I be of service?"
]
const randomIndex = Math.floor(Math.random() * icebreakers.length);
const icebreakerSentence = icebreakers[randomIndex];


class TodayAdd extends Component {
	static contextType = AuthUserContext;
	_isMounted = false;

	constructor(props) {
		super(props);

        //// TIMEZONE MODULE IF ERROR UTC
        var userTimezone = GetTimeZone(this.props.firebase, this.props.user);
        //console.log('timezone receivecd', userTimezone)
        const today = new Date();
        var hours = today.getHours();
        if(userTimezone !== 'UTC'){
            hours = new Date(today.toLocaleString('en-US', { timeZone: userTimezone})).getHours();
        }

		this.state = {
			open: false,
			text: "Default Message",
			title: "Rocky.AI",
			insights: [],
			hours,
			todaysLeadershipQuality: "",
			fontSize: 30,
			scope: 'todays',
			primingStatus: 'START',
			reflectionStatus: 'START',
		};
	} 

	componentDidMount() {
		this._isMounted = true;
		const dialogueStatus = SetAgentStatus(this.props.user);
		//console.log('dialogueStatus', dialogueStatus)
		this.setState(dialogueStatus);
		if(this.props.chatPreview === true){
			//this.loadLatestMessage();
		}
	}

	componentWillUnmount(){
		this._isMounted = false;
	}

	loadLatestMessage(){
		if(this.props.user?.uid?.length > 2){
			//console.log('last message checl', this.props.user)
			this.props.firebase.messages()
			.where('userId', '==', this.props.user.uid)
			.where('messageFilter', '==', 'bot')
			.orderBy('createdAt', 'desc')
			.limit(1)
			.get().then(snapshot => {
				snapshot.forEach(doc =>{ 
					var message = doc.data();
					console.log('last message', message.text, message.text[message.text.length -1])
					if(message.text?.length > 0){
						this.setState({latestText: message.text[message.text.length -1]})
					}
				})
			})
		}
	}

	handleStartRoutine() {
		this.props.history.push(ROUTES.ROUTINE);
	}
	
	handleOpen = (title, text) => {
		this.setState({
			open: true,
			text,
			title,
		});
	}

	render() {
		const { classes, theme } = this.props;
		const { hours } = this.state;
		if(this.props.actionCardOnly){
			return(
				this.renderActionCard()
			)
		}
		else{
			return (
				<div style={{paddingLeft: 20, paddingRight: 20}}>
					<div style={{position: 'relative'}}>
						<h4 style={theme.CardContent.titleText}>
							{false && (this.context.dialogueCounter === undefined || this.context.dialogueCounter <= 4) &&
								<>Day ① -&nbsp;</>
							}
							{this.props.defaultSet?.programAvatarName?.length > 2
								? 	this.props.defaultSet?.programAvatarName 
								: 	'AI Coach'
								//:	(hours>21 ? 'Reflective Chat' : hours > 18 ? 'Reflective Chat' : hours > 10 ? 'Mindset Practice' : hours > 7 ? 'Mindset Practice' : 'Reflective Chat' )
							}
						</h4>
						{this.props.tutorialOpen 
						&&
							<div style={{position: 'absolute', top: -4, right: -6}}>
								<IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('chat')}}>
									<HelpOutlineIcon />
								</IconButton>
							</div>
						}
					</div>
					{(true || this.state.primingStatus === 'START' || this.state.reflectionStatus === 'START' || this.state.primingStatus === 'DONE' || this.state.reflectionStatus === 'DONE')
						?	this.renderActionChatCard()
						:	this.renderActionCard()
					}
					{(true || this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
					?
						<p style={theme.textSupportCenter}> 
							5-minute AI chat &bull; Reflective self-coachinig
						</p>
					:	(this.props.user.dialogueCounter === undefined || this.props.user.dialogueCounter < 5)
						?
							<p style={theme.textSupportCenter}>
								Daily objectives &bull; Artificial Intelligence powered 
							</p>
						:
							<p style={theme.textSupportCenter}> 
								5-minute AI chat &bull; Reflective self-coachinig
							</p>
					}
				</div>
			);
		}
	}

	renderActionChatCard(){
		const { classes, theme } = this.props;
		//console.log('renderActionChatCard', this.state)
		const bgCardColor = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'backgroundColor') || theme.palette.secondary.main;
		return(
			<div>
				<LeadershipQualityPicker
					defaultSet={this.props.defaultSet || undefined}
					scope={'current'}
					user={this.context}
					openChooseTopic1={this.state.openLeadershipQualityPicker || false}
					openChooseTopic2={this.state.openLeadershipQualityPicker2 || false}
					leadershipCategories={this.props.leadershipCategories}
					leadershipQualities={this.props.leadershipQualities}
					handler={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
					handlerOpen2={(entry) =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: true })}}
					handlerClose2={() =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: false })}}
					handlerCloseAll={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
					closeDialog={() => {}}
				/>
				<div style={{marginBottom: 20}}>
					<Card className={classes.cardBot}>
						<CardContent className={classes.cardPadding}>
							<span className={classes.bot}>
								{this.state.latestText?.length > 3 ? this.state.latestText : icebreakerSentence}
							</span>
						</CardContent>
					</Card>
				</div>
				{(!(window?.innerHeight > 800) || this.state.latestText?.length > 3)
				&&
					<div style={{marginBottom: 20}}>
						{this.renderTextField()}
					</div>
				}
				<div style={{textAlign: 'right', marginBottom: this.props.screen === 'desktop' ? 60 : 40}}>
					<Button 
						style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
						color="secondary"
						//variant="contained"
						variant="outlined"
						onClick = {() => {
							if(true){
								this.props.history.push({
									pathname: ROUTES.ROUTINE,
									state: {
										openCurrentAchievementGoal: false, 
										guidedChat: false,
										startNewChat: true,
										openChat: true,
										startText:( this.state.comment?.length >= 2 ? this.state.comment : undefined)
									}
								})
							}
							else{
								SetAgentDialogue(this.context, this.props.firebase, true)
								.then((agentStatus) => {
									this.props.history.push({
										pathname: ROUTES.ROUTINE,
										state: {
											openCurrentAchievementGoal: false, 
											guidedChat: false,
											startNewChat: true,
											openChat: true,
											startText:( this.state.comment?.length >= 2 ? this.state.comment : undefined)
										}
									})
								})  
							}
							ExtendDialogueMessage(this.props.firebase, this.context, 'I need a mentor', 'What do you need right now?', 'utter_ask_help', '', 'profile');
						}}
					>
						I need Answers
						&nbsp;
						<WidgetsIcon fontSize='small'/>
					</Button>
					<Button 
						style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
						color="secondary"
						//variant="contained"
						variant="outlined"
						onClick = {() => {
							if(true){
								this.props.history.push({
									pathname: ROUTES.ROUTINE,
									state: {
										openCurrentAchievementGoal: false, 
										guidedChat: true,
										startNewChat: true,
										smallTalk: true
									}
								})
							}
							else{
								SetAgentDialogue(this.context, this.props.firebase, true)
									.then((agentStatus) => {
										this.props.history.push({
											pathname: ROUTES.ROUTINE,
											state: {
												openCurrentAchievementGoal: false, 
												guidedChat: true,
												startNewChat: true,
												smallTalk: true
											}
										})
									})  
							}
							ExtendDialogueMessage(this.props.firebase, this.context, 'I want some small talk', 'What do you need right now?', 'utter_ask_help', '', 'profile');
						}}
					>
						Small Talk
						&nbsp;
						{this.props.defaultSet?.programLogo?.includes('http')
							?   <ForumIcon fontSize="small" />
							:   <RockyIcon fontSize="small" />
						}
					</Button>
					{this.props.leadershipCategories?.length>0
					&&
						<DialogQuickTopicSetting
							buttonsOnly={true}
							openShop={true}
							defaultSet={this.props.defaultSet || undefined}
							openCurrentAchievementGoal = {this.state.openCurrentAchievementGoal || false}
							handleClose = {() => this.setState({openCurrentAchievementGoal: false})}
							handleOpen = {
								() => {
									this.setState({openLeadershipQualityPicker: true});
									ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my topic', 'Is there a topic that you would like to choose from today?', 'utter_ask_help', '', 'profile');
								}
							}
							handleSubmitCurrentAchievementGoal = { (flag = true, quality = "") => {
								SetAgentDialogue(this.context, this.props.firebase, true)
								.then((agentStatus) => {
									//console.log('status returned', agentStatus, quality, flag)
									if(flag === false){
										this.props.history.push({
											pathname: ROUTES.ROUTINE,
											state: {
												openCurrentAchievementGoal: false, 
												guidedChat: true,
											}
										})
									}
									else if(false && quality?.length > 0 && agentStatus !== undefined && agentStatus?.currentDialogueId?.length > 0){
										var dialogueIdAvailable = agentStatus.currentDialogueId;
										this.props.firebase.messages().add({
											userId: this.context.uid,
											isCoach: false,
											createdAt: this.props.firebase.fieldValue.serverTimestamp(),
											dialogueId: dialogueIdAvailable,
											text: [quality],
											messageFilter: 'bot',
											timezone: (this.context.timezone || 'UTC')
										})
										.then(() => {
											this.props.history.push({
												pathname: ROUTES.ROUTINE,
												state: {
													openCurrentAchievementGoal: false, 
													guidedChat: true,
													startText: quality,
												}
											})
										});
									}
									else{
										this.props.history.push({
											pathname: ROUTES.ROUTINE,
											state: {
												openCurrentAchievementGoal: false, 
												guidedChat: true,
												startNewChat: true, //((this.state.reflectionStatus === 'START' || this.state.reflectionStatus === undefined) ? true : false),
											}
										})
									}
								})
							}}
							unselectTopics={this.state.unselectTopics || false}
							user={this.context}
							//renderPreview={true}
							leadershipCategories={this.props.leadershipCategories}
							leadershipQualities={this.props.leadershipQualities} 
						/>
					}
					{(this.state.primingStatus === 'CONTINUE' || this.state.reflectionStatus === 'CONTINUE' )
					&&	
						<Button 
							style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
							onClick = {() => {   
								this.props.history.push(ROUTES.ROUTINE);
							}}
						>
							Continue previous chat
							&nbsp;
							{this.props.defaultSet?.programAvatarName?.length > 2
								?   <ForumIcon fontSize="small" />
								:   <RockyIcon fontSize="small" />
							}
						</Button>
					}
					{((this.state.primingInTimeRange && this.state.primingStatus === 'DONE') || (this.state.reflectionInTimeRange && this.state.reflectionStatus === 'DONE'))
					&&	
						<Button 
							style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
							onClick = {() => {   
								this.props.history.push(ROUTES.ROUTINE);
							}}
						>
							Check previous chat
							&nbsp;
							{this.props.defaultSet?.programAvatarName?.length > 2
								?   <ForumIcon fontSize="small" />
								:   <RockyIcon fontSize="small" />
							}	
						</Button>
					}
				</div>
				{(window?.innerHeight > 800) && !(this.state.latestText?.length > 3)
				&&
					<div>
						{this.renderTextField()}
					</div>
				}
			</div>
		)
	}

	renderTextField(){
		const {theme} = this.props;
		return(
			<TextField
				name="comment"
				style={ {fontSize: '16px'} }
				value={this.state.comment || ""} 
				onChange={(e) => {this.setState({ comment: e.target.value})} }
				type="text"
				variant="outlined"
				placeholder="Start typing ..."
				fullWidth
				multiline
				InputProps={{
					style:{fontSize: '16px', backgroundColor: theme.palette.white},
					endAdornment: (
						<InputAdornment position="end">
							{this.state.comment?.length > 0
								?
									<IconButton
										edge="end"
										aria-label="Send message"
										onClick={ () => {
											SetAgentDialogue(this.context, this.props.firebase, true)
											.then((agentStatus) => {
												//console.log('status returned', agentStatus)
												if(agentStatus !== undefined && agentStatus?.currentDialogueId?.length > 0){
													var dialogueIdAvailable = agentStatus.currentDialogueId;
													this.props.firebase.messages().add({
														userId: this.context.uid,
														isCoach: false,
														createdAt: this.props.firebase.fieldValue.serverTimestamp(),
														dialogueId: dialogueIdAvailable,
														text: [this.state.comment],
														messageFilter: 'bot',
														timezone: (this.context.timezone || 'UTC')
													})
													.then(() => {
														this.props.history.push({
															pathname: ROUTES.ROUTINE,
															state: {
																openCurrentAchievementGoal: false, 
																guidedChat: true,
																startNewChat: true,
																openChat: true,
																startText: this.state.comment,
															}
														})
													});
												}
												else{
													this.props.history.push({
														pathname: ROUTES.ROUTINE,
														state: {
															openCurrentAchievementGoal: false, 
															guidedChat: true
														}
													})
												}
											})
										}}
									>
										{ this.state.comment.length >  10 
											? <SendIcon style={{color: theme.palette.themeRed}} />
											: <SendIcon  />
										}
									</IconButton>
								:            
									<IconButton
										edge="end"
										aria-label="Write message"
										disabled={true}
										>
										<SendIcon />
									</IconButton>
							}
						</InputAdornment>
					),
					startAdornment: (
						<InputAdornment position="start">
							<SpeechToTextButton 
								renderKey={'today_add_'}
								returnText={(result) => this.setState({comment : result})}
								existingText={this.state.comment || ""}
							/>
						</InputAdornment>
					),
				}}
			/>
		)
	}

	renderActionBadgeCard(){
		const { classes, theme } = this.props;
		const { hours } = this.state;
		return(
			<BadgeCard
				backgroundColor = {'white'} 
				image={logo}
				//title = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory)}
				//subtitle = {FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.user.currentCategory, 'explanationShort')}
				//strongSubtitle = {false}
				title={
					<div style={{ margin: 30}}>
							{(() => {
							switch (this.state.reflectionStatus || 'START') {
							case 'START':
								return(
									<div style={theme.buttonCenter}> 
										<Button>
											Start Reflection &nbsp;
											{this.props.defaultSet?.programAvatarName?.length > 2
												?   <ForumIcon fontSize="large" />
												:   <RockyIcon fontSize="large" />
											}	
										</Button>
									</div>
								)
							case 'DONE':
								return(
									<div style={theme.buttonCenter}> 
										<Button>
											Continue Chat &nbsp;
											{this.props.defaultSet?.programAvatarName?.length > 2
												?   <ForumIcon fontSize="large" />
												:   <RockyIcon fontSize="large" />
											}	
										</Button>
									</div>
								)
							case 'CONTINUE':
								return(
									<div style={theme.buttonCenter}> 
										<Button >
											Finish AI Chat &nbsp;
											{this.props.defaultSet?.programAvatarName?.length > 2
												?   <ForumIcon fontSize="large" />
												:   <RockyIcon fontSize="large" />
											}	
										</Button>
									</div>
								)
							default:
								return(
									<div style={theme.buttonCenter}> 
										<Button>
											Reflective Chat &nbsp;
											{this.props.defaultSet?.programAvatarName?.length > 2
												?   <ForumIcon fontSize="large" />
												:   <RockyIcon fontSize="large" />
											}	
										</Button>
									</div>
								)
							} 
							})()}
						</div>

				}
				onClick={ this.props.handlerContinue !== undefined
					? 	() => this.props.handlerContinue()
					:	() => this.props.history.push(ROUTES.ROUTINE) 
				}
			/>
		)
	}

	renderActionCard(){
		const { classes, theme } = this.props;
		const { hours } = this.state;
		const bgCardColor = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'backgroundColor') || theme.palette.secondary.main;
		return(
			<Card 
				style={
					this.props.screen === "desktop"
					?	this.props.defaultSet?.programLogo?.length > 2
						?
							{
								...theme.CardGoals,
								backgroundImage: `url(${this.props.defaultSet.programLogo}), linear-gradient(289deg, ${bgCardColor}AB 0%, ${bgCardColor} 100%)`,
								backgroundPosition: "6%",
								backgroundSize: "auto 100%",
								backgroundRepeat: "no-repeat",
								position: 'relative'
							}
						:
							{
								...theme.CardGoals,
								backgroundImage: `url(${logo}), linear-gradient(289deg, ${bgCardColor}AB 0%, ${bgCardColor} 100%)`,
								//backgroundImage: `url(${logo})`,
								backgroundPosition: "6%",
								//backgroundColor: ((FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'backgroundColor') || theme.palette.secondary.main)+"AB"),
								//backgroundColor: theme.palette.secondary.main+"50",
								backgroundSize: "auto 100%",
								backgroundRepeat: "no-repeat",
								position: 'relative'
							}
					:
						true
						?
							{
								...theme.CardGoals,
								position: 'relative',
								backgroundColor: ((FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.context.currentCategory, 'backgroundColor') || theme.palette.secondary.main)+"AB"),
								//backgroundRepeat: "no-repeat",
								//backgroundColor: theme.palette.secondary.main+"50",
							}
						:
							{
								...theme.CardGoals,
								backgroundImage: `url(${
									hours>21 ? night : hours > 16 ? evening : hours > 10 ? afternoon : hours > 7 ? morning : night 
								})`,
								backgroundPosition: "center",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								position: 'relative'
							}
				}
				onClick={ this.props.handlerContinue !== undefined
					? 	() => this.props.handlerContinue()
					:	() => this.props.history.push(ROUTES.ROUTINE) 
				}
				raised={((this.state.primingInTimeRange && this.state.primingStatus !== 'DONE') || (this.state.reflectionInTimeRange && this.state.reflectionStatus !== 'DONE'))
					?	true
					: 	false
				}
				>
				{this.state.primingStatus !== 'PASSED' && this.state.primingStatus !== 'TIME' && this.state.primingInTimeRange
				?
					<div style={{ width: '100%', marginTop: 30, marginBottom: 30}}>
						{(() => {
						switch (this.state.primingStatus || 'START') {
						case 'START':
							return (
								<div style={theme.buttonCenter}> 
									<Button>
										Start Coaching Chat &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}										
									</Button>
								</div>
							)
						case 'DONE':
							return (
								<div style={theme.buttonCenter}> 
									<Button>
										Continue Chat &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}	
									</Button>
								</div>
							)
						case 'CONTINUE':
							return (
								<div style={theme.buttonCenter}> 
									<Button>
										Finish Coaching Chat &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}	
									</Button>
								</div>
							)
						default:
							return (
								<div style={theme.buttonCenter}> 
									<Button >
										Ask Questions &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}
									</Button>
								</div>
							)
						} 
						})()}
					</div>
				: 
					<div></div> 
				}
				{this.state.reflectionStatus !== 'PASSED' && this.state.reflectionStatus !== 'TIME'  && this.state.reflectionInTimeRange
				?
					<div style={{ margin: 30}}>
						{(() => {
						switch (this.state.reflectionStatus) {
						case 'START':
							return(
								<div style={theme.buttonCenter}> 
									<Button>
										Start Reflection &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}	
									</Button>
								</div>
							)
						case 'DONE':
							return(
								<div style={theme.buttonCenter}> 
									<Button>
										Continue Chat &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}
									</Button>
								</div>
							)
						case 'CONTINUE':
							return(
								<div style={theme.buttonCenter}> 
									<Button >
										Finish AI Chat &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}
									</Button>
								</div>
							)
						default:
							return(
								<div style={theme.buttonCenter}> 
									<Button>
										Ask Questions &nbsp;
										{this.props.defaultSet?.programAvatarName?.length > 2
											?   <ForumIcon fontSize="large" />
											:   <RockyIcon fontSize="large" />
										}
									</Button>
								</div>
							)
						} 
						})()}
					</div>
				: 
					<div></div> 
				}
				<div style={{
						position: 'absolute',
						top:4,
						right:10,
						height:0,
						textAlign: 'right',
						zIndex: 2,
						width: '100%',
						color: theme.palette.secondary.main
					}}
				>
					{'Start'}
				</div>
			</Card>
		)
	}
}

export default compose(
	withFirebase,
	withRouter,
	withStyles(styles, { withTheme: true})
)(TodayAdd);
