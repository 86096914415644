import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
//import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
//import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import EmojiEmotionsTwoToneIcon from '@material-ui/icons/EmojiEmotionsTwoTone';
import SentimentVeryDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentVeryDissatisfiedTwoTone';
import ChatBubbleTwoToneIcon from '@material-ui/icons/ChatBubbleTwoTone';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
});


class SettingContentSelect extends Component {
  static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: '',
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
            flagAddNew : false
        });
    }

    handleSettingChange = (param, event) => {
        //console.log('select',event.target.value)
        if(event.target.value === "ADD NEW"){
            this.setState({
                flagAddNew : true,
                [param]: "ADD NEW",
            });
        }
        else{
            this.setState({
                [param]: event.target.value,
                flagAddNew: false
            });
        }
    }

    handleSettingChangeSave = (param, value, event) => {
        event.stopPropagation();
        if(value !== undefined && param !== undefined){
            this.setState({
                [param]: value, 
                flagAddNew: false
            }, () => {
                //console.log('select', param, value)
                this.handleSettingSubmit();
            });
        }
    }

    handleOptionChange= (param, event) => {
        //console.log('select',event.target.value)
        this.setState({
            [param+'_new']: event.target.value
        });
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        //console.log('new value',this.props.params, this.state)
        this.props.params.map((p) => {
            if(this.state[p] !== null && (this.state[p]!=='' || (this.state[p]==='' && this.props.content[p] !== undefined)) && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                var newValue = this.state[p];
                //console.log('add value',this.state[p])
                if(newValue === "ADD NEW"){
                    newValue = this.state[p+'_new'] || "";
                    if ((newValue) === ""){
                        newValue = "";
                    }
                }
                else if(newValue === ""){
                    newValue = this.props.firebase.fieldValue.delete()
                }
                if(newValue !== ""){
                    var updateSet = { [p]: newValue };
                    if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                        Object.assign(updateSet, this.props.updateExtraChange)
                    }
                    if(this.props.updateState !== undefined){
                        this.props.updateState(updateSet);
                    }
                    if( this.props.dbUpdateLink !== undefined){
                        this.props.dbUpdateLink.update(updateSet);
                    }                    
                    //console.log('new value',this.state[p])
                }
            }
            return '';
        })
        this.handleSettingClose();
    }

    render() {
        const { content, params, title, labels, selectOptions, selectOptionsText, emptyOption } = this.props;
        const { theme, classes } = this.props;
        //console.log('text', selectOptionsText)
        return (
        content
            ?
                <div className={classes.grow}>
                    <Card style={theme.cardAdminSettings} onClick={this.handleSettingOpen}>
                        <Grid container>
                            <Grid item xs={9} >
                            <h6><a to="#">{title}</a>
                            {this.props.mandatory 
                                &&  <strong style={{color:theme.palette.themeRed}}>&nbsp;*</strong>
                            }
                            {this.props.mandatory===false
                                &&  <span style={{fontSize: 'small', fontWeight:'normal'}}>&nbsp;(optional)</span>
                            }
                            </h6>
                          </Grid>
                            <Grid item xs={1}>  </Grid>
                            <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                                {(this.props.access === undefined || this.props.access === true) && this.props.showButtons !== true
                                &&
                                    <Tooltip title={'Edit/Change'}>
                                        <IconButton onClick={this.handleSettingOpen}> 
                                            <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                        {this.props.showButtons && this.props.addNewOption !== true && selectOptions?.length > 0 
                        ?   ""
                        :   params.map((p, index) => (
                                <p style={theme.textSettings} key={"current_"+p}>
                                    {content[p]!== undefined
                                        ?   <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>
                                            {selectOptions!== undefined && selectOptionsText !== undefined && selectOptions.indexOf(content[p]) >= 0
                                                ?   selectOptionsText[ selectOptions.indexOf(content[p]) ]
                                                :   content[p]
                                            }
                                            </span>
                                        :   <span style={{ float: 'right'}}>{this.props.emptyStage || "[n/a]"}</span>
                                    }
                                    {this.props.noLabel!==true &&
                                        <span>{labels!==undefined? labels[index]: p}</span>
                                    }
                                </p>
                            ))
                        }
                        {this.props.showButtons && this.props.addNewOption !== true && selectOptions?.length > 0 && selectOptions?.length < 5 
                        &&
                            <>
                                {params.map((p, index) => (
                                    <div style={{textAlign: 'right'}} key={"current_"+p}>
                                        <ToggleButtonGroup 
                                            size="small" 
                                            key={"current_"+p}
                                            exclusive={true}
                                            onChange={(e, value) => {
                                                (this.props.access === undefined || this.props.access === true )
                                                ?   this.handleSettingChangeSave(p, value, e)
                                                :   this.setState({errorMessage: "Please check if you have the complete information provided or if you have full access rights"});
                                            }}
                                        >
                                            {selectOptions.map((s, indexS) => (
                                                <ToggleButton
                                                    value={s}
                                                    key={"button_"+s}
                                                    selected={content[p]===s}
                                                >
                                                    {(s===undefined || s==="" || s==="default")
                                                        ?   <RadioButtonUncheckedIcon style={{color: (content[p]===undefined || content[p]==='default') ? "blue" : "grey"}}/>
                                                        :   (s==="active")
                                                            ?   <CheckCircleIcon style={{color: content[p]===s ? "blue" : "grey"}}/>
                                                            :   (s==="inactive")
                                                                ?   <NotInterestedIcon />
                                                                :  (s==="positive")
                                                                    ?   <EmojiEmotionsTwoToneIcon style={{color: content[p]===s ? "green" : "grey"}}/>
                                                                    :   (s==="negative")
                                                                        ?   <SentimentVeryDissatisfiedTwoToneIcon style={{color: content[p]===s ? "red" : "grey"}}/>
                                                                        :   <ChatBubbleTwoToneIcon />
                                                    }
                                                    &nbsp;{(this.props.selectOptionsText?.[indexS] || s)}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </div>
                                ))}

                                {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                    ?
                                        <p style={{color : 'red'}}>
                                            {this.state.errorMessage}
                                        </p>
                                    :   ""
                                }
                            </>
                        }
                    </Card>
                    <Dialog
                        open={this.state.openSetting || false}
                        onClose={this.handleSettingClose}
                        fullWidth
                        maxWidth="sm">
                        <DialogTitle>Change Setting</DialogTitle>
                        <form>
                        <DialogContent>
                            {
                                params.map((p, index) => (
                                    <div style={{width: `100%`}} key={"change_"+p}>
                                    <strong>{labels!==undefined? labels[index]: p}</strong>
                                    <span style={{color:'darkgrey'}}>&nbsp;-&nbsp;
                                        {selectOptions!== undefined && selectOptionsText !== undefined && selectOptions.indexOf(content[p]) >= 0
                                            ?   selectOptionsText[ selectOptions.indexOf(content[p]) ]
                                            :   content[p]
                                        }
                                    </span>                                        
                                    { 
                                    this.state[p] !== undefined && 
                                    <>
                                        <Select
                                            style={{width:'100%', textAlign: "right"}}
                                            value={this.state[p]}
                                            onChange={(e) => this.handleSettingChange(p, e)}
                                            name={p}
                                        >
                                        {selectOptions!== undefined && selectOptions?.map((entry,index) => {
                                            return (
                                                <MenuItem key={"select_"+entry} value={entry}
                                                    selected={entry === this.state[p] && this.state.flagAddNew!==true}
                                                >
                                                    { selectOptionsText !== undefined && selectOptionsText[index] !== undefined && selectOptionsText[index] !== ""
                                                        ? selectOptionsText[index]
                                                        : entry
                                                    }
                                                </MenuItem>
                                                );
                                            })//end map
                                        }
                                        {!(selectOptions?.includes(content[p])) && content[p]!== undefined &&
                                            <MenuItem key={"select_plys_"+content[p] } value={content[p] }
                                                selected={content[p] === this.state[p] && this.state.flagAddNew!==true} 
                                            >
                                                {content[p] + " [NO ACCESS]"}
                                            </MenuItem>
                                        }
                                        {emptyOption &&
                                            <MenuItem key={"select_0_"+p} value={''}  >
                                                {'---REMOVE---'}
                                            </MenuItem>
                                        }
                                        {this.props.addNewOption === true 
                                            &&
                                            <MenuItem key={"select_new_"+p} value={'ADD NEW'} 
                                                selected={this.state.flagAddNew===true} 
                                            >
                                                {'---ADD NEW---'}
                                            </MenuItem>
                                        }
                                        </Select>
                                        {this.props.addNewOption === true && this.state.flagAddNew
                                            &&
                                            <div key={"org_"+index}> 
                                                Add new:
                                                <TextField
                                                    style={{ float: 'right', width: this.props.noLabel?'90%':'60%', fontSize: '16px'}}
                                                    fullWidth
                                                    key={"change_"+index}
                                                    id={"change_"+index}
                                                    value={this.state[p+'_new'] || ''}
                                                    label={((this.props.max > 0 ? (" (" + (this.state[p+'_new']?.length || 0)+ "/"+ this.props.max+ " character)") : ""))}
                                                    onChange={(e) => this.handleOptionChange(p, e)}
                                                />
                                            </div>                                            
                                        }
                                    </>
                                    }
                                    </div>
                                ))
                            }
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                    <p style={{color : 'red'}}>
                                        {this.state.errorMessage}
                                    </p>
                                :   <p> </p>
                            }
                        </DialogContent>
                        </form>
                        <DialogActions>
                            <Button onClick={this.handleSettingClose}>
                                Cancel
                            </Button>
                            <Button onClick={this.handleSettingSubmit}
                                //disabled={this.state.flagAddNew===true && this.state.}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            :   <div> </div>
                
        );
    }
}

SettingContentSelect.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentSelect);
