import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { Link } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import SnackbarInfo from '../Modules/SnackbarInfo';
import MessagesUser from '../Support/MessagesUser';
import HomeIcon from '@material-ui/icons/Home';
import LoadingProgress from '../Modules/LoadingProgress';

///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

//import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GroupIcon from '@material-ui/icons/Group';

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import { AccessUsersPanel } from '../../constants/roles';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

//import Grid from '@material-ui/core/Grid';
//import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';



const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    highlightTab: {
        position: theme.highlightTab.position,
        left:  IsMobileDevice()? theme.HomeNoNavigationShiftContent.paddingLeft : theme.HomeNavigationShiftContent.paddingLeft ,
        top: IsMobileDevice()? theme.highlightTab.top : 60,
        backgroundColor:theme.highlightTab.backgroundColor, 
        zIndex:theme.highlightTab.zIndex,
    },
    unHighlightTab: {
        position: "flexible",
        //backgroundColor:theme.highlightTab.backgroundColor, 
        backgroundColor: "transparent", 
    },
});



class HomeSupport extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        var startTab = "Latest";
        this.state = {
            loading: true,
            availableGroups: ['default'],
            value: startTab,
            days: [],
            user: null,
            headerVisible: true,
        };
    }
    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                //document.body.scrollTop = document.documentElement.scrollTop = 0;
                if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                //if(this.dialogRef !== undefined && this.dialogRef.scrollTop !== undefined ) this.dialogRef.scrollTop  = 0;
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
            });
            //console.log(this.state)
        }
    }
    
    componentDidMount() {
        this._isMounted = true;
        this.loadGroups();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadGroups() {
        if(this.context.roles !== undefined && 
            (this.context.roles?.includes('REMOTECOACH')
                || this.context.roles?.includes('ADMIN')
                || this.context.roles?.includes('TRAM')
                || this.context.roles?.includes('COACHADMIN'))
            ){
            this.props.firebase.groups()
            //.where('code', 'in', this.context.groups)
            .where('owner', 'array-contains', this.context.uid)
            .get()
            .then((data) => {
                var availableGroups = [];
                var groupOwners = {};
                var startTab = 'Latest';
                data.forEach(myGroupsDoc => {
                    availableGroups.push(myGroupsDoc.id);
                    //startTab = myGroupsDoc.id;
                    groupOwners[myGroupsDoc.id] = myGroupsDoc.data().owner;
                })
                availableGroups.push("default")
                //console.log('admin', availableGroups, groupOwners, startTab)
                if(this._isMounted){
                    this.setState({availableGroups, groupOwners, loading: false, value: startTab})
                }
            })
        }
        else{
            var availableGroups = [];
            var startTab = 'Latest';
            var groupOwners = {};
            if(this.context.groups !== undefined && this.context.groups.length>0){
                this.props.firebase.groups()
                .where('code', 'in', this.context.groups)
                .get()
                .then((data) => {
                    data.forEach(myGroupsDoc => {
                        availableGroups.push(myGroupsDoc.id);
                        startTab = myGroupsDoc.id;
                        groupOwners[myGroupsDoc.id] = myGroupsDoc.data().owner;
                    })
                    if(this._isMounted){
                        this.setState({availableGroups, groupOwners, loading: false, value: startTab})
                    }
                })
            }
            else{
                if(this._isMounted){
                    this.setState({ loading: false})
                }
            }
        }
    }
    

    render() {
        const { theme } = this.props;
        const { loading, value, availableGroups } = this.state;
        const { user, screen } = this.props;
        let today = new Date(Date.now());
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        if(user !== null ){
            //console.log("new user", user)
            return(
                <div id='pageroot'>
                    <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef}>
                        <Grid item xs={screen==="desktop" ? 2 : false} >
                            {AccessUsersPanel(this.context)
                                &&
                                    <Card style={theme.CardDesktopLeft}>
                                        <Card style={theme.CardNaviLeft}>
                                            <h4 style={theme.CardContent.titleText}>Support</h4>
                                            {this.renderTabs("vertical")}
                                        </Card>
                                    </Card>
                            }
                        </Grid>
                        <Grid item xs={screen==="desktop" ? AccessUsersPanel(this.context) ? 10 : 8 : 12} >
                            {loading
                                ?
                                    <Card style={theme.CardContentItem.rootFeed}>
                                        <LoadingProgress 
                                            defaultSet={this.props.defaultSet}
                                            type={'LoadingPageAnimated'} loadingText={"loading messages"} /> 
                                    </Card>
                                :
                                    <Card style={screen==="desktop"? theme.CardDesktopMain : theme.CardAppMain}>
                                        <VizSensor 
                                            partialVisibility={"top"}
                                            onChange={(isVisible) => { 
                                                if(screen!=="desktop" && isVisible && this._isMounted) { 
                                                    this.setState({headerVisible: true});
                                                    //console.log('visible top cart')
                                                }
                                                else if (screen!=="desktop" && this._isMounted){
                                                    this.setState({headerVisible: false});
                                                    //console.log('hding top cart')
                                                }
                                            }}>
                                            <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                        </VizSensor>
                                        <Card style={{
                                                ...(this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop), 
                                                position: this.state.headerVisible? 'relative' : 'fixed',
                                                zIndex: this.state.headerVisible? 0 : 100,
                                                top: 0,
                                                opacity: this.state.headerVisible? '1.0' : '0.94',
                                            }}>
                                            {this.state.headerVisible
                                                ?   <h2 style={theme.CardContent.titleText}><GroupIcon style={theme.inlineIconH2}/>&nbsp;Support</h2> 
                                                :   <div style={theme.textMonthCenter}><strong>{value}</strong></div>
                                            }
                                            {(!this.state.headerVisible)
                                                &&
                                                    <div style={{position: 'fixed', top: 3, right: 8}}>
                                                        <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                            <HelpOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                            }
                                            {  this.renderTabs("horizontal") }
                                        </Card>
                                        {
                                        /* 
                                        screen!=="desktop"
                                            ?
                                                <Card style={{
                                                        ...theme.CardTop, 
                                                        position: this.state.headerVisible? 'relative' : 'fixed',
                                                        zIndex: this.state.headerVisible? 0 : 100,
                                                        top: 0,
                                                        opacity: this.state.headerVisible? '1.0' : '0.94',
                                                    }}>
                                                    <p  hidden={ !this.state.headerVisible} style={theme.textTodayDate}>{weekdays[today.getDay()] + ', ' + months[today.getMonth()] + ' ' + today.getDate()}</p>
                                                    {this.state.headerVisible
                                                        ? <h2 style={theme.CardContent.titleText}><GroupIcon />&nbsp; Support</h2>
                                                        : <div style={theme.textMonthCenter}><strong>Support</strong></div>
                                                    }
                                                    {user !== null && availableGroups!== undefined && availableGroups.length > 0
                                                    &&
                                                        this.renderTabs()   
                                                    }
                                                    
                                                </Card>
                                            :
                                                <h2 style={theme.CardContent.titleText}><GroupIcon />&nbsp; Support - {value}</h2>
                                                */
                                        }
                                        
                                        <Card style={{
                                                ...theme.CardContentItem.CardContentSpace,
                                                paddingTop: this.state.headerVisible? 20 : 200,
                                                paddingBottom: this.state.headerVisible? 20 : '70vh',
                                            }} >
                                            
                                            {user !== null && availableGroups!== undefined && availableGroups.length > 0
                                            ?
                                                <div>
                                                    {
                                                        <div key={'Latest'} index={0} hidden={value !== 'Latest'}>
                                                            Latest
                                                            <MessagesUser 
                                                                groupId={'Latest'}
                                                                isCoach={
                                                                    value==='default' 
                                                                        ?   (this.context.roles?.includes("ADMIN")) ? true : false 
                                                                        :   (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("REMOTECOACH") || this.context.roles?.includes("COACHADMIN")) ? true : false
                                                                }
                                                                //groupOwners={['Latest']}
                                                            />  
                                                        </div>
                                                    }
                                                    {availableGroups.map((id, index) => (
                                                        <div key={id} index={0} hidden={value !== id}>
                                                            {(value===id || this.state[id]) && 
                                                                <MessagesUser 
                                                                    groupId={id}
                                                                    isCoach={
                                                                        value==='default' 
                                                                            ?   (this.context.roles?.includes("ADMIN")) ? true : false 
                                                                            :   (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("REMOTECOACH") || this.context.roles?.includes("COACHADMIN")) ? true : false
                                                                    }
                                                                    groupOwners={this.state.groupOwners?.[id] || undefined}
                                                                />    
                                                            }
                                                        </div>
                                                    ))}
                                                    
                                                    <SnackbarInfo 
                                                        alertText = 'You can communicate here with your assigned coach or with the Technical Support.'
                                                        alertName = 'supportintro'
                                                        userId = {this.context.uid}
                                                        />
                                                </div>
                                            :
                                                <div>
                                                    <p>You can communicate here with your assigned coach or with the Technical Support.</p>
                                                </div>
                                            }
                                        </Card>
                                    </Card> 
                            }
                        </Grid>
                        <Grid item xs={screen==="desktop" && !AccessUsersPanel(this.context) ? 2 : false} ></Grid>
                    </Grid>
                </div>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent} ref={this.dialogRef}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )// end 2nd return
        } // end else
    }// end render

    renderTabs(orientation = "horizontal") {
        const { value, availableGroups  } = this.state;
        const { user, theme, screen} = this.props;
        var messageTotalCounter = 0;
        if(this.context.newMessages!== undefined){
            try{ messageTotalCounter = this.context.newMessages.filter(q => q.type === "SUPPORT").length;}
            catch (error) {messageTotalCounter = 0}
        }
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/>Home</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.SUPPORT} onClick={() => (this.handleChange(undefined, "Latest"))}>
                        <span>
                            <GroupIcon style={theme.inlineIconLeft}/>
                            <Badge badgeContent={ messageTotalCounter }
                                color="error">
                                Latest Messages
                            </Badge>
                        </span>
                    </Link>
                    <hr/>
                    {availableGroups?.includes("default")
                        &&
                        <>
                            <Link to={ROUTES.SUPPORT} onClick={() => (this.handleChange(undefined, 'default'))} 
                                value={'default'} key={'default'}>
                                <span>
                                    <HeadsetMicIcon style={theme.inlineIconLeft}/>
                                    <Badge badgeContent={ this.context.newMessages?.filter(q => q.groupId === 'default')?.length || 0 }
                                        color="error"> Support Request
                                    </Badge>
                                </span>
                            </Link>
                            <hr/>
                        </>
                    }
                    {availableGroups.map((id, index) => {
                        var messageCounter = 0;
                        if(this.context.newMessages!== undefined){
                            try{ messageCounter = this.context.newMessages.filter(q => q.groupId === id).length;}
                            catch (error) {messageCounter = 0}
                        }
                        if(id==="default"){
                            return ""
                        }
                        else{
                            return(
                                <Link to={ROUTES.SUPPORT} onClick={() => (this.handleChange(undefined, id))} 
                                    value={id} key={index}>
                                    <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}>
                                        <Badge badgeContent={ messageCounter }
                                            color="error">
                                            {id==="default" ? "Support Request" : id}
                                        </Badge>
                                    </li>
                                </Link>
                            )
                        }
                    })}
                </>
            )
        }
        else{
            return(
                <>
                {user !== null && availableGroups!== undefined && availableGroups.length > 0
                    ?  
                    <Tabs
                        value={value}
                        orientation={orientation}
                        onChange={this.handleChange}
                        aria-label="My Groups"
                        variant="scrollable"
                        scrollButtons="auto"
                        initialselectedindex={"Community"}
                        style={{
                            marginTop: 20,
                            marginBottom: (screen==="desktop" ? 0 : -1),
                            marginLeft: -20,
                            width: 'calc(100% + 40px)'
                        }}
                        ref={el => this.containerLine = el}
                    >
                        {false && this.props.screen==="desktop" && 
                            <Tab value="Home" label={<span><HomeIcon style={theme.inlineIconLeft}/>Home</span>}/>
                        }
                        <Tab value={'Latest'} key={'Latest'} 
                            label={
                                <span><GroupIcon style={theme.inlineIconLeft}/>
                                    <Badge badgeContent={ messageTotalCounter }
                                        color="error">
                                        Latest Messages
                                    </Badge>
                                </span>
                            } 
                        />
                        {availableGroups?.includes("default")
                        &&
                            <Tab value={'default'} key={'default'} 
                                label={
                                    <span><HeadsetMicIcon style={theme.inlineIconLeft}/>
                                        <Badge badgeContent={ this.context.newMessages.filter(q => q.groupId === 'default').length }
                                            color="error">
                                            Support Request
                                        </Badge>
                                    </span>
                                } 
                            />
                        }
                        {availableGroups.map((id, index) => {
                            var messageCounter = 0;
                            if(this.context.newMessages!== undefined){
                                try{ messageCounter = this.context.newMessages.filter(q => q.groupId === id).length;}
                                catch (error) {messageCounter = 0}
                            }
                            if(id === 'default'){
                                return ""
                            }
                            else
                                return(
                                    <Tab value={id} key={index} 
                                        label={
                                            <Badge badgeContent={ messageCounter }
                                                color="error">
                                                {id==="default" ? "Support Request" : id}
                                            </Badge>
                                        } 
                                    />
                                )
                        })}
                    </Tabs>                
                    : "loading groups"
                } 
                </>
            )
        }
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(HomeSupport);
  
