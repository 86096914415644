import React, { Component } from 'react';
//import {  Link } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';

import {ConvertTimestampToDateString} from '../../Modules/ConvertTimestampToDateString';

import Grid from '@material-ui/core/Grid';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
//import Table from '@material-ui/core/Table';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import TableBody from '@material-ui/core/TableBody';
import {Column, Table, SortDirection} from 'react-virtualized';
import {  AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'
import _ from "lodash";
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import ButtonSwitch from '@material-ui/core/Switch';


import {
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
  } from 'react-virtualized';

//// for keywords
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    },
    innerHTML : {
        width: '100%', 
        maxWidth: '100%', 
        height: 'auto', 
        //margin:20, 
        padding: 20,
        '& p':{
            width: '100%', 
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& img": {
            width: '100%', 
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& span": {
            width: '100%', 
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& h1": {
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& div": {
            maxWidth: '100%', 
            height: 'auto', 
        },
        "& table": {
            maxWidth: '100%', 
            width: '100%',
            height: 'auto', 
        },
    },
};

class AdminFlagsList extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        const sortBy = 'active';
        const sortDirection = SortDirection.ASC;
        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            title: '',
            author: '',
            program: '',
            open: false,
            sortBy,
            sortDirection,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: true,
            open: false, 
            title: '',
            author: '',
            program: '',
            showTab: '',
            errorMessage : ''
        });

        var name = '';

        this.unsubscribe = this.props.firebase
        .collectionByName('appErrors')
        .orderBy('createdAt', 'desc')
        .limit(1000)
        .onSnapshot(snapshot => {
            if(this._isMounted ){
                let contentsTab = [];
                let contentsTabList = [];
                let contentsLabels = [];
                let maxVotes = [];
                var content={};

                var keySorting = 'source'
                
                snapshot.forEach(doc =>{
                    if(this.context.roles?.includes('ADMIN')){
                        if(this.context.programs.includes(doc.data().program) || doc.data().program===undefined){
                            name = doc.data()[keySorting];
                            content = doc.data();
                            if(name === undefined){
                                name = '---undefined---'
                            }
                            if(!(name in contentsTab)){
                                //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                contentsTab[name] = [];
                                contentsTabList[name]= [];
                                contentsLabels.push(name );
                                maxVotes[name] = 1;
                            }
                            contentsTab[name].push({ ...doc.data(), uid: doc.id });
                            contentsTabList[name].push({
                                source: content.source,
                                date:  ConvertTimestampToDateString(content.createdAt, true),
                                dataPoint1: content.dataPoint1 || '-',
                                dataPoint2: content.dataPoint2,
                                userId: content.userId,
                                errorMessage : content.errorMessage || '-',
                                cid: doc.id,
                            });
                            if(doc.data().contentVotes > maxVotes[name]){
                                maxVotes[name] = doc.data().contentVotes;
                            }
                        } // end if true
                    } // end of if ADMIN
                });//end of snapshot


                //console.log('contents', contentsTab)
                if(this.state.showTab === undefined || this.state.showTab === ''){
                    this.setState({showTab: name})
                }
                this.setState({
                    contentsTab: contentsTab,
                    contentsTabList,
                    contentsLabels,
                    maxVotes,
                    loading: false,
                });
            }//end if mounted
        });

        this.loadAvailableIntents();
    }

    _sortList = ({sortBy, sortDirection}) => {
        const list = this.state.contentsTabList[this.state.showTab];
        //console.log('sortingbefore', sortBy ,sortDirection)
        let newList = _.sortBy(list, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            newList.reverse();
        }
        //console.log('sorting',newList, this.state.showTab )
        return newList;
    }
    _sort = ({sortBy, sortDirection}) => {
        const sortedList = this.state.contentsTabList;
        sortedList[this.state.showTab] = this._sortList({sortBy, sortDirection});
        this.setState({sortBy, sortDirection, contentsTabList: sortedList});
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.title !== '' && this.state.author !== '' && this.state.category !== '') {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.contents().add({
                title: this.state.title,
                author: [this.state.author],
                program: this.state.program,
                active: false,
                createdAt: timestamp
            });
            this.setState({errorMessage : 'Added content (category undefined)'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a content'})
        }
        
        this.setState({
            title: '',
            author: '',
            program: '',
        });
        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue
        });
    }

    loadAvailableIntents(){
        var availableIntents = {
            goals:[],
                intentions:[], 
                challenges:[],
                sentiment:[], 
                failures:[],
                successes:[],
                conclusions: []
            }
        this.props.firebase.agentIntentClasses()
        .where('active', '==', true)
        .where('sentiment', '>=', 'inform')
        .where('tenses', 'array-contains', 'default')
        .orderBy('sentiment', 'asc')
        .orderBy('description', 'asc')
        .get().then((snapshot) => {
            snapshot.forEach(doc => {
                var dataSet = doc.data();
                var intent = dataSet.intent;
                //var description = dataSet.description;
                if(intent === undefined) intent = "nothingToFind"
                if(intent.includes('inform_sentiment')){
                    availableIntents['sentiment'].push(intent);
                }
                else if(intent.includes('inform_challenge')){
                    availableIntents['challenges'].push(intent);
                }
                else if(intent.includes('inform_goal')){
                    availableIntents['goals'].push(intent);
                }
                else if(intent.includes('inform_intention') || intent.includes('inform_activit')){
                    availableIntents['intentions'].push(intent);
                }
                else if(intent.includes('inform_failure')){
                    availableIntents['failures'].push(intent);
                }
                else if(intent.includes('inform_conclusion')){
                    availableIntents['conclusions'].push(intent);
                }
                else if(intent.includes('inform_success') || intent.includes('inform_accomplish') ){
                    availableIntents['successes'].push(intent);
                }
            });
            this.setState({availableIntents});
        })
    }

    render() {
        const { showTab, contentsTab, contentsLabels, maxVotes, loading } = this.state;
        //console.log('votes', maxVotes)
        return (
            <div style={{height: '100vh', backgroundColor: "#DDDDDD"}}>
            {loading &&
                <LoadingProgress 
                    defaultSet={this.props.defaultSet}
                    type={'LoadingPageAnimated'} 
                    loadingText={"many, many, data"} 
                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                    allowRefresh={true}
                />
            }
            {this.state.errorMessage !== '' && 
                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
            }
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                onChange={this.handleTabChange}
                initialselectedindex={0}
                value={showTab}
                >
                {contentsLabels.length>0 && 
                    contentsLabels.map( (key) => (
                    <Tab 
                        value={key} 
                        key={key}
                        label={key + " ("+contentsTab[key].length+")"}/>
                    ))
                }
            </Tabs>
        
            {contentsLabels.length>0 && contentsLabels.map( (key) => (
                <div 
                    value={key} 
                    key={key} 
                    hidden={showTab !==key}>
                    <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            width={width}
                            height={700}
                            headerHeight={20}
                            rowHeight={30}
                            rowCount={this.state.contentsTabList[key].length}
                            sort={this._sort}
                            sortBy={this.state.sortBy}
                            sortDirection={this.state.sortDirection}
                            rowGetter={({index}) => this.state.contentsTabList[key][index]}
                            onRowClick={({ event, index, rowData })  => {
                                this.setState({
                                    currentContentId: this.state.contentsTabList[key][index].cid, 
                                    currentContentSet: this.state.contentsTabList[key][index],
                                    dialogOpen:true,
                                    currentMaxVote: maxVotes[key],
                                    currentContent: this.state.contentsTabList[key][index],
                                }
                            )}}
                        >
                            <Column label="Source" dataKey="source" width={200} disableSort={false}  />
                            <Column label="Date" dataKey="date" width={450} disableSort={false}  />
                            <Column label="Info" dataKey="dataPoint1" width={250} disableSort={false} />
                            <Column label="Error" dataKey="errorMessage" width={450} disableSort={false}  />
                        </Table>
                    )}
                    </AutoSizer>
                </div>
            ))
            }
            <FlagsItemDialog 
                contentId={this.state.currentContentId}
                content={this.state.currentContentSet ||  undefined}
                maxVote={this.state.currentMaxVote}
                contentsLabels={contentsLabels}
                openDialog={this.state.dialogOpen|| false}
                closeDialog={(e) => {this.setState({dialogOpen: false })}}
                availableIntents={this.state.availableIntents || {}}
            />
            </div>
        );
    }
}


class FlagsItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var cid = this.props.contentId;
        var errorMessage = '';
        var contentsLabels = ['tips, quotes'];
        var maxVote = 30;

        // delivered from db section pool
    
        contentsLabels = this.props.contentsLabels;
        maxVote = this.props.maxVote;
        
        //console.log('location',cid)
        this.state = {
            loading: false,
            content: null,
            cid: cid,
            contentsLabels: contentsLabels,
            errorMessage: errorMessage,
            maxVote : maxVote,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unscrubscribe !== undefined ) this.unsubscribe();
    }

    render() {
        const {loading} = this.state;
        const {content} = this.props;
         
        //console.log('my context', this.context)
        return (
            <Dialog
                //PaperProps={{style: {position: "absolute", right: 10}}}
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
            >
                <DialogTitle >
                    <Grid container spacing={0} direction='row' justify="space-between" >
                        <Grid item >
                            <IconButton onClick={this.props.closeDialog}>
                                (X)
                            </IconButton>
                        </Grid>
                        <Grid item >
                            Modify Content
                        </Grid>
                        <Grid item >
                            {this.props.contentId?.length>1 
                                &&
                                <Button onClick={() => { this.flagDelete(this.props.contentId) }}>
                                    Delete
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent >
                    <div>
                        <h5 style={styles.subtitle}>Content ({this.props.contentId})</h5>
                        {loading && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        }
                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {content !== undefined &&
                            <div>
                                <p>
                                    <strong>Date: </strong> {content.date}
                                </p>
                                <p>
                                    <strong>Date: </strong> {content.source}
                                </p>
                                <p>
                                    <strong>Original UserId: </strong> {content.userId}
                                </p>
                                <p>
                                    <strong>Info: </strong> {content.dataPoint1}
                                </p>
                                <p>
                                    <strong>Error: </strong> {content.errorMessage}
                                </p>
                                <p>
                                    <strong>Details: </strong> {JSON.stringify(content.dataPoint2 || '')}
                                </p>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog> 
        );
    }

    handleCloseDialogueKeyWords = () => {
        this.setState({openDialogueKeyWords: false} )
    }
    handleOpenDialogueKeyWords = () => {
        this.props.firebase.content(this.state.cid + "/postprocess/searchKeyWords" ).get()
        .then((doc) => {
            var postprocessData = { triggerStartSearch : false}
            if(doc){
                if(doc.data() !== undefined && doc.data().triggerStartSearch !== undefined){
                    postprocessData = doc.data();
                }
            }
            //console.log(('processdata'), postprocessData)
            if(this._isMounted){
                this.setState({postprocessData} );
            }
        })
        this.props.firebase.content(this.state.cid + "/keyWords/tags" ).get()
        .then((doc) => {
            var identifiedTags = []
            if(doc){
                if(doc.data() !== undefined && doc.data().tags !== undefined){
                    identifiedTags = doc.data().tags;
                }
            }
            if(this._isMounted){
                this.setState({identifiedTags} );
            }
        })

        var activeTags = [];
        var activeTagsObjects = {};
        if(this.state.content !== undefined && this.state.content !== null && this.state.content.tags !== undefined ){
            activeTags = [...this.state.content.tags];
            //activeTagsObject = Object.assign(...activeTags.map(k => ({ ['tags_'+k.replace(/ /g,'')] : true })));
            activeTagsObjects =  Object.assign.apply(null, this.state.content.tags.map(k =>({['tags_'+k.replace(/ /g,'')]:true})));
        }
        if(this._isMounted){
            this.setState({openDialogueKeyWords: true, activeTags, activeTagsObjects: activeTagsObjects} )
        }

        if(this.state.content !== undefined && this.state.content.leadership_quality !== undefined ){
            this.props.firebase.contents()
            .where('leadership_quality', 'array-contains-any', (this.state.content.leadership_quality || []))
            .get()
            .then((snapshot) => {
                if(snapshot){
                    var otherTags = [];
                    snapshot.forEach(doc => {
                        var tempTags = doc.data().tags;
                        //console.log("tempTags", tempTags)
                        if( tempTags !== undefined && tempTags.length>1 ){

                            //console.log("other tags", otherTags)
                            otherTags = _.union(otherTags, tempTags)
                        }
                    })
                    if(this._isMounted){
                        //console.log("other tags", otherTags)
                        this.setState({otherTags} );
                    }
                }
            })
        }
        //console.log('activtags', this.state)
    }
    flagDelete(cid) {
        this.props.firebase.documentIdByCollection('appErrors', cid).delete();
        this.props.closeDialog();
    } 

        // Default sizes help Masonry decide how many images to batch-measure
    cache = new CellMeasurerCache({
        defaultHeight: 250,
        defaultWidth: 200,
        fixedWidth: true,
    });
    
    // Our masonry layout will use 3 columns with a 10px gutter between
    cellPositioner = createMasonryCellPositioner({
        cellMeasurerCache: this.cache,
        columnCount: 3,
        columnWidth: 200,
        spacer: 10,
    });

    renderRowCheckbox = ({ index, key, parent, style}) => {
        var alternativeTag = this.state.otherTags[index]
        return(
            <CellMeasurer cache={this.cache} index={index} key={key} parent={parent}>
            <FormControlLabel
                key={key+"_"+index}
                id={key+"_"+index}
                control={<Checkbox
                    checked = {
                        this.state.activeTagsObjects !== undefined &&  this.state.activeTagsObjects['tags_'+alternativeTag.replace(/ /g,'')] 
                            ? true
                            : false
                    }
                    onClick={(event) => this.updateActiveTags(alternativeTag, true) }
                    name={key+"_"+index} />}
                label={alternativeTag}
            />
            </CellMeasurer>
        )
    }

    updateActiveTags = (tag, activate) => {
        if (this._isMounted && this.state.activeTags!==undefined && Array.isArray(this.state.activeTags)) {
            //console.log('before',activate,  tag,  this.state.activeTags)
            var tagUpdate = this.state.activeTags
            if (activate){
                //tagUpdate.push(tag)
                tagUpdate = _.union(tagUpdate, [tag])
                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : true} })
            }
            else{

                this.setState({ activeTagsObjects : {...this.state.activeTagsObjects, ['tags_'+tag.replace(/ /g,'')] : false} })
                var index = tagUpdate.indexOf(tag);
                tagUpdate.splice(index, 1);
                tagUpdate = tagUpdate.filter((_, i) => i !== tag)
            }
            //console.log('after', tag,  this.state.activeTags)
            this.setState({activeTags : tagUpdate})
            //console.log('activtagsafter update', this.state)
        }
    }

    saveUpdatedKeyWords = () => {
        if (this.state.activeTags!==undefined && Array.isArray(this.state.activeTags) && this.state.activeTags.length>1) {
            this.props.firebase.content(this.state.cid ).update({tags : this.state.activeTags })
            .then( () => {
                this.handleCloseDialogueKeyWords()
            })
        }
    }
}

AdminFlagsList.propTypes = {
    classes: PropTypes.object.isRequired
}

export const FlagsItemDialog = withFirebase(FlagsItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    withFirebase,
    withAuthorization(condition),
)(AdminFlagsList);


