import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
// MUI stuff
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//style icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
//import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: theme.maxWidthDialog,
        backgroundColor: theme.palette.themeLightGrey,
        height: '100%'
    },
});



class HomeProducts extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            user: null,
            paymentTransaction : {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        if(this.props.location.search!==undefined){
            var paymentTransaction = {};
            const transactionQuery = new URLSearchParams(this.props.location.search);

            /**
                // variants from Bluesnap 
                
                https://app.rocky.ai/#/payment?
                email=!{shopper.email}
                &firstName=!{shopper.firstName}
                &lastName=!{shopper.lastName}
                &invoiceStatus=!{invoiceStatus}
                &invoiceId=!{invoice.id}
                &price=!{invoice.total}
                &priceLocalCur=!{invoice.currency}

            */
            for(var pair of transactionQuery.entries()) {
                paymentTransaction[pair[0]] = pair[1];
            }
            this.setState({ paymentTransaction : paymentTransaction })
        }
        this.unsubscribe = this.props.firebase
        .user(this.context.uid)
        .onSnapshot(snapshot => {
            if(this._isMounted){
                this.setState({
                    user: snapshot.data(),
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }


    render() {
        const { user, loading, paymentTransaction } = this.state;
        const { theme, classes } = this.props;
        console.log("transaction", this.state.paymentTransaction, this.props);
        return (
            <div className={classes.root}>
                <Card style={IsMobileDevice()
                        ? theme.CardTopCompactMobileNavi
                        : theme.CardTop
                    }>
                        <h2 style={theme.textMonthCenter}>My Subscriptions</h2>
                </Card>

                <div style={{paddingTop: 100}}>
                    &nbsp;
                </div>
        
                <div style={theme.textMonthCenter}>
                    <AccountCircleIcon style={{ color: theme.palette.primary.dark, fontSize: 60  }} />
                    {user!== undefined && user!==null 
                            ? <h2 style={theme.textMonthCenter}>{user.username}</h2>
                            : <h2 style={theme.textMonthCenter}>Me</h2>
                    }
                </div>

                {loading &&
                    <div style={theme.divCenterPadding}>
                        <CircularProgress color="secondary"/>
                    </div>
                }

                <div>&nbsp;</div>
                <div>
                    <Card style={{...theme.CardDialogueButton, backgroundColor:'green'}}>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item xs={3} >
                                <CheckCircleIcon/>
                            </Grid>
                            <Grid item xs={9}>
                                <h5>Congratulations </h5>
                                <span>Your order has been completed succefully </span>
                            </Grid>
                        </Grid>
                    </Card>
                </div>

                <div>&nbsp;</div>

                {user!== undefined && user!==null &&
                    <div style={{ maxWidth: IsMobileDevice() ? '100%' :  theme.maxWidthDialog }}> 
                    { paymentTransaction.invoiceStatus !== undefined && paymentTransaction.invoiceStatus === "APPROVED"
                        &&
                        <Accordion expanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="NotificationExpand"
                                aria-controls="Notifications-content"
                                id="Notifications-header"
                                >
                                <FormControlLabel
                                    aria-label="Notifications"
                                    control={<ThumbUpAltOutlinedIcon style={theme.cardSettings.iconDesign} />}
                                    label="Thank you"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <h4>Dear&nbsp;{ paymentTransaction.firstName }&nbsp;{ paymentTransaction.lastName }
                                        </h4>
                                    <h5>Thank you for your order (#{ paymentTransaction.invoiceId })</h5>
                                    <span>Your order has been completed succefully.</span>
                                    <span><br/>
                                        { paymentTransaction.priceLocalCur } &nbsp;
                                        { paymentTransaction.price } 
                                    </span>
                                    <span><br/>A confirmation email has been sent to { paymentTransaction.email }.</span>
                                    <span><br/>You can also view a copy of your email here.</span>

                                    <span><br/><br/>Regards</span>
                                    <h5>Your Technical Support Team</h5>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                   }

  
                    </div>
                }
            </div>
        );
    }
}

HomeProducts.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(HomeProducts);
