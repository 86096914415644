import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import { Link } from "react-router-dom";

// core
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import DrawerBrandOverview from '../Coach/DrawerBrandOverview';
import TutorialPage from '../Onboarding/TutorialPage';
import { AccessCheckCoachCreator, AccessCheckCreatorTrialPhase } from '../../constants/roles';

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import BadgeCard from '../Modules/BadgeCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

import DialogSettingPlan from '../Profile/DialogSettingPlan';
import BuildIcon from '@material-ui/icons/Build';
import HomeIcon from '@material-ui/icons/Home';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// Modules
import ManagePrograms from '../Coach/ManagePrograms';
import ManageContent from '../Coach/ManageContent';
import {WizardQuestion} from '../Coach/ManageQuestions';
import ManageQuestions from '../Coach/ManageQuestions';
import LoadingProgress from '../Modules/LoadingProgress';
import SnackbarInfo from '../Modules/SnackbarInfo';
import AdminQuestionIntentsPage from '../Admin/QuestionIntents';
//import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";

import Button from '@material-ui/core/Button';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
//import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
//import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class HomeManageContent extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        var startTab = "Programs & Quests";
        this.dialogRef = React.createRef();
        this.state = {
            loading: true,
            value: startTab,
            user: null,
            scrolled: false,
            headerVisible: true,
            'Programs & Quests': true,
        };
    }
    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                //document.body.scrollTop = document.documentElement.scrollTop = 0;
                //if(this.dialogRef !== undefined && this.dialogRef.scrollTop !== undefined ) this.dialogRef.scrollTop  = 0;
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
            });
        }
    }
    componentDidMount() {
        var startTab = this.state.value;
        var defaultState = { loading: false, value: startTab };
        if (this.context.roles?.includes('COACHADMIN') && !(this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode))){
            startTab = "Programs & Quests"
        }
        try{
            if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
        } catch  (err) {
            console.log('No scroll to top');
        }
        this.setState(defaultState);
        this.componentDidUpdate({})
    }
    componentDidUpdate(prevProps) {
        //console.log('props', prevProps, this.props.location)
        if(this.props.location?.state?.value !== undefined && !(this.props.location?.state?.value === prevProps?.location?.state?.value )){
            var defaultState = {  }
            Object.keys(this.props.location.state).map(key => {
                //console.log(key, this.props.location.state[key])
                defaultState[key] = this.props.location.state[key];
                return true
            })
            //console.log('props', defaultState, this.props.location)
            //console.log('state', defaultState)
            this.setState(defaultState);
        }
        //console.log('state', this.state)
    }

    componentWillUnmount() {
        //this.unsubscribeDialogues();
        this.setState({ loading: true });
        //window.removeEventListener('scroll', this.handleScroll);
    }


    render() {
        const { theme } = this.props;
        const { loading,  value } = this.state;
        const {user, leadershipCategories, leadershipQualities, screen} = this.props;
        //console.log('homemanagemen content', this.props)
        if(user !== null ){
            var previewAvailable = this.props.defaultSet?.programMinPrograms?.length > 0 && this.context.adminPrograms?.some(programItem => this.props.defaultSet.programMinPrograms.includes(programItem)) ? true : false;
            //console.log("new user", user)
            return(
                <Grid key="splitsidenavi" container direction="row" justify="space-between" ref={this.dialogRef} >
                    <Grid item 
                        //style={{width: 240}}
                        xs={screen==="desktop" ? 2 : false}
                    >
                        {screen==="desktop"
                            &&
                            <Card style={theme.CardDesktopLeft}>
                                <Card style={theme.CardNaviLeft}>
                                    <div 
                                        style={{...theme.cardBorder, backgroundColor: theme.palette.themeAlertRed+'0F', borderColor: theme.palette.themeAlertRed, textAlign: 'center'}}
                                    >
                                        <p><strong>
                                            <ImportantDevicesIcon style={theme.inlineIconLeft}/>Creator Tool
                                        </strong></p>
                                        <span style={theme.textSupport}>
                                            Manage content, programs & quests
                                        </span>
                                    </div>
                                    {false && 
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <Button 
                                            variant="outlined" color="secondary"
                                            onClick={() => {
                                                this?.props?.history?.push(ROUTES.HOME)
                                            }}
                                        >
                                            <span>
                                                <HomeIcon style={theme.inlineIconLeft}/>
                                                Home
                                            </span>
                                        </Button>
                                    </div>
                                    }
                                    {this.renderTabs("vertical")}
                                </Card>
                            </Card>
                        }
                    </Grid>
                    <DrawerBrandOverview 
                        user={this.context}
                        bottomPosition={true}
                        defaultSet={this.props.defaultSet || undefined}
                        leadershipCategories={leadershipCategories}
                        leadershipQualities={leadershipQualities}
                    /> 
                    <DialogSettingPlan 
                        defaultSet={this.props.defaultSet || undefined}
                        user={this.context}
                        coach={true}
                        handleOpen={this.state.DialogSettingPlanOpen}
                        premiumOnly={true}
                        purchaseSuccessFlag={() => {
                            this.setState({nonSubscriber: false, premiumSubscriber: true})
                        }}
                        purchaseSuccessHandler={() => {
                            this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                        }}
                        handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                    />
                    <Grid item xs={screen==="desktop" ? 10 : 12} >
                        {loading
                            ?
                                <div>
                                    <LoadingProgress type={'LoadingPageAnimated'} loadingText={"loading client information"} />
                                </div>
                            :
                                <Card style={screen==="desktop"? theme.CardDesktopMain : theme.CardAppMain}>
                                    <VizSensor 
                                        partialVisibility={"top"}
                                        onChange={(isVisible) => { 
                                            if(screen!== "desktop" && isVisible && this._isMounted) { 
                                                this.setState({headerVisible: true});
                                                //console.log('visible top cart')
                                            }
                                            else if (screen!== "desktop" && this._isMounted){
                                                this.setState({headerVisible: false});
                                                //console.log('hding top cart')
                                            }
                                        }}>
                                        <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                    </VizSensor>

                                    {user !== null && leadershipQualities.length > 0 
                                        &&
                                        <Card style={{
                                                ...(this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop), 
                                                position: this.state.headerVisible? 'relative' : 'fixed',
                                                zIndex: this.state.headerVisible? 0 : 100,
                                                top: 0,
                                                opacity: this.state.headerVisible? '1.0' : '0.94',
                                            }}>
                                            {this.state.headerVisible
                                                ?   screen==="desktop"
                                                    ?   
                                                        <h4 style={theme.CardContent.titleText}>
                                                            <ImportantDevicesIcon style={theme.inlineIconLeft}/>&nbsp;Creator Tool
                                                        </h4>
                                                    :   <h1 style={theme.CardContent.titleText}><AddShoppingCartIcon />&nbsp;{value}</h1>
                                                :   <div style={theme.textMonthCenter}><strong>&nbsp;{value}</strong></div>
                                            }
                                            {true &&
                                                this.renderTabs("horizontal")
                                            }
                                        </Card>      
                                    }
                                    <Card style={{
                                            ...theme.CardContent, 
                                            marginTop: this.state.headerVisible? 0 : 200,
                                            paddingBottom: this.state.headerVisible? 0 : '70vh',
                                            paddingTop: 20,
                                            borderRadius: theme.borderRadius,
                                            backgroundColor: theme.palette.backPaper
                                        }} >
                                        
                                        {user !== null && leadershipQualities.length > 0
                                        ?
                                            <div>
                                                <div value={"Programs & Quests"} index={0} hidden={value !== "Programs & Quests"}>
                                                    {(value==='Programs & Quests' || this.state['Programs & Quests']) &&
                                                        <ManagePrograms 
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            user={user}
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            refreshQualities = {(content, reload) => {
                                                                this.setState({'Articles & Videos': false, 'Bot Questions': false});
                                                                if(this.props.refreshQualities !== undefined){
                                                                    this.props.refreshQualities(content, reload)
                                                                }
                                                            }}
                                                            refreshDefaultSet={this.props.refreshDefaultSet || undefined}
                                                            refreshCategories = {this.props.refreshCategories || undefined}
                                                            refreshCatQualAfterChange
                                                            launchCategory={this.state.selectedCategory || undefined}
                                                            launchQuality={this.state.selectedQuality || undefined}
                                                            clickAction={(category=undefined, quality=undefined, tab = undefined) => {
                                                                //console.log("tab, program", tab, category, quality)
                                                                this.setState({selectedCategory: category, selectedQuality: quality});
                                                                if(tab !== undefined){
                                                                    this.handleChange(undefined, tab);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    {(value==='Programs & Quests' || this.state['Programs & Quests']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'You can change the text of your module here.'
                                                            alertName = 'Programsintro'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Articles & Videos"}  index={0} hidden={value !== "Articles & Videos"}>
                                                    {!((this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator) || AccessCheckCreatorTrialPhase(this.context))
                                                        ?
                                                            <div style={{margin: 20, textAlign: 'center', maxWidth: 500}}>
                                                                <BadgeCard
                                                                    key={"creator"}
                                                                    backgroundColor= {"white"}
                                                                    image={undefined} 
                                                                    avatarImage = {<ImportantDevicesIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                                    title = {!AccessCheckCreatorTrialPhase(this.context) ? "Activate Creator Tool" : "Manage coaching content"}
                                                                    subtitle = {"Subscribe to the business packages"}
                                                                    strongSubtitle = {false}
                                                                    onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                                    clickText= {'Subscribe'}
                                                                />
                                                            </div>
                                                        :   (value==='Articles & Videos' || this.state['Articles & Videos'])
                                                            ?
                                                                <ManageContent     
                                                                    defaultSet={this.props.defaultSet || undefined}
                                                                    leadershipCategories={leadershipCategories}
                                                                    leadershipQualities={leadershipQualities}
                                                                    launchCategory={this.state.selectedCategory || undefined}
                                                                    launchQuality={this.state.selectedQuality || undefined}
                                                                    user={user}
                                                                    clickAction={(category=undefined, quality=undefined, tab = undefined) => {
                                                                        //console.log("tab, program", tab, category)
                                                                        this.setState({selectedCategory: category, selectedQuality: quality});
                                                                        if(tab !== undefined){
                                                                            this.handleChange(undefined, tab);
                                                                        }
                                                                    }}
                                                                />
                                                            :   ""
                                                    }
                                                    {(value==='Articles & Videos' || this.state['Articles & Videos']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'You can modify and add your content here.'
                                                            alertName = 'Articles & Videos'
                                                            userId = {this.context.uid}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Bot Questions"} index={0} hidden={value !== "Bot Questions"}>
                                                    {(value==='Bot Questions' || this.state['Bot Questions']) &&
                                                        <ManageQuestions 
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            user={user}
                                                            launchCategory={this.state.selectedCategory || undefined}
                                                            launchQuality={this.state.selectedQuality || undefined}
                                                            clickAction={(category=undefined, quality=undefined, tab = undefined) => {
                                                                //console.log("tab, program", tab, category)
                                                                this.setState({selectedCategory: category, selectedQuality: quality});
                                                                if(tab !== undefined){
                                                                    this.handleChange(undefined, tab);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    {(value==='Bot Questions' || this.state['Bot Questions']) &&
                                                        <SnackbarInfo 
                                                            alertText = 'You can add and modify your questions here.'
                                                            alertName = 'Bot Questions'
                                                            userId = {this.context.uid}
                                                        />
                                                    }
                                                </div>
                                                <div value={"Self-Assessments"} index={0} hidden={value !== "Self-Assessments"}>
                                                    {(value==='Self-Assessments' || this.state['Self-Assessments']) &&
                                                        <Grid container direction="row">
                                                            <Grid item xs={12}>
                                                                Self-Assessments
                                                                <div style={{
                                                                    marginBottom: 20, 
                                                                    marginTop: 20, 
                                                                    maxWidth: 500, 
                                                                    borderStyle: 'solid', 
                                                                    borderWidth: 'thin', 
                                                                    borderRadius: theme.borderRadius, 
                                                                    padding: 20, 
                                                                    width: "calc(100% - 20px)"
                                                                }}>
                                                                    <p>Create Self-Assessments</p>
                                                                    <span>You can create your own self-assessment questionnaires for your audience</span>
                                                                    <br/>
                                                                    <br/>
                                                                    <span style={theme.textSupport}>
                                                                        ! Your interface is currently re-designed and will be available end of Nov. 2024.
                                                                        <br/> 
                                                                        <br/> 
                                                                            More info: You can check out the current self-assessments available in the app.
                                                                    </span>
                                                                    
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </div>
                                                <div value={"AI Training"} index={0} hidden={value !== "AI Training"}>
                                                    {!((this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator) || AccessCheckCreatorTrialPhase(this.context))
                                                        ?
                                                            <div style={{margin: 20, textAlign: 'center', maxWidth: 500}}>
                                                                <BadgeCard
                                                                    key={"creator"}
                                                                    backgroundColor= {"white"}
                                                                    image={undefined} 
                                                                    avatarImage = {<ImportantDevicesIcon fontSize="large" style={{color: theme.palette.themeAlertOrangee}}/>}
                                                                    title = {!AccessCheckCreatorTrialPhase(this.context) ? "Activate Creator Tool" : "Manage coaching content"}
                                                                    subtitle = {"Subscribe to the business packages"}
                                                                    strongSubtitle = {false}
                                                                    onClick={ () => this.setState({DialogSettingPlanOpen: true})}  
                                                                    clickText= {'Subscribe'}
                                                                />
                                                            </div>
                                                        :   (value==='AI Training' || this.state['AI Training']) 
                                                            ?
                                                                (this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("TEAM") || (this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode)))
                                                                ?
                                                                    <WizardQuestion
                                                                        defaultSet={this.props.defaultSet}
                                                                        leadershipCategories={leadershipCategories}
                                                                        leadershipQualities={leadershipQualities} 
                                                                        user={user}
                                                                    />
                                                                :   "You must either create 'Programs & Quests' or obtain administrative rights for existing ones."
                                                            :   ""
                                                    }
                                                </div>
                                                {this.context.roles !== undefined && 
                                                    (this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN"))
                                                    ?
                                                        <div value={"AITraining"}  index={0} hidden={value !== "AITraining"}>
                                                            {(value==='AITraining' || this.state['AITraining']) &&
                                                                <AdminQuestionIntentsPage 
                                                                
                                                                />
                                                            }
                                                        </div>
                                                    :   this.context.adminIntents !== undefined && this.context.adminIntents.length > 0
                                                        ?
                                                            <div value={"AITraining"}  index={0} hidden={value !== "AITraining"}>
                                                                {(value==='AITraining' || this.state['AITraining']) &&
                                                                    <AdminQuestionIntentsPage
                                                                        simpleView={true}
                                                                        filterIntents={this.context.adminIntents}
                                                                    />
                                                                }
                                                            </div>
                                                        :   ''
                                                        
                                                }
                                            </div>
                                        :
                                            <div>
                                                <p >We have a problem with your page access!</p>
                                            </div>
                                        }
                                    </Card>
                                    <Grid item xs={12}>
                                        <Button 
                                            onClick={() => {
                                                window.open("https://help.rocky.ai/rocky-for-creators", "_blank") 
                                            }}
                                        >
                                            Detailed Help
                                        </Button>
                                        {false &&
                                        <Button
                                            variant="outlined" color="secondary"
                                            onClick={() => {this.setState({showTutorial : !(this.state.showTutorial) })}}
                                        >
                                            <span>
                                                <HelpOutlineIcon style={theme.inlineIconLeft}/>
                                                {this.state.showTutorial ? "Hide Tutorial" : "Show Tutorial"}
                                            </span>
                                        </Button>
                                        }
                                        {this.state.showTutorial
                                        &&
                                            <div style={{marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, borderColor: theme.palette.secondary.light, backgroundColor: theme.palette.backPaper, width:"100%"}}>
                                                <TutorialPage 
                                                    showDesktop={true}
                                                    showStepper={false}
                                                    addDefaults={true}
                                                    badgeFamily={"teams"} 
                                                    handleClose={undefined}
                                                    handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Card> 
                        }
                    </Grid>
                </Grid>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent} ref={this.dialogRef}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )// end 2nd return
        } // end else
    }// end render

    renderTabs(orientation = "horizontal") {
        const { value } = this.state;
        const { user, leadershipQualities, leadershipCategories } = this.props;
        const { theme } = this.props;
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/>Home</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.COACH_CONTENT} onClick={() => (this.handleChange(undefined, "Programs & Quests" ))}>
                        <span><ImportantDevicesIcon style={theme.inlineIconLeft}/>Creator Tool</span>
                    </Link>
                    {this.state.selectedCategory?.length > 2 
                    ?
                        <Link to={ROUTES.COACH_CONTENT} onClick={() => {
                            this.setState({selectedCategory: undefined, selectedQuality: undefined})
                            this.handleChange(undefined, value)
                        }}
                        >
                            <li style={{paddingTop: 8, paddingLeft: 5, whiteSpace: 'nowrap'}}> {value} </li>
                        </Link>
                    : 
                        <li style={{paddingTop: 8, paddingLeft: 5, whiteSpace: 'nowrap'}}> {value} </li>
                    }
                    {(value !== "AI Training") && // Because all are discuss
                        <>
                            {this.state.selectedCategory?.length > 2 
                            ?
                                <>
                                    <Link to={ROUTES.COACH_CONTENT} onClick={() => (this.setState({selectedCategory: undefined, selectedQuality: undefined}))}>
                                        <li style={{paddingTop: 8, paddingLeft: 8, whiteSpace: 'nowrap'}}> My Programs  </li>
                                    </Link>
                                    {this.state.selectedQuality?.length > 2 
                                        ?
                                            <Link to={ROUTES.COACH_CONTENT} onClick={() => (this.setState({selectedQuality: undefined}))}>
                                                <li style={{paddingTop: 8, paddingLeft: 11, whiteSpace: 'nowrap'}}> {FindTextValueInDict(this.props.firebase, leadershipCategories, this.state.selectedCategory)}  </li>
                                            </Link>
                                        :   <li style={{paddingTop: 8, paddingLeft: 11, whiteSpace: 'nowrap'}}> {FindTextValueInDict(this.props.firebase, leadershipCategories, this.state.selectedCategory)}  </li>
                                    }
                                </>
                            :
                                ""
                            }
                        </>
                    }
                    {this.state.selectedQuality?.length > 2 
                    &&
                        <li style={{paddingTop: 8, paddingLeft: 14, whiteSpace: 'nowrap'}}> {FindTextValueInDict(this.props.firebase, leadershipQualities, this.state.selectedQuality)}  </li>
                    }
                    {/***
                        <Link to={ROUTES.COACH_CONTENT} onClick={() => (this.setState({selectedQuality: undefined}))}>
                            <li style={{paddingTop: 8, paddingLeft: 14, whiteSpace: 'nowrap'}}> {FindTextValueInDict(this.props.firebase, leadershipQualities, this.state.selectedQuality)}  </li>
                        </Link> */}
                    <hr/>
                    <Link to={ROUTES.COACH_ADMIN}>
                        <span><BuildIcon style={theme.inlineIconLeft}/>App Settings</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.COACH}>
                        <span><RecordVoiceOverIcon style={theme.inlineIconLeft}/>Coach Panel</span>
                    </Link>
                </>
            )
        }
        else{ 
            return(
            <div style={{textAlign: "center"}}>
                {user !== null && leadershipQualities!== undefined && leadershipQualities.length > 0
                &&
                    <Tabs
                        value={value}
                        orientation={orientation}
                        onChange={this.handleChange}
                        aria-label="Coach Content"
                        variant="scrollable"
                        scrollButtons="auto"
                        initialselectedindex={'Articles & Videos'}
                        style={{
                            marginTop: 20,
                            marginBottom: 0,
                            marginLeft: orientation === 'vertical' ? -20 : 0,
                            width: 'calc(100% + 40px)'
                        }}
                        ref={el => this.containerLine = el}
                    >
                        {false &&
                            <Tab label="Home" value="Home"/>
                        }
                        <Tab value={"Programs & Quests"} label="Programs & Quests"/>
                        <Tab value={"Articles & Videos"} label="Articles & Videos"/>
                        {/*** */}
                        {(AccessCheckCoachCreator(this.context) || this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN"))
                        &&
                            <Tab value={"Bot Questions"} label="Bot Questions"/>
                        }
                        {false && ((AccessCheckCoachCreator(this.context) || this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN")) && this.context.adminPrograms?.length > 0)
                        &&
                            <Tab value={"AI Training"} label="AI Training"/>
                        }
                        <Tab value={"Self-Assessments"} label="Self-Assessments"/>
                    </Tabs>    
                } 
                {orientation === 'vertical' && ((this.context.roles?.includes("TEAM") || this.context.roles?.includes("ADMIN") || this.context.roles?.includes("COACHADMIN")) && this.context.adminPrograms?.length > 0)
                &&
                    <div>
                        <br/>
                        <h4 style={theme.CardContent.titleText}>Quick Actions</h4>
                        <Button 
                            variant="outlined" color="secondary"
                            onClick={() => this.handleChange(undefined,"AI Training")}
                        >
                            <span>
                                <QuestionAnswerIcon style={theme.inlineIconLeft}/>
                                AI Training
                            </span>
                        </Button>
                        <br/>
                        <br/>
                    </div>
                }
            </div>
            )
        }
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(HomeManageContent);
  
