import React, { Component} from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import { QualityID } from '../Modules/LoadCategoriesAndQualities';

// used for the ThumbUps
import Grid from '@material-ui/core/Grid';
import PeopleIcon from '@material-ui/icons/People';

import Badge from '@material-ui/core/Badge';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import FavoriteIcon from '@material-ui/icons/Favorite';


const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});

class ContentCommentHighlight extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        //console.log('initia;', this.props)
        var myComment = '';
        var editComment = false;
        var editCommentVisible = false;
        var myCommentsBack = [];
        var mycommentVotes = 0;
        

        this.state = {
            open: false,
            commentHide : "none",
            editComment : editComment,
            commentVisible : editCommentVisible,
            commentsBack :  myCommentsBack,
            commentVotes : mycommentVotes,
            contentId: this.props.contentId,
            comment: myComment,
            oldComment: myComment,
            otherComments:[],
            userVotedFlag: [],
            userId: this.props.userId,
        };
        if(this.props.sorting === 'commentDate'){
            this.getCommentsFromOthers('commentDate');
        }
        else{
            this.getCommentsFromOthers('commentVotes');
        }  
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({isMounted : true});
    }
    componentWillUnmount() {
        this._isMounted = false;
        //this.setState({isMounted : false, commentInWork:''});
    }

    render() {
        var {theme} = this.props;
        if(this.state.otherComments.length>0 )  {
            return(
                <div style={{marginLeft:0, marginRight: 0, marginTop: 20, width:"100%"}} > 
                    <Badge 
                        badgeContent = { this.context.newMessages!== undefined &&  this.context.newMessages.filter(q => q.contentId === this.props.contentId).length ?  this.context.newMessages.filter(q => q.contentId === this.props.contentId).length : 0 }
                        anchorOrigin = {{ horizontal:'left', vertical:'top'}}
                        color= "error"
                        >
                        <div style={{width:"100%"}}  > 
                            {this.state.otherComments.map( (c) => (
                                <Grid container  alignItems="center" key={c.userId}  spacing={0} style={{width: '100%', margin: 0}}>
                                    <Grid item xs={2}>
                                        <PeopleIcon style={{ color: theme.palette.primary.dark, fontSize: 30  }}/>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/*** <em>{c.userId.substring(0, 2)}...commented</em> **/}
                                        <em>{c.userName.split(" ")[0]}&nbsp;commented</em>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <span style={{ color: theme.palette.secondary.light }}>
                                        {
                                            c.text !== undefined && c.text.length > 100
                                            ? <p>{c.text.substring(0,99)}...</p> 
                                            : <p>{c.text}</p> 
                                        }
                                        </span>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <em>
                                            <Badge badgeContent={c.commentVotes!==undefined? c.commentVotes : 0} color='secondary'>
                                                <FavoriteIcon fontSize="small" />
                                            </Badge>
                                        </em>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <em>
                                            <Badge badgeContent={c.commentsBack!==undefined? c.commentsBack.length :  0} color='secondary'>
                                                <SpeakerNotesIcon fontSize="small" />
                                            </Badge>
                                        </em>
                                    </Grid>
                                    <Grid item xs={7}>
                                    </Grid>
                                </Grid>        
                            ))}
                        </div> 
                    </Badge>
                </div>
            )// end return
        }
        else return ""
    }

    
    addEngagementPoints = (points, type) => {
        //console.log('data', this.props.content)
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        var d = new Date();
        var myDay = weekdays[d.getDay()]
        var qualitySearchTerm = '' 
        if(typeof this.props.contentData.leadershipQualitySearchTerm === 'string'){
            qualitySearchTerm = this.props.contentData.leadershipQualitySearchTerm;
        }  
        //console.log('engage', timestamp)
        this.props.firebase.insightsEngagement().add({
            createdAt: timestamp, 
            day: myDay,
            message: this.state.comment, 
            origin: 'resources',
            contentTitle: this.props.contentData.title,
            leadership_quality: [QualityID( qualitySearchTerm)],
            leadership_category: this.props.contentData.leadership_category,
            contentId: this.state.contentId,
            points: points,
            type: type,
            userId: this.context.uid,
          }); 
    }

    getCommentsFromOthers = (sorting) => {
        if(this.state.userId !== ''){
            var limitComments = 1;
            if(this.props.visualCommentsLimit !== undefined && this.props.visualCommentsLimit>1){
                limitComments = this.props.visualCommentsLimit;
            }
            var db = undefined;
            if(sorting === "commentVotes"){
                db = this.props.firebase.contentBookmarks(this.state.contentId)
                //.where('commentVisible','==', true)
                .orderBy('commentVotes','desc')
                .limit(limitComments)
            }
            else if(sorting === "commentDate"){
                db = this.props.firebase.contentBookmarks(this.state.contentId)
                .where('commentVisible', '==', true)
                .orderBy('createdAt','desc')
                .limit(limitComments)
            }
            else{
                db = this.props.firebase.contentBookmarks(this.state.contentId)
                //.where('commentVisible','==', true)
                .orderBy('commentVotes','desc')
                //.orderBy('createdAt','desc')
                .limit(limitComments)
            }
            this.unsubscribeComments = db.get()
            .then(snapshot => {
                if(this._isMounted ){
                    if (snapshot.emtpy) {
                        return;
                    }
                    var counterComments = 0;
                    var otherComments = [];
                    var highestVotes = 1;
                    snapshot.forEach(doc => {
                        var commentDataSet = doc.data();
                        //console.log('comment item', commentDataSet)
                        // will only add commments that are not own and not already in this array
                        if((commentDataSet?.comment?.length > 20) && (true || (commentDataSet.userId !==  this.state.userId 
                            && !otherComments.find(userId => userId.userId ===commentDataSet.userId)))
                            ){
                            //console.log('uid', this.state.userId)
                            //console.log('dcuid', commentDataSet.userId)
                            counterComments += 1;
                            var votes = 1
                            if(commentDataSet.commentVotes === undefined || commentDataSet.commentVotes < 1){
                                votes = 1
                            }
                            else{
                                votes = commentDataSet.commentVotes
                            }
                            var userName = 'User';
                            if(commentDataSet.userName !== undefined && commentDataSet.userName.length > 2 && commentDataSet.commentVisible){
                                userName = commentDataSet.userName;
                            }
                            var newData = {
                                commentVotes:votes, 
                                userId: commentDataSet.userId, 
                                userName: userName,
                                text: commentDataSet.comment, 
                                createdAt: commentDataSet.timestamp,
                                commentsBack : commentDataSet.commentsBack,
                            };
                            if(votes > highestVotes && this.props.sorting !== 'commentDate'){
                                otherComments.unshift(newData);
                                highestVotes = votes;
                            }
                            else otherComments.push(newData);
                        }
                    });// for each
                    if(counterComments > 0){
                        if(this._isMounted){
                            this.setState({otherComments: otherComments});
                            if(this.props.loadHandler!==undefined){
                                this.props.loadHandler(true)
                            }
                        }
                    }
                }//end ismounted
            }) //end then
        }
    }

} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ContentCommentHighlight);

  