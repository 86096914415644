import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
//import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

//import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ForumIcon from '@material-ui/icons/Forum';

// Navigation showing step of dialogue
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SnackbarInfo from '../Modules/SnackbarInfo';

import Card from '@material-ui/core/Card';

import { styled } from "@material-ui/styles";
import theme from '../../themes';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    colorCompleted: theme.palette.themeAlertOrange
});

const useDialogueStepIconStyles = makeStyles({
    root: {
        //color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    active: {
        color: theme.palette.themeAlertOrange,
        zIndex: 1,
        fontSize: 18,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        color: theme.palette.themeGrey
    },
    completed: {
        color: theme.palette.themeAlertOrange,
        zIndex: 1,
        fontSize: 18,
    },
    checked: {
        color: theme.palette.themeAlertGreen,
        zIndex: 1,
        fontSize: 18,
    },
});

const StyledStepLabel = styled(StepLabel)({
    "& .MuiStepLabel-active": {
      color: theme.palette.themeAlertOrange
    },
    "&.Mui-disabled .MuiStepLabel-label": {
      color: theme.palette.themeGrey
    },
    "& .MuiStepLabel-completed": {
      color: theme.palette.themeAlertGreen
    },
    "& .MuiStepLabel-alternativeLabel": {
        fontSize: 12,
        marginTop: 4
    }
});

function DialogueStepIcon(props) {
    const classes = useDialogueStepIconStyles();
    const { active, completed } = props;
    return (
        <div className={classes.root} >
            {(completed)
                ?   //<StarTwoToneIcon className={classes.completed} /> 
                    <CheckCircleOutlineIcon className={classes.checked} /> 
                :   active
                    ?   <ForumIcon className={classes.active} /> 
                    :   <div className={classes.circle} />
            }
        </div>
    );
}
function StepFinished() {
    const classes = useDialogueStepIconStyles();
    return (
        <div className={classes.root} >
            <CheckCircleOutlineIcon className={classes.checked} />
        </div>
    );
}
  
DialogueStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

let stageText = {
    default : 'Whatever is on your mind can be said or asked.', 
    mindset : 'Express how you feel right now. Just let your emotions flow.',
    awareness : 'This is the hardest part. Take a moment to reflect on the world around you.',
    activities : 'Describe the most urgent or crucial task you need to complete.',
    insights : 'Explore your personal goals, tasks and skills.',
    end: 'You will receive your rewards once you have concluded the conversation.',
    endResults: 'Below are some suggestions and ideas you might find helpful.'
}

class DialogueStages extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            step: 0
        }
    }

    componentDidMount() {
        this._isMounted = true;
        //const { theme } = this.props;
        this.setDialogueStages();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        this.setDialogueStages();
    }

    setDialogueStages(){
        //console.log('dialoguestage xx',this.props)
        if(this._isMounted && this.props.stages !== undefined){
            var step = 0;
            var stage = 'default';
            if(this.props.stages.end !== undefined && this.props.stages.end.phase === 'ended'){
                step = 5;
                stage = 'end';
            }
            else if(this.props.stages.insights !== undefined && this.props.stages.insights.phase === 'ended'){
                step = 4;
                stage = 'end';
            }
            else if(this.props.stages.activities !== undefined && this.props.stages.activities.phase === 'ended'){
                step = 3;
                stage = 'insights';
            }
            else if(this.props.stages.awareness !== undefined && this.props.stages.awareness.phase === 'ended'){
                step = 2;
                stage = 'activities';
            }
            else if(this.props.stages.mindset !== undefined && this.props.stages.mindset.phase === 'ended'){
                step = 1;
                stage = 'awareness';
            }
            else if(this.props.stages.mindset !== undefined && this.props.stages.mindset.phase === 'started'){
                step = 0;
                stage = 'mindset';
            }
            //console.log('1111 stage,', step, stage)
            if(step !== undefined && (this.state.stage !== stage || this.state.step !== step)){
                if(this.props.message !== undefined ){
                    this.props.message(stageText[stage]);
                }
                this.setState({step, stage})
                //console.log('stage,', step, stage)
            }
        }
    }

    render() {
        const {theme} = this.props;
        const {step} = this.state;
        //console.log('this.props', this.props)
        if(this.props.showCompleted === false && this.state.step === 5){
            return ""
        }
        else if(this.props.stages !== undefined && Object.keys(this.props.stages).length>0 ){
            return(
                <Card style={
                    this.props.background === "transparent"
                    ?   {backgroundColor: "transparent", padding: 0, boxShadow: "none", marginBottom: (this.props.marginBottom || 0) }
                    :
                        this.props.chatPaddingProfile
                            ?   {...theme.CardDialogueButton, backgroundColor: theme.palette.backPrimary, marginTop: 4, borderTopLeftRadius: 0, marginBottom: (this.props.marginBottom || 0)}
                            :   {...theme.CardDialogueButton, backgroundColor: theme.palette.backPrimary, marginBottom: (this.props.marginBottom || 0)}
                    } 
                >
                    <Stepper
                        alternativeLabel
                        activeStep={step}
                        style={{paddingLeft:0, paddingRight: 0, paddingTop: this.props.background === "transparent" ? 0 : 14, paddingBottom: this.props.background === "transparent" ? 0 : 14, backgroundColor: 'transparent'}}
                        >
                        <Step 
                            onClick={() => this.setState({openAlert: true, alertText: stageText.mindset })}
                        >
                            <StyledStepLabel StepIconComponent={DialogueStepIcon}>Mindset</StyledStepLabel>
                        </Step>
                        <Step 
                            onClick={() => this.setState({openAlert: true, alertText: stageText.awareness })}
                        >
                            <StyledStepLabel StepIconComponent={DialogueStepIcon}>Thoughts</StyledStepLabel>
                        </Step>
                        <Step 
                            onClick={() => this.setState({openAlert: true, alertText: stageText.activities})}
                        >
                            <StyledStepLabel StepIconComponent={DialogueStepIcon}>Actions</StyledStepLabel>
                        </Step>
                        <Step 
                            onClick={() => this.setState({openAlert: true, alertText: stageText.insights })}
                        >
                            <StyledStepLabel StepIconComponent={DialogueStepIcon}>Insights</StyledStepLabel>
                        </Step>
                        <Step 
                            onClick={() => this.setState({openAlert: true, alertText: this.props.finished? stageText.endResults : stageText.end})}
                        >
                            <StyledStepLabel StepIconComponent={this.props.finished? StepFinished : DialogueStepIcon}>Results</StyledStepLabel>
                        </Step>
                    </Stepper>
                    <SnackbarInfo 
                        alertText = {this.state.alertText}
                        openOnClick = {true}
                        alertOpenEvent = {this.state.openAlert || false}
                        closeHandler = {() => {this.setState({openAlert: false})}}
                    />
                </Card>
            );
        }
        else if(this.props.emptyPadding) {
            return(
                <div style={{height: 84}}> &nbsp;</div>
            )
        }
        else {
            return ''
        }
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DialogueStages);
