import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
//import PropTypes from 'prop-types';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
//import DateBadge from '../Modules/DateBadge';
import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";
import {CurrentXPLevel} from '../Charts/XPLevels';
//import Card from '@material-ui/core/Card';
//import Grid from '@material-ui/core/Grid';
import BadgeCard from '../Modules/BadgeCard';
import ActionFabButton from '../Home/ActionFabButton';


//import {AvatarCard} from '../Modules/AvatarImage';
import SnackbarInfo from '../Modules/SnackbarInfo';
//import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import {LoadStateEngagementStats} from '../Modules/LoadStateEngagementStats';

import badgeStart from "../../images/Badge_XP_congrats.png";
import badgeWhatsNext from '../../images/Badge_XP_whatsnext.png';

//import {IsMobileDevice} from '../Modules/IsMobileDevice';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import {SetStateLoadingBatches} from '../Charts/LoadingBatches';

import DialogCardBadges from '../Charts/DialogCardBadges';

// Help
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

//import LinearProgress from '@material-ui/core/LinearProgress';

//import BeenhereIcon from '@material-ui/icons/Beenhere';
//import LockOpenIcon from '@material-ui/icons/LockOpen';
//// Grid List
//import GridList from '@material-ui/core/GridList';
//import GridListTile from '@material-ui/core/GridListTile';
//import GridListTileBar from '@material-ui/core/GridListTileBar';

//import Masonry from 'react-masonry-css';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    root: {
        flexGrow: 1,
        display:"inline-block",
    },
    progressBar: {
        width: 80,
        display:"inline-block"
    },
    slideAnimation: {
        //position: 'absolute',
        zIndex: 1400,
        height: 100,
        width: 100,
        //left: '50%',
        //marginLeft: '-50px',
        //top: '50%',
        //marginTop: '-50px',
        overlayStyle:{backgroundColor: 'transparent'},
    },
    masonry:{
        width: "100%",
        display: "-webkit-box",
        display: '-ms-flexbox',
        display: "flex",
        paddingRight: 10
    },
    masonryColumn :{
        paddingLeft: 10,
        backgroundClip: "padding-box",
    },
});

class CardBadges extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        var limit=10;
        if(this.props.visualLimit !== undefined && this.props.visualLimit > 0) {
            limit = this.props.visualLimit;
        }
        var badgesListComplete = [{
            description: 'Started the Journey with Rocky',
            iconUrl :  badgeStart,
            target: 2,
            badgeFamily: "total",
        }];
        var badgesListInComplete = [{
            iconUrl: badgeWhatsNext,
            description: 'Gain more XP to unlock Features',
            target: 5,
            badgeFamily: "total",
        }]
        this.state = {
            maxPoints:100,
            loading: false,
            stats: {},
            label: "1",
            labelTotal: "1",
            completed: 5,
            completedTotal: 5,
            totalXP:0,
            limit,
            badgesListComplete,
            badgesListInComplete,
        };
        //console.log("after Bott Utters", this.state.botUtterSets);
    }


    componentDidMount() {
        this._isMounted = true;
        LoadStateEngagementStats(this, this.context.uid);
        this.getLatestBatch();
        const userLevel = CurrentXPLevel(this.context);
        if(this._isMounted){
            this.setState({userLevel}); 
        }
    }


    getLatestBatch(){
        if (this._isMounted){
            SetStateLoadingBatches(this, this.props.firebase, this.context.uid, 
                this.props.badgeFamilyDetailSelection, this.state.limit)
        }
    }
  
    componentWillUnmount() {
        this._isMounted = false;
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    } 

    render() {
        const { theme, classes } = this.props;
        //const {engagementStats} = this.state;
        //console.log('state', this.state)
        //console.log('card badges', this.state.badgesListComplete, this.props.badgeFamilyDetailSelection)
        if(this.props.variant==='XPLevel'){
            return(
                <div>
                    {this.state.badgesListComplete?.map( (badgeItem, index) => (
                        (this.props.visualLimit=== undefined || index < this.props.visualLimit)
                            &&
                            <BadgeCard
                                key={badgeItem.id+'_'+index}
                                backgroundColor ={"transparent"}
                                image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                avatarImage = {undefined}
                                //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                title = { badgeItem.title || undefined }
                                subtitle = {badgeItem.description !== undefined ?  badgeItem.description :  'Finish a reflection practice'}
                                strongSubtitle = {true}
                                onClick={ this.props.noAlert === true
                                    ?   undefined
                                    :   () => {this.handlerOpenBadgeDetails((badgeItem.family===undefined ?  'total': badgeItem.family ))}  }
                                clickText= {this.props.noAlert === true ? undefined : 'Show'}
                            />
                        )
                    )}
                    <DialogCardBadges 
                        handleOpen={this.state.dialogCardOpen || false}
                        handleClose={() => {this.setState({dialogCardOpen: false})}}
                        badgeFamilyDetailSelection= {this.state.handleBadgeFamily}
                    />
                </div>
            )
        }
        else if(this.props.variant==='XPNext'){
            return(
                <div>
                    {this.props.showUnfinished !== false && this.state.badgesListInComplete?.map( (badgeItem, index) => (
                        (this.props.visualLimit=== undefined || index < this.props.visualLimit)
                            &&
                            <BadgeCard
                                key={badgeItem.id+'_'+index}
                                backgroundColor ={"transparent"}
                                image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                avatarImage = {undefined}
                                avatarGreyOut = {true}
                                //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                title = {<span style={theme.textSupport}>Next Achievement:</span> }
                                subtitle = {badgeItem.description !== undefined ?  badgeItem.description :  'Finish a reflection practice'}
                                strongSubtitle = {true}
                                //onClick={ () => {this.handlerOpenBadgeDetails((badgeItem.family===undefined ?  'total': badgeItem.family ))}  }
                                //clickText= {'Show'}
                            />
                        )
                    )}
                </div>
            )
        }
        else{
            return (
                <div >
                    {this.props.noAlert!== true &&
                    <SnackbarInfo 
                        alertText = 'These badges show your progress in interacting with Rocky. You develop Experience Points with every chat or by reading content details.'
                        alertName = 'badgeintro'
                        userId = {this.context.uid}
                        />
                    }
                    <div style={{position: 'relative'}}>
                        <h4 style={theme.CardContent.titleText}>Milestones</h4>
                        {this.props.tutorialOpen 
                        &&
                            <div style={{position: 'absolute', top: -4, right: -6}}>
                                <IconButton style={{marginRight: 0}} onClick={() => {if(this.props.tutorialOpen) this.props.tutorialOpen('chat')}}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </div>
                        }
                    </div>
                    {this.context?.achievementSelf && this.context?.achievementSelf.text
                        &&
                            <>
                                {this.props.variant === 'circle'
                                    ?   <div style={{width: '100%', textAlign: 'center', paddingBottom: 20}}>
                                            <ActionFabButton
                                                text={this.context?.achievementSelf?.text + (this.state.userLevel > 0 ? ' - Level '+this.state.userLevel : '')}
                                                icon={undefined}
                                                image={this.context?.achievementSelf?.icon || logoWhatsNext}
                                                theme={theme}
                                                //percentage = {this.props.actionUpdatesScore || 0}
                                                height = '15vH'
                                                //colorCircle = {theme.palette.themeDarkBlue }
                                                //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                percentage = {this.state.badgeTotal?.target > 0 ? ((this.context.engagementCounter || 1) / (this.state.badgeTotal.target*1.4) *100) : 10}
                                                colorCircle = {theme.palette.themeAltGold}
                                                colorButton = {'transparent'}
                                            />
                                            <strong>{this.context?.achievementSelf?.explanationShort}</strong>
                                        </div>
                                    :
                                        <BadgeCard
                                            backgroundColor ={"transparent"}
                                            image={this.context?.achievementSelf?.icon || logoWhatsNext} 
                                            avatarImage = {undefined}
                                            title = {this.context?.achievementSelf?.text + (this.state.userLevel > 0 ? ' - Level '+this.state.userLevel : '')}
                                            subtitle = {this.context?.achievementSelf?.explanationShort || undefined}
                                            strongSubtitle = {false}
                                            onClick={undefined}
                                            clickText= {undefined  }
                                        />
                                }
                            </>
                    }
                    {false && this.props.variant !== 'circle'
                        && <h4 style={theme.CardContent.titleText}>New Milestones</h4>
                    }
                    <Grid container direction="row" 
                        justify={this.props.variant === 'circle' ? "center" : 'flex-start'} 
                        alignItems="center"
                        >
                        {this.props.showUnfinished !== false && this.state.badgesListInComplete !== undefined 
                            &&  this.state.badgesListInComplete.map( (badgeItem, index2) => (
                                (this.props.visualLimit === undefined || index2 < this.props.visualLimit) 
                                    // && (badgeItem.parent === 'root' || badgeItem.family === 'total')
                                    && 
                                    <React.Fragment key={'badge_'+index2}>
                                        {this.props.variant === 'circle'
                                            ?
                                                badgeItem.id === this.state.highlightedBadgeId
                                                ?
                                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                                        <ActionFabButton
                                                            text={'?'}
                                                            icon={undefined}
                                                            image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                                            theme={theme}
                                                            //percentage = {this.props.actionUpdatesScore || 0}
                                                            height = '15vH'
                                                            //colorCircle = {theme.palette.themeDarkBlue }
                                                            //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                            percentage = {
                                                                badgeItem.target > 0 
                                                                    ?   (( this.state.engagementStats?.[badgeItem.category]?.[badgeItem.family?.replace('xp_', '')]?.points || this.context.engagementCounter || 1) / badgeItem.target*100) 
                                                                    :   0
                                                            }
                                                            avatarGreyOut = {true}
                                                            avatarNotVisible = {true}
                                                            colorCircle = {theme.palette.themeAlertGreen}
                                                            colorButton = {'transparent'}
                                                        />
                                                        <strong>
                                                            {badgeItem.family === 'XXXXtotal' 
                                                                ?   (this.context.engagementCounter +'/'+badgeItem.target+' XP')
                                                                :   (badgeItem.title || '')
                                                            }
                                                        </strong>
                                                    </Grid>
                                                :
                                                    <Grid item 
                                                        key={badgeItem.id+'_'+index2}
                                                        style={{width: '12vH', marginRight: 10}}
                                                        onClick={ () => {this.setState({highlightedBadgeId: badgeItem.id})} }
                                                        >
                                                        <ActionFabButton
                                                            text= { undefined }
                                                            icon={undefined}
                                                            image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                                            theme={theme}
                                                            //percentage = {this.props.actionUpdatesScore || 0}
                                                            height = '12vH'
                                                            //colorCircle = {theme.palette.themeDarkBlue }
                                                            //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                            percentage = {
                                                                badgeItem.target > 0 
                                                                    ?   (( this.state.engagementStats?.[badgeItem.category]?.[badgeItem.family?.replace('xp_', '')]?.points || this.context.engagementCounter || 1) / badgeItem.target*100) 
                                                                    :   0
                                                            }
                                                            avatarGreyOut = {true}
                                                            avatarNotVisible = {true}
                                                            colorCircle = {theme.palette.themeAlertGreen}
                                                            colorButton = {'transparent'}
                                                        />
                                                    </Grid>
                                            :
                                                <BadgeCard
                                                    key={badgeItem.id+'_'+index2}
                                                    backgroundColor ={"transparent"}
                                                    image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                                    avatarImage = {undefined}
                                                    avatarGreyOut = {true}
                                                    //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                                    //title = { 'Next milestone:' }
                                                    subtitle = {badgeItem.description !== undefined ?  badgeItem.description :  'Finish a reflection practice'}
                                                    strongSubtitle = {true}
                                                    //onClick={ () => {this.handlerOpenBadgeDetails((badgeItem.family===undefined ?  'total': badgeItem.family ))}  }
                                                    //clickText= {'Show'}
                                                />
                                        }
                                </React.Fragment >
                            ))
                        }
                        {this.state.badgesListComplete !== undefined 
                            && 
                            this.state.badgesListComplete.map( (badgeItem, index) => (
                                (this.props.visualLimit=== undefined || index < this.props.visualLimit)
                                    &&
                                    <React.Fragment key={'completd_'+index}>
                                        {this.props.variant === 'circle'
                                            ?
                                                badgeItem.id === this.state.highlightedBadgeId
                                                ?
                                                    <Grid item xs={12} style={{textAlign: 'center'}} key={'newBadge_'+index}>
                                                        <ActionFabButton
                                                            text= { badgeItem.title }
                                                            icon={undefined}
                                                            image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                                            theme={theme}
                                                            //percentage = {this.props.actionUpdatesScore || 0}
                                                            height = '15vH'
                                                            //colorCircle = {theme.palette.themeDarkBlue }
                                                            //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                            percentage = {badgeItem.target > 0 ? ((this.context.engagementCounter || 1)/(badgeItem.target*1.4)*100) : 0}
                                                            colorCircle = {theme.palette.themeNaviGrey}
                                                            colorButton = {'transparent'}
                                                        />
                                                        <strong>{badgeItem.description}</strong>
                                                    </Grid>
                                                :
                                                    <Grid item
                                                        key={badgeItem.id+'_'+index}
                                                        style={{width: '12vH', marginRight:10}}
                                                        onClick={ () => {this.setState({highlightedBadgeId: badgeItem.id})} }
                                                        >
                                                        <ActionFabButton
                                                            text= { undefined }
                                                            icon={undefined}
                                                            image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                                            theme={theme}
                                                            //percentage = {this.props.actionUpdatesScore || 0}
                                                            height = '12vH'
                                                            //colorCircle = {theme.palette.themeDarkBlue }
                                                            //colorButton = {this.props.actionUpdatesScore > 40? theme.palette.themeAltGold : theme.palette.themeDarkBlue }
                                                            percentage = {badgeItem.target > 0 ? ((this.context.engagementCounter || 1)/(badgeItem.target*1.4)*100) : 0}
                                                            colorCircle = {theme.palette.themeNaviGrey}
                                                            colorButton = {'transparent'}
                                                        />
                                                    </Grid>
                                            :
                                                <BadgeCard
                                                    key={badgeItem.id+'_'+index}
                                                    backgroundColor ={"white"}
                                                    image={(badgeItem.iconUrl!== undefined && badgeItem.iconUrl!== '')?  badgeItem.iconUrl :  badgeStart} 
                                                    avatarImage = {undefined}
                                                    //title = { badgeItem.target!== undefined ? "Next milestone:  (" + badgeItem.target + ' Points)' : 'Next milestone:' }
                                                    title = { badgeItem.title || undefined }
                                                    subtitle = {badgeItem.description !== undefined ?  badgeItem.description :  'Finish a reflection practice'}
                                                    strongSubtitle = {true}
                                                    onClick={ () => {this.handlerOpenBadgeDetails((badgeItem.family===undefined ?  'total': badgeItem.family ))}  }
                                                    clickText= {'Show'}
                                                />
                                        }
                                    </React.Fragment>
                            ))
                        }
                        {/**** <AvatarCard title={true} textBlock={'Unlocked Milestones'}  type={'icon'} image={<LockOpenIcon/>} squareSize='20' backgroundColor={'black'}/> */}
                        
                    </Grid>

                    <DialogTutorialPage 
                        handleOpen={this.state.handleOpen || false}
                        handleBadgeFamily = {this.state.handleBadgeFamily}
                        handleClose={() => {this.setState({handleOpen: false})}}
                    />
                    <DialogCardBadges 
                        handleOpen={this.state.dialogCardOpen || false}
                        handleClose={() => {this.setState({dialogCardOpen: false})}}
                        badgeFamilyDetailSelection= {this.state.handleBadgeFamily}
                    />
                </div>
            ); // end retuyrn
        }
    }

    handlerOpenBadgeDetails = (badgeFamily) => {
        console.log('badgeFamily',badgeFamily)
        if(badgeFamily !== undefined && badgeFamily!== "" && badgeFamily!== "all" ){
            if(this.props.badgeFamilyDetailSelection?.length > 1){
                badgeFamily = this.props.badgeFamilyDetailSelection;
            }
            this.setState({ 
                handleBadgeFamily:badgeFamily,
            });
            this.setState({ 
                handleBadgeFamily:badgeFamily,
                dialogCardOpen: true,
            });
        }
        else{
            this.setState({ 
                handleBadgeFamily:'all',
                handleOpen: true,
            });

        }
    }
    
}


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(CardBadges);
