import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { QualityID } from '../Modules/LoadCategoriesAndQualities';


import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

//import RoleOverview from '../Profile/RoleOverview';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// Modules
import CardAlert from '../Alerts/CardAlert';
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';

// UI
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import FlareIcon from '@material-ui/icons/Flare';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import StarBorderIcon from '@material-ui/icons/StarBorder';
// Modules for parameter change
import SettingContentBase from '../Admin/Modules/SettingContentBase';
import SettingContentTime from '../Admin/Modules/SettingContentTime';
import SettingContentMapList from '../Admin/Modules/SettingContentMapList';
//import SettingContentNumber from '../Admin/Modules/SettingContentNumber';
//import SettingContentArray from '../Admin/Modules/SettingContentArray';
//import SettingContentImage from '../Admin/Modules/SettingContentImage';
//import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentSelect from '../Admin/Modules/SettingContentSelect';

const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

class CoachUserActions extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading users',
            user: null,
            userFollowUps: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        if(this.props.userId !== undefined && this.props.userId!== ""){
            this.unsubscribe = this.props.firebase
            .user(this.props.userId)
            .onSnapshot(snapshot => {
                if(this._isMounted ){
                    this.setState({
                        user: snapshot.data(),
                        userId: this.props.userId,
                        loading: false,
                    });
                }
            });
            this.getFollowUps(this.props.userId);
        }
        else{
            this.setState({ loading: true });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
        this.setState({ loading: false, user: null, userFollowUps:[] });
    }

    render(){
        const {theme} = this.props;
        const {user, userFollowUps} = this.state;
        if(user!== null && user!== undefined){
            var lastActionDate = user.lastEngagement && ConvertTimestampToDateString(user.lastEngagement, true);
            var emailNotifications =  user.emailNotifications;
            var mobileNotifications = user.mobileDeviceToken!== undefined ? user.mobileDeviceToken===''? 'deinstalled': user.mobileNotifications : 'n/a';
            return(
                <div>
                    <h4>{user.username}</h4>
                    <p> <span  style={theme.textSupport}>Email:&nbsp;</span>{user.roles?.includes("default") && user.roles?.length ===1 ? "Anonymous" : user.email}</p>
                    <p> <span  style={theme.textSupport}>Email reminders:&nbsp;</span>{emailNotifications}</p>
                    <p> <span  style={theme.textSupport}>Device:&nbsp;</span>{this.state.user.mobileDevice !== undefined ? this.state.user.mobileDevice : this.state.user.mobileDeviceToken !== undefined? "Mobile": "Browser"}</p>
                    <p> <span  style={theme.textSupport}>Mobile reminders:&nbsp;</span>{mobileNotifications}</p>
                    <p> <span  style={theme.textSupport}>Last Engagement:&nbsp;</span>{lastActionDate}</p>
                    <p>XP: <strong>{this.state.user.engagementCounter}</strong></p>

                    {(this.props.variant === undefined || this.props.variant === 'reminders')
                        && 
                        <>         
                            <Accordion key="user_reminder"  id={`panel-reminder`} TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="reminder-content"
                                    id="reminder-header"
                                    >
                                    <div style={theme.divFullWidth}>
                                        <NotificationsActiveIcon style={theme.inlineIconLeft}/>&nbsp;
                                        <strong>Reminder Settings &nbsp;</strong> 
                                        <br/>Last notification: 
                                        {user.lastEmailNotificationSentAt !== undefined
                                            ? ConvertTimestampToDateString(user.lastEmailNotificationSentAt , true)
                                            : '-'
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={theme.divFullWidth}>
                                        <strong style={theme.textSupport}>Adjust regular reminder settings</strong>
                                        {user.emailNotifications==="never"
                                            &&
                                                <p style={theme.textErrorCenterPadding}>
                                                    User has unsubscribed from emails!
                                                </p>
                                        }
                                        {user.emailNotifications==="never"
                                            ?
                                                <SettingContentSelect
                                                    dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                    title={"Mobile notification frequency:"} 
                                                    content={user} 
                                                    params={['mobileNotifications']}
                                                    labels={['Mobile reminders']}
                                                    selectOptions={['daily', 'casually', 'weekly', 'fixed', 'inactive']}
                                                    selectOptionsText={['2 per day', '4 per week', '2 per week (random)', '2 per week (fixed)', 'inactive']}
                                                    />

                                            :   (user.mobileDeviceToken === undefined || user.mobileDeviceToken==='')
                                                ?
                                                    <SettingContentSelect
                                                        dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                        title={"Email notification frequency:"} 
                                                        updateExtraChange={{'lastEmailSettingAt' : this.props.firebase.fieldValue.serverTimestamp(),
                                                            'emailNotificationsInitiated': false}}
                                                        content={user} 
                                                        params={['emailNotifications']}
                                                        labels={['Email reminders']} 
                                                        selectOptions={['daily', 'casually', 'weekly', 'fixed', 'inactive']}
                                                        selectOptionsText={['2 per day', '4 per week', '2 per week (random)', '2 per week (fixed)', 'inactive']}
                                                        />
                                                :
                                                    <SettingContentSelect
                                                        dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                        title={"Email & Mobile notification frequency:"}
                                                        updateExtraChange={{'lastEmailSettingAt' : this.props.firebase.fieldValue.serverTimestamp(),
                                                            'emailNotificationsInitiated': false, 
                                                            'mobileNotificationsInitiated': false}}
                                                        content={user} 
                                                        labels={['Emails', 'Mobile alerts']}
                                                        params={['emailNotifications', 'mobileNotifications']}
                                                        selectOptions={['daily', 'casually', 'weekly', 'fixed', 'inactive']}
                                                        selectOptionsText={['2 per day', '4 per week', '2 per week (random)', '2 per week (fixed)', 'inactive']}
                                                        />
                                        }
                                        {(user.emailNotifications === "fixed" || user.mobileNotifications === "fixed" )
                                            && 
                                            <SettingContentSelect 
                                                dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                                title={"Notification days for fixed reminders:"} 
                                                content={user} 
                                                labels={['Day of morning mindset', 'Day of evening reflection']}
                                                params={['primingDay', 'reflectionDay']}
                                                selectOptions={['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']}
                                                selectOptionsText={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']}
                                                /> 
                                        }
                                        <SettingContentTime 
                                            dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                            title={"Notification time:"} 
                                            content={user} 
                                            labels={['Morning reminder', 'Evening reminder (after 3pm)']}
                                            params={['emailPrimingReminderTime', 'emailReflectionReminderTime']}
                                            /> 
                                    </div>
                                </AccordionDetails>
                            </Accordion> 
                            <Accordion key="user_message"  id={`panel-message`} TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="message-content"
                                    id="message-header"
                                    >
                                    <div style={theme.divFullWidth}>
                                        <RingVolumeIcon style={theme.inlineIconLeft}/>&nbsp;
                                        <strong>Create Scheduled Notification</strong>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card style={{...theme.CardGoals, backgroundColor:theme.palette.themeLightGrey, padding:20}}>
                                        <CardAlert 
                                            userId={this.props.userId} 
                                            user={this.props.user} 
                                            senderName={this.context.username} 
                                            preMessage={this.state.user.ctaText} 
                                        />
                                    </Card>
                                </AccordionDetails>
                            </Accordion>
                        </>  
                    }
                    {(this.props.variant === undefined || this.props.variant === 'followups')
                        &&
                        <>
                            {false &&
                            <Accordion key="question_details1"  id={`question-key1`}   TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="question-content"
                                    id="questions"
                                    >
                                    <div style={theme.divFullWidth}>
                                        <QuestionAnswerIcon style={theme.inlineIconLeft}/>&nbsp;
                                        <span style={theme.textSupport}>Personal Coach Questions:&nbsp;</span>
                                        <br/>You can add up to 3 personal coaching questions.
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                <div style={theme.divFullWidth}>
                                    <SettingContentMapList
                                        dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                        title={"Optional: Extra Personal Coach Questions"} 
                                        content={user}
                                        limit={3}
                                        params={['interactiveQuestions']}
                                        noLabel={true}
                                        objectSet={true}
                                        mapOptions={['question', 'description', 'counter']}
                                        mapOptionsText={['Question', 'Description (Optional)', 'Max.']}
                                        />
                                </div>
                                </AccordionDetails>
                            </Accordion>
                            }
                            <Accordion key="goal_details1"  id={`goal-key1`}   TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon />}
                                    aria-controls="goal-content"
                                    id="goal"
                                    >
                                    <div style={theme.divFullWidth}>
                                        <FlareIcon style={theme.inlineIconLeft}/>&nbsp;
                                        <span style={theme.textSupport}>Vision / North Star Goal:&nbsp;</span>
                                        <br/>Rocky will ask about the 'Personal or Professional Vision' once a while.
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                <div style={theme.divFullWidth}>
                                    <SettingContentBase 
                                        dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                        title={"North Star Goal"} 
                                        noLabel={true}
                                        labels={["Vision Statement"]}
                                        max={160}
                                        content={user} 
                                        params={['achievementGoal']}
                                        /> 
                                </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion key="user_details3"  id={`panel-key3`} TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRightIcon />}
                                    aria-controls="actions-content"
                                    id="actions-header"
                                    >
                                    <div style={theme.divFullWidth}>
                                        <StarBorderIcon style={theme.inlineIconLeft}/>&nbsp;
                                        <span style={theme.textSupport}>Sub-Goals & Call-To-Actions:&nbsp;</span>
                                        <br/>Add, modify and change goals for the user.
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={theme.divFullWidth}>
                                        <h5>Available Goals</h5>
                                        <div>
                                            <p style={{textAlign:'right'}} >
                                                Create a new Sub-Goal / Call To Action 
                                                <IconButton style={{color:'red'}} onClick={ () =>  this.setState({openNew:true})}>
                                                    <AddCircleOutlineIcon/>
                                                </IconButton> 
                                            </p>
                                            <Dialog
                                                open={this.state.openNew || false}
                                                onClose={() =>  this.setState({openNew:false})}
                                                maxWidth="sm">
                                                <DialogTitle>New Sub-Goal and Call To Action </DialogTitle>
                                                <DialogContent>
                                                    Program Quests: &nbsp;<Select
                                                        value={this.state.newContext || 'priority'}
                                                        onChange={(e) =>  this.setState({newContext :e.target.value})}
                                                        name='newContext'
                                                        >
                                                        <MenuItem value={'priority'}><strong>Productivity</strong></MenuItem>
                                                        <MenuItem value={'people'}><strong>Relationships</strong></MenuItem>
                                                        <MenuItem value={'communication'}><strong>Communication</strong></MenuItem>
                                                        <MenuItem value={'success'}><strong>Achievements</strong></MenuItem>
                                                        <MenuItem value={'clarity'}><strong>Meaning</strong></MenuItem>
                                                        <MenuItem value={'mindset'}><strong>Positive Emotions</strong></MenuItem>
                                                        <MenuItem value={'balance'}><strong>Life-Balance</strong></MenuItem>
                                                        <MenuItem value={'business'}><strong>Business Topics</strong></MenuItem>
                                                    </Select>
                                                    <br/>
                                                    <TextField
                                                        name="intention"
                                                        type="text"
                                                        multiline
                                                        label="Call To Action or Goal"
                                                        variant="outlined"
                                                        placeholder="Enter call to action text ..."
                                                        value={this.state.newIntention}
                                                        onChange={(e) =>  this.setState({newIntention :e.target.value})}
                                                        fullWidth
                                                        style={ {fontSize: '16px'} }
                                                    />
                                                    <TextField
                                                        name="subGoal"
                                                        type="text"
                                                        multiline
                                                        variant="outlined"
                                                        label="Description or Context (Optional)"
                                                        placeholder="Provide some extra information ..."
                                                        value={this.state.newSubgoal}
                                                        onChange={(e) =>  this.setState({newSubgoal :e.target.value})}
                                                        fullWidth
                                                        style={ {fontSize: '16px'} }
                                                    />
                                                    <DialogActions>
                                                        <Button onClick={() =>  this.setState({openNew:false})}>
                                                            Cancel
                                                        </Button>
                                                        <Button 
                                                            disabled={ 
                                                                (
                                                                    // this.state.newSubgoal!==undefined && this.state.newSubgoal.length >= 12 &&
                                                                    this.state.newIntention!==undefined && this.state.newIntention.length >= 12
                                                                )
                                                                ? false
                                                                : true
                                                            }
                                                            onClick={this.handleSubmitNewIntention}>
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </DialogContent>
                                            </Dialog>
            
                                        </div> 
                                        <SettingContentBase 
                                            dbUpdateLink={this.props.firebase.user(this.props.userId)}
                                            title={"Next 'Call to Action' prompt within AI Chat"} 
                                            content={user} 
                                            labels={['Call to Action']}
                                            params={['ctaText']}
                                            /> 
                                        {userFollowUps !== undefined && userFollowUps.length >= 1
                                        ?
                                            <div>
                                                {userFollowUps.map((action, index) => (
                                                    <Grid  key={action.id} container spacing={0} direction='row' justify="space-between" >
                                                        <Grid item xs={1}>{index+1}.</Grid>
                                                        <Grid item xs={11}>
                                                            <Card style={theme.cardAdminSettings} key={action.id}>
                                                                <div key={action.id}>
                                                                    <p>Sub-Goal / Intention:
                                                                        <br/><span style={theme.cardJournalDayHighlight.iconDesign}>{action.question}</span>
                                                                        <br/><strong>{action.intention}</strong></p>
                                                                    <p>Last comment:
                                                                        <br/><strong style={{color: 'darkblue'}}><q>{action.message}</q></strong></p>
                                                                    <p>Current status:
                                                                        <strong>
                                                                            {action.onTrack? " On Track " : ""}
                                                                            {action.offTrack?" Off Track " : ""}
                                                                            {action.done? " Accomplished " : ""}
                                                                        </strong>
                                                                    </p>
                                                                    <Grid container spacing={0} direction='row' justify="space-between" >
                                                                        <Grid item >
                                                                            <IconButton onClick={() => this.actionDelete(index, action.id)} style={{color:'red'}} size="small"><DeleteForeverIcon/></IconButton>
                                                                        </Grid>
                                                                        <Grid item >
                                                                            <Button onClick={() => this.actionHide(index, action.id)} style={{backgroundColor:'red'}} size="small">Archive</Button>
                                                                        </Grid>
                                                                        <Grid item >
                                                                            {!action.important 
                                                                                ? <Button onClick={() => this.actionImportant(index, action.id, action.createdAtInit!== undefined? action.createdAtInit : action.createdAt)} size="small">Important</Button>
                                                                                : 'important'
                                                                            }
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {!action.urgent 
                                                                                ? <Button onClick={() => this.actionUrgent(index, action.id, action.createdAtInit!== undefined? action.createdAtInit : action.createdAt)} size="small">Urgent</Button>
                                                                                : 'urgent'
                                                                            }
                                                                        </Grid>
                                                                        <Grid item >
                                                                            {index === 0
                                                                                ?
                                                                                    <Button onClick={() => this.actionMoveUp(index, action.id, action.createdAtInit!== undefined? action.createdAtInit : action.createdAt, true, action.message)}  style={{backgroundColor:'black'}}  size="small">
                                                                                        <> <ImportExportIcon/>to&nbsp;CTA/Goals&nbsp;</>
                                                                                    </Button>
                                                                                :
                                                                                    <Button onClick={() => this.actionMoveUp(index, action.id, action.createdAtInit!== undefined? action.createdAtInit : action.createdAt, false)}  style={{backgroundColor:'black'}}  size="small">
                                                                                        <><ImportExportIcon/>to&nbsp;{index}.&nbsp;</>
                                                                                    </Button> 
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                ) )}
                                            </div>

                                        :   "No follow ups"
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                </div>
            )

        }
        else{
            return(
                <div>loading
                </div>
            )
        }
    }

    getFollowUps = (userId) => {
        var userFollowUps = [];
        this.setState({userFollowUps});
        this.props.firebase
        .journeyFollowUps()
        .where("userId","==",userId)
        .orderBy('createdAt', 'desc')
        .limit(40).get()
        .then(followups => {
            var counter = 0;
            if (followups.size) {
                followups.forEach(doc =>{
                    var set = doc.data();
                    if(set.hide !== true && counter <= 11){  
                        counter += 1;
                        //console.log(doc.data())
                        userFollowUps.push({ ...doc.data(), id: doc.id });
                    }
                })
                //console.log(userFollowUps);
            }
            else {
                console.log('No follow-up actions defined');
            }
            this.setState({userFollowUps});
        })
        .catch(err => {
            console.log('error getting document', err);
        });
    }

    actionHide = (index, actionId) => {
        this.props.firebase.journeyFollowUpAction(actionId).update({hide: true})
        .then(() => {
            this.getFollowUps(this.props.userId);
        })
    }
    actionDelete = (index, actionId) => {
        this.props.firebase.journeyFollowUpAction(actionId).delete()
        .then(() => {
            this.getFollowUps(this.props.userId);
        })
    }
    actionMoveUp = (index, actionId, oldDate, makeCTA = false, ctaText) => {
        if(makeCTA){
            this.props.firebase.user(this.props.userId).update({ctaText: ctaText})
        }
        else{
            var timestamp = this.props.firebase.fieldValue.serverTimestamp();
            if(index> 0 && this.state.userFollowUps !== undefined && this.state.userFollowUps.length > index){
                if (this.state.userFollowUps[index-1].createdAt!== undefined){
                    timestamp = this.state.userFollowUps[index-1].createdAt;
                    timestamp.seconds = timestamp.seconds+1 
                    //console.log('timestamp', timestamp)
                }
            }
            var set = {
                createdAt: timestamp,
                createdAtInit: oldDate
            }
            this.props.firebase.journeyFollowUpAction(actionId).update(set)
            .then(() => {
                this.getFollowUps(this.props.userId);
            })
        }
    }
    actionImportant = (index, actionId, oldDate) => {
        //const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        var set = {
            important:true
        }
        this.props.firebase.journeyFollowUpAction(actionId).update(set)
        .then(() => {
            this.getFollowUps(this.props.userId);
        })
    }
    actionUrgent = (index, actionId, oldDate) => {
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        var set = {
            createdAt: timestamp,
            createdAtInit: oldDate,
            urgent:true
        }
        this.props.firebase.journeyFollowUpAction(actionId).update(set)
        .then(() => {
            this.getFollowUps(this.props.userId);
        })
    }

    handleSubmitNewIntention = () => {
        var goal_setting = 'success';
        if (this.state.newContext !== undefined && this.state.newContext !== '' ){
            goal_setting = this.state.newContext;
        }
        var growthPath = [
            this.props.user.currentLeadershipQuality,
            this.props.user.additionalLeadershipQuality1,
            this.props.user.additionalLeadershipQuality2,
            this.props.user.additionalLeadershipQuality3,
            this.props.user.additionalLeadershipQuality4,
            this.props.user.additionalLeadershipQuality5,
            this.props.user.additionalLeadershipQuality6
        ];
        growthPath = growthPath.filter(Boolean);
        //console.log('growthPath', growthPath)
        var now = new Date();
        var stringDate = now.toISOString().split('T')[0] + ' Mon 09:00:00'
        var newSet = {
            createdAt : this.props.firebase.fieldValue.serverTimestamp(),
            'date' : stringDate,
            question: this.state.newSubgoal || 'New Goal from your Coach', 
            intention : this.state.newIntention, 
            message : this.state.newIntention, 
            userId : this.props.userId,
            questionTitle : 'Recommended by Coach',
            leadership_category: this.props.user.currentCategory,
            leadership_quality: QualityID(growthPath),
            intentionApproved : true,
            intentSet : true,
            important : true,
            urgent: true,
            bot_utter : ('utter_ask_'+goal_setting),
            intentClass : ('inform_intention_'+goal_setting)
        }
        var refIntentList = [
            'question_conclusions_'+goal_setting,
            'inform_accomplishment_'+goal_setting,
            'inform_activity_'+goal_setting,
            'inform_sentiment_'+goal_setting,
            'inform_failurereason_'+goal_setting,
            'inform_conclusions_'+goal_setting,
            'inform_intention_'+goal_setting,
            'inform_indicator_'+goal_setting,
            'inform_challenge_'+goal_setting,
        ]
        //console.log('newset', newSet, refIntentList)
        var followUpId = '';
        this.props.firebase.journeyFollowUps()
            .where('userId', '==', this.props.userId)
            .where('intentClass', 'in', refIntentList)
            .limit(1)
            .get()
            .then(snapshot => {
                if (snapshot.size) {
                    snapshot.forEach(doc => {
                        console.log('date found', doc);
                        var oldDataSet = doc.data();
                        followUpId = doc.id;
                        var dateOriginal = oldDataSet.createdAt;
                        if(oldDataSet.createdAtInit !== undefined ){
                            dateOriginal = oldDataSet.createdAtInit;
                        }
                        /***
                         */
                        this.props.firebase.journeyFollowUpAction( followUpId ).update({
                            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                            createdAtInit: dateOriginal,
                            important: true,
                            urgent: true,
                            hide: false,    
                            intentionApproved: true,
                            intentClass: 'inform_intention_'+goal_setting,
                            failed: false,    
                            intention : this.state.newIntention, 
                            message : this.state.newIntention, 
                            activityArchive: this.props.firebase.fieldValue.arrayUnion( {
                                date: stringDate, 
                                message: this.state.newIntention,
                                question: this.state.newSubgoal || 'New Goal from your Coach', 
                            })
                        }).then(() => {
                            this.getFollowUps(this.props.userId);
                        })
                    })
                    //console.log('days data',this.state.days);
                } 
                else {
                    followUpId = '';
                    this.props.firebase.journeyFollowUps().add(newSet).then(() => {
                        this.getFollowUps(this.props.userId);
                    })
                }
            })
        //console.log('newSet', newSet)
        this.setState({openNew : false})
    }

}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CoachUserActions);
