import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import CoachGroupSetting from '../Coach/CoachGroupSetting';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import {RemoveScroll} from 'react-remove-scroll';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});

class DialogCoachGroupSetting extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
  
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(){
    }

    componentWillUnmount(){
        this._isMounted =false;
    }



    render(){
        const { theme } = this.props;
        return(
            <RemoveScroll enabled={this.props.open || false}> 
                <Dialog
                    open={this.props.open || false}
                    onClose={this.props.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    >
                    <DialogTitle style={theme.dialogTitle} >
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.props.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    {this.props.newGroup
                                        ?   'Create New Team'
                                        :   'Change Team Settings'
                                    }
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.props.handleClose}>
                                    Cancel
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.props.open &&
                            <div>
                                <CoachGroupSetting 
                                    defaultSet={this.props.defaultSet || undefined}
                                    groupId={this.props.groupId}
                                    group={this.props.group}
                                    handleClose={this.props.handleClose}
                                    handleReload={this.props.handleReload || undefined}
                                    newGroup={this.props.newGroup}
                                    consultantsList={this.props.consultantsList}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    ownedCompetitionIds={this.props.ownedCompetitionIds || []}
                                    coachGroupsNames={this.props.coachGroupsNames || []}
                                    refreshDefaultSet={this.props.refreshDefaultSet || undefined}
                                />
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>  
        )// end return
    }//end render

};//end class

export default compose(
    withStyles(styles, { withTheme: true }),
)(DialogCoachGroupSetting);