import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PropTypes from 'prop-types';

import JournalActivitiesItem from '../Days/JournalActivitiesItem';


import {AvatarCard} from '../Modules/AvatarImage';

import Grid from '@material-ui/core/Grid';



// collapse details
//import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
//import CancelIcon from '@material-ui/icons/Cancel';
import HomeCardContentItems from '../Content/HomeCardContentItems';
import {IconPicker} from '../Modules/IconPicker';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import LoadingProgress from '../Modules/LoadingProgress';
import DateBadge from '../Modules/DateBadge';



// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// defautl category images
import imageProductivity from '../../images/category_priority_white_320x320.png';
import imageClarity from '../../images/category_clarity_white_320x320.png';
import imagePeople from '../../images/category_people_white_320x320.png';
import imageCommunication from '../../images/category_communication_white_320x320.png';
import imageBalance from '../../images/category_balance_white_320x320.png';
import imageSelf from '../../images/category_confidence_white_320x320.png';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class MapRender extends Component {
    _isMounted =  false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            loadingText: "loading journal",
            allowRefresh: true,

            renderMapType: this.props.renderMapType,
            filterTrueCoachUtter: this.props.filterTrueCoachUtter,
            filterTrueIntentCoach: this.props.filterTrueIntentCoach,
            filterTrueIntentUser: this.props.filterTrueIntentUser,
            filterFalseIntentUser: this.props.filterFalseIntentUser,
            filterTrueEntityCoach: this.props.filterTrueEntityCoach,
            filterTrueEntityUser: this.props.filterTrueEntityUser,
        };
    }

    componentDidMount() {
        this._isMounted =  true;
        this.setState({ loading: true });
        this.getJourneyEngagements();
    }
    componentWillUnmount(){
        this._isMounted =  false;
        this.unsubscribe();
    }

    getJourneyEngagements(){
        this.unsubscribe = this.props.firebase.journeyFollowUps()
        .where("intentSet","==", true)
        .where("userId","==",this.context.uid)
        .orderBy('createdAt','desc')
        //.limit(200)
        .onSnapshot(snapshot => {
        //.get().then(snapshot => {
            this.setState({loadingText: "loading diary"});
            if (snapshot.size) {
                var journeyElementsByQuestion = {};
                snapshot.forEach(doc =>    {
                    var createdAt = new Date();
                    var element = doc.data();
                    element["id"] = doc.id;
                    var checkMapType = false; 
                    if(element.intentClass !== undefined && 
                        (
                            element.intentClass.includes('conclusio') || element.intentClass.includes('activit') || element.intentClass.includes('intention') || element.intentClass.includes('goal') || element.intentClass.includes('challenge')
                        )
                    ){
                        //if ('intentCoach' in element && 'bot_utter' in element && element.hide !== true && element.done!==true){
                        if ('intentCoach' in element && 'bot_utter' in element && element.hide !== true){
                            //console.log(doc.id,element )
                            if( element.bot_utter!==undefined 
                                && this.state.filterTrueCoachUtter.some(word => element.bot_utter.includes(word)) ){
                                //console.log(element.message, 'filterTrueCoachUtter', this.state.filterTrueCoachUtter )
                                checkMapType = true; 
                            }
                            if( element.intentCoach !== undefined  && element.intentCoach.entities !== undefined 
                                && this.state.filterTrueIntentCoach.some(word => element.intentCoach.intent.includes(word)) ){
                                //console.log(element.message, 'filterTrueIntentCoach', this.state.filterTrueIntentCoach )
                                checkMapType = true; 
                            }
                            if( element.intentCoach !== undefined  && element.intentCoach.entities !== undefined 
                                && this.state.filterTrueEntityCoach.some(entity => element.intentCoach.entities[entity] !== undefined) ){
                                //console.log(element.message, 'filterTrueEntityCoach', this.state.filterTrueEntityCoach )
                                checkMapType = true; 
                            }
                            if( element.intentUser !== undefined  && element.intentUser.entities !== undefined 
                                && this.state.filterTrueEntityUser.some(entity => element.intentUser.entities[entity] !== undefined) ){
                                //console.log(element.message, 'filterTrueEntityUser', this.state.filterTrueEntityUser )
                                checkMapType = true; 
                            }
                            if( element.intentUser !== undefined  && element.intentUser.intent !== undefined 
                                && this.state.filterTrueIntentUser.some(word => element.intentUser.intent.includes(word)) ){
                                //console.log(element.message, 'filterTrueIntentUser', this.state.filterTrueIntentUser )
                                checkMapType = true; 
                            }
                            if( element.intentUser !== undefined  && element.intentUser.intent !== undefined 
                                && this.state.filterFalseIntentUser.some(word => element.intentUser.intent.includes(word)) ){
                                //console.log(element.message, 'filterFalseIntentUser', this.state.filterFalseIntentUser )
                                checkMapType = false; 
                            }
                            if(checkMapType)  {
                                var questionType = element.bot_utter;
                                if( element.intentUser !== undefined  && element.intentUser.intent !== undefined) {
                                    if(element.intentUser.intent.includes('people')){
                                        questionType = "utter_inform_people";
                                    }
                                    else if(element.intentUser.intent.includes('communication')){
                                        questionType = "utter_inform_communication";
                                    }
                                    else if(element.intentUser.intent.includes('productivity') || element.intentUser.intent.includes('work') || element.intentUser.intent.includes('priority')){
                                        questionType = "utter_inform_productivity";
                                    }
                                    else if(element.intentUser.intent.includes('success') || element.intentUser.intent.includes('business') || element.intentUser.intent.includes('clarity') || element.intentUser.intent.includes('career')){
                                        questionType = "utter_inform_clarity";
                                    }
                                    else if(element.intentUser.intent.includes('balance') ){
                                        questionType = "utter_inform_balance";
                                    }
                                    else if(element.intentUser.intent.includes('self') || element.intentUser.intent.includes('mindset') || element.intentUser.intent.includes('learning') || element.intentUser.intent.includes('confidence') ) {
                                        questionType = "utter_inform_self";
                                    }
                                    //console.log(questionType, element.intentUser.intent)
                                }
                                if(element.createdAt !== null && element.createdAt !== undefined && element.createdAt.seconds !== undefined  ){
                                    createdAt = new Date(element.createdAt.seconds * 1000); // firestore to date JS
                                }
                                /***
                                var categoryMaps = this.props.user.currentCategory || 'clarity';
                                //console.log(element.leadership_category)

                                if (element.leadership_category !== undefined && element.leadership_category !== ""){
                                    categoryMaps = element.leadership_category;
                                }
                                //var day = createdAt.getFullYear()+'-'+(createdAt.getMonth()+1)+'-'+createdAt.getDate() // month and day can be 1 digit
                                day = createdAt.toISOString().slice(0,10)
                                //foundLeadershipCartegory = dateTopicMapping[day];
                                foundLeadershipCartegory = categoryMaps;
                                if(journeyElementsByDay[foundLeadershipCartegory] !== undefined){
                                    if(day in journeyElementsByDay[foundLeadershipCartegory]){
                                        journeyElementsByDay[foundLeadershipCartegory][day] = Object.assign(journeyElementsByDay[foundLeadershipCartegory][day] , journeyElementsByDay[foundLeadershipCartegory][day].chatsIntents.push(element));
                                    }
                                    else{
                                        journeyElementsByDay[foundLeadershipCartegory][day] = {date: createdAt, chatsIntents:[element]};
                                    } 
                                }
                                else{
                                    journeyElementsByDay[foundLeadershipCartegory] = {day : {date: createdAt, chatsIntents:[element]}};
                                }
                                */

                                if(journeyElementsByQuestion[questionType] !== undefined){
                                    journeyElementsByQuestion[questionType]= Object.assign(journeyElementsByQuestion[questionType] , journeyElementsByQuestion[questionType].chatsIntents.push(element));
                                }
                                else{
                                    journeyElementsByQuestion[questionType] = {date: createdAt, chatsIntents:[element]};
                                }
                            }
                        }
                    }
                })};

                //console.log(journeyElementsByQuestion)

                if(this._isMounted){
                    if(this.state.allowRefresh){
                        this.setState({
                            journeyElementsByQuestion : journeyElementsByQuestion,
                            journeyElementsByQuestionTemp : journeyElementsByQuestion,
                            loading: false
                        });
                    } 
                    else{
                        //console.log('refresh blocked')
                        this.setState({
                            journeyElementsByQuestionTemp : journeyElementsByQuestion,
                            loading: false
                        });
                    }
                }
            }) 
        //.then(() => this.setState({ loading: false}))
    }

    allowRefreshAndUpdate = () => {
        //console.log('updateat content')
        this.setState({
            journeyElementsByQuestion : this.state.journeyElementsByQuestionTemp,
        });
        this.setState({
            allowRefresh: true,
        });
    }
  

  
    render() {
        const {  theme } = this.props;

        return (
            <div> 
            {this.state.loading 
            ?
                <LoadingProgress 
                    defaultSet={this.props.defaultSet}
                    type={'LinearProgress'} loadingText={this.state.loadingText} />
            :
                <div  style={{marginBottom: 20 }}>
                    { this.state.journeyElementsByQuestion !== undefined && 
                        Object.keys(this.state.journeyElementsByQuestion).map( (questionType, index) => (
                            <Accordion 
                                TransitionProps={{ mountOnEnter: true }} 
                                id={"type_"+questionType}
                                key={"type_"+questionType}
                                style={{
                                    borderTopLeftRadius : index === 0 ? theme.borderRadius : 0,
                                    borderTopRightRadius : index === 0 ? theme.borderRadius : 0,
                                    borderBottomLeftRadius : index === (Object.keys(this.state.journeyElementsByQuestion).length-1) ? theme.borderRadius : 0,
                                    borderBottomRightRadius : index === (Object.keys(this.state.journeyElementsByQuestion).length-1)  ? theme.borderRadius : 0,
                                    //marginBottom: (Object.keys(this.state.journeyElementsByQuestion).length-1)?10 : 'auto',
                                }}
                                >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="NotificationExpand"
                                    aria-controls="Notifications-content"
                                    id={"type_"+questionType}
                                    key={"type_"+questionType}
                                    >
                                        {
                                         this.visualiseChatGroup(this.state.journeyElementsByQuestion[questionType].chatsIntents, questionType)
                                        }
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.visualiseChat(this.state.journeyElementsByQuestion[questionType].chatsIntents)}
                                </AccordionDetails>
                            </Accordion>    
                        ))
                    }
                    
                </div>
            }
            </div>
        );
    }
    
    visualiseChatGroup = (chats, group_utter) => {
        const { theme } = this.props;
        //console.log(chats)
        var chat = chats[0];
        var positiveStatus = 0;
        var secondColor = "black";
        chats.forEach(chatItem => {
            if(chatItem.done || chatItem.onTrack || chatItem.failed)   positiveStatus+=1 ;
            if(!chatItem.done && !chatItem.onTrack && chatItem.urgent && chatItem.important ){ secondColor = theme.palette.themeAlertRed;}
            else if((!chatItem.done && (chatItem.urgent || chatItem.offTrack)) )  secondColor = theme.palette.themeAlertOrange;
            else if(!chatItem.done && !chatItem.onTrack && (chatItem.important || chatItem.urgent ) ) secondColor = theme.palette.themeAlertBlue;
            else if(chatItem.done || chatItem.onTrack ) secondColor = theme.palette.themeAlertGreen;
        })
        //var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        switch (group_utter){
            case "utter_inform_communication":
                return(
                    <div style={{width:'100%'}}>
                        <AvatarCard 
                            textBlock={this.state.renderMapType!== undefined && this.state.renderMapType !== "" ? (this.state.renderMapType + ": Communication") : "Communication" } 
                            type='imageIcon' image={imageCommunication} 
                            squareSize='40' backgroundColor={secondColor}/>
                        <br/> <strong> {positiveStatus} / {chats.length}  </strong>
                    </div>
                )
            case "utter_inform_productivity":
                return(
                    <div style={{width:'100%'}}>
                        <AvatarCard 
                            textBlock={this.state.renderMapType!== undefined && this.state.renderMapType !== "" ? (this.state.renderMapType + ": Productivity") : "Productivity" } 
                            type='imageIcon' image={imageProductivity} 
                            squareSize='40' backgroundColor={secondColor}/>
                        <br/> <strong> {positiveStatus} / {chats.length}  </strong>
                    </div>
                )
            case "utter_inform_clarity":
                return(
                    <div style={{width:'100%'}}>
                        <AvatarCard 
                            textBlock={this.state.renderMapType!== undefined && this.state.renderMapType !== "" ? (this.state.renderMapType + ": Success") : "Success" } 
                            type='imageIcon' image={imageClarity} 
                            squareSize='40' backgroundColor={secondColor}/>
                        <br/> <strong> {positiveStatus} / {chats.length}  </strong>
                    </div>
                )
            case "utter_inform_balance":
                return(
                    <div style={{width:'100%'}}>
                        <AvatarCard 
                            textBlock={this.state.renderMapType!== undefined && this.state.renderMapType !== "" ? (this.state.renderMapType + ": Wellbeing") : "Wellbeing" } 
                            type='imageIcon' image={imageBalance} 
                            squareSize='40' backgroundColor={secondColor}/>
                        <br/> <strong> {positiveStatus} / {chats.length}  </strong>
                    </div>
                )
            case "utter_inform_self":
                return(
                    <div style={{width:'100%'}}>
                        <AvatarCard 
                            textBlock={this.state.renderMapType!== undefined && this.state.renderMapType !== "" ? (this.state.renderMapType + ": Capabilities") : "Capabilities" } 
                            type='imageIcon' image={imageSelf} 
                            squareSize='40' backgroundColor={secondColor}/>
                        <br/> <strong> {positiveStatus} / {chats.length}  </strong>
                    </div>
                )
            case "utter_inform_people":
                return(
                    <div style={{width:'100%'}}>
                        <AvatarCard 
                            textBlock={this.state.renderMapType!== undefined && this.state.renderMapType !== "" ? (this.state.renderMapType + ": People") : "People" } 
                            type='imageIcon' image={imagePeople} 
                            squareSize='40' backgroundColor={secondColor}/>
                        <br/> <strong> {positiveStatus} / {chats.length}  </strong>
                    </div>
                )
            
            default:
                var questionTitle='Key thought';
                if(chat.questionTitle!== undefined && !chat.questionTitle.includes('_')){
                    questionTitle = chat.questionTitle;
                    if(chat.questionTitle.length>20){
                        questionTitle = chat.questionTitle.substring(0,19)+"...";
                    }
                }
                return(
                    <div style={{width:'100%'}}>
                        <AvatarCard 
                            textBlock={questionTitle} 
                            type='icon' image={IconPicker(chat.bot_utter)} 
                            squareSize='40' backgroundColor={secondColor}/>
                        <br/> <strong> {positiveStatus} / {chats.length}  </strong>
                    </div>
                )
        }
    }

    visualiseChat = (chats) => {
        const { theme } = this.props;

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        //console.log(chats)
        if(IsMobileDevice()){
            return(
                <div style={{width:'100%'}} >
                    {chats!== undefined && chats.map((chat, index) =>(  
                        <Grid container spacing={0} key={index} 
                            style={theme.cardGridJournalDayHighlight}
                            direction="row"
                            justify="space-between"
                        >
                            <Grid item xs={9}>
                                <JournalActivitiesItem 
                                    user={this.props.user}
                                    defaultSet={this.props.defaultSet || undefined}
                                    compactMode={true}
                                    allowRefresh={() => this.allowRefreshAndUpdate()}
                                    blockRefresh={() => this.setState({allowRefresh:false}) }
                                    open={this.state['openJournal_'+chat.id] || false }
                                    handleClose={() => this.setState({['openJournal_'+chat.id] : false})}
                                    key={chat.id} 
                                    cta={chat} 
                                    ctaId={chat.id} 
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    handlerMoment = {this.props.handlerMoment || undefined}
                                    />
                            </Grid>
                            <Grid item xs={3}> 
                                <DateBadge showDays={true} date={new Date(chat.date.substring(0, 10))} valueMonth={months[parseInt(chat.date.slice(5,7))-1]} 
                                    valueDay={chat.date.slice(8,10)}/>
                            </Grid>
                            <Grid item xs={12} onClick = { () => this.setState({['openJournal_'+chat.id] : true})} > 
                                <q style={theme.textSupport}><strong>{chat.message}</strong> </q>   
                                <p>{chat.intentionApproved? chat.intention: chat.question}</p>
                            </Grid>
                        </Grid>
                    ))}
                </div> 
            )
        }
        else{
            return(
                <div style={{width:'100%'}} >
                    {chats!== undefined && chats.map((chat, index) => ( 
                        <Grid container spacing={2} key={index} style={theme.cardGridJournalDayHighlight}
                            //onClick = { () => this.setState({['openJournal_'+chat.id] : true})}
                        >
                            <Grid item xs={3} style={theme.cardGridJournalDayHighlight}>
                                <JournalActivitiesItem 
                                    user={this.props.user}
                                    defaultSet={this.props.defaultSet || undefined}
                                    compactMode={true}
                                    allowRefresh={() => this.allowRefreshAndUpdate()}
                                    blockRefresh={() => this.setState({allowRefresh:false}) }
                                    open={this.state['openJournal_'+chat.id] || false }
                                    handleClose={() => this.setState({['openJournal_'+chat.id] : false})}
                                    key={chat.id} 
                                    cta={chat} 
                                    ctaId={chat.id} 
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    handlerMoment = {this.props.handlerMoment || undefined}
                                    />
                            </Grid>
                            <Grid item xs={7} > 
                                <q style={theme.textSupport}><strong>{chat.intentionApproved && chat.intention === chat.message ? chat.question : chat.message}</strong></q>   
                                <p>{chat.intentionApproved? chat.intention : chat.question}</p>
                            </Grid>
                            <Grid item xs={2}> 
                                <DateBadge showDays={true} date={new Date(chat.date.substring(0, 10))} valueMonth={months[parseInt(chat.date.slice(5,7))-1]} 
                                    valueDay={chat.date.slice(8,10)}/>
                            </Grid>
                        </Grid>
                    ))}
                </div> 
            )
        }
    }

    visualiseContents = (content) => {
        //const { theme } = this.props;
        //console.log(content)
        return(
                <div>
                {content.message !== undefined && content.message !== ''
                &&
                    <q>{content.message}</q>
                }
                <HomeCardContentItems
                    user={this.props.user}
                    defaultSet={this.props.defaultSet || undefined}
                    visualLimit={1}
                    specialQuery={'contentByIds'}
                    direction={'column'}
                    allowUpdate={false}
                    qualityFilter = {[content.contentId]}
                    leadershipCategories={this.props.leadershipCategories}
                    leadershipQualities={this.props.leadershipQualities}
                    allQualitiesLoaded = { []} />
                </div> 
            )
    }

  
}

MapRender.propTypes = {
    classes: PropTypes.object.isRequired,
};
  

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(MapRender);
