import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import { AddAnalytics } from '../Modules/AddAnalytics';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';

import Badge from '@material-ui/core/Badge';
//import BadgeCard from '../Modules/BadgeCard';
//import DialogCardBadges from '../Charts/DialogCardBadges';
import HomeIcon from '@material-ui/icons/Home';
import SnackbarInfo from '../Modules/SnackbarInfo';
/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import badgeStart from "../../images/Badge_XP_congrats.png";
import Grid from '@material-ui/core/Grid';

import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import LoadingProgress from '../Modules/LoadingProgress';

import StatsBar from '../Charts/StatsBar';

///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

//import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GroupIcon from '@material-ui/icons/Group';

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import RepliesOnContents from '../Community/RepliesOnContents';
import {SetStateLoadingBatches} from '../Charts/LoadingBatches';



//import Grid from '@material-ui/core/Grid';
//import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';



const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    highlightTab: {
        position: theme.highlightTab.position,
        left:  IsMobileDevice()? theme.HomeNoNavigationShiftContent.paddingLeft : theme.HomeNavigationShiftContent.paddingLeft ,
        top: IsMobileDevice()? theme.highlightTab.top : 60,
        backgroundColor:theme.highlightTab.backgroundColor, 
        zIndex:theme.highlightTab.zIndex,
    },
    unHighlightTab: {
        position: "flexible",
        //backgroundColor:theme.highlightTab.backgroundColor, 
        backgroundColor: "transparent", 
    },
});



class HomeCommunity extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();

        var historyLimit = 3;
        if (this.props.historyLimit>0){
            historyLimit = this.props.historyLimit
        }
        var startTab = "Discuss";
        if (this.props.location && this.props.location.state && this.props.location.state.startTab){
            startTab= this.props.location.state.startTab;
        }
        this.state = {
            loading: true,
            historyLimit: historyLimit,
            value: startTab,
            days: [],
            headerVisible: true,
        };
    }
    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
            });
        }
    }
    
    componentDidMount() {
        this._isMounted = true;
        let availableContentComments = [];
        let availableContentReplies = [];
        if(this.context.newMessages !== undefined && this.context.newMessages.filter(q => q.group === "comment").length > 0){
            var messagesComments = this.context.newMessages.filter(q => q.group === "comment");
            messagesComments.forEach( message => {
                if(message.contentId !== undefined && message.contentId !== "" ){
                    availableContentComments.push(message.contentId)
                }
            })
        }
        if(this.context.newMessages !== undefined && this.context.newMessages.filter(q => q.group === "reply").length > 0){
            var messagesReplies = this.context.newMessages.filter(q => q.group === "reply");
            messagesReplies.forEach( message => {
                if(message.contentId !== undefined && message.contentId !== "" ){
                    availableContentReplies.push(message.contentId)
                }
            })
        }
        this.setState({availableContentComments, availableContentReplies, loading:false},
            (() => {
                try{
                    if(this.dialogRef !== undefined ) {
                        this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                        //console.log('locaded into view on start')
                    }
                }
                catch (err) {
                    console.log('No scroll to top');
                }
            })
        );
        //console.log(availableContentComments, availableContentReplies)
        //window.addEventListener('scroll', this.handleScroll);
        this.getLatestBatch();
        AddAnalytics('screen_view', 'PageCommunity');
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({ loading: false });
    }
    

    render() {
        const { theme } = this.props;
        const { loading,  value } = this.state;
        const {user, leadershipCategories, leadershipQualities, screen} = this.props;
        if(user !== null ){
            //console.log("new user", user)
            return(
                <Grid key="splitsidenavi" container direction="row" ref={this.dialogRef}>
                    <DialogTutorialPage 
                        handleOpen={this.state.handleOpenTutorial || false}
                        handleBadgeFamily = {'social'}
                        handleClose={() => {this.setState({handleOpenTutorial: false})}}
                    />
                    <Grid item xs={screen==="desktop" ? 2 : false} >
                        {false && screen==="desktop"
                            &&
                            <Card style={theme.CardDesktopLeft}>
                                <Card style={theme.CardNaviLeft}>
                                    <h4 style={theme.CardContent.titleText}>Discuss</h4>
                                    {this.renderTabs("vertical")}
                                </Card>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 8 : 12} >
                        {loading
                            ?
                                <div>
                                    <LoadingProgress
                                        defaultSet={this.props.defaultSet}
                                        type={'LoadingPageAnimated'} loadingText={"loading user comments"} />
                                </div>
                            :
                                <Card style={screen==="desktop"? theme.CardDesktopMain : theme.CardAppMain }>
                                    <VizSensor 
                                        partialVisibility={"top"}
                                        onChange={(isVisible) => { 
                                            if(screen !== "desktop" && isVisible && this._isMounted) { 
                                                this.setState({headerVisible: true});
                                                //console.log('visible top cart')
                                            }
                                            else if (screen !== "desktop" && this._isMounted){
                                                this.setState({headerVisible: false});
                                                //console.log('hding top cart')
                                            }
                                        }}>
                                        <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                    </VizSensor>
                                    <Card style={{
                                            ...(this.props.screen === 'desktop' ? theme.CardDesktopTop :theme.CardTop), 
                                            position: this.state.headerVisible? 'relative' : 'fixed',
                                            zIndex: this.state.headerVisible? 0 : 100,
                                            top: 0,
                                            opacity: this.state.headerVisible? '1.0' : '0.94',
                                        }}>
                                        {screen !== "desktop"
                                            ?
                                                <>
                                                    {!this.state.headerVisible
                                                        && <div style={{width: 40, height:10, position: 'relative',  float : 'left'}}></div>
                                                    }
                                                    <StatsBar type='realtime' hide={!this.state.headerVisible ? true: false} />
                                                </>
                                            :
                                                <div style={{position: 'absolute', top: 6, right: 8}}>
                                                    <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                        }
                                        {this.state.headerVisible
                                            ?   <h2 style={theme.CardContent.titleText}><GroupIcon style={theme.inlineIconH2}/>&nbsp;Discuss</h2>
                                            :   <div style={theme.textMonthCenter}><strong>Discuss</strong></div>
                                        }
                                        {!this.state.headerVisible
                                            &&
                                                <div style={{position: 'fixed', top: 3, right: 8}}>
                                                    <IconButton onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </div>
                                        }
                                        {
                                            /*** 
                                        <div hidden={ !this.state.headerVisible}>
                                            {(this.state.badgeIcon!== undefined || this.state.badgeIconTotal=== undefined )  
                                            &&
                                                <BadgeCard
                                                    backgroundColor ={"white"}
                                                    image={(this.state.badgeIcon!== undefined && this.state.badgeIcon!== '')?  this.state.badgeIcon :  badgeStart } 
                                                    avatarImage = {undefined}
                                                    title = {"Milestone:"}
                                                    subtitle = { this.state.badgeName !== undefined ?  this.state.badgeName :  'Comment and reply' }
                                                    strongSubtitle = {true}
                                                    //onClick={ () => {this.handlerOpenBadgeDetails(( this.state.badge!== undefined ? this.state.badge.family===undefined ?  'xp_social': this.state.badge.family  : 'xp_social' ))} }
                                                    onClick={() => {this.setState({handleOpenTutorial: true})}}
                                                    clickText= {undefined}
                                                    button={undefined}
                                                />
                                            }
                                            { this.state.badgeIcon === undefined && this.state.badgeIconTotal!== undefined 
                                            &&
                                                <BadgeCard
                                                    backgroundColor ={"white"}
                                                    image={ (this.state.badgeIconTotal!== undefined && this.state.badgeIconTotal!== '')?  this.state.badgeIconTotal :  badgeStart } 
                                                    avatarImage = {undefined}
                                                    title = {"Milestone:"}
                                                    subtitle = {this.state.badgeNameTotal !== undefined ?  this.state.badgeNameTotal :  'Comment and reply'}
                                                    strongSubtitle = {true}
                                                    //onClick={ () => {this.handlerOpenBadgeDetails((this.state.badgeTotal!== undefined ? this.state.badgeTotal.family===undefined ?  'total': this.state.badgeTotal.family  : 'total'))} }
                                                    onClick={() => {this.setState({handleOpenTutorial: true})}}
                                                    clickText= {undefined}
                                                    button={undefined}
                                                />
                                            }
                                        </div>
                                        <DialogCardBadges 
                                            handleOpen={this.state.dialogCardOpen || false}
                                            handleClose={() => {this.setState({dialogCardOpen: false})}}
                                            badgeFamilyDetailSelection= {this.state.handleBadgeFamily}
                                            />
                                            */
                                        }
                                        {user !== null && leadershipQualities.length > 0 && true // screen!=="desktop"
                                        &&     
                                            this.renderTabs()        
                                        }
                                        
                                    </Card>
                                    
                                    <Card style={{
                                            ...theme.CardContent,
                                            paddingLeft: screen=== "desktop" ? 0 : 0,
                                            paddingRight: screen=== "desktop" ? 0 : 0,
                                            marginTop: this.state.headerVisible? 0 : 200,
                                            paddingBottom: this.state.headerVisible? 0 : '30vh',
                                        }} >
                                        
                                        {user !== null && leadershipQualities.length > 0
                                        ?
                                            <div>
                                                <div value={"Discuss"}  index={0} hidden={value !== "Discuss"}>
                                                    {(value==='Discuss' || this.state['Discuss']) &&
                                                        <RepliesOnContents 
                                                            user={user} 
                                                            defaultSet={this.props.defaultSet}
                                                            loadExtraContentList = {this.state.availableContentComments !== undefined  ? this.state.availableContentComments : []}
                                                            commentKey = {'commentFavorites'}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            rerender={value !== "Discuss"? false : true}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Comments"}  index={0} hidden={value !== "Comments"}>
                                                    {(value==='Comments' || this.state['Comments']) &&
                                                        <RepliesOnContents 
                                                            user={user} 
                                                            defaultSet={this.props.defaultSet}
                                                            loadExtraContentList = {this.state.availableContentComments !== undefined  ? this.state.availableContentComments : []}
                                                            commentKey = {'commentComments'}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            rerender={value !== "Comments"? false : true}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Replies"}  index={0} hidden={value !== "Replies"}>
                                                    {(value==='Replies' || this.state['Replies']) &&
                                                        <RepliesOnContents 
                                                            user={user}
                                                            defaultSet={this.props.defaultSet}
                                                            loadExtraContentList = {this.state.availableContentReplies !== undefined  ? this.state.availableContentReplies : []}
                                                            commentKey = {'commentResponses'}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            rerender={value !== "Replies"? false : true}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Primary Quest"}  index={0} hidden={value !== "Primary Quest"}>
                                                    {(value==='Primary Quest' || this.state['Primary Quest']) &&
                                                        <RepliesOnContents 
                                                            user={user} 
                                                            defaultSet={this.props.defaultSet}
                                                            commentKey = {'commentQuality'}
                                                            commentQuality = {user.currentLeadershipQuality}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            rerender={value !== "Primary Quest"? false : true}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Other Quests"}  index={0} hidden={value !== "Other Quests"}>
                                                    {(value==='Other Quests' || this.state['Other Quests']) &&
                                                        <RepliesOnContents 
                                                            user={user} 
                                                            defaultSet={this.props.defaultSet}
                                                            commentKey = {'commentQuality'}
                                                            commentQuality = {user.todaysLeadershipQuality}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            rerender={value !== "Other Quests"? false : true}
                                                            />
                                                    }
                                                </div>
                                                <div value={"Program"}  index={0} hidden={value !== "Program"}>
                                                    {(value==='Program' || this.state['Program']) &&
                                                        <RepliesOnContents 
                                                            user={user} 
                                                            defaultSet={this.props.defaultSet}
                                                            commentKey = {'commentCategory'}
                                                            commentCategory = {user.currentCategory}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            rerender={value !== "Program"? false : true}
                                                            />
                                                    }
                                                </div>

                                                {(value==='Discuss' || this.state['Discuss']) &&
                                                    <SnackbarInfo 
                                                        alertText = 'You can see here latest public comments of other people who share your bookmarked content. You can start discussions in the content section  as well.'
                                                        alertName = 'communityintro'
                                                        userId = {this.context.uid}
                                                        />
                                                }
                                            </div>
                                        :
                                            <div>
                                                <p >You can read answers, comments, and questions from other like-minded people who share the same content bookmarks.</p>
                                            </div>
                                        }
                                    </Card>
                                </Card> 
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 2 : false} ></Grid>
                </Grid>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )
        } // end else
    }// end render

    getLatestBatch(){
        SetStateLoadingBatches(this, this.props.firebase, this.context.uid, 
            'xp_social', 10)
    }
    getLatestBatchOld() {
        var foundBatch = false;
        var foundBatchTotal = false;
        this.props.firebase.journeyBadges(this.context.uid)
        .where('active', '==', true)
        .where('completed', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(10)
        .get()
        .then((snapshot) => {
            if (snapshot.size) {
                snapshot.forEach(doc => {
                    //console.log(doc.data())
                    if (!foundBatch){
                        var badgeName = doc.data().description;
                        var badgeIcon = doc.data().iconUrl;
                        if(badgeIcon !== undefined && badgeIcon !== '' && doc.data().category !== 'total'){
                            this.setState({badgeName, badgeIcon, badge: doc.data()})
                            foundBatch = true
                        }
                    }
                    if (!foundBatchTotal){
                        var badgeNameTotal = doc.data().description;
                        var badgeIconTotal = doc.data().iconUrl;
                        if(badgeIconTotal !== undefined && badgeIconTotal !== '' && doc.data().category === 'total'){
                            this.setState({badgeNameTotal, badgeIconTotal, badgeTotal: doc.data()})
                            foundBatchTotal = true
                        }
                    }
                })
            }
        })
    }

    handlerOpenBadgeDetails = (badgeFamily) => {
        if(badgeFamily !== undefined && badgeFamily!== "" && badgeFamily!== "all" && this.props.badgeFamilyDetailSelection === undefined){
            this.setState({ 
                handleBadgeFamily:badgeFamily,
            });
            this.setState({ 
                handleBadgeFamily:badgeFamily,
                dialogCardOpen: true,
            });
        }
        else{
            this.setState({ 
                handleBadgeFamily:'all',
                handleOpen: true,
            });

        }
    }
    nextUnlockCard = (title, points) => {
        const {theme} = this.props;
        return(
            <Card style={theme.CardDialogueButton}  onClick={() => {this.handlerOpenBadgeDetails((  'total' ))} }>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs={3} >
                    <div style={{
                        height: theme.CardDialogueButton.height, 
                        maxHeight:theme.CardDialogueButton.maxHeight}}>
                        <span>
                            <img src={ badgeStart} 
                            style={{maxWidth:'100%', maxHeight: '100%', height: 'auto', verticalAlign: 'middle'}} 
                            alt={ 'Next Unlock'}  />
                        </span>
                    </div>
                    </Grid>
                    <Grid item xs={9}>
                        <span style={theme.CardContentItem.TextSubTitle}> {title} </span>
                    </Grid>
                </Grid>
            </Card>
        )
    }

    renderTabs(orientation = "horizontal") {
        const { value  } = this.state;
        const { user, leadershipQualities, leadershipCategories, theme, screen} = this.props;
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/>Home</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.MESSAGES} onClick={() => (this.handleChange(undefined, "Discuss"))}>
                        <span><GroupIcon style={theme.inlineIconLeft}/>Discuss</span>
                    </Link>
                    {(true || value !== "Discuss") && // Because all are discuss
                        <>
                            {value === "Discuss"
                            ?
                                <Link onClick={() => (this.handleChange(undefined, value))}>
                                    <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}> Bookmarks </li>
                                </Link>
                            : 
                                <Link onClick={() => (this.handleChange(undefined, value))}>
                                    <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}> {value} </li>
                                </Link>
                            }
                        </>
                    }
                </>
            )
        }
        else{
            return(
                <>
                {user !== null && leadershipQualities!== undefined && leadershipCategories!== undefined  && leadershipQualities.length > 0
                    ?  
                        <Tabs
                            value={value}
                            onChange={this.handleChange}
                            orientation={orientation}
                            aria-label="Coaching Modules"
                            variant="scrollable"
                            scrollButtons="auto"
                            initialselectedindex={"Discuss"}
                            style={{
                                marginTop: 20,
                                marginBottom: (screen==="desktop" ? 0 : -1),
                                marginLeft: -20,
                                width: 'calc(100% + 40px)'
                            }}
                            ref={el => this.containerLine = el}
                        >
                            {false && this.props.screen==="desktop" && 
                                <Tab value="Home" label={<span><HomeIcon style={theme.inlineIconLeft}/>Home</span>}/>
                            }
                            <Tab value={"Discuss"}
                                label={'My Bookmarks'}
                                /> 
                            <Tab value={"Comments"}
                                label={
                                    <Badge badgeContent={ this.context.newMessages!== undefined &&  this.context.newMessages.filter(q => q.group === "comment").length ?  this.context.newMessages.filter(q => q.group === "comment").length : 0 }
                                        color="error">
                                            My Comments
                                    </Badge>
                                    }
                                />
                            {this.state.availableContentReplies?.length>0
                                &&
                                <Tab value={"Replies"}  
                                    label={
                                    <Badge badgeContent={ this.context.newMessages!== undefined &&  this.context.newMessages.filter(q => q.group === "reply").length ?  this.context.newMessages.filter(q => q.group === "reply").length : 0 }
                                        color="error">
                                            My Replies
                                    </Badge>
                                    }
                                />
                            }
                            <Tab value={"Program"} 
                                label = {FindTextValueInDict(this.props.firebase, leadershipCategories, user.currentCategory)} />
                            <Tab value={"Primary Quest"} 
                                label = {FindTextValueInDict(this.props.firebase, leadershipQualities, user.currentLeadershipQuality)} />
                            {user.todaysLeadershipQuality !==undefined && user.todaysLeadershipQuality !== "" &&
                                <Tab value={"Other Quests"} 
                                    label = {FindTextValueInDict(this.props.firebase, leadershipQualities, user.todaysLeadershipQuality)} />
                            }
                        </Tabs>                 
                    :   "loading groups"
                } 
                </>
            )
        }
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(HomeCommunity);
  
