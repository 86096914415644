import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

import VizSensor from 'react-visibility-sensor';
import * as ROUTES from '../../constants/routes';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
//import HomeCardContentItems from '../Content/HomeCardContentItems';
import {SetNewQuality} from '../Goals/TopicSuggestions';
import JournalActivities from '../Days/JournalActivities';
import {freeTopics, CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';
import DialogSettingPlan from '../Profile/DialogSettingPlan';

import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TodayAdd from '../Home/TodayAdd';
import WordCloudItem from '../Days/WordCloudItem';
import TopicSuggestions from '../Goals/TopicSuggestions';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfiniteContent from '../Content/InfiniteContent';
import AssessmentSlider from '../Charts/AssessmentSlider';
import AssessmentTimeline from '../Charts/AssessmentTimeline';
import {AvatarCard} from '../Modules/AvatarImage';
//import ContentSuggestCarousel from '../Content/ContentSuggestCarousel';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';

/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Filter2Icon from '@material-ui/icons/Filter2';
import StarsIcon from '@material-ui/icons/Stars';
import {ExtendDialogueMessage} from '../Routine/DiaryManual';


//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
});

class ContentEngagementOverview extends Component { 
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            statsList : {},
            newContentSets : {},
            newContentIds : [],
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: false,
        });
        if (this.props.selectedItem?.length>2){
            this.loadEngagements(this.props.selectedItem);
        }
    }
    componentWillUnmount(){
        this._isMounted = false;
        if(this.subscribeDialogue !== undefined) this.subscribeDialogue();
    }

    loadEngagements(quality){
        //console.log("loadEngagements", this.props.user)
        if(quality!== undefined && quality!== null && quality!=='' && this._isMounted){
            var now = new Date();
            var keyWordsUser = [];
            var keyWordsList = {};
            var keyWordsListCoach = {};
            var wordCloud = {'user':[], 'coach':[], 'userExtra':[]};
            var keyWordsCoach = [];
            //var selectDate = new Date(engagementDate);
            var selectDayUntil = new Date(now);
            var statsList = this.state.statsList;
            this.props.firebase
            .insightsEngagement()
            .where('userId', '==', this.props.user.uid)
            .where('leadership_quality', 'array-contains', quality)
            //.where('createdAt', '<=', selectDayUntil)
            .orderBy('createdAt', 'desc')
            .get().then(snapshot => {
                if(this._isMounted ){
                    if (snapshot.size) {
                        var oldContentIds = [];
                        if(this._isMounted){
                            this.setState({loading:true});
                        }
                        if (statsList[quality] === undefined ) statsList[quality] = {}; 
                        snapshot.forEach(doc => {
                            var engagementSet = doc.data();
                            if (statsList[quality][engagementSet.type] === undefined ) statsList[quality][engagementSet.type] =  []; 
                            statsList[quality][engagementSet.type].push(engagementSet)
                            if(engagementSet.type==="content" && engagementSet.contentId!==undefined && engagementSet.contentId!==''){
                                if(engagementSet.contentId!== undefined && !oldContentIds.includes(engagementSet.contentId)){
                                    oldContentIds.push(engagementSet.contentId)
                                }
                            }
                            if(engagementSet.type==="chat" && engagementSet.keyWordsUser!==undefined){
                                if(engagementSet.keyWordsUser[0]!==undefined){
                                    //keyWordsUser = keyWordsUser.concat(Object.keys(engagementSet.keyWordsUser[0]))
                                    for(var [key,value] of Object.entries(engagementSet.keyWordsUser[0])){
                                        key = key.trim();
                                        if(key!==""){
                                            if(keyWordsList[key] !== undefined ) keyWordsList[key]  = keyWordsList[key]  + value;
                                            else keyWordsList[key] = value;
                                        }
                                    }
                                }

                                if( engagementSet.keyWordsCoach!==undefined && engagementSet.keyWordsCoach[0]!==undefined){
                                    //keyWordsCoach = keyWordsCoach.concat(Object.keys(engagementSet.keyWordsCoach[0]))
                                    for(var [key2,value2] of Object.entries(engagementSet.keyWordsCoach[0])){
                                        key2 = key2.trim();
                                        if(keyWordsListCoach[key2] !== undefined ) keyWordsListCoach[key2]  = keyWordsListCoach[key2]  + value2;
                                        else keyWordsListCoach[key2] = value2;
                                    }
                                }
                                keyWordsUser = Object.keys(keyWordsList).sort(function(a,b){return keyWordsList[a]-keyWordsList[b]})
                                keyWordsCoach = Object.keys(keyWordsListCoach).sort(function(a,b){return keyWordsListCoach[a]-keyWordsListCoach[b]})
                                if(keyWordsCoach.length>8) wordCloud['coach'] = keyWordsCoach.splice(keyWordsCoach.length-9, keyWordsCoach.length-1)
                                if(keyWordsUser.length>8) wordCloud['user'] = keyWordsUser.splice(keyWordsUser.length-9, keyWordsUser.length-1)
                                if(keyWordsUser.length>16) wordCloud['eserExtra'] = keyWordsUser.splice(keyWordsUser.length-17, keyWordsUser.length-9)
                            }
                            //console.log('data', data);
                        }); // end of snapshot
                        if(oldContentIds.length>10){oldContentIds.slice(0,9)}
                        statsList[quality]['wordCloud'] = wordCloud;
                        statsList[quality]['oldContentIds'] = oldContentIds;
                        if(this._isMounted ){
                            this.setState({statsList, oldContentIds});
                        }
                    } 
                }//end is mounted
                //console.log("created data set", statsList, wordCloud, keyWordsCoach, keyWordsUser);
            });
        }
    }
    
    loadLatestDialogue(){
        this.subscribeDialogue = this.props.firebase
        .dialogues()
        .where('userId', '==', this.props.user.uid)
        .orderBy('createdAt', 'desc')
        .limit(4)
        .onSnapshot(snapshot => {
            snapshot.forEach(doc =>{
                var newContentSets = this.state.newContentSets;
                var previousContentIds = this.state.newContentIds;
                var newContentIds = [...previousContentIds];
                var currentDialogue = doc.data();
                if(currentDialogue !== undefined && currentDialogue.contentSelection !== undefined && currentDialogue.contentSelection.length > 0){
                    currentDialogue.contentSelection.map( (item) => {
                        if(item !== undefined && item.contentId !== undefined && !(newContentIds.includes(item.contentId))){
                            if(this.props.type === 'category' && item.leadership_category !== undefined ){
                                if(newContentSets[item.leadership_category] !== undefined){
                                    newContentSets[item.leadership_category].unshift(item);
                                }
                                else{
                                    newContentSets[item.leadership_category] = [item];
                                }
                                newContentIds.push(item.contentId )
                                console.log('content', item);
                            }
                            else if(item.leadership_quality !== undefined ){
                                item.leadership_quality.forEach(itemQuality => {
                                    if(newContentSets[itemQuality] !== undefined){
                                        newContentSets[itemQuality].unshift(item);
                                    }
                                    else{
                                        newContentSets[itemQuality] = [item];
                                    }
                                })
                                newContentIds.push(item.contentId)
                                //console.log('content', item);
                            }
                        }
                        return true;
                    })
                }
                if(this._isMounted && newContentSets !== undefined && newContentIds.length !== previousContentIds.length){ 
                    //console.log('newContentSets', newContentSets);
                    if( newContentSets.length>10) {newContentSets.slice(0,9)}
                    this.setState({newContentSets, newContentIds})
                }
            })
        })
    }

    render(){
        const { theme } = this.props;
        //console.log('props', this.props)
        return(
            <div>
                <DialogTutorialPage 
                    handleOpen={this.state.handleOpenTutorial || false}
                    handleBadgeFamily = {'path'}
                    handleClose={() => {this.setState({handleOpenTutorial: false})}}
                />
                <DialogSettingPlan 
                    defaultSet={this.props.defaultSet || undefined}
                    user={this.props.user}
                    handleOpen={this.state.dialogSettingPlanOpen}
                    premiumOnly={false}
                    purchaseSuccessFlag={() => {
                        if(this.props.handlerPurchase !== undefined){
                            this.props.handlerPurchase();
                        }
                        this.setState({nonSubscriber: false, premiumSubscriber: true})
                    }}
                    infoText={'Please subscribe to a plan in order to activate this module.'}
                    purchaseSuccessHandler={() => {
                        if(this.props.handlerPurchase !== undefined){
                            this.props.handlerPurchase();
                        }
                        this.setState({dialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                    }}
                    handleClose={() => {
                        if(this.props.handlerPurchase !== undefined){
                            ///// JUST FOR TESTING the FLAG
                            //this.props.handlerPurchase();
                        }
                        //console.log('nonSubscriber')
                        this.setState({dialogSettingPlanOpen : false})
                    }}
                />
                {this.renderQualityOverview( )}
            </div>
        )// end return
    }//end render

    renderQualityOverview() {
        const { theme , engagementStats} = this.props;
        var {newContentSets} = this.state;
        //console.log('ready to render qualities', this.props, this.state)
        //Contentids {this.state.lastContent}
        if(this.props.selectedItem!== undefined && this.props.selectedItem!== null && this.props.selectedItem!=='' ){
            return(
                <div style={{marginTop: 20}}>
                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <AvatarCard 
                                key={this.props.selectedItem} 
                                dict={this.props.type === 'category' ? this.props.leadershipCategories : this.props.leadershipQualities} 
                                type={this.props.type || 'quality'}
                                item={this.props.selectedItem} 
                                squareSize='40' 
                            />
                        </Grid>
                        <Grid item>
                            {this.props.nonSubscriber && this.state.nonSubscriber !== true && this.props.type === 'quality' &&
                                <div style={{width:'100%', textAlign:'right'}}>
                                    <strong>
                                        {FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.selectedItem, 'program') === 'default' ?
                                                <>
                                                    <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeBlue}} />
                                                    Essentials
                                                </>
                                            :
                                                <>
                                                    <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeAltGold}} />
                                                    Premium
                                                </>
                                        }
                                        {freeTopics.includes(this.props.selectedItem)
                                            ?   <span>&nbsp;(Free)</span>
                                            :   this.props.trialPeriod && this.props.nonSubscriber
                                                ?   <span>&nbsp;(Trial)</span>
                                                :   ''
                                        }
                                    </strong>
                                </div>
                            }  
                        </Grid>
                    </Grid>
                    {true && engagementStats?.['leadership_quality']?.[this.props.selectedItem]?.["points"] > 0
                    && 
                        <div style={{width: '100%', textAlign: 'right', fontSize: 'small', marginTop: 20}}> 
                            <span style={{width: '100%', textAlign: 'right', fontSize: 'small'}}>
                                {engagementStats?.['leadership_quality']?.[this.props.selectedItem]?.["points"] > 0
                                    &&
                                    " (" + 
                                        Math.min(Math.round(engagementStats['leadership_quality'][this.props.selectedItem]["points"] / this.props.engagementBenchmarkQuality * 100 ), 100)
                                        + "% completed)"
                                                
                                }
                            </span>
                            <LinearProgress 
                                variant="determinate" 
                                style={{
                                    width:'100%',
                                    height: 5,
                                }}
                                value={
                                    engagementStats?.['leadership_quality']?.[this.props.selectedItem]?.["points"]
                                        ?   Math.min((Math.round(engagementStats['leadership_quality'][this.props.selectedItem]["points"] / this.props.engagementBenchmarkQuality * 100)), 100)
                                        :   0
                                    } 
                                color="secondary" 
                                />
                        </div>
                    }
                    <h4>
                        {this.props.title || FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.selectedItem, 'explanationShort')}
                    </h4>
                    <p>
                        {this.props.text || FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.selectedItem, 'definition')}
                    </p>
                    {this.props.showChatBoot && this.props.type === 'quality' &&
                        <TodayAdd
                            user={this.props.user}
                            defaultSet={this.props.defaultSet || undefined}
                            actionCardOnly={true}
                            handlerContinue={() => {
                                var subscriberStatus = CheckSubscriptionStatus(this.props.user);
                                if(false && !freeTopics.includes(this.props.selectedItem) && this.state.nonSubscriber !== false && (subscriberStatus.nonSubscriber && !subscriberStatus.trialPeriod) ){
                                    this.setState({
                                        dialogSettingPlanOpen: true
                                    });
                                    //console.log('npnosubsriber', subscriberStatus)
                                }
                                else{
                                    if(this.props.selectedItem !== this.props.user?.currentLeadershipQuality){
                                        SetNewQuality(this.props, this.props.selectedItem, (FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.selectedItem, 'category') || this.props.user.currentCategory), ('Let us start today with: '+this.props.title ));
                                    }
                                    this.props.history.push({
                                        pathname: ROUTES.ROUTINE,
                                        state: {
                                            openCurrentAchievementGoal: false, 
                                            guidedChat: true,
                                        }
                                    });
                                }
                            }}
                            leadershipCategories={this.props.leadershipCategories}
                            leadershipQualities={this.props.leadershipQualities} 
                        />
                    }
                    {false && this.props.type === 'quality' && this.props.selectedItem!== undefined && this.props.selectedItem !== "" && this.props.user !== undefined
                        && this.props.selectedItem !== this.props.user.currentLeadershipQuality
                        //&& this.props.user.roles!==undefined 
                        //&& (!this.props.user.roles.includes("COACHEE"))
                        &&
                        <div style={{paddingBottom:20, paddingTop:20, marginLeft: 0, marginRight:0}}>
                            <Accordion TransitionProps={{ mountOnEnter: true }} style={{borderRadius: theme.borderRadius}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="NotificationExpand"
                                    aria-controls="Notifications-content"
                                    id="Notifications-header"
                                    >
                                    {(this.props.user.todaysLeadershipQuality === this.props.selectedItem
                                        || (this.props.user!==undefined && this.props.user.roles!==undefined && this.props.user.roles.includes("COACHEE")))
                                        ?
                                            <FormControlLabel
                                                aria-label="Make Main"
                                                control={<StarsIcon  style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                                label="Change to Main Module"
                                            />
                                        :
                                            <FormControlLabel
                                                aria-label="Add/Remove"
                                                control={<Filter2Icon  style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                                label="Add or Remove Module"
                                            />
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{width: '100%'}}>
                                        <p><strong>{this.props.title}</strong></p>
                                        {false && this.props.user.todaysLeadershipQuality === this.props.selectedItem
                                            ?
                                                <div>
                                                    <p>You can activate "{this.props.title}" for the next chat with your AI Coach. Choose an option:</p>
                                                    <Grid container spacing={0} direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item >
                                                            <Button onClick={ () => {this.addNewMain(this.props.selectedItem, this.props.title)}}>
                                                                Main Module &nbsp;<StarsIcon/>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item >
                                                            <Button onClick={ () => {this.addTodaysTopics(this.props.selectedItem, this.props.title)}}>
                                                                Second Module &nbsp;<Filter2Icon/>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            :
                                                <div>
                                                    <p>Do you want to set a new main module?</p>
                                                    <TopicSuggestions 
                                                        user={this.props.user}
                                                        defaultSet={this.props.defaultSet || undefined}
                                                        leadershipCategories={this.props.leadershipCategories}
                                                        leadershipQualities={this.props.leadershipQualities} 
                                                        proposedTopic={this.props.selectedItem}
                                                        handlerCloseAll={this.props.handleClose}
                                                        nonSubscriber={(this.props.nonSubscriber === false || this.state.nonSubscriber === false) ? false : true}
                                                        handlerPurchase={() => {
                                                            this.setState({nonSubscriber: false});
                                                            if(this.props.handlerPurchase !== undefined){
                                                                this.props.handlerPurchase();
                                                            }
                                                        }}
                                                        handlerContinue={this.props.handlerContinue? this.props.handlerContinue : undefined}
                                                        />
                                                </div>
                                        }
                                        {(this.props.user===undefined)
                                            ?   ''
                                            :   this.props.user?.todaysLeadershipQuality === this.props.selectedItem 
                                                ?
                                                    <>
                                                        <br/><br/><p>or remove it from your personal Coaching Plan:</p>
                                                        <Button variant="outlined" color="secondary" onClick={ () => this.removeTodaysTopic()}>
                                                            Remove Module &nbsp;<DeleteForeverIcon/>
                                                        </Button>
                                                    </>
                                                :   this.props.user.todaysLeadershipQuality !== this.props.selectedItem 
                                                    && this.props.userQualityPath !== undefined && this.props.userQualityPath.length > 2
                                                    ?
                                                        <>
                                                            <br/><br/><p>or remove it from your personal Coaching Plan:</p>
                                                            <Button variant="outlined" color="secondary" onClick={ () => {this.removeTopicFromPath(this.props.selectedItem, this.props.title)}}>
                                                                Remove Module &nbsp;<DeleteForeverIcon/>
                                                            </Button>
                                                        </>
                                                    :   ''
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>   
                        </div>
                    }
                    {this.props.type === 'quality' && this.props.selectedItem!== undefined && this.props.selectedItem !== "" && this.props.user !== undefined
                        && (this.props.user?.programs?.includes("default") || this.props.user?.programs?.length > 1)
                        && this.props.selectedItem !== this.props.user.currentLeadershipQuality && 
                        !(this.props.user?.roles?.includes("COACHEE")) && this.props.userQualityPath?.length>2 &&
                        <>
                            {this.props.user.todaysLeadershipQuality === this.props.selectedItem 
                            ?
                                <div style={{paddingBottom:20, paddingTop:20, marginLeft: 0, marginRight:0}}>
                                    <Button variant="outlined" color="secondary" 
                                        onClick={ () => {
                                            if(this.props.userQualityPath?.length>2 && this.props.userQualityPath?.includes(this.props.selectedItem)){
                                                //console.log('path', this.props.userQualityPath, this.props.selectedItem)
                                                this.props.firebase.user(this.props.user.uid).update({todaysLeadershipQuality: this.props.firebase.fieldValue.delete()});
                                                this.removeTopicFromPath(this.props.selectedItem, this.props.title)
                                            }
                                            else{
                                                //console.log('n po path', this.props.userQualityPath, this.props.selectedItem)
                                                this.removeTodaysTopic();
                                            }
                                        }}
                                    >
                                        Hide Module &nbsp;<DeleteForeverIcon/>
                                    </Button>
                                </div>
                            :
                                <div style={{paddingBottom:20, paddingTop:20, marginLeft: 0, marginRight:0}}>
                                    <Button variant="outlined" color="secondary" onClick={ () => {this.removeTopicFromPath(this.props.selectedItem, this.props.title)}}>
                                        Hide Module &nbsp;<DeleteForeverIcon/>
                                    </Button>
                                </div>
                            }
                        </>
                    }
                    {this.props.showActivities && this.props.selectedItem?.length>2 && this.props.user !== undefined
                        &&
                        <div style={{paddingBottom:20, paddingTop:20, marginLeft: -20, marginRight:-20, width: 'calc(100% + 40px)'}}>
                            <JournalActivities 
                                leadershipCategories={this.props.leadershipCategories}
                                leadershipQualities={this.props.leadershipQualities} 
                                specialQuery={'contentByQuality'}
                                historyLimit={3}
                                quality = {this.props.selectedItem}
                                user={this.props.user} 
                                checkMode={true}
                                groupItems={true}
                            />
                        </div>
                    }
                    {this.props.showTimeline && this.props.selectedItem!== undefined && this.props.selectedItem !== "" && this.props.user !== undefined
                        &&
                        <div style={{paddingBottom: 0, marginTop: 20, marginBottom: 20, paddingTop: 0, marginLeft: 0, marginRight: 0}}>
                            <Accordion TransitionProps={{ mountOnEnter: true }} style={{borderRadius: theme.borderRadius}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="timeline"
                                    aria-controls="timeline-content"
                                    id="timeline-header"
                                >
                                    <FormControlLabel
                                        aria-label="Timeline and Comments"
                                        control={<EventNoteIcon  style={{...theme.cardSettings.iconDesign, paddingRight: 6}}/>}
                                        label="Timeline and Comments"
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={0} direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item xs={12} style={{marginTop: 60, textAlign: 'center'}}>
                                            <div>
                                                <AssessmentSlider
                                                    circular = {true}
                                                    user = {this.props.user }
                                                    assessmentKey = {this.props.selectedItem}
                                                    assessmentText = {this.props.title || FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.selectedItem, 'title')}
                                                    assessmentQuestion = 'How satisfied are you currently with:'
                                                    //updateScore = { (score) => { if (this.props.updateScore !== undefined) this.props.updateScore(score) } }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop: 60}}>
                                            <AssessmentTimeline
                                                user = {this.props.user }
                                                assessmentKey = {this.props.selectedItem}
                                                assessmentText = {this.props.title || FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.selectedItem, 'title')}
                                                assessmentQuestion = 'How satisfied are you currently with:'
                                                //updateScore = { (score) => { if (this.props.updateScore !== undefined) this.props.updateScore(score) } }
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    }
                    {this.props.showContent && (newContentSets!== undefined || this.props.selectedItem?.length > 1)
                    &&
                        <VizSensor 
                            partialVisibility={true} 
                            active={ this.state.isVisible !== true ? true : false}
                            onChange={(isVisible) => { 
                                if(isVisible &&  this._isMounted) { 
                                    this.setState({isVisible: true});
                                }
                            }} >
                            <div style={{marginTop: 40, minHeight: '80vh', position: 'relative'}}>
                            {this.state.isVisible &&
                                <InfiniteContent 
                                    user = {this.props.user}
                                    defaultSet={this.props.defaultSet || undefined}
                                    scrollIntoViewDisabled = {true}
                                    screenWidth = {(this.props.screenWidth!== undefined && this.props.screenWidth>=1200) ? this.props.screenWidth : undefined}
                                    visualLimit = {8}
                                    hideLimitPrompt = {true}
                                    overWriteQualityValue = {this.props.selectedItem || undefined}
                                    raised = {true}
                                    resultOfContentSearch = {
                                        newContentSets !== undefined && newContentSets[this.props.selectedItem] !== undefined && newContentSets[this.props.selectedItem].length > 0
                                            ?   newContentSets[this.props.selectedItem].map(a => a.contentId)
                                            :   undefined
                                        }
                                    filterQuality = {
                                        (this.state.responseContent?.length > 0 || this.state.responseRecommendation?.length > 0 || this.state.dialogue?.contentSelection?.length > 0 )
                                            ?   undefined
                                            :   this.props.selectedItem
                                        }
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    rerender={this.props.handleOpen || false}
                                />
                            }
                        </div>
                        </VizSensor> 
                    }
                    { false && this.state.statsList !== undefined && this.state.statsList[this.props.selectedItem] !== undefined && this.state.statsList[this.props.selectedItem]['wordCloud'] !== undefined
                        &&
                            <WordCloudItem 
                                title= {'WordCloud'}
                                tagsPrimary={this.state.statsList[this.props.selectedItem]['wordCloud']['user']}
                                tagsSecondary={this.state.statsList[this.props.selectedItem]['wordCloud']["userExtra"]}
                                tagsThird={this.state.statsList[this.props.selectedItem]['wordCloud']['coach']}
                            />
                    }
                </div>
            )
        }
    }
    
    addTodaysTopics(quality, title){
        if(quality !== undefined && quality !== ''){
            this.props.firebase.user(this.props.user.uid).update({
                todaysLeadershipQuality: quality
            });
            ExtendDialogueMessage(this.props.firebase, this.props.user, 'I also want to change: '+title, "What are you currently interested in?", 'utter_reflection_topic', '', 'profile');
            this.props.handleClose();
            if(this.props.handlerContinue){ 
                this.props.handlerContinue();
            }
        }
    }
    addNewMain(quality, title){
        if(quality !== undefined && quality !== ''){
            var oldMainQuality = this.props.user.currentLeadershipQuality;
            var setCategory = this.props.user.currentCategory;
            var newCategory = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, quality, 'category');
            //console.log('newCategory', newCategory)
            if(newCategory!==undefined && newCategory!== ''){
                setCategory = newCategory;
            }
            this.props.firebase.user(this.props.user.uid).update({
                currentLeadershipQuality: quality,
                todaysLeadershipQuality: oldMainQuality,
                currentCategory: setCategory
            });
            ExtendDialogueMessage(this.props.firebase, this.props.user, 'Most important is: '+title, "What are you currently interested in?", 'utter_reflection_topic', '', 'profile');
            if(this.props.user !== undefined && quality !== undefined && quality !== '' && this.props.userQualityPath !== undefined){
                if(this.props.user.currentLeadershipQuality !== undefined && this.props.user.currentLeadershipQuality!== ''){
                    var growthPathItems = [quality];
                    growthPathItems[1] = oldMainQuality;
                    this.props.userQualityPath.map( quality => {
                        if(quality.value !== undefined && quality.value !== ''){
                            var qualityValue = quality.value;
                            if(!growthPathItems.includes(qualityValue)){
                                growthPathItems.push(qualityValue)
                            }
                        }
                        return true;
                    })
                    if(growthPathItems.length >= 2){
                        this.setTopicsRanking(growthPathItems);
                    }
                    //console.log('new quaity sorting', growthPathItems)
                    this.props.handleClose();
                }
            }
            if(this.props.handlerContinue){ 
                this.props.handlerContinue();
            }
        }
    }
    removeTodaysTopic(){
        this.props.firebase.user(this.props.user.uid).update({todaysLeadershipQuality: this.props.firebase.fieldValue.delete()})
        if(this.props.handlerContinue){ 
            this.props.handlerContinue();
        }
        this.props.handleClose();
    }
    removeTopicFromPath(removeQualityItem, title){
        if(this.props.user !== undefined && removeQualityItem !== undefined && removeQualityItem !== '' && this.props.userQualityPath !== undefined && this.props.userQualityPath.length > 1){
            if(this.props.user.currentLeadershipQuality !== undefined && this.props.user.currentLeadershipQuality!== ''){
                var growthPathItems = [this.props.user.currentLeadershipQuality];
                //console.log('current path', this.props.userQualityPath)
                if(this.props.user?.todaysLeadershipQuality?.length > 2 && this.props.user?.todaysLeadershipQuality !== removeQualityItem){
                    growthPathItems[1] = this.props.user.todaysLeadershipQuality;
                }
                this.props.userQualityPath.map( quality => {
                    if(quality.value !== undefined && quality.value !== ''){
                        var qualityValue = quality.value;
                        if(removeQualityItem !== qualityValue){
                            if(!growthPathItems.includes(qualityValue)){
                                growthPathItems.push(qualityValue)
                            }
                        }
                    }
                    return true;
                })
                if(growthPathItems.length >= 1){
                    this.setTopicsRanking(growthPathItems);
                }
                //console.log('new quaity sorting', growthPathItems)
                if(this.props.handlerContinue){ 
                    this.props.handlerContinue();
                }
                this.props.handleClose();
            }
        }
    }

    setTopicsRanking(newQualityList){
        var profileQualityUpdate = {
            additionalLeadershipQuality1: this.props.firebase.fieldValue.delete(),
            additionalLeadershipQuality2: this.props.firebase.fieldValue.delete(),
            additionalLeadershipQuality3: this.props.firebase.fieldValue.delete(),
            additionalLeadershipQuality4: this.props.firebase.fieldValue.delete(),
            additionalLeadershipQuality5: this.props.firebase.fieldValue.delete(),
            additionalLeadershipQuality6: this.props.firebase.fieldValue.delete()
        }
        newQualityList.forEach((value, index) => {
            if (index === 0){
                profileQualityUpdate['currentLeadershipQuality'] = value;
            }
            else if (index === 1){
                //profileQualityUpdate['todaysLeadershipQuality'] = value;
                profileQualityUpdate['todaysLeadershipQuality'] = this.props.firebase.fieldValue.delete();
                profileQualityUpdate['additionalLeadershipQuality1'] = value;
            }
            else if (index < 7){
                profileQualityUpdate['additionalLeadershipQuality'+index] = value;
            }
        })
        //console.log('newQualityList', newQualityList)
        if(newQualityList!== undefined && newQualityList.length >= 1 && this.props.user !== undefined){
            //console.log('profileQualityUpdate', profileQualityUpdate)
            this.props.firebase.user(this.props.user.uid).update(profileQualityUpdate);
        }
        if(this.props.handlerContinue){ 
            this.props.handlerContinue();
        }
    }
};//end class


export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(ContentEngagementOverview);
