export const ADMIN = 'ADMIN';
export const TEAM = 'TEAM';
export const COACHEE = 'COACHEE';
export const OBSERVE = 'OBSERVE';
export const COACHADMIN = 'COACHADMIN';
export const REMOTECOACH = 'REMOTECOACH';


export function AccessCheckCoachCreator(userDict){
    var result = false
    if(userDict?.roles?.includes("COACHADMIN") || userDict?.roles?.includes("ADMIN")){
        return true
    }
    else if(userDict?.purchasedProduct?.creator === true){
        return true
    }
    return result
}
export const AccessCheckCreatorTrialPhase = (userDict) => {
    var trialphase = false;
    if(userDict?.roles?.includes("COACHADMIN")){
        trialphase = true;
    }
    else if(userDict?.seatsMonthlyToGive > 0 || userDict?.seatsToGive > 0){
        trialphase = true;
    }
    //14 days
    else{
        let limitDate = new Date(Date.now() - (15 * 24 * 60 * 60 * 1000));
        let startDate = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000));
        if(userDict?.roles?.includes("COACHADMIN") && userDict?.journeyStartedAt?.seconds !== undefined){
            startDate = new Date(userDict.journeyStartedAt.seconds * 1000);
            //console.log('startdate', startDate)
            if(startDate > limitDate){
                trialphase = true;
            }
        }
    }
    //console.log('AccessCheckCreatorTrialPhase',userDict, startDate, limitDate)
    return trialphase
}
export function AccessUsersPanel(userDict){
    var result = false
    if(userDict?.roles?.includes("COACHADMIN") || userDict?.roles?.includes("ADMIN") || userDict?.roles?.includes("REMOTECOACH")){
        return true
    }
    else if(userDict?.purchasedProduct?.creator === true){
        return true
    }
    else if(userDict?.seatsMonthlyToGive >= 0 || userDict?.seatsToGive >= 0){
        return true
    }
    return result
}